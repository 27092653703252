import {
	Toolbar,
	TextField,
	IconButton,
	LinearProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
	Table,
	Button,
	Page,
	PageHeader,
	PageBody
} from 'components';
import { Query } from 'react-apollo';
import { EVENT_LOGS } from 'graph/queries';

class EventLogs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchString: '',
			sort: 'createdAt',
			dir: 'desc',
			page: 0
		}

		this.sortTable = this.sortTable.bind(this);
	}

	render() {
		return (
			<Page>
				<PageHeader>

				</PageHeader>
				
				<Query query={EVENT_LOGS} variables={{
					searchString: this.state.searchString,
					sort: this.state.sort,
					direction: this.state.dir,
					limit: 10,
					page: this.state.page
				}}>
					{({ loading, error, data }) => {
						return (
							<PageBody loading={loading} error={error} padding={0}>
								{
									loading ? <LinearProgress /> : null
								}
								<Toolbar>
									<TextField
										id="outlined-dense"
										label={i18n.t('general.search')}
										color="secondary"
										onChange={(e) => this.search(e.target.value)}
									/>
								</Toolbar>

								<Table
									page={this.state.page}
									limit={10}
									sort={this.state.sort}
									count={data && data.eventLogs ? data.eventLogs.count : 0}
									direction={this.state.dir}
									onChangeSort={this.sortTable}
									onChangePage={(page) => this.setState({ page })}
									rows={
										(data && data.eventLogs && data.eventLogs.eventLogs) ?
											data.eventLogs.eventLogs.map((eventLog, i) => ({
												user: eventLog.user ? eventLog.user.firstName + ' ' + eventLog.user.lastName : 'Anonymous User',
												ip: eventLog.ip,
												type: eventLog.type,
												createdAt: moment(eventLog.createdAt).fromNow(),
												view: <Link to={{
													pathname: `/eventLogs/${eventLog.id}`,
													state: { title: 'Event Log: ' + eventLog.type }
												}}><IconButton><SearchIcon /></IconButton></Link>
											}))
											: []
									}
									columns={[{
										key: 'user',
										label: 'User'
									}, {
										key: 'ip',
										label: 'IP'
									}, {
										key: 'type',
										label: 'Type',
										sortable: true
									}, {
										key: 'createdAt',
										label: 'Date',
										sortable: true
									}, {
										key: 'view',
										label: ''
									}]} />
							</PageBody>
						);
					}}
				</Query>
			</Page>
		);
	}

	search(value, e) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				searchString: value
			});
		}, 500);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
}

export default EventLogs;