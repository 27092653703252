import { Query, withApollo } from 'react-apollo';
import {
	Loading,
	FullScreenError
} from 'components';
import _map from 'lodash/map';
import getGraphQLError from 'utils/getGraphQLError';

export default function withQuery(WrappedComponent) {
	return class extends Component {
		render() {
			const { query, fetchPolicy, variables, ...passThroughProps } = this.props;

			if (query) {
				return (
					<Query query={query} fetchPolicy={fetchPolicy || 'network-only'} variables={variables}>
						{(queryResult) => {
							if (queryResult.loading) {
								return <Loading />
							}
		
							if (queryResult.error) {
								return <FullScreenError error={queryResult.error} />
							}
							
							return <WrappedComponent {...passThroughProps} queryResult={queryResult} />
						}}
					</Query>
				);
			} else {
				return <WrappedComponent {...passThroughProps} />
			}
		}
	}
}

export const withQueries = function(getQueries) {
	return (WrappedComponent) => {
		class QueryingComponent extends Component {
			constructor(props) {
				super(props);

				this.state = {
					loading: true,
					error: false
				};

				this.queryResult = {};

				this.executeQueries = this.executeQueries.bind(this);
			}

			componentDidMount() {
				this.executeQueries();
			}

			componentDidUpdate(prevProps) {
				if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
					this.executeQueries();
				}
			}

			render() {
				const { client, ...passThroughProps } = this.props;

				if (this.state.loading) {
					return <Loading />
				} else if (this.state.error) {
					return <FullScreenError error={this.state.error} />
				} else {
					return <WrappedComponent {...passThroughProps} queryResult={this.queryResult} refetchQueries={this.executeQueries} />
				}
			}

			executeQueries() {
				const { client } = this.props;

				this.setState({
					loading: true
				});

				let queries = {};

				if (getQueries && typeof getQueries === 'function') {
					queries = getQueries.bind(this)();
				}

				const queryKeys = Object.keys(queries);

				if (queryKeys.length) {
					const promises = [];

					queryKeys.forEach((key) => {
						promises.push(client.query({
							query: queries[key].query,
							variables: queries[key].variables,
							fetchPolicy: queries[key].fetchPolicy || 'network-only'
						}));
					});

					Promise.all(promises).then((res) => {
						const results = {};

						queryKeys.forEach((key, i) => {
							results[key] = res[i].data;
						});

						this.queryResult = results;

						this.setState({
							loading: false
						});
					}).catch((e) => {
						this.setState({
							loading: false,
							error: getGraphQLError(e)
						});
					});
				} else {
					this.setState({
						loading: false
					});
				}
			}
		}

		return withApollo(QueryingComponent);
	}
}