import PropTypes from 'prop-types';
import SigPad from './sp';

class SignaturePad extends Component {
	componentDidMount() {
		this.resizeCanvas = this.resizeCanvas.bind(this);
		this.signaturePad = new SigPad(this.canvas);
		window.addEventListener('resize', this.resizeCanvas);
		this.resizeCanvas();

		if (this.props.readonly) {
			this.signaturePad.off();
		}

		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.signaturePad.onEnd = (e) => {
				this.props.onChange(this.signaturePad.toData());
			};
		}
	}

	componentDidUpdate() {
		this.resizeCanvas();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeCanvas);
		this.signaturePad.off();
	}

	render() {
		return (
			<div ref={(ref) => this.canvasContainer = ref} style={{ width: '100%', border: '1px solid #ccc', background: '#fff', borderRadius: 4 }}>
				<canvas width="480" height="150" ref={(ref) => this.canvas = ref}></canvas>
			</div>
		);
	}

	resizeCanvas() {
		const parent = document.getElementById(this.props.parentId);

		const { width } = parent.getBoundingClientRect();

		this.canvas.style.width = width;
		this.canvas.width = width;
		this.canvas.style.height = width / 3.2;
		this.canvas.height = width / 3.2;

		const scale = width / 480;

		this.canvas.getContext('2d').scale(scale, scale);
		this.signaturePad.pointerScale = scale;
		this.signaturePad.clear();

		if (this.props.path) {
			this.signaturePad.fromData([...this.props.path]);
		}
	}
}

SignaturePad.propTypes = {
	readonly: PropTypes.bool,
	path: PropTypes.arrayOf(PropTypes.object),
	parentId: PropTypes.string,
	onChange: PropTypes.func
};

export default SignaturePad;