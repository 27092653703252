import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
};

class Loading extends Component {
	render() {
		const { classes, size, color } = this.props;

		let spinnerSize = 40;

		switch (size) {
			case 'small':
				spinnerSize = 20;
				break;
			case 'medium':
				spinnerSize = 40;
				break;
			case 'large':
				spinnerSize = 80;
				break;
		}

		return (
			<div className={classes.container}>
				<CircularProgress color={color} size={spinnerSize} className={classes.spinner} />
			</div>
		);
	}
}

Loading.propTypes = {
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	color: PropTypes.string
};

Loading.defaultProps = {
	size: 'medium',
	color: 'secondary'
};

export default withStyles(styles)(Loading);