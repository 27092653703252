import {
	Section,
	Button
} from 'components';
import {
	Typography,
	Divider
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import MapIcon from '@material-ui/icons/Map';
import withStyles from 'utils/withStyles';
import { withRouter } from 'react-router-dom';

const styles = {};

class ProjectCard extends Component {
	render() {
		const { project, classes, history } = this.props;

		return (
			<Section title={`${project.name}`}>
				<div className={classes.flexRow}>
					<div>
						<MapIcon className={classes.icon} fontSize="large" />
					</div>
					<div className={classes.flexFill}>
						<Typography variant="subtitle2">{i18n.t('general.address')}</Typography>
						<Typography>
							{`${project.address.line1} ${project.address.line2}, ${project.address.city}, ${project.address.postalCode}`}
						</Typography>
					</div>
				</div>

				{
					project.clients.slice(0, 3).map((client, i) => (
						<React.Fragment key={i}>
							<Divider className={classes.divider} />

							<div className={classes.flexRow}>
								<div>
									<PeopleIcon className={classes.icon} fontSize="large" />
								</div>
								<div className={classes.flexFill}>
									<Typography variant="subtitle2">{i18n.t('general.client')}</Typography>
									<Typography>{client.firstName} {client.lastName}</Typography>
								</div>
							</div>
						</React.Fragment>
					))
				}

				<Divider className={classes.divider} />

				<div style={{ textAlign: 'center' }}>
					<Button variant="text" onClick={() => {
						history.push(`/projects/${project.id}`, {
							title: `${project.name}`
						});
					}}>{i18n.t('projects.viewFullProject')}</Button>
				</div>
			</Section>
		)
	}
}

export default withRouter(withStyles(styles)(ProjectCard));