import { Hidden } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { Query, compose } from "react-apollo";
import withStyles from "utils/withStyles";
import {
  Page,
  PageHeader,
  PageBody,
  Button,
  MonthlyCalendar,
  WeeklyCalendar,
  ServiceCallCard,
  Grid
} from "components";
import _uniqBy from "lodash/uniqBy";
import { SERVICE_CALLS } from "graph/queries";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

class ServiceCalls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      sort: "date",
      dir: "desc",
      page: 0,
      viewMode: "calendar"
    };
  }

  render() {
    return (
      <Page>
        <PageHeader>
          {this.state.viewMode === "calendar" ? (
            <div style={{ flex: 1, textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                icon={DashboardIcon} // TODO FInd Card Icon
                onClick={() => this.setState({ viewMode: "card" })}
              >
                {i18n.t("serviceCalls.card")}
              </Button>
            </div>
          ) : (
            <div style={{ flex: 1, textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                icon={CalendarIcon}
                onClick={() => this.setState({ viewMode: "calendar" })}
              >
                {i18n.t("serviceCalls.calendar")}
              </Button>
            </div>
          )}
        </PageHeader>

        {this.state.viewMode === "card"
          ? this.renderCardView()
          : this.renderCalendarView()}
      </Page>
    );
  }

  renderCardView() {
    const { classes } = this.props;
    return (
      <Page>
        <Query query={SERVICE_CALLS} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            const serviceCalls =
              data && data.serviceCalls ? data.serviceCalls.serviceCalls : null;
            return (
              <PageBody loading={loading} error={error}>
                <Grid container spacing={16}>
                  {serviceCalls
                    ? serviceCalls.map((serviceCall, i) => (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                          <ServiceCallCard serviceCall={serviceCall} />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </PageBody>
            );
          }}
        </Query>
      </Page>
    );
  }

  renderCalendarView() {
    return (
      <React.Fragment>
        <Hidden lgUp>
          <WeeklyCalendar />
        </Hidden>
        <Hidden mdDown>
          <MonthlyCalendar />
        </Hidden>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(ServiceCalls);
