import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  withStyles
} from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import BuildIcon from "@material-ui/icons/Build";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StoreIcon from "@material-ui/icons/Store";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";

import withUser from "utils/withUser";

const styles = theme => ({
  drawer: {
    width: 240,
    height: "100%",
    overflow: "auto",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
  },
  drawerPaper: {
    width: 240
  },
  buttonRipple: {
    color: theme.palette.grey[900]
  }
});

class SideMenu extends Component {
  render() {
    const { open, closeDrawer, currentUser, classes } = this.props;

    let menu = null;

    if (currentUser.type === "Admin") {
      menu = this.renderAdminMenuOptions();
    } else if (currentUser.type === "Tech") {
      menu = this.renderTechMenuOptions();
    } else if (currentUser.type === "Client") {
      menu = this.renderClientMenuOptions();
    }

    return (
      <React.Fragment>
        <Hidden smDown implementation="css">
          <nav className={classes.drawer}>{menu}</nav>
        </Hidden>

        <Hidden mdUp implementation="css">
          <Drawer
            open={open}
            variant="temporary"
            classes={{ paper: classes.drawerPaper }}
            onClose={closeDrawer}
          >
            {menu}
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  }

  renderAdminMenuOptions() {
    const { closeDrawer, currentUser, classes } = this.props;

    const trp = { classes: { root: classes.buttonRipple } };

    return (
      <List>
        <Link
          to={{
            pathname: "/",
            state: { title: i18n.t("title.home") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.home")} />
          </ListItem>
        </Link>

        <Divider />

        {currentUser.hasPermission("invoices") ? (
          <Link
            to={{
              pathname: "/invoices",
              state: { title: i18n.t("title.invoices") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.invoices")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("payments") ? (
          <Link
            to={{
              pathname: "/payments",
              state: { title: i18n.t("title.payments") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.payments")} />
            </ListItem>
          </Link>
        ) : null}

        <Divider />

        {currentUser.hasPermission("clients") ? (
          <Link
            to={{
              pathname: "/clients",
              state: { title: i18n.t("title.clients") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.clients")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("techs") ? (
          <Link
            to={{
              pathname: "/techs",
              state: { title: i18n.t("title.techs") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.techs")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("admins") ? (
          <Link
            to={{
              pathname: "/admins",
              state: { title: i18n.t("title.admins") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.admins")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("projects") ? (
          <Link
            to={{
              pathname: "/projects",
              state: { title: i18n.t("title.projects") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.projects")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("tickets") ? (
          <Link
            to={{
              pathname: "/tickets",
              state: { title: i18n.t("title.tickets") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.tickets")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("tickets") ? (
          <Link
            to={{
              pathname: "/serviceCalls",
              state: { title: i18n.t("title.serviceCalls") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.serviceCalls")} />
            </ListItem>
          </Link>
        ) : null}

        <Divider />

        {currentUser.hasPermission("webhooks") ? (
          <Link
            to={{
              pathname: "/webhooks",
              state: { title: i18n.t("title.webhooks") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.webhooks")} />
            </ListItem>
          </Link>
        ) : null}

        {currentUser.hasPermission("eventLogs") ? (
          <Link
            to={{
              pathname: "/eventLogs",
              state: { title: i18n.t("title.eventLogs") }
            }}
            className="navlink"
          >
            <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={i18n.t("title.eventLogs")} />
            </ListItem>
          </Link>
        ) : null}
      </List>
    );
  }

  renderTechMenuOptions() {
    const { closeDrawer, currentUser, classes } = this.props;

    const trp = { classes: { root: classes.buttonRipple } };

    return (
      <List>
        <Link
          to={{
            pathname: "/serviceCalls",
            state: { title: i18n.t("title.serviceCalls") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.serviceCalls")} />
          </ListItem>
        </Link>

        <Link
          to={{
            pathname: "/tickets",
            state: { title: i18n.t("title.tickets") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <ConfirmationNumberIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.tickets")} />
          </ListItem>
        </Link>

        <Link
          to={{
            pathname: "/projects",
            state: { title: i18n.t("title.projects") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.projects")} />
          </ListItem>
        </Link>
      </List>
    );
  }

  renderClientMenuOptions() {
    const { closeDrawer, currentUser, classes } = this.props;

    const trp = { classes: { root: classes.buttonRipple } };

    return (
      <List>
        <Link
          to={{
            pathname: "/projects",
            state: { title: i18n.t("title.projects") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.projects")} />
          </ListItem>
        </Link>

        <Link
          to={{
            pathname: "/tickets",
            state: { title: i18n.t("title.tickets") }
          }}
          className="navlink"
        >
          <ListItem button TouchRippleProps={trp} onClick={closeDrawer}>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("title.tickets")} />
          </ListItem>
        </Link>
      </List>
    );
  }
}

export default withUser(withStyles(styles)(SideMenu));
