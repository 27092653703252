import gql from 'graphql-tag';
import { admin } from 'graph/types';

export const CREATE_ADMIN = gql`
mutation CreateAdmin(
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String!,
	$language: String!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!,
	$permissions: [Input_Permission!]!) {
	createAdmin(
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		phones: $phones,
		addresses: $addresses
		permissions: $permissions
	) {
		${admin}
	}
}
`;

export const EDIT_ADMIN = gql`
mutation EditAdmin(
	$id: ID!,
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String!,
	$language: String!,
	$isDeactivated: Boolean!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!,
	$permissions: [Input_Permission!]!) {
	editAdmin(
		id: $id,
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		isDeactivated: $isDeactivated,
		phones: $phones,
		addresses: $addresses
		permissions: $permissions
	) {
		${admin}
	}
}
`;