import PropTypes from 'prop-types';
import { Query, withApollo, compose } from 'react-apollo';
import {
	LinearProgress,
	Typography
} from '@material-ui/core';
import {
	FullScreenError,
	Button
} from 'components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import Note from './Note';
import { PROJECT_NOTES, TICKET_NOTES } from 'graph/queries';
import { ADD_PROJECT_NOTE, ADD_TICKET_NOTE } from 'graph/mutations';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		width: '100%',
		height: '100%'
	},

	notesContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		padding: 2
	},

	editorContainer: {
		position: 'relative',
		paddingTop: theme.spacing.unit
	},

	editorAddNoteButton: {
		float: 'right'
	},

	quillEditor: {
		minHeight: 180,
		display: 'flex',
		background: '#fff',
		flexDirection: 'column',
		'& .ql-container': {
			flex: 1,
			height: 'auto',
			display: 'flex'
		},
		'& .ql-editor': {
			flex: 1,
			height: 'auto'
		}
	}
});

class Notes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: '',
			editorVisible: false,
			loading: false
		};

		if (props.schemaType === 'ticket') {
			this.QUERY = TICKET_NOTES;
			this.MUTATION = ADD_TICKET_NOTE;
			this.queryField = 'ticket';
		} else if (props.schemaType === 'project') {
			this.QUERY = PROJECT_NOTES;
			this.MUTATION = ADD_PROJECT_NOTE;
			this.queryField = 'project';
		}

		this.saveNote = this.saveNote.bind(this);
		this.showEditor = this.showEditor.bind(this);
		this.cancelNote = this.cancelNote.bind(this);
	}

	render() {
		const { classes, parentId, isInternal, schemaType } = this.props;

		return (
			<Query query={this.QUERY} variables={{
				id: parentId
			}}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<div className={classes.container}>
								<LinearProgress />
							</div>
						)	
					} else if (error) {
						return <FullScreenError error={getGraphQLError(error)} />
					}

					const notes = isInternal ? data[this.queryField].internalNotes : data[this.queryField].notes;

					return (
						<div className={classes.container}>
							<div className={classes.notesContainer} ref={(ref) => this.scroller = ref}>
								<Typography variant="caption">
									{
										isInternal ? i18n.t('tickets.internalNotesDesc') : i18n.t('tickets.notesDesc')
									}
								</Typography>
								{
									notes.map((note, i) => {
										return <Note key={i} parentId={parentId} note={note} schemaType={schemaType} isInternal={isInternal} />
									})
								}
							</div>

							<div className={classes.editorContainer}>
							{
								this.state.editorVisible ?
								<React.Fragment>
									<ReactQuill
										className={classes.quillEditor}
										value={this.state.text}
										onChange={(content, delta, source, editor) => this.setState({ text: content, rawText: editor.getText() })} />

									<div style={{ textAlign: 'right', marginTop: 8 }}>
										<Button
											color="primary"
											onClick={this.saveNote}
											disabled={this.state.loading}
											loading={this.state.loading}
										>{i18n.t('general.done')}</Button>

										<Button
											variant="text"
											color="default"
											style={{ marginLeft: 8 }}
											onClick={this.cancelNote}
											disabled={this.state.loading}
											loading={this.state.loading}
										>{i18n.t('general.cancel')}</Button>
									</div>
								</React.Fragment>
								:
								<Button
									className={classes.editorAddNoteButton}
									color="primary"
									variant="contained"
									onClick={this.showEditor}
								>{i18n.t('tickets.addNote')}</Button>
							}
							</div>
						</div>
					);
				}}
			</Query>
		);
	}

	saveNote() {
		const { client, parentId, isInternal } = this.props;

		if (this.state.text && this.state.text.length) {
			client.mutate({
				mutation: this.MUTATION,
				variables: {
					id: parentId,
					note: this.state.text,
					isInternal
				}
			}).then(() => {
				this.setState({
					loading: false,
					text: '',
					editorVisible: false
				}, () => {
					this.scroller.scrollTop = this.scroller.scrollHeight;
				});
			}).catch((e) => {
				this.setState({
					loading: false
				});

				this.props.ui.showError(getGraphQLError(e));
			});
		}
	}

	showEditor() {
		this.setState({
			editorVisible: true
		}, () => {
			this.scroller.scrollTop = this.scroller.scrollHeight;
		});
	}

	cancelNote() {
		this.setState({
			editorVisible: false,
			text: ''
		})
	}
}

Notes.propTypes = {
	schemaType: PropTypes.oneOf(['project', 'ticket']),
	ticketId: PropTypes.string,
	isInternal: PropTypes.bool
};

Notes.defaultProps = {
	isInternal: false
};

export default compose(
	withApollo,
	withUI,
	withStyles(styles)
)(Notes);