import PropTypes from 'prop-types';
import {
	Snackbar,
	IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from 'utils/withStyles';

const styles = (theme) => ({
	snackbarCloseButton: {
		padding: theme.spacing.unit / 2
	}
});

class _Snackbar extends Component {
	render() {
		const { open, message, onClose, classes } = this.props;
		
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={open}
				autoHideDuration={6000}
				onClose={onClose}
				message={<span>{message}</span>}
				action={
					<IconButton
						color="inherit"
						className={classes.snackbarCloseButton}
						onClick={onClose}>
						<CloseIcon />
					</IconButton>
				}
			/>
		);
	}
}

_Snackbar.propTypes = {
	open: PropTypes.bool,
	message: PropTypes.string,
	onClose: PropTypes.func
};

export default withStyles(styles)(_Snackbar);