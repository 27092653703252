import gql from 'graphql-tag';
import { admin, admins } from 'graph/types';

export const ADMIN = gql`
	query admin($id: ID!) {
		admin(id: $id) {
			${admin}
		}
	}
`;

export const ADMINS = gql`
	query admins(
		$searchString: String
		$isVerified: Boolean
		$isDeactivated: Boolean
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		admins(
			searchString: $searchString
			isVerified: $isVerified
			isDeactivated: $isDeactivated
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			admins {
				${admins}
			}
		}
	}
`;