import {
	Section,
	StateChip,
	Button
} from 'components';
import withStyles from 'utils/withStyles';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _filter from 'lodash/filter';
import {
	Typography,
	Grid,
	Divider
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import { VictoryPie, VictoryLegend } from 'victory';

class InvoicesDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			height: 0
		};
	}

	componentDidMount() {
		this.resizeHandler = () => {
			this.setState({
				height: this.listContainer.clientWidth
			});
		}

		window.addEventListener('resize', this.resizeHandler);

		this.resizeHandler();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeHandler);
	}

	render() {
		return (
			<Section title={i18n.t('title.invoices')}>
				<Grid container alignItems="center" spacing={16}>
					<Grid item xs={12} md={6} lg={4}>
						<InvoicesChart />
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<div style={{ height: this.state.height }} ref={ref => this.listContainer = ref}>
							<InvoicesList />
						</div>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<InvoicesStats />
					</Grid>
				</Grid>
			</Section>
		);
	}
}

export default InvoicesDashboard;

class InvoicesChart extends Component {
	render() {
		return (
			<Query query={MONTHLY_INVOICES} variables={{
				startDate: moment().startOf('month').toDate(),
				endDate: moment().endOf('month').toDate()
			}}>
				{({ loading, error, data }) => {
					if (loading || error) return null;

					const invoices = data.monthlyInvoices;

					const draft = _filter(invoices, { state: 'draft' }).length;
					const sent = _filter(invoices, { state: 'sent' }).length;
					const paid = _filter(invoices, { state: 'paid' }).length;
					const voids = _filter(invoices, { state: 'void' }).length;
					const discarded = _filter(invoices, { state: 'discarded' }).length;

					const chartData = [];
					const colors = [];

					if (draft > 0) {
						chartData.push({ x: 'Draft', y: draft });
						colors.push('grey');
					}

					if (sent > 0) {
						chartData.push({ x: 'Sent', y: sent });
						colors.push('#3f51b5');
					}

					if (paid > 0) {
						chartData.push({ x: 'Paid', y: paid });
						colors.push('#23bf00');
					}

					if (voids > 0) {
						chartData.push({ x: 'Void', y: voids });
						colors.push('black');
					}

					if (discarded > 0) {
						chartData.push({ x: 'Discarded', y: discarded });
						colors.push('grey');
					}

					const legendData = chartData.map((n, i) => {
						return {
							name: n.x + ': ' + n.y,
							symbol: { fill: colors[i] }
						}
					});
					if ( discarded > 0 || voids > 0 || paid > 0 || sent > 0 || draft > 0) {
						return (
							<div style={{ position: 'relative' }}>
								<Grid container>
									<Grid item xs={12}>
										<Typography variant="button" align="center" style={{ position: 'absolute', top: 16, width: '100%' }}>{moment().format('MMMM, YYYY')}</Typography>
									</Grid>
								</Grid>
								<Grid item>
									<VictoryPie
										data={chartData}
										animate={{
											duration: 4000
										}}
										style={{ labels: { display: 'none' } }}
										colorScale={colors} />

									<div style={{ position: 'absolute', top: 0, left: 0 }}>
										<VictoryLegend
											data={legendData}
											width={120}
											height={150}
											style={{ labels: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' } }} />
									</div>
									
								</Grid>
								
							</div>
						);
					} else {
						return (
							<Grid container >
								<Grid item xs={12}>
									<div style={{ padding: '70px', position: 'relative' }}>
										<Typography variant="button" align="center" >No invoices data for {moment().format('MMMM, YYYY')}</Typography>
									</div>
								</Grid>
							</Grid>
						);

					}
					
				}}
			</Query>
		);
	}
}

class _InvoicesList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Query query={UNPAID_INVOICES}>
				{({ loading, error, data }) => {
					if (loading || error) {
						return null;
					}

					if (!data.unpaidInvoices.length) {
						return (
							<Section style={{ height: '100%', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
								<Typography variant="caption">{i18n.t('dashboard.noUnpaidInvoices')}</Typography>
								<Button
									color="primary"
									variant="contained"
									icon={AddIcon}
									onClick={() => this.props.history.push('/invoices/create')}
								>{i18n.t('invoices.invoice')}</Button>
							</Section>
						)
					}

					return (
						<Section style={{ height: '100%', boxSizing: 'border-box', overflowY: 'auto' }}>
							{
								data.unpaidInvoices.map((invoice, i) => {
									return (
										<div key={i} style={{ marginBottom: 16, cursor: 'pointer' }} onClick={() => this.props.history.push('/invoices/' + invoice.id)}>
											<div className={classes.flexRow}>
												<Typography variant="button">{i18n.t('invoices.invoice')} #{invoice.number}</Typography>
												<div style={{ flex: 1, textAlign: 'right' }}>
													<StateChip state={invoice.state} />
												</div>
											</div>

											<div className={classes.flexRow}>
												<div>
													<StoreIcon className={classes.icon} fontSize="large" />
												</div>
												<div className={classes.flexFill}>
													<Typography>
														{invoice.project.name}
													</Typography>
												</div>
												<div style={{ flex: 1, textAlign: 'right' }}>
													<Typography variant="subtitle2">
														${this.getTotal(invoice)}
													</Typography>
												</div>
											</div>

											<Divider className={classes.divider} />
										</div>
									);
								})
							}
						</Section>
					)
				}}
			</Query>
		)
	}

	getSubtotal(invoice) {
		const { items } = invoice;

		let sum = 0;
		for (let item of items) {
			sum += item.rate * item.quantity;
		}

		return Math.round(sum * 100) / 100;
	}

	getGST(invoice) {
		const subtotal = this.getSubtotal(invoice);
		return Math.round(subtotal * 0.05 * 100) / 100;
	}

	getQST(invoice) {
		const subtotal = this.getSubtotal(invoice);
		return Math.round(subtotal * 0.09975 * 100) / 100;
	}

	getTotal(invoice) {
		return this.getSubtotal(invoice) + this.getGST(invoice) + this.getQST(invoice);
	}
}

const InvoicesList = withStyles({})(withRouter(_InvoicesList));

class InvoicesStats extends Component {
	render() {
		return (
			<Query query={INVOICES_STATS}>
				{({ loading, error, data }) => {
					if (loading || error) return null;

					return (
						<Grid container justify="center" spacing={16}>
							<Grid item xs={12} md={4} lg={12}>
								<div style={{ textAlign: 'center', marginTop: 16 }}>
									<Typography variant="button">{i18n.t('dashboard.unpaidInvoices')}</Typography>
									<Typography variant="h5">{data.invoicesStats.count}</Typography>
								</div>
							</Grid>

							<Grid item xs={12} md={4} lg={12}>
								<div style={{ textAlign: 'center', marginTop: 16 }}>
									<Typography variant="button">{i18n.t('dashboard.unpaidInvoicesTotal')}</Typography>
									<Typography variant="h5">${data.invoicesStats.total}</Typography>
								</div>
							</Grid>
						</Grid>
					)
				}}
			</Query>
		)
	}
}

const UNPAID_INVOICES = gql`
	query unpaidInvoices {
		unpaidInvoices {
			id
			state
			number
			createdAt
			items {
				quantity
				rate
			}
			project {
				id
				name
			}
		}
	}
`;

const INVOICES_STATS = gql`
	query invoicesStats {
		invoicesStats {
			count
			total
		}
	}
`;

const MONTHLY_INVOICES = gql`
	query monthlyInvoices(
		$startDate: Date!
		$endDate: Date!
	) {
		monthlyInvoices(
			startDate: $startDate
			endDate: $endDate
		) {
			id
			state
		}
	}
`;