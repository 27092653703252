import {
	FormControl,
	Input,
	InputLabel,
	Typography,
	withStyles
} from '@material-ui/core';
import Button from 'components/Button/Button';
import { Link, withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import Session from 'utils/session';

import { LOGIN } from 'graph/mutations';
import { VALIDATE_TOKEN } from 'graph/queries';
import FullPagePaper from 'components/FullPagePaper/FullPagePaper';

const styles = (theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},

	submit: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	},

	errorMessage: {
		marginTop: theme.spacing.unit,
		color: '#e81d24'
	}
});

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: ''
		};
	}

	render() {
		const { classes } = this.props;

		return (
			<FullPagePaper title="My Homesync" subtitle={i18n.t('login.login')}>
				<Mutation
					mutation={LOGIN}
					update={(cache, { data }) => {
						cache.writeQuery({
							query: VALIDATE_TOKEN,
							data: {
								validateToken: data.login.user
							}
						});
					}}>
					{(login, { data, loading, error }) => (
						<form className={classes.form} onSubmit={(e) => {
							e.preventDefault();

							if (this.state.email && this.state.password) {
								login({
									variables: {
										email: this.state.email,
										password: this.state.password
									}
								}).then((res) => {
									Session.setToken(res.data.login.token);

									this.props.history.push('/', {
										title: i18n.t('title.home')
									});
								});
							}
						}}>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="email">{i18n.t('login.email')}</InputLabel>
								<Input
									id="email"
									name="email"
									inputProps={{
										autoCapitalize: 'off',
										autoComplete: 'email',
										spellCheck: 'false',
    									autoCorrect: 'off'
									}}
									autoFocus
									value={this.state.email}
									onChange={(e) => this.setState({ email: e.target.value })} />
							</FormControl>

							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="password">{i18n.t('login.password')}</InputLabel>
								<Input
									name="password"
									type="password"
									id="password"
									inputProps={{
										autoCapitalize: 'off',
										autoComplete: 'current-password'
									}}
									value={this.state.password}
									onChange={(e) => this.setState({ password: e.target.value })} />

							</FormControl>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								className={classes.submit}
								loading={loading}>

								{i18n.t('login.login')}
							</Button>

							<Link to="/forgot-password" className="navlink">
								<Typography variant="body2">{i18n.t('login.forgotPassword')}</Typography>
							</Link>

							{
								error ?
								<Typography className={classes.errorMessage} variant="caption">{i18n.t('login.invalidUserPassword')}</Typography>
								: null
							}
						</form>
					)}
				</Mutation>
			</FullPagePaper>
		)
	}
}

export default withRouter(withStyles(styles)(Login));