import gql from 'graphql-tag';

export const VALIDATE_TOKEN = gql`
	query validateToken {
		validateToken {
			id
			email
			firstName
			lastName
			language
			type
			permissions {
				key
				level
			}
		}
	}
`;

export const GET_CURRENT_USER = gql`
	query validateToken {
		validateToken @client {
			id
			email
			firstName
			lastName
			language
			type
			permissions {
				key
				level
			}
		}
	}
`;