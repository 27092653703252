import {
	Toolbar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	LinearProgress
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Query, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import withStyles from 'utils/withStyles';
import {
	Table,
	Button,
	Page,
	PageHeader,
	PageBody
} from 'components';
import withUser from 'utils/withUser';
import { CLIENTS } from 'graph/queries';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	green: {
		color: 'green'
	},
	red: {
		color: 'red'
	}
});

class Clients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchString: '',
			sort: 'email',
			dir: 'asc',
			isVerified: '',
			isActive: null,
			page: 0
		}
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						(currentUser && currentUser.hasPermission('clients', 1)) ?
							<div style={{ flex: 1, textAlign: 'right' }}>
								<Link className="navlink" to={{
									pathname: '/clients/create',
									state: { title: i18n.t('title.createClient') }
								}}><Button variant="contained" color="primary" icon={AddIcon}>{i18n.t('general.create')}</Button></Link>
							</div>
							: null
					}
				</PageHeader>

				<Query query={CLIENTS} fetchPolicy={'network-only'} variables={{
					searchString: this.state.searchString,
					isVerified: typeof this.state.isVerified === 'boolean' ? this.state.isVerified : null,
					isDeactivated: typeof this.state.isActive === 'boolean' ? !this.state.isActive : null,
					sort: this.state.sort,
					direction: this.state.dir,
					limit: 10,
					page: this.state.page
				}}>
					{({ loading, error, data }) => {
						return (
							<PageBody loading={loading} error={error} padding={0}>
								<Toolbar>
									<div className={classes.flexRow}>
										<TextField
											id="outlined-dense"
											label={i18n.t('general.search')}
											color="secondary"
											onChange={(e) => this.search(e.target.value)}
										/>

										<FormControl className={classes.formControl}>
											<InputLabel>{i18n.t('general.isVerified')}</InputLabel>
											<Select value={this.state.isVerified} onChange={(e) => this.setState({ isVerified: e.target.value })}>
												<MenuItem value={''}>{i18n.t('general.any')}</MenuItem>
												<MenuItem value={true}>{i18n.t('general.yes')}</MenuItem>
												<MenuItem value={false}>{i18n.t('general.no')}</MenuItem>
											</Select>
										</FormControl>

										<FormControl className={classes.formControl}>
											<InputLabel>{i18n.t('general.isActive')}</InputLabel>
											<Select value={this.state.isActive === null ? '' : this.state.isActive} onChange={(e) => this.setState({ isActive: e.target.value })}>
												<MenuItem value={''}>{i18n.t('general.any')}</MenuItem>
												<MenuItem value={true}>{i18n.t('general.yes')}</MenuItem>
												<MenuItem value={false}>{i18n.t('general.no')}</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Toolbar>

								<Table
									page={this.state.page}
									limit={10}
									sort={this.state.sort}
									count={data && data.clients ? data.clients.count : 0}
									direction={this.state.dir}
									onChangeSort={this.sortTable.bind(this)}
									onChangePage={(page) => this.setState({ page })}
									rows={
										(data && data.clients && data.clients.clients) ?
											data.clients.clients.map((client, i) => ({
												email: client.email,
												firstName: client.firstName,
												lastName: client.lastName,
												isVerified: client.isVerified ? <CheckCircleOutlineIcon className={classes.green} /> : <CloseIcon className={classes.red} />,
												isActive: !client.isDeactivated ? <CheckCircleOutlineIcon className={classes.green} /> : <CloseIcon className={classes.red} />,
												stripeCustomerId: client.stripeCustomerId,
												edit: <Link to={{
													pathname: `/clients/${client.id}`,
													state: { title: client.firstName + ' ' + client.lastName }
												}}><IconButton><EditIcon /></IconButton></Link>
											}))
											: []
									}
									columns={[{
										key: 'avatar',
										label: ''
									}, {
										key: 'email',
										label: i18n.t('general.email'),
										sortable: true
									}, {
										key: 'firstName',
										label: i18n.t('general.firstName'),
										sortable: true
									}, {
										key: 'lastName',
										label: i18n.t('general.lastName'),
										sortable: true
									}, {
										key: 'isVerified',
										label: i18n.t('general.isVerified'),
										sortable: true,
										center: true
									}, {
										key: 'isActive',
										label: i18n.t('general.isActive'),
										sortable: true,
										center: true
									}, {
										key: 'stripeCustomerId',
										label: i18n.t('clients.stripeCustomerId'),
										sortable: true,
										center: true
									}, {
										key: 'edit',
										label: ''
									}]} />
							</PageBody>
						);
					}}
				</Query>
			</Page>
		);
	}

	search(value, e) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				searchString: value
			});
		}, 500);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
};

export default compose(
	withUser,
	withStyles(styles)
)(Clients);