import gql from 'graphql-tag';
import { checklist } from 'graph/types';

export const CREATE_CHECKLIST = gql`
mutation createCheckList(
	$projectId: ID!
	$name: String!) {
	createCheckList(
		projectId: $projectId
		name: $name
	) {
		${checklist}
	}
}
`;

export const EDIT_CHECKLIST = gql`
mutation editCheckList(
	$id: ID!
	$name: String!
) {
	editCheckList(
		id: $id
		name: $name
	) {
		${checklist}
	}
}
`;

export const REMOVE_CHECKLIST = gql`
mutation removeCheckList(
	$id: ID!
) {
	removeCheckList(
		id: $id
	)
}
`;

export const ADD_CHECKLIST_ITEM = gql`
mutation addCheckListItem(
	$id: ID!
	$text: String!
) {
	addCheckListItem(
		id: $id
		text: $text
	) {
		${checklist}
	}
}
`;

export const REMOVE_CHECKLIST_ITEM = gql`
mutation removeCheckListItem(
	$id: ID!
	$itemId: ID!
) {
	removeCheckListItem(
		id: $id
		itemId: $itemId
	) {
		${checklist}
	}
}
`;

export const EDIT_CHECKLIST_ITEM = gql`
mutation editCheckListItem(
	$id: ID!
	$itemId: ID!
	$text: String!
	$checked: Boolean!
) {
	editCheckListItem(
		id: $id
		itemId: $itemId
		text: $text
		checked: $checked
	) {
		${checklist}
	}
}
`;