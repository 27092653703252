import {
	TextField,
	Confirm
} from 'components';
import {
	Typography,
	Checkbox,
	Menu,
	MenuItem,
	IconButton
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import { compose, withApollo } from 'react-apollo';
import { REMOVE_CHECKLIST_ITEM, EDIT_CHECKLIST_ITEM } from 'graph/mutations';

const styles = (theme) => ({
	
});

class CheckListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false,
			anchorEl: null,
			editMode: false,
			editText: '',
			deleteConfirmOpen: false
		};

		this.deleteCheckListItem = this.deleteCheckListItem.bind(this);
		this.checkItem = this.checkItem.bind(this);
		this.saveEdit = this.saveEdit.bind(this);
		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}
	
	render() {
		const { item, classes } = this.props;
		const { editMode, editText, deleteConfirmOpen } = this.state;

		if (editMode) {
			return (
				<li className={classes.flexRow}>
					<TextField
						style={{ flex: 1, marginRight: 8 }}
						onKeyDown={(e) => e.keyCode === 13 && this.saveEdit()}
						onChange={(e) => this.setState({ editText: e.target.value })}
						value={editText} />

					<IconButton onClick={this.saveEdit}>
						<CheckIcon />
					</IconButton>

					<IconButton onClick={() => this.setState({ editMode: false })}>
						<ClearIcon />
					</IconButton>
				</li>
			)
		}

		return (
			<li className={classes.flexRow}>
				<Checkbox
					color="primary"
					checked={item.checked}
					onChange={() => this.checkItem(item)}
					/>

				<Typography
					onDoubleClick={() => this.setState({ editMode: true, editText: item.text, menuOpen: false })}
					style={{ flex: 1, margin: '0px 8px', textDecoration: item.checked ? 'line-through' : 'none' }}>
					{item.text}
				</Typography>

				<IconButton onClick={this.openMenu}>
					<MoreIcon />
				</IconButton>

				<Menu
					anchorEl={this.state.anchorEl}
					open={this.state.menuOpen}
					onClose={this.closeMenu}>
						<MenuItem onClick={() => this.setState({ editMode: true, editText: item.text, menuOpen: false })}>
							{i18n.t('general.edit')}
						</MenuItem>

						<MenuItem onClick={() => this.setState({ deleteConfirmOpen: true })}>
							{i18n.t('general.delete')}
						</MenuItem>
				</Menu>

				<Confirm
					title={i18n.t('projects.deleteChecklistItemTitle')}
					body={i18n.t('projects.deleteChecklistItemBody')}
					open={deleteConfirmOpen}
					confirmButtonText={i18n.t('general.delete')}
					onConfirm={this.deleteCheckListItem}
					onCancel={() => this.setState({ deleteConfirmOpen: false })} />
			</li>
		);
	}

	deleteCheckListItem() {
		const { client, item, checklistId } = this.props;

		this.setState({
			menuOpen: false,
			anchorEl: null
		});

		client.mutate({
			mutation: REMOVE_CHECKLIST_ITEM,
			variables: {
				id: checklistId,
				itemId: item.id
			}
		}).then(() => {
			this.setState({
				deleteConfirmOpen: false
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
		});
	}

	checkItem() {
		const { client, item, checklistId } = this.props;

		client.mutate({
			mutation: EDIT_CHECKLIST_ITEM,
			variables: {
				id: checklistId,
				itemId: item.id,
				checked: !item.checked,
				text: item.text
			}
		}).then(() => {

		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
		});
	}

	saveEdit() {
		const { client, item, checklistId } = this.props;
		const { editText } = this.state;

		if (!editText) return;

		client.mutate({
			mutation: EDIT_CHECKLIST_ITEM,
			variables: {
				id: checklistId,
				itemId: item.id,
				checked: item.checked,
				text: editText
			}
		}).then(() => {
			this.setState({
				editMode: false
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
		});
	}

	openMenu(e) {
		this.setState({
			menuOpen: true,
			anchorEl: e.target
		});
	}

	closeMenu() {
		this.setState({
			menuOpen: false,
			anchorEl: null
		});
	}
}

export default compose(
	withApollo,
	withUI,
	withStyles(styles)
)(CheckListItem);
