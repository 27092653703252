import { Modal, Typography, Button, Paper, withStyles } from "@material-ui/core";

const styles = theme => ({
  paper: {
    position: "absolute",
    width: 300,
    maxWidth: "80%",
    padding: theme.spacing.unit * 4,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  buttonRow: {
    marginTop: theme.spacing.unit * 3,
    textAlign: "right"
  }
});

class M extends Component {
  render() {
    const { title, body, buttonText, open, onClose, classes } = this.props;

    return (
      <Modal open={open} onClose={onClose}>
        <Paper className={classes.paper}>
          <Typography variant="h6">{title}</Typography>
          <Typography component="div">{body}</Typography>

          <div className={classes.buttonRow}>
            <Button onClick={onClose}>{buttonText || i18n.t("general.close")}</Button>
          </div>
        </Paper>
      </Modal>
    );
  }
}

export default withStyles(styles)(M);
