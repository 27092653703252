import {
	Toolbar,
	TextField,
	IconButton
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Query, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import withStyles from 'utils/withStyles';
import {
	Table,
	Button,
	Page,
	PageHeader,
	PageBody
} from 'components';
import withUser from 'utils/withUser';
import { PROJECTS } from 'graph/queries';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	green: {
		color: 'green'
	},
	red: {
		color: 'red'
	}
});

class Projects extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchString: '',
			sort: 'email',
			dir: 'asc',
			isVerified: '',
			isActive: null,
			page: 0
		}
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						(currentUser && currentUser.hasPermission('projects', 1)) ?
							<div style={{ flex: 1, textAlign: 'right' }}>
								<Link className="navlink" to={{
									pathname: '/projects/create',
									state: { title: i18n.t('title.createProject') }
								}}><Button variant="contained" color="primary" icon={AddIcon}>{i18n.t('general.create')}</Button></Link>
							</div>
							: null
					}
				</PageHeader>

				<Query query={PROJECTS} fetchPolicy={'network-only'} variables={{
					searchString: this.state.searchString,
					sort: this.state.sort,
					direction: this.state.dir,
					limit: 10,
					page: this.state.page
				}}>
					{({ loading, error, data }) => {
						return (
							<PageBody loading={loading} error={error} padding={0}>
								<Toolbar>
									<div className={classes.flexRow}>
										<TextField
											id="outlined-dense"
											label={i18n.t('general.search')}
											color="secondary"
											onChange={(e) => this.search(e.target.value)}
										/>
									</div>
								</Toolbar>

								<Table
									page={this.state.page}
									limit={10}
									sort={this.state.sort}
									count={data && data.projects ? data.projects.count : 0}
									direction={this.state.dir}
									onChangeSort={this.sortTable.bind(this)}
									onChangePage={(page) => this.setState({ page })}
									rows={
										(data && data.projects && data.projects.projects) ?
											data.projects.projects.map((project, i) => ({
												name: project.name,
												address: `${project.address.line1}${(project.address.line2) ? ' ' + project.address.line2 : ''}`,
												clients: project.clients.length,
												edit: <Link to={{
													pathname: `/projects/${project.id}`,
													state: {
														title: project.name,
														project: project
													}
												}}><IconButton><EditIcon /></IconButton></Link>
											}))
											: []
									}
									columns={[{
										key: 'name',
										label: i18n.t('general.name'),
										sortable: true
									}, {
										key: 'address',
										label: i18n.t('general.address')
									}, {
										key: 'clients',
										label: i18n.t('title.clients')
									}, {
										key: 'edit',
										label: ''
									}]} />
							</PageBody>
						)
					}}
				</Query>
			</Page>
		);
	}

	search(value, e) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				searchString: value
			});
		}, 500);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
};

export default compose(
	withUser,
	withStyles(styles)
)(Projects);