import { Query, withApollo, compose } from "react-apollo";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Grid,
  Typography
} from "@material-ui/core";
import { Button, Section, FullScreenError } from "components";
import AddIcon from "@material-ui/icons/Add";
import ReactQuill from "react-quill";
import withStyles from "utils/withStyles";
import withUI from "utils/withUI";
import getGraphQLError from "utils/getGraphQLError";
import Description from "./Description";
import { TICKET_DESCRIPTIONS } from "graph/queries";
import { ADD_TICKET_DESCRIPTION } from "graph/mutations";

const styles = theme => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },

  descriptionsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 2
  },

  editorContainer: {
    position: "relative",
    paddingTop: theme.spacing.unit
  },

  editorAddDescriptionButton: {
    float: "right"
  },

  quillEditor: {
    minHeight: 180,
    display: "flex",
    background: "#fff",
    flexDirection: "column",
    "& .ql-container": {
      flex: 1,
      height: "auto",
      display: "flex"
    },
    "& .ql-editor": {
      flex: 1,
      height: "auto"
    }
  }
});

class Descriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      text: "",
      rawText: "",
      category: "",
      solved: false,
      editorVisible: false
    };

    this.saveDescription = this.saveDescription.bind(this);
    this.showEditor = this.showEditor.bind(this);
    this.cancelDescription = this.cancelDescription.bind(this);
  }

  render() {
    const { classes, ticketId, currentUser } = this.props;

    return (
      <Query query={TICKET_DESCRIPTIONS} variables={{ id: ticketId }}>
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <div className={classes.container}>
                <LinearProgress />
              </div>
            );
          }

          if (error) {
            return <FullScreenError error={getGraphQLError(error)} />;
          }

          const descriptions =
            data.ticket && data.ticket.descriptions && data.ticket.descriptions.length > 0
              ? data.ticket.descriptions
              : null;
          return (
            <div className={classes.container}>
              <div className={classes.descriptionsContainer} ref={ref => (this.scroller = ref)}>
                {descriptions ? (
                  descriptions.map((description, i) => {
                    return (
                      <Description
                        key={i}
                        description={description}
                        ticketId={ticketId}
                        currentUser={currentUser}
                      />
                    );
                  })
                ) : (
                  <Typography variant="caption">
                    There is no description for this ticket yet
                  </Typography>
                )}
              </div>
              <div className={classes.editorContainer}>
                {currentUser.hasPermission("tickets", 1) ? (
                  !this.state.editorVisible ? (
                    <Button
                      className={classes.editorAddDescriptionButton}
                      color="primary"
                      variant="contained"
                      icon={AddIcon}
                      onClick={this.showEditor}
                    >
                      {i18n.t("tickets.description")}
                    </Button>
                  ) : (
                    <React.Fragment>
                      <Section>
                        <div className={classes.flexRow}>
                          <div>
                            <FormControl variant="outlined">
                              <InputLabel>Category</InputLabel>
                              <Select
                                autoWidth
                                value={this.state.category}
                                style={{ width: 150, marginRight: 16 }}
                                onChange={e => {
                                  this.setState({
                                    category: e.target.value
                                  });
                                }}
                              >
                                <MenuItem value={"lighting"}>Lighting</MenuItem>
                                <MenuItem value={"audio"}>Audio</MenuItem>
                                <MenuItem value={"video"}>Video</MenuItem>
                                <MenuItem value={"network"}>Network</MenuItem>
                                <MenuItem value={"camera"}>Camera</MenuItem>
                                <MenuItem value={"hvac"}>HVAC</MenuItem>
                                <MenuItem value={"blinds"}>Blinds</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <ReactQuill
                            className={classes.quillEditor}
                            value={this.state.text}
                            onChange={(content, delta, source, editor) =>
                              this.setState({
                                text: content,
                                rawText: editor.getText()
                              })
                            }
                          />
                        </div>
                      </Section>

                      <div style={{ textAlign: "right", marginTop: 8 }}>
                        <Button
                          color="primary"
                          onClick={this.saveDescription}
                          disabled={this.state.loading}
                          loading={this.state.loading}
                        >
                          {i18n.t("general.done")}
                        </Button>

                        <Button
                          variant="text"
                          color="default"
                          style={{ marginLeft: 8 }}
                          onClick={this.cancelDescription}
                          disabled={this.state.loading}
                          loading={this.state.loading}
                        >
                          {i18n.t("general.cancel")}
                        </Button>
                      </div>
                    </React.Fragment>
                  )
                ) : null}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }

  saveDescription() {
    const { client, ticketId } = this.props;
    if (this.state.text && this.state.text.length) {
      client
        .mutate({
          mutation: ADD_TICKET_DESCRIPTION,
          variables: {
            id: ticketId,
            description: this.state.text,
            category: this.state.category,
            solved: this.state.solved
          }
        })
        .then(() => {
          this.setState(
            {
              loading: false,
              text: "",
              editorVisible: false
            },
            () => {
              this.scroller.scrollTop = this.scroller.scrollHeight;
            }
          );
        })
        .catch(e => {
          this.setState({
            loading: false
          });

          this.props.ui.showError(getGraphQLError(e));
        });
    }
  }

  showEditor() {
    this.setState(
      {
        editorVisible: true
      },
      () => {
        this.scroller.scrollTop = this.scroller.scrollHeight;
      }
    );
  }

  cancelDescription() {
    this.setState({
      editorVisible: false,
      text: ""
    });
  }
}

export default compose(
  withApollo,
  withUI,
  withStyles(styles)
)(Descriptions);
