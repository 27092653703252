import {
	Paper,
	Typography,
	TextField,
	Divider,
	withStyles,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { compose, withApollo } from 'react-apollo';
import { CardElement, Elements, injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import withQuery from 'utils/withQuery';
import { PUBLIC_INVOICE } from 'graph/queries';
import { PAY_INVOICE } from 'graph/mutations';
import {
	Grid,
	PageBody,
	Button,
	Confirm,
	InvoiceEmail,
	HelpTooltip,
	FullScreenLoading,
	Banner
} from 'components';

const styles = (theme) => ({
	container: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},

	paper: {
		padding: theme.spacing.unit * 2,
		margin: theme.spacing.unit * 2
	},

	leftColumn: {
		padding: theme.spacing.unit * 2
	},

	spacing: {
		marginBottom: theme.spacing.unit * 2
	},

	select: {
		marginBottom: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2
	},

	stripeBadge: {
		width: '100px',
		marginLeft: '16px',
		verticalAlign: 'text-bottom',
		float: 'right'
	},

	stripeElementContainer: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		padding: '6px 0',
		marginBottom: theme.spacing.unit * 2,
		'&:hover': {
			borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
			marginBottom: theme.spacing.unit * 2 - 1,
		}
	}
});

class _InvoicePaymentForm extends Component {
	constructor(props) {
		super(props);

		this.invoice = props.queryResult && props.queryResult.data && props.queryResult.data.publicInvoice || null;

		if (!this.invoice) {
			this.props.history.push('/');
		}

		this.state = {
			cardComplete: false,
			cvc: '',
			expirationMonth: '',
			expirationYear: '',
			postalCode: '',
			firstName: '',
			lastName: '',
			errors: [],
			error: false,
			errorMessage: '',
			loading: false,
			paymentConfirmOpen: false
		};

		this.payInvoice = this.payInvoice.bind(this);
		this.showInvoiceConfirm = this.showInvoiceConfirm.bind(this);
		this.isValid = this.isValid.bind(this);

		this.elementStyle = {
			base: {
				fontSize: '16px'
			}
		};
	}

	render() {
		if (!this.invoice) {
			return null;
		}

		const { classes } = this.props;

		const invoiceYears = [];
		const year = moment().startOf('year');

		for (let x = 0; x <= 10; x++) {
			invoiceYears.push(year.format('YY'));
			year.add(1, 'year');
		}

		const invoiceTotal = this.getInvoiceTotal();
		const stripeFee = 0 // this.getStripeTransactionFee();
		const finalCharge = stripeFee + invoiceTotal;

		return (
			<div className={classes.container}>
				<PageBody>
					<Paper className={classes.paper}>
						{
							(this.state.loading) ?
							<FullScreenLoading />
							: null
						}

						<Confirm
							title={i18n.t('payments.payInvoice')}
							body={i18n.t('payments.payInvoiceConfirmBody') + `$${finalCharge.toFixed(2)}`}
							open={this.state.paymentConfirmOpen}
							confirmButtonText={i18n.t('payments.payInvoice')}
							onConfirm={this.payInvoice}
							onCancel={() => this.setState({ paymentConfirmOpen: false })} />

						<Grid container>
							<Grid item xs={12} md={6} className={classes.leftColumn}>
								<Typography variant="h6">
									{i18n.t('payments.payInvoice')}

									<img src={require('assets/powered_by_stripe.png')} className={classes.stripeBadge} />
								</Typography>

								<Divider className={classes.spacing} />

								<Banner message={this.state.errorMessage} visible={this.state.error} elevated icon={<ErrorIcon />} />

								<Typography variant="caption">{i18n.t('invoices.confidential')}</Typography>

								<Table style={{ marginTop: 16, marginBottom: 16 }}>
									<TableBody>
										<TableRow>
											<TableCell><Typography>{i18n.t('payments.invoiceTotal')}</Typography></TableCell>
											<TableCell align="right"><Typography>${invoiceTotal.toFixed(2)}</Typography></TableCell>	
										</TableRow>

										<TableRow>
											<TableCell><Typography style={{ fontWeight: 500 }}>{i18n.t('payments.finalCharge')}</Typography></TableCell>
											<TableCell align="right"><Typography style={{ fontWeight: 500 }}>${finalCharge.toFixed(2)}</Typography></TableCell>	
										</TableRow>
									</TableBody>
								</Table>

								{
									(this.state.paymentComplete)
									?
									<Typography variant="h5" align="center">
										{i18n.t('invoices.thanksForPaying')}
									</Typography>
									:
									(this.invoice.state === 'paid')
									?
									<Typography variant="h5" align="center">
										{i18n.t('invoices.alreadyPaid')}
									</Typography>
									:
									<form onSubmit={this.showInvoiceConfirm}>
										<div className={classes.stripeElementContainer}>
											<CardElement style={this.elementStyle} hidePostalCode={true} onChange={(c) => this.setState({ cardComplete: c.complete })} />
										</div>

										<Grid container>
											<Grid item xs={12} md={6}>
												<TextField
													fullWidth
													required
													error={!!this.state.errors.length && !this.state.firstName}
													autoComplete="off"
													label={i18n.t('general.firstName')}
													value={this.state.firstName}
													className={classes.spacing}
													onChange={(e) => this.setState({ firstName: e.target.value })} />
											</Grid>

											<Grid item xs={12} md={6}>
												<TextField
													fullWidth
													required
													error={!!this.state.errors.length && !this.state.lastName}
													autoComplete="off"
													label={i18n.t('general.lastName')}
													value={this.state.lastName}
													className={classes.spacing}
													onChange={(e) => this.setState({ lastName: e.target.value })} />
											</Grid>
										</Grid>

										<TextField
											required
											error={!!this.state.errors.length && !this.state.postalCode}
											autoComplete="off"
											label={i18n.t('payments.postalCode')}
											value={this.state.postalCode}
											className={classes.select}
											onChange={(e) => this.setState({ postalCode: e.target.value })} />

										<br />

										<Button
											type="submit"
											color="primary"
											variant="contained"
											style={{ marginBottom: 8 }}
											onClick={this.showInvoiceConfirm}
											disabled={!this.isValid()}>
											{i18n.t('payments.payInvoice')}
										</Button>
									</form>
									}
							</Grid>
							<Grid item xs={12} md={6} style={{ overflowX: 'auto' }}>
								<InvoiceEmail
									invoice={{
										...this.invoice,
										deliveredAt: moment(this.invoice.deliveredAt).format('YYYY-MM-DD')
									}} />
							</Grid>
						</Grid>
					</Paper>
				</PageBody>
			</div>
		);
	}

	payInvoice() {
		if (this.state.loading) {
			return;
		}

		this.setState({
			loading: true,
			paymentConfirmOpen: false
		});

		this.props.stripe.createToken({
			name: `${this.state.firstName} ${this.state.lastName}`,
			address_zip: this.state.postalCode
		}).then((res) => {
			if (res.error) {
				this.setState({
					loading: false,
					error: true
				});
			} else {

				this.props.client.mutate({
					mutation: PAY_INVOICE,
					variables: {
						publicId: this.invoice.publicId,
						stripeToken: res.token.id
					}
				}).then((res) => {
					this.setState({
						loading: false,
						error: false,
						errorMessage: '',
						paymentComplete: true
					});
				}).catch((err) => {
					this.setState({
						loading: false,
						error: true,
						errorMessage: <div>{i18n.t('invoices.errors.paymentFailed')}<a href="mailto:support@homesync.ca">support@homesync.ca</a>.</div>
					});
				});
			}
		});
	}

	showInvoiceConfirm(e) {
		e.preventDefault();
		
		if (this.state.loading) {
			return;
		}
		
		this.setState({
			paymentConfirmOpen: true
		});
	}

	isValid() {
		let isValid = true;

		if (!this.state.cardComplete) {
			isValid = false;
		} else if (!this.state.postalCode) {
			isValid = false;
		} else if (!this.state.firstName) {
			isValid = false;
		} else if (!this.state.lastName) {
			isValid = false;
		}

		return isValid;
	}

	getInvoiceTotal() {
		let subTotal = 0;

		for (let item of this.invoice.items) {
			subTotal += item.rate * item.quantity;
		}

		return Math.round((subTotal + (subTotal * 0.05 ) + (subTotal * 0.09975))  * 100) / 100;
	}

	getStripeTransactionFee() {
		const total = this.getInvoiceTotal();

		return Math.round(((total + 0.3) / (1 - 0.029) - total) * 100) / 100;
	}
}

const InvoicePaymentForm = compose(
	withQuery,
	withApollo,
	injectStripe,
	withStyles(styles)
)(_InvoicePaymentForm);

class InvoicePaymentFormContainer extends Component {
	render() {
		const { match } = this.props;

		const locale = i18n.default.language ? i18n.default.language.substring(0, 2) : 'en'

		if (match.params && match.params.invoiceId) {
			return (
				<Elements locale={locale}>
					<InvoicePaymentForm query={PUBLIC_INVOICE} variables={{ publicId: match.params.invoiceId }} {...this.props} />
				</Elements>
			);
		} else {
			return null;
		}
	}
}

export default InvoicePaymentFormContainer;
