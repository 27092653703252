import {
	Section,
	TextField,
	Button,
	Confirm
} from 'components';
import CheckListItem from './CheckListItem';
import {
	Typography,
	Divider
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import { compose, withApollo } from 'react-apollo';
import { CHECKLISTS } from 'graph/queries';
import { REMOVE_CHECKLIST, ADD_CHECKLIST_ITEM } from 'graph/mutations';

const styles = (theme) => ({
	itemList: {
		listStyleType: 'none',
		paddingLeft: 0
	}
});

class CheckList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			deleteConfirmOpen: false,
			isDeleting: false,
			newItemText: '',
			newItemLoading: false
		};

		this.deleteCheckList = this.deleteCheckList.bind(this);
		this.createCheckListItem = this.createCheckListItem.bind(this);
	}
	
	render() {
		const { checklist, onClose, classes } = this.props;
		const { isDeleting, deleteConfirmOpen, newItemText, newItemLoading } = this.state;

		return (
			<Section
				title={checklist.name}
				buttons={[
					<Button
						key={1}
						color="primary"
						variant="contained"
						icon={DeleteIcon}
						loading={isDeleting}
						onClick={() => this.setState({ deleteConfirmOpen: true })}>
						{i18n.t('general.delete')}
					</Button>
				]}>

				<Typography variant="caption"><a href="#" onClick={(e) => { e.preventDefault; onClose()}}>Back</a></Typography>

				<ul className={classes.itemList}>
					{
						checklist.items.map((item, i) => (
							<CheckListItem item={item} checklistId={checklist.id} key={i} />
						))
					}
				</ul>

				<Divider className={classes.divider} />

				<div className={classes.flexRow}>
					<div style={{ flex: 1 }}>
						<TextField
							fullWidth
							autoComplete="off"
							label={i18n.t('projects.checklistTypeHere')}
							variant="outlined"
							onKeyDown={(e) => e.keyCode === 13 && this.createCheckListItem()}
							onChange={(e) => this.setState({ newItemText: e.target.value })}
							value={newItemText} />
					</div>

					<div style={{ marginLeft: 16 }}>
						<Button
							color="primary"
							variant="contained"
							icon={AddIcon}
							disabled={!newItemText}
							loading={newItemLoading}
							onClick={this.createCheckListItem}>
							{i18n.t('general.create')}
						</Button>
					</div>
				</div>

				<Confirm
					title={i18n.t('projects.deleteChecklistTitle')}
					body={i18n.t('projects.deleteChecklistBody')}
					open={deleteConfirmOpen}
					confirmButtonText={i18n.t('general.delete')}
					onConfirm={this.deleteCheckList}
					onCancel={() => this.setState({ deleteConfirmOpen: false })} />
			</Section>
		);
	}

	deleteCheckList() {
		const { client, checklist, onClose } = this.props;

		this.setState({
			deleteConfirmOpen: false,
			isDeleting: true
		});

		client.mutate({
			mutation: REMOVE_CHECKLIST,
			variables: {
				id: checklist.id
			},
			refetchQueries: [{
				query: CHECKLISTS,
				variables: { projectId: checklist.project.id }
			}]
		}).then(() => {
			onClose();
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				isDeleting: false
			});
		});
	}

	createCheckListItem() {
		const { client, checklist } = this.props;
		const { newItemText } = this.state;

		if (!newItemText) return;

		this.setState({
			newItemLoading: true
		});

		client.mutate({
			mutation: ADD_CHECKLIST_ITEM,
			variables: {
				id: checklist.id,
				text: newItemText
			}
		}).then(() => {
			this.setState({
				newItemLoading: false,
				newItemText: ''
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				newItemLoading: false
			});
		});
	}
}

export default compose(
	withApollo,
	withUI,
	withStyles(styles)
)(CheckList);
