import gql from "graphql-tag";
import { serviceCall } from "graph/types";

export const ADMIN_CREATE_SERVICE_CALL = gql`
mutation admin_createServiceCall(
	$ticket: String!
	$date: Date!
	$status: String!
	$workItems: [Input_WorkItem!]!
	$equipmentUsed: [Input_EquipmentUsed!]!) {
	admin_createServiceCall(
		ticket: $ticket
		date: $date
		status: $status
		workItems: $workItems
		equipmentUsed: $equipmentUsed
	) {
		${serviceCall}
	}
}
`;

export const ADMIN_EDIT_SERVICE_CALL = gql`
mutation admin_editServiceCall(
	$id: ID!
	$date: Date!
	$status: String!
	$workItems: [Input_WorkItem!]!
	$equipmentUsed: [Input_EquipmentUsed!]!
	$closeTicket: Boolean) {
		admin_editServiceCall(
		id: $id
		date: $date
		status: $status
		workItems: $workItems
		equipmentUsed: $equipmentUsed
		closeTicket: $closeTicket
	) {
		${serviceCall}
  }
}
`;

export const DELETE_SERVICE_CALL = gql`
  mutation deleteServiceCall($id: ID!) {
    deleteServiceCall(id: $id)
  }
`;

export const TECH_EDIT_SERVICE_CALL = gql`
mutation tech_editServiceCall(
	$id: ID!
	$workItems: [Input_WorkItem!]!
	$techNotes: String
	$signingClientName: String!
	$clientSignature: String!
	$signingTechName: String!
	$techSignature: String!) {
		tech_editServiceCall(
		id: $id
		workItems: $workItems
		techNotes: $techNotes
		signingClientName: $signingClientName
		clientSignature: $clientSignature
		signingTechName: $signingTechName
		techSignature: $techSignature
	) {
		${serviceCall}
  }
}
`;

export const ADD_EQUIPMENT_USED = gql`
mutation addEquipmentUsed(
	$serviceCallId: ID!
	$name: String!
	$price: Float!
	$quantity: Int!
	$notes: String) {
		addEquipmentUsed(
			serviceCallId: $serviceCallId
			name: $name
			price: $price
			quantity: $quantity
			notes: $notes
		) {
			${serviceCall}
		}
	} 
`;

export const EDIT_EQUIPMENT_USED = gql`
mutation addEquipmentUsed(
	$id: ID!
	$serviceCallId: ID!
	$name: String!
	$quantity: Int!
	$price: Float!
	$notes: String) {
		editEquipmentUsed(
			id: $id
			serviceCallId: $serviceCallId
			name: $name
			quantity: $quantity
			price: $price
			notes: $notes
		) {
			${serviceCall}
		}
	} 
`;

export const DELETE_EQUIPMENT_USED = gql`
mutation deleteEquipmentUsed(
	$id: ID!
	$serviceCallId: ID!) {
		deleteEquipmentUsed(
			id: $id
			serviceCallId: $serviceCallId
		) {
			${serviceCall}
		}
	} 
`;
