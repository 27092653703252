// ticketDescription in servicecall and service calls

export const serviceCall = `
	id
	date
	totalHours
	status
	createdAt
	updatedAt
	signingClientName
	clientSignature
	signingTechName
	techSignature
	techNotes
	ticket {
		id
		timeEstimate
		workOrder
		status
		createdAt
		updatedAt
		priority
		descriptions {
			id
			category
			description
			solved
			solveDescription
			solvedBy {
				id
				firstName
				lastName
				type
			}
		}
		project {
			id
			name
			address {
				line1
				line2
				city
				province
				country
				postalCode
			}
			clients {
				id
				firstName
				lastName
				email
				phones {
					number
					label
				}
			}
		}
		client {
			id
			firstName
			lastName
			email
			phones {
				number
				label
			}
		}
	}
	workItems {
		id
		tech {
			id
			firstName
			lastName
			email
		}
		startTime
		endTime
		actualStartTime
		actualEndTime
		breakTime
		notes
	}
	equipmentUsed {
		id
		name
		price
		quantity
		notes
	}
`;

export const serviceCalls = `
	id
	date
	status
	totalHours
	createdAt
	updatedAt
	workItems {
		id
		tech {
			id
			firstName
			lastName
			email
		}
		startTime
		endTime
		actualStartTime
		actualEndTime
		breakTime
		notes
	}
	equipmentUsed {
		id
		name
		price
		quantity
		notes
	}
	ticket {
		id
		workOrder
		descriptions {
			id
			category
			description
			solved
		}
		project {
			id
			name
		}
		client {
			id
			firstName
			lastName
		}
	}
`;
