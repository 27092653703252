const Session = {
	setToken: (token) => {
		localStorage.setItem('_homesync_token', token);
	},

	clearToken: () => {
		localStorage.removeItem('_homesync_token');
	},

	getToken: () => {
		const token = localStorage.getItem('_homesync_token');

		return token || null;
	}
}

export default Session;