import gql from 'graphql-tag';

export const TAG = gql`
	query tag($id: ID!) {
		tag(id: $id) {
			id
            en
            fr
		}
	}
`;

export const TAGS = gql`
	query tags(
		$searchString: String
		$sort: String
		$direction: SortDirection
		$page: Int
		$limit: Int
	) {
		tags(
			searchString: $searchString
			sort: $sort
			direction: $direction
			page: $page
			limit: $limit
		) {
			count
			tags {
				id
                en
                fr
			}
		}
	}
`;