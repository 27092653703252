import {
	Section,
	TextField,
	Button,
	Confirm,
	Grid
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import { Query, compose, withApollo } from 'react-apollo';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';

import { SERVICE_CALL } from 'graph/queries';
import { ADD_EQUIPMENT_USED, EDIT_EQUIPMENT_USED, DELETE_EQUIPMENT_USED } from 'graph/mutations';

class _Equipment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: props.equipment.name,
			price: props.equipment.price,
			quantity: props.equipment.quantity,
			notes: props.equipment.notes,
			loading: false,
			deleteConfirmOpen: false,
			dirty: false
		};

		this.isValid = this.isValid.bind(this);
		this.save = this.save.bind(this);
		this.delete = this.delete.bind(this);
	}

	render() {
		return (
			<Section>
				<TextField
					required
					variant="outlined"
					fullWidth
					autoComplete="off"
					style={{ marginBottom: 16 }}
					label={i18n.t('general.name')}
					value={this.state.name}
					onChange={(e) => this.setState({ name: e.target.value, dirty: true })} />

				<TextField
					fullWidth
					variant="outlined"
					type="number"
					required
					autoComplete="off"
					style={{ marginBottom: 16 }}
					label={i18n.t('serviceCalls.price')}
					value={this.state.price}
					onChange={(e) => this.setState({ price: e.target.value, dirty: true })}
					onBlur={(e) => this.validateEquipmentUsedPrice(e.target.value)} />

				<TextField
					fullWidth
					variant="outlined"
					type="number"
					required
					autoComplete="off"
					style={{ marginBottom: 16 }}
					label={i18n.t('tickets.quantity')}
					value={this.state.quantity}
					onChange={(e) => this.setState({ quantity: e.target.value, dirty: true })}
					onBlur={(e) => this.validateEquipmentUsedQuantity(e.target.value)} />

				<TextField
					fullWidth
					variant="outlined"
					autoComplete="off"
					style={{ marginBottom: 16 }}
					label={i18n.t('general.notes')}
					value={this.state.notes}
					onChange={(e) => this.setState({ notes: e.target.value, dirty: true })} />

				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 8 }}>
					<Button
						variant="text"
						loading={this.state.newEquipmentLoading}
						disabled={!this.isValid()}
						onClick={this.save}>
						{i18n.t('general.save')}
					</Button>

					<Button
						style={{ marginLeft: 8 }}
						variant="text"
						loading={this.state.newEquipmentLoading}
						onClick={() => this.setState({ deleteConfirmOpen: true })}>
						{i18n.t('general.delete')}
					</Button>
				</div>

				<Confirm
					title={i18n.t('serviceCalls.deleteEquipmentUsedTitle')}
					body={i18n.t('serviceCalls.deleteEquipmentUsedBody')}
					open={this.state.deleteConfirmOpen}
					confirmButtonText={i18n.t('general.delete')}
					onConfirm={this.delete}
					onCancel={() => this.setState({ deleteConfirmOpen: false })} />
			</Section>
		);
	}

	validateEquipmentUsedPrice(value) {
		let v = parseFloat(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		v = Math.round(v * 100) / 100;

		this.setState({
			price: v.toFixed(2)
		});
	}

	validateEquipmentUsedQuantity(value) {
		let v = parseInt(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		this.setState({
			quantity: v
		});
	}

	isValid() {
		return (
			typeof this.state.name === 'string' && this.state.name.length &&
			this.state.dirty
		);
	}

	save() {
		if (!this.isValid()) {
			return;
		}

		const {
			name,
			price,
			quantity,
			notes
		} = this.state;

		const { equipment, serviceCallId, client } = this.props;

		const { id } = equipment;

		this.setState({
			loading: true
		});

		client.mutate({
			mutation: EDIT_EQUIPMENT_USED,
			variables: {
				id,
				serviceCallId,
				name,
				price: parseFloat(price),
				quantity: parseInt(quantity),
				notes
			}
		}).then(() => {
			this.setState({
				loading: false,
				dirty: false
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false
			});
		});
	}

	delete() {
		const { equipment, serviceCallId, client } = this.props;

		const { id } = equipment;

		this.setState({
			loading: true
		});

		client.mutate({
			mutation: DELETE_EQUIPMENT_USED,
			variables: {
				id,
				serviceCallId
			}
		}).then(() => {
			this.setState({
				loading: false,
				dirty: false,
				deleteConfirmOpen: false
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false,
				deleteConfirmOpen: false
			});
		});
	}
}

const Equipment =  compose(
	withUI,
	withApollo
)(_Equipment);

class EquipmentUsed extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newEquipmentName: '',
			newEquipmentPrice: 100,
			newEquipmentQuantity: 1,
			newEquipmentNotes: '',
			newEquipmentLoading: false
		};

		this.isValid = this.isValid.bind(this);
		this.addEquipmentUsed = this.addEquipmentUsed.bind(this);
	}
	
	render() {
		return (
			<Query query={SERVICE_CALL} variables={{ id: this.props.serviceCall.id }}>
				{({ loading, error, data }) => {
					if (loading || error) {
						return null;
					}

					const serviceCall = data.serviceCall;

					return (
						<Grid container spacing={16}>
							<Grid item xs={12}>
								<Section>
									<Grid container spacing={16}>
										<Grid item xs={12} md={3}>
											<TextField
												required
												variant="outlined"
												fullWidth
												autoComplete="off"
												label={i18n.t('general.name')}
												value={this.state.newEquipmentName}
												onChange={(e) => this.setState({ newEquipmentName: e.target.value })} />
										</Grid>

										<Grid item xs={12} md={2}>
											<TextField
												fullWidth
												variant="outlined"
												type="number"
												required
												autoComplete="off"
												label={i18n.t('serviceCalls.price')}
												value={this.state.newEquipmentPrice}
												onChange={(e) => this.setState({ newEquipmentPrice: e.target.value })}
												onBlur={(e) => this.validateEquipmentUsedPrice(e.target.value)} />
										</Grid>

										<Grid item xs={12} md={2}>
											<TextField
												fullWidth
												variant="outlined"
												type="number"
												required
												autoComplete="off"
												label={i18n.t('tickets.quantity')}
												value={this.state.newEquipmentQuantity}
												onChange={(e) => this.setState({ newEquipmentQuantity: e.target.value })}
												onBlur={(e) => this.validateEquipmentUsedQuantity(e.target.value)} />
										</Grid>

										<Grid item xs={12} md={5}>
											<TextField
												fullWidth
												variant="outlined"
												autoComplete="off"
												label={i18n.t('general.notes')}
												value={this.state.newEquipmentNotes}
												onChange={(e) => this.setState({ newEquipmentNotes: e.target.value })} />
										</Grid>
									</Grid>

									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 8 }}>
										<Button
											icon={AddIcon}
											loading={this.state.newEquipmentLoading}
											disabled={!this.isValid()}
											onClick={() => this.addEquipmentUsed()}>
											{i18n.t('general.add')}
										</Button>
									</div>
								</Section>
							</Grid>

							{
								serviceCall.equipmentUsed.map((e, i) => (
									<Grid key={i} item xs={12} md={6} lg={4}>
										<Equipment equipment={e} serviceCallId={serviceCall.id} />
									</Grid>
								))
							}
						</Grid>
					);
				}}
			</Query>
		)
	}

	isValid() {
		return (
			typeof this.state.newEquipmentName === 'string' && this.state.newEquipmentName.length
		);
	}

	validateEquipmentUsedPrice(value) {
		let v = parseFloat(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		v = Math.round(v * 100) / 100;

		this.setState({
			newEquipmentPrice: v.toFixed(2)
		});
	}

	validateEquipmentUsedQuantity(value) {
		let v = parseInt(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		this.setState({
			newEquipmentQuantity: v
		});
	}

	addEquipmentUsed() {
		if (!this.isValid()) {
			return;
		}

		const {
			newEquipmentName,
			newEquipmentPrice,
			newEquipmentQuantity,
			newEquipmentNotes
		} = this.state;

		const { client, serviceCall } = this.props;

		this.setState({
			newEquipmentLoading: true
		});

		client.mutate({
			mutation: ADD_EQUIPMENT_USED,
			variables: {
				serviceCallId: serviceCall.id,
				name: newEquipmentName,
				price: parseFloat(newEquipmentPrice),
				quantity: parseInt(newEquipmentQuantity),
				notes: newEquipmentNotes
			}
		}).then(() => {
			this.setState({
				newEquipmentLoading: false,
				newEquipmentName: '',
				newEquipmentPrice: 100,
				newEquipmentQuantity: 1,
				newEquipmentNotes: ''
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				newEquipmentLoading: false
			});
		});
	}
}

export default compose(
	withUI,
	withApollo
)(EquipmentUsed);