import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'rgba(0, 0, 0, 0.3)',
		zIndex: 10000
	}
};

class FullScreenLoading extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				<CircularProgress color="secondary" className={classes.spinner} />
			</div>
		);
	}
}

export default withStyles(styles)(FullScreenLoading);