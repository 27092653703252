import gql from 'graphql-tag';

export const CREATE_TAG= gql`
mutation CreateTag(
    $en: String!
    $fr: String!) {
	createTag(
        fr: $fr
        en: $en
	) {
		id
        en
        fr
	}
}
`;

export const EDIT_TICKET = gql`
mutation EditTag(
	$id: ID!
	$en: String!
    $fr: String!) {
	editTag(
		id: $id
		fr: $fr
        en: $en
	) {
		id
		id
        en
        fr
  }
}
`;
