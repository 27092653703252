import {
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	TableFooter,
	IconButton,
	withStyles
} from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = (theme) => ({
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	spacer: {
		minWidth: theme.spacing.unit * 2,
		display: 'inline-block'
	},
	button: {
		'&:disabled': {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.54)'
		}
	},
	pageButton: {
		width: 24,
		height: 24,
		marginBottom: -3,
		paddingTop: 3,
		fontSize: '1rem'
	}
});

class DataTable extends Component {
	render() {
		const { classes, columns, rows, hasMore, hasLess, onNextPage, onPreviousPage } = this.props;

		return (
			<Paper className={classes.paper}>
				<Table padding="dense">
					<TableHead>
						<TableRow>
							{
								columns.map((column, i) => (
									<TableCell className={column.center ? classes.center : ''} key={i} align={column.align || 'inherit'}>
										{column.label}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rows.map((row, i) => (
								<TableRow key={i}>
									{
										columns.map((column, j) => (
											<TableCell className={column.center ? classes.center : ''} key={j} align={column.align || 'inherit'}>
												{row[column.key]}
											</TableCell>
										))
									}
								</TableRow>
							))
						}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell align="right" colSpan={columns.length}>
								<IconButton disabled={!hasLess} onClick={onPreviousPage}><KeyboardArrowLeft /></IconButton>
								<IconButton disabled={!hasMore} onClick={onNextPage}><KeyboardArrowRight /></IconButton>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		);
	}
};

export default withStyles(styles)(DataTable);