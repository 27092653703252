import {
  LinearProgress,
  Typography,
  IconButton,
  Divider
} from "@material-ui/core";
import { FullScreenError } from "components";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import moment from "moment";
import withStyles from "utils/withStyles";
import getGraphQLError from "utils/getGraphQLError";
import { SERVICE_CALLS } from "graph/queries";

const styles = theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },

  loading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },

  calendarHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },

  scrollContainer: {
    overflowY: "auto",
    flex: 1
  },

  day: {
    padding: theme.spacing.unit,
    "& a": {
      textDecoration: "none"
    }
  },

  calendarEvent: {
    borderRadius: 4,
    marginBottom: 4,
    padding: 4
  },

  grey: {
    background: "grey"
  },

  orange: {
    background: "#f08c07"
  },

  blue: {
    background: "#3f51b5"
  },

  green: {
    background: "#23bf00"
  }
});

class WeeklyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weekStart: moment().startOf("week")
    };

    this.previousWeek = this.previousWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
    this.currentWeek = this.currentWeek.bind(this);
  }

  render() {
    const { classes } = this.props;
    const { weekStart } = this.state;

    return (
      <div className={classes.container}>
        <Query
          query={SERVICE_CALLS}
          variables={{
            startDate: weekStart.toDate(),
            endDate: moment(weekStart)
              .endOf("week")
              .toDate()
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (error) {
              return <FullScreenError error={getGraphQLError(error)} />;
            }

            this.serviceCalls = loading ? [] : data.serviceCalls.serviceCalls;

            return this.renderCalendar(loading);
          }}
        </Query>
      </div>
    );
  }

  renderCalendar(loading) {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {loading ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
        {this.renderCalendarHeader()}
        <Divider />
        <div className={classes.scrollContainer}>
          <div style={{ paddingBottom: 100 }}>
            {[...Array(7)].map((n, i) => this.renderDay(i))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCalendarHeader() {
    const { classes } = this.props;
    const { weekStart } = this.state;

    return (
      <div className={classes.calendarHeader}>
        <IconButton onClick={this.previousWeek}>
          <NavigateBeforeIcon />
        </IconButton>

        <Typography
          variant="h5"
          style={{ cursor: "pointer" }}
          onClick={this.currentWeek}
        >
          {`${weekStart.format("MMM D")} - ${moment(weekStart)
            .endOf("week")
            .format("MMM D")}, ${weekStart.format("YYYY")}`}
        </Typography>

        <IconButton onClick={this.nextWeek}>
          <NavigateNextIcon />
        </IconButton>
      </div>
    );
  }

  renderDay(index) {
    const { classes } = this.props;
    const { weekStart } = this.state;

    const date = moment(weekStart).add(index, "days");

    return (
      <React.Fragment key={index}>
        <div className={classes.day}>
          <Typography variant="subtitle2">
            {date.format(i18n.t("formats.dateFriendly"))}
          </Typography>
          {this.renderEvents(date)}
        </div>
        <Divider style={{ marginTop: 16 }} />
      </React.Fragment>
    );
  }

  renderEvents(date) {
    const events = this.serviceCalls.filter(sc => {
      return moment(sc.date).isSame(date, "day");
    });

    return (
      <React.Fragment>
        {events.length ? (
          events.map((serviceCall, i) => (
            <CalendarEvent serviceCall={serviceCall} key={i} />
          ))
        ) : (
          <Typography variant="caption" style={{ fontStyle: "italic" }}>
            {i18n.t("serviceCalls.noServiceCalls")}
          </Typography>
        )}
      </React.Fragment>
    );
  }

  previousWeek() {
    this.setState({
      weekStart: moment(this.state.weekStart).add(-1, "week")
    });
  }

  nextWeek() {
    this.setState({
      weekStart: moment(this.state.weekStart).add(1, "week")
    });
  }

  currentWeek() {
    this.setState({
      weekStart: moment().startOf("week")
    });
  }
}

class _CalendarEvent extends Component {
  render() {
    const { serviceCall, classes } = this.props;

    const c = [classes.calendarEvent];

    if (serviceCall.status === "open") {
      c.push(classes.grey);
    } else if (serviceCall.status === "booked") {
      c.push(classes.orange);
    } else if (serviceCall.status === "complete") {
      c.push(classes.blue);
    } else if (serviceCall.status === "approved") {
      c.push(classes.green);
    }

    const techs = serviceCall.workItems.map(
      wi => `${wi.tech.firstName[0]}${wi.tech.lastName[0]}`
    );

    return (
      <Link
        to={{
          pathname: `/serviceCalls/${serviceCall.id}`,
          state: { title: i18n.t("title.serviceCall") }
        }}
      >
        <div className={c.join(" ")}>
          <Typography style={{ color: "#fff" }}>{`${
            serviceCall.ticket.project.name
          } (${techs.join("+")})`}</Typography>
        </div>
      </Link>
    );
  }
}

const CalendarEvent = withStyles(styles)(_CalendarEvent);

export default withStyles(styles)(WeeklyCalendar);
