// 	ticketDescription  in ticket and tickets

export const ticket = `
	id
	timeEstimate
	workOrder
	status
	createdAt
	updatedAt
	priority
	descriptions {
		id
		description
		category
		solved
		solveDescription
		solvedAt
		solvedBy {
			id
			firstName
			lastName
			type
		}
	}
	notes {
		id
		createdAt
		note
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	internalNotes {
		id
		createdAt
		note
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	tags {
		id
		en
		fr
	}
	project {
		id
		name
		warrantyExpiration
		warrantyStart
		address {
			line1
			line2
			city
			province
			country
			postalCode
		}
		clients {
			id
			firstName
			lastName
			email
		}
	}
	client {
		id
		firstName
		lastName
		email
		phones {
			label
			number
		}
	}
	admin {
		id
		firstName
		lastName
		email
	}
	invoice {
		id
		number
	}
`;

export const ticketDescriptions = `
	id
	descriptions {
		id
		category
		description
		solved
		solveDescription
		solvedAt
		solvedBy {
			id
			firstName
			lastName
			type
		}
	}
`;

export const ticketNotes = `
	id
	notes {
		id
		createdAt
		note
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	internalNotes {
		id
		createdAt
		note
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
`;

export const tickets = `
	id
	workOrder
	status
	createdAt
	updatedAt
	priority
	descriptions {
		id
		description
		solveDescription
		category
	}
	tags {
		id
		en
		fr
	}
	project {
		id
		name
		warrantyExpiration
		warrantyStart
		clients {
			id
			firstName
			lastName
			email
		}
	}
	client {
		id
		firstName
		lastName
		email
	}
	admin {
		id
		firstName
		lastName
		email
	}
	invoice {
		id
		number
	}
`;
