import { Section, StateChip, Button } from "components";
import { Typography, Divider } from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChatIcon from "@material-ui/icons/Chat";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import StoreIcon from "@material-ui/icons/Store";
import withStyles from "utils/withStyles";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { LENGTH_MAX_STRING } from "utils/constants";

const styles = {};

class ServiceCallCard extends Component {
  render() {
    const { serviceCall, classes, history } = this.props;

    return (
      <Section title={`${serviceCall.ticket.project.name}`}>
        <div style={{ marginBottom: 16 }}>
          <StateChip state={serviceCall.status} />
        </div>

        <div className={classes.flexRow}>
          <div>
            <ConfirmationNumberIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.ticketNumber")}</Typography>
            <Link
              to={{
                pathname: `/tickets/${serviceCall.ticket.id}`,
                state: {
                  title: `${i18n.t("tickets.ticket")} #${serviceCall.ticket.workOrder}`
                }
              }}
            >
              <Typography>#{serviceCall.ticket.workOrder}</Typography>
            </Link>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <StoreIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.project")}</Typography>
            <Link
              to={{
                pathname: `/projects/${serviceCall.ticket.project.id}`,
                state: {
                  title: serviceCall.ticket.project.name
                }
              }}
            >
              <Typography>{serviceCall.ticket.project.name}</Typography>
            </Link>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <CalendarIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("general.date")}</Typography>
            <Typography>
              {moment(serviceCall.date).format(i18n.t("formats.dateFriendly"))}
            </Typography>
          </div>
        </div>

        {serviceCall.workItems.map((wi, i) => (
          <React.Fragment key={i}>
            <Divider className={classes.divider} />

            <div className={classes.flexRow}>
              <div>
                <AssignmentIcon className={classes.icon} fontSize="large" />
              </div>
              <div className={classes.flexFill}>
                <Typography variant="subtitle2">{`${wi.tech.firstName} ${wi.tech.lastName}`}</Typography>
                <Typography>{`${moment(wi.startTime).format(i18n.t("formats.time"))} - ${moment(
                  wi.endTime
                ).format(i18n.t("formats.time"))}`}</Typography>
              </div>
            </div>
          </React.Fragment>
        ))}

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <ChatIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.categories")}</Typography>
            <Typography>
              {serviceCall.ticket.descriptions && serviceCall.ticket.descriptions.length > 0
                ? formatedDescriptionCategories(serviceCall.ticket)
                : "No Description"}
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ textAlign: "center" }}>
          <Button
            variant="text"
            onClick={() => {
              history.push(`/serviceCalls/${serviceCall.id}`, {
                title: `${i18n.t("title.serviceCall")}`
              });
            }}
          >
            {i18n.t("serviceCalls.viewFullServiceCall")}
          </Button>
        </div>
      </Section>
    );
  }

  formatedDescriptionCategories(ticket) {
    if (ticket.descriptions && ticket.descriptions.length > 0) {
      let text = "";

      ticket.descriptions.map((desc, i) => {
        text = text + `${i18n.t(`tickets.descriptionCategories.${desc.category}`)}, `;
      });

      text = text.substring(0, text.length - 2);
      if (text.length > LENGTH_MAX_STRING) {
        return text.substring(0, LENGTH_MAX_STRING);
      } else return text;
    } else return "";
  }
}

export default withRouter(withStyles(styles)(ServiceCallCard));
