import gql from 'graphql-tag';

export const PAYMENTS = gql`
	query payments(
		$clientId: String
		$chargeId: String
		$cursor: String # ending_before or starting_after
		$limit: Int
	) {
		payments(
			clientId: $clientId
			chargeId: $chargeId
			cursor: $cursor
			limit: $limit
		) {
			payments {
				createdAt
				amount
				liveMode
				chargeId
				captured
				customerId
				description
				metadata
				refunded
				status
			}
			hasMore
		}
	}
`;