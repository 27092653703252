import gql from 'graphql-tag';
import { projectKeyStore } from 'graph/types';

export const CREATE_KEYSTORE = gql`
mutation CreateKeyStore(
	$projectId: ID!
	$label: String!
	$value: String!) {
	createKeyStore(
		projectId: $projectId,
		label: $label,
		value: $value
	) {
		${projectKeyStore}
	}
}
`;

export const EDIT_KEYSTORE = gql`
mutation editKeyStore(
	$projectId: ID!
	$keyStoreId: ID!
	$value: String!) {
	editKeyStore(
		projectId: $projectId,
		keyStoreId: $keyStoreId,
		value: $value
	) {
		${projectKeyStore}
	}
}
`;

export const DELETE_KEYSTORE = gql`
mutation DeleteKeyStore(
	$projectId: ID!
	$keyStoreId: ID!) {
	deleteKeyStore(
		projectId: $projectId,
		keyStoreId: $keyStoreId
	) {
		${projectKeyStore}
	}
}
`;