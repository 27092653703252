import gql from 'graphql-tag';
import { tech } from 'graph/types';

export const CREATE_TECH = gql`
mutation CreateTech(
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String,
	$language: String!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!) {
	createTech(
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		phones: $phones,
		addresses: $addresses
	) {
		${tech}
	}
}
`;

export const EDIT_TECH = gql`
mutation EditTech(
	$id: ID!,
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String,
	$language: String!,
	$isDeactivated: Boolean!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!) {
	editTech(
		id: $id,
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		isDeactivated: $isDeactivated,
		phones: $phones,
		addresses: $addresses
	) {
		${tech}
	}
}
`;