import gql from 'graphql-tag';
import { project, projects, projectNotes, projectKeyStore } from 'graph/types';

export const PROJECT = gql`
	query project($id: ID!) {
		project(id: $id) {
			${project}
		}
	}
`;

export const PROJECTS = gql`
	query projects(
		$searchString: String
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		projects(
			searchString: $searchString
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			projects {
				${projects}
			}
		}
	}
`;

export const PROJECT_NOTES = gql`
	query projectNotes($id: ID!) {
		project(id: $id) {
			${projectNotes}
		}
	}
`;

export const PROJECT_KEYSTORE = gql`
	query projectKeyStore($id: ID!) {
		project(id: $id) {
			${projectKeyStore}
		}
	}
`;
