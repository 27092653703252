import {
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	TableFooter,
	IconButton,
	withStyles
} from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = (theme) => ({
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	spacer: {
		minWidth: theme.spacing.unit * 2,
		display: 'inline-block'
	},
	button: {
		'&:disabled': {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.54)'
		}
	},
	pageButton: {
		width: 24,
		height: 24,
		marginBottom: -3,
		paddingTop: 3,
		fontSize: '1rem'
	}
});

class DataTable extends React.PureComponent {
	render() {
		const { columns, rows, page, limit, count, sort, direction, classes, onChangeSort, onChangePage, footer } = this.props;

		const totalPages = Math.ceil(count / limit);

		const firstButton = Math.max(0, Math.min(totalPages - 11, page - 5));

		const numButtons = Math.min(totalPages, 11);

		return (
			<Paper className={classes.paper}>
				<Table padding="dense">
					<TableHead>
						<TableRow>
							{
								columns.map((column, i) => (
									<TableCell className={column.center ? classes.center : ''} key={i} align={column.align || 'inherit'}>
										{
											column.sortable ?
											<TableSortLabel active={sort === column.key} direction={direction} onClick={() => onChangeSort(column.key)}>
												{column.label}
											</TableSortLabel>
											:
											column.label
										}
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rows.map((row, i) => (
								<TableRow key={i}>
									{
										columns.map((column, j) => (
											<TableCell className={column.center ? classes.center : ''} key={j} align={column.align || 'inherit'}>
												{row[column.key]}
											</TableCell>
										))
									}
								</TableRow>
							))
						}
					</TableBody>
					{
						footer ? 
							<TableFooter>
								<TableRow>
									{
										(typeof count === 'number') ?
										<TableCell align="right" colSpan={columns.length}>
											<span>{page * limit + 1} - {Math.min((page + 1) * limit, count)} {i18n.t('general.of')} {count}</span>
											
											<div className={classes.spacer}></div>
											
											<IconButton disabled={page === 0} onClick={() => onChangePage(page - 1)}><KeyboardArrowLeft /></IconButton>

											{
												Array.apply(null, Array(numButtons)).map((x, i) => (
													<IconButton key={i} disabled={page === i + firstButton} className={classes.button} onClick={() => onChangePage(i + firstButton)}>
														<div className={classes.pageButton}>
															{i + 1 + firstButton}
														</div>
													</IconButton>
												))
											}

											<IconButton disabled={page >= Math.ceil(count / limit) - 1} onClick={() => onChangePage(page + 1)}><KeyboardArrowRight /></IconButton>
										</TableCell>
										: null
									}
								</TableRow>
							</TableFooter> : null
					}
					
				</Table>
			</Paper>
		);
	}
};

DataTable.defaultProps = {
	footer : true,
}

export default withStyles(styles)(DataTable);