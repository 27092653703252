import PropTypes from "prop-types";
import { Typography, Tabs, Tab, Divider } from "@material-ui/core";
import _isInteger from "lodash/isInteger";
import withStyles from "utils/withStyles";

const styles = theme => ({
  sectionPaper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing.unit * 2
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  },

  sectionPaperFlex: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column"
    }
  },

  sectionPaper__header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },

  sectionPaper__divider: {
    marginBottom: theme.spacing.unit * 2
  },

  sectionPaper__buttonContainer: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "6px 0px",
    marginRight: theme.spacing.unit,
    "& button": {
      marginLeft: theme.spacing.unit
    }
  }
});

class Section extends React.PureComponent {
  render() {
    const { flex, title, buttons, tabs, tabIndex, onChangeTab, classes, className } = this.props;

    let tabsNode = null;

    let tab = _isInteger(tabIndex) ? tabIndex : 0;

    let style = (tabs && tabs.length) || (buttons && buttons.length) ? { paddingTop: 0 } : {};

    const classNames = [className, classes.sectionPaper];

    if (this.props.style) {
      style = {
        ...this.props.style,
        ...style
      };
    }

    if (flex) {
      classNames.push(classes.sectionPaperFlex);
    }

    if (tabs && tabs.length) {
      tabsNode = (
        <React.Fragment>
          <Tabs value={tab} onChange={onChangeTab}>
            {tabs.map((tab, i) => (tab ? <Tab key={i} label={tab} /> : null))}
          </Tabs>
          <Divider className={classes.sectionPaper__divider} />
        </React.Fragment>
      );
    }

    return (
      <div className={classNames.join(" ")} style={style}>
        <div className={classes.sectionPaper__header}>
          {title ? <Typography variant="h6">{title}</Typography> : null}

          {buttons && buttons.length ? (
            <div className={classes.sectionPaper__buttonContainer}>{buttons}</div>
          ) : null}
        </div>

        {title || (buttons && buttons.length) ? (
          <Divider className={classes.sectionPaper__divider} />
        ) : null}

        {tabsNode}

        {this.props.children}
      </div>
    );
  }
}

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  flex: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.node),
  tabs: PropTypes.arrayOf(PropTypes.string),
  tabIndex: PropTypes.number,
  onChangeTab: PropTypes.func
};

export default withStyles(styles)(Section);
