import { Tooltip, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const styles = (theme) => ({
	container : {
		display: 'inline-block',
		verticalAlign: 'middle',
		margin: '1px 2px 0'
	},
	icon: {
		fontSize: '1rem'
	}
});

class HelpTooltip extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	render() {
		const { message, classes } = this.props;

		return (
			<div className={classes.container} onClick={() => this.setState({ open: true })}>
				<Tooltip title={message} placement="right" leaveTouchDelay={2500} open={this.state.open} onClose={() => this.setState({ open: false})}>
					<HelpIcon className={classes.icon} />
				</Tooltip>
			</div>
		);
		
	}
}

export default withStyles(styles)(HelpTooltip);