import {
	Toolbar,
	TextField,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import withStyles from 'utils/withStyles';
import {
	Section,
	Table,
	Page,
	PageBody
} from 'components';
import { Query } from 'react-apollo';
import { STRIPE_WEBHOOKS, MAILGUN_WEBHOOKS } from 'graph/queries';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},

	red: {
		color: 'red'
	},

	green: {
		color: 'green'
	}
});

class Webhooks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tabIndex: 0,

			stripeSearch: '',
			stripeSort: 'createdAt',
			stripeDir: 'desc',
			stripeLivemode: true,
			stripePage: 0,

			mailgunSearch: '',
			mailgunSort: 'createdAt',
			mailgunDir: 'desc',
			mailgunPage: 0
		}
	}

	render() {
		return (
			<Page>
				<PageBody
					tabs={['Stripe', 'Mailgun']}
					tabIndex={this.state.tabIndex}
					onChangeTab={(e, value) => this.setState({ tabIndex: value })}>
					<Section>
						{this.state.tabIndex === 0 && this.renderStripeWebhooks()}
						{this.state.tabIndex === 1 && this.renderMailgunWebhooks()}
					</Section>
				</PageBody>
			</Page>
		);
	}

	renderStripeWebhooks() {
		const { classes } = this.props;
		
		return (
			<Query query={STRIPE_WEBHOOKS} fetchPolicy={'network-only'} variables={{
				searchString: this.state.stripeSearch,
				livemode: this.state.stripeLivemode,
				sort: this.state.stripeSort,
				direction: this.state.stripeDir,
				limit: 10,
				page: this.state.stripePage
			}}>
				{({ loading, error, data, refetch }) => {
					this.refetch = refetch;

					return (<div>
						<Toolbar>
							<div className={classes.flexRow}>
								<TextField
									id="outlined-dense"
									label={i18n.t('general.search')}
									color="secondary"
									onChange={(e) => this.search(e.target.value, 'stripe')}
								/>

								<FormControlLabel
									label={i18n.t('webhooks.isLiveMode')}
									labelPlacement="end"
									control={
										<Checkbox
											color="primary"
											checked={this.state.stripeLivemode}
											onChange={(e) => this.setState({ stripeLivemode: e.target.checked })} />
									} />
							</div>
						</Toolbar>

						<Table
							page={this.state.stripePage}
							limit={10}
							sort={this.state.stripeSort}
							count={data && data.stripeWebhooks ? data.stripeWebhooks.count : 0}
							direction={this.state.stripeDir}
							onChangeSort={this.sortTable.bind(this, 'stripe')}
							onChangePage={(stripePage) => this.setState({ stripePage })}
							rows={
								(data && data.stripeWebhooks && data.stripeWebhooks.stripeWebhooks) ?
									data.stripeWebhooks.stripeWebhooks.map((webhook, i) => ({
										type: webhook.type,
										livemode: webhook.livemode ? <CheckCircleOutlineIcon className={classes.green} /> : <CloseIcon className={classes.red} />,
										createdAt: moment(webhook.createdAt).format('YYYY-MM-DD hh:mm A'),
										eventId: <a href={`https://dashboard.stripe.com/${webhook.livemode ? '' : 'test/'}events/${webhook.eventId}`} target="_blank">{webhook.eventId}</a>
									}))
									: []
							}
							columns={[{
								key: 'type',
								label: i18n.t('webhooks.type'),
								sortable: true
							}, {
								key: 'livemode',
								label: i18n.t('webhooks.isLiveMode'),
								center: true
							}, {
								key: 'createdAt',
								label: i18n.t('general.created'),
								sortable: true
							}, {
								key: 'eventId',
								label: i18n.t('webhooks.eventId'),
								sortable: true
							}]} />
					</div>);
				}}
			</Query>
		);
	}

	renderMailgunWebhooks() {
		const { classes } = this.props;
		
		return (
			<Query query={MAILGUN_WEBHOOKS} fetchPolicy={'network-only'} variables={{
				searchString: this.state.mailgunSearch,
				sort: this.state.mailgunSort,
				direction: this.state.mailgunDir,
				limit: 10,
				page: this.state.mailgunPage
			}}>
				{({ loading, error, data, refetch }) => {
					this.refetch = refetch;

					return (<div>
						<Toolbar>
							<div className={classes.flexRow}>
								<TextField
									id="outlined-dense"
									label={i18n.t('general.search')}
									color="secondary"
									onChange={(e) => this.search(e.target.value, 'mailgun')}
								/>
							</div>
						</Toolbar>

						<Table
							page={this.state.mailgunPage}
							limit={10}
							sort={this.state.mailgunSort}
							count={data && data.mailgunWebhooks ? data.mailgunWebhooks.count : 0}
							direction={this.state.mailgunDir}
							onChangeSort={this.sortTable.bind(this, 'mailgun')}
							onChangePage={(mailgunPage) => this.setState({ mailgunPage })}
							rows={
								(data && data.mailgunWebhooks && data.mailgunWebhooks.mailgunWebhooks) ?
									data.mailgunWebhooks.mailgunWebhooks.map((webhook, i) => ({
										event: webhook.event,
										createdAt: moment(webhook.createdAt).format('YYYY-MM-DD hh:mm A'),
										recipient: webhook.recipient,
										domain: webhook.domain,
										subject: webhook.subject
									}))
									: []
							}
							columns={[{
								key: 'event',
								label: i18n.t('webhooks.type'),
								sortable: true
							}, {
								key: 'createdAt',
								label: i18n.t('general.created'),
								sortable: true
							}, {
								key: 'recipient',
								label: i18n.t('webhooks.recipient'),
								sortable: true
							}, {
								key: 'domain',
								label: i18n.t('webhooks.domain'),
								sortable: true
							}, {
								key: 'subject',
								label: i18n.t('webhooks.subject')
							}]} />
					</div>);
				}}
			</Query>
		);
	}

	search(value, type) {
		if (type === 'stripe') {
			clearTimeout(this.stripeDebounce);

			this.stripeDebounce = setTimeout(() => {
				this.setState({
					stripeSearch: value
				});
			}, 500);
		} else if (type === 'mailgun') {
			clearTimeout(this.mailgunDebounce);

			this.mailgunDebounce = setTimeout(() => {
				this.setState({
					mailgunSearch: value
				});
			}, 500);
		}
	}

	sortTable(type, fieldName) {
		if (type === 'stripe') {
			let sortDir = 'asc';

			if (fieldName === this.state.stripeSort) {
				sortDir = (this.state.stripeDir === 'asc') ? 'desc' : 'asc';
			}

			this.setState({
				stripeSort: fieldName,
				stripeDir: sortDir
			});
		} else if (type === 'mailgun') {
			let sortDir = 'asc';

			if (fieldName === this.state.mailgunSort) {
				sortDir = (this.state.mailgunDir === 'asc') ? 'desc' : 'asc';
			}

			this.setState({
				mailgunSort: fieldName,
				mailgunDir: sortDir
			});
		}
	}
};

export default withStyles(styles)(Webhooks);