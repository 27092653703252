import { Page, PageBody, Section, Snackbar, StateChip, Grid, Descriptions } from "components";
import { Typography, IconButton, Divider } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import SignoffTab from "./SignoffTab";
import EquipmentUsed from "./Equipment";
import PriorityIcon from "@material-ui/icons/PriorityHigh";
import ChatIcon from "@material-ui/icons/Chat";
import MapIcon from "@material-ui/icons/Map";
import NavigationIcon from "@material-ui/icons/Navigation";
import PeopleIcon from "@material-ui/icons/People";
import StoreIcon from "@material-ui/icons/Store";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { compose, Query } from "react-apollo";
import { Link } from "react-router-dom";
import { withQueries } from "utils/withQuery";
import withStyles from "utils/withStyles";
import withUser from "utils/withUser";

import { SERVICE_CALL } from "graph/queries";

class ServiceCall extends Component {
  constructor(props) {
    super(props);

    this.serviceCall = props.queryResult.serviceCall
      ? props.queryResult.serviceCall.serviceCall
      : null;

    if (!this.serviceCall) {
      return;
    }

    this.state = {
      tabIndex: 0,
      techNotes: this.serviceCall.techNotes || "",
      workItems: this.serviceCall.workItems || [],
      signingTechName:
        this.serviceCall.signingTechName ||
        props.currentUser.firstName + " " + props.currentUser.lastName,
      signingClientName:
        this.serviceCall.signingClientName ||
        this.serviceCall.ticket.client.firstName + " " + this.serviceCall.ticket.client.lastName,
      techSignature: this.serviceCall.techSignature || [],
      clientSignature: this.serviceCall.clientSignature || []
    };

    this.onSignoffTabValueChanged = this.onSignoffTabValueChanged.bind(this);
  }

  render() {
    const { currentUser } = this.props;
    if (!this.serviceCall) {
      return (
        <Redirect
          to={{
            pathname: "/serviceCalls",
            state: { title: i18n.t("title.serviceCalls") }
          }}
        />
      );
    }

    return (
      <Page>
        <PageBody
          tabs={[
            i18n.t("serviceCalls.serviceCall"),
            i18n.t("serviceCalls.tasks"),
            i18n.t("serviceCalls.equipmentUsed"),
            i18n.t("serviceCalls.signoff")
          ]}
          tabIndex={this.state.tabIndex}
          onChangeTab={(e, value) => this.setState({ tabIndex: value })}
        >
          {this.serviceCall && this.state.tabIndex === 0 && this.renderServiceCallTab()}
          {this.serviceCall && this.state.tabIndex === 1 && this.renderTasksTab()}
          {this.serviceCall && this.state.tabIndex === 2 && this.renderEquipmentTab()}
          {this.serviceCall && this.state.tabIndex === 3 && this.renderSignoffTab()}
        </PageBody>

        <Snackbar
          message={this.state.snackbar || ""}
          open={!!this.state.snackbar}
          onClose={this.handleCloseSnackbar}
        />
      </Page>
    );
  }

  renderTasksTab() {
    return (
      <Descriptions currentUser={this.props.currentUser} ticketId={this.serviceCall.ticket.id} />
    );
  }

  renderServiceCallTab() {
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          {this.renderServiceCall()}
        </Grid>

        <Grid item xs={12} md={6}>
          {this.renderTicket()}
        </Grid>

        <Grid item xs={12} md={6}>
          {this.renderProject()}
        </Grid>
      </Grid>
    );
  }

  renderServiceCall() {
    const { classes } = this.props;

    return (
      <Section title={i18n.t("title.serviceCall")}>
        <div style={{ marginBottom: 16 }}>
          <StateChip state={this.serviceCall.status} />
        </div>

        <Grid container spacing={16}>
          <Grid item xs={12}>
            <div className={classes.flexRow}>
              <div>
                <CalendarIcon className={classes.icon} fontSize="large" />
              </div>
              <div className={classes.flexFill}>
                <Typography variant="subtitle2">{i18n.t("general.date")}</Typography>
                <Typography>
                  {moment(this.serviceCall.date).format(i18n.t("formats.dateFriendly"))}
                </Typography>
              </div>
            </div>

            {this.serviceCall.workItems.map((wi, i) => (
              <React.Fragment key={i}>
                <Divider className={classes.divider} />

                <div className={classes.flexRow}>
                  <div>
                    <AssignmentIcon className={classes.icon} fontSize="large" />
                  </div>
                  <div className={classes.flexFill}>
                    <Typography variant="subtitle2">{`${wi.tech.firstName} ${wi.tech.lastName}`}</Typography>
                    <Typography>{`${moment(wi.startTime).format(i18n.t("formats.time"))} - ${moment(
                      wi.endTime
                    ).format(i18n.t("formats.time"))}`}</Typography>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Section>
    );
  }

  renderTicket() {
    const { classes } = this.props;

    const ticket = this.serviceCall.ticket;

    return (
      <Section title={i18n.t("tickets.ticket")}>
        <div className={classes.flexRow}>
          <div>
            <ConfirmationNumberIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.ticketNumber")}</Typography>
            <Link
              to={{
                pathname: `/tickets/${this.serviceCall.ticket.id}`,
                state: {
                  title: `${i18n.t("tickets.ticket")} #${this.serviceCall.ticket.workOrder}`
                }
              }}
            >
              <Typography>#{this.serviceCall.ticket.workOrder}</Typography>
            </Link>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <PriorityIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.priority")}</Typography>
            <Typography>{ticket.priority}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <ChatIcon className={classes.icon} fontSize="large" />
          </div>
          {/* <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("general.description")}</Typography>
            <Typography>{ticket.ticketDescription}</Typography>
          </div> */}
        </div>
      </Section>
    );
  }

  renderProject() {
    const { classes } = this.props;

    const project = this.serviceCall.ticket.project;

    const address = `${project.address.line1}${
      project.address.line2 ? " " + project.address.line2 : ""
    }, ${project.address.city}, ${project.address.postalCode}`;

    return (
      <Section title={i18n.t("title.project")}>
        <div className={classes.flexRow}>
          <div>
            <StoreIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("projects.projectName")}</Typography>
            <Link
              to={{
                pathname: `/projects/${project.id}`,
                state: { title: project.name }
              }}
            >
              <Typography>{project.name}</Typography>
            </Link>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <MapIcon className={classes.icon} />

          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("general.address")}</Typography>
            <Typography>{address}</Typography>
          </div>

          <div style={{ textAlign: "center" }}>
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${escape(address)}`}
              target="_blank"
            >
              <IconButton color="primary">
                <NavigationIcon />
              </IconButton>
            </a>
            <Typography variant="caption">Directions</Typography>
          </div>
        </div>

        {project.clients.map((client, i) => (
          <React.Fragment key={i}>
            <Divider className={classes.divider} />

            <div className={classes.flexRow}>
              <PeopleIcon className={classes.icon} />

              <div className={classes.flexFill}>
                <Typography variant="subtitle2">{i18n.t("general.client")}</Typography>
                <Typography>{`${client.firstName} ${client.lastName}`}</Typography>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Section>
    );
  }

  renderEquipmentTab() {
    return <EquipmentUsed serviceCall={this.serviceCall} />;
  }

  renderSignoffTab() {
    const {
      techNotes,
      workItems,
      signingTechName,
      signingClientName,
      techSignature,
      clientSignature
    } = this.state;

    let tsig = techSignature;
    if (typeof tsig === "string") {
      tsig = JSON.parse(tsig);
    }

    let csig = clientSignature;
    if (typeof csig === "string") {
      csig = JSON.parse(csig);
    }

    return (
      <SignoffTab
        serviceCall={this.serviceCall}
        techNotes={techNotes}
        workItems={workItems}
        signingClientName={signingClientName}
        signingTechName={signingTechName}
        techSignature={tsig}
        clientSignature={csig}
        refetchQueries={this.props.refetchQueries}
        onChange={this.onSignoffTabValueChanged}
      />
    );
  }

  onSignoffTabValueChanged(field, value) {
    this.setState({
      [field]: value
    });
  }
}

export default compose(
  withStyles({}),
  withUser,
  withQueries(function() {
    const { match } = this.props;

    const queries = {
      serviceCall: {
        query: SERVICE_CALL,
        variables: {
          id: match.params.id
        }
      }
    };

    return queries;
  })
)(ServiceCall);
