import gql from 'graphql-tag';
import { tech, techs } from 'graph/types';

export const TECH = gql`
	query tech($id: ID!) {
		tech(id: $id) {
			${tech}
		}
	}
`;

export const TECHS = gql`
	query techs(
		$searchString: String
		$isVerified: Boolean
		$isDeactivated: Boolean
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		techs(
			searchString: $searchString
			isVerified: $isVerified
			isDeactivated: $isDeactivated
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			techs {
				${techs}
			}
		}
	}
`;