import {
	Typography,
	TextField,
	Chip,
	Popover,
	withStyles
} from '@material-ui/core';
import {
	Grid
} from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from 'components/Button/Button';

const styles = (theme) => ({
	phoneChip: {
		margin: theme.spacing.unit
	},
	popover: {
		padding: theme.spacing.unit * 2
	}
});

class Addresses extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			popover: false,
			anchorEl: null,
			label: '',
			line1: '',
			line2: '',
			city: '',
			province: '',
			country: '',
			postalCode: '',
			selectedAddress: null
		};

		this.addAddress = this.addAddress.bind(this);
		this.deleteAddress = this.deleteAddress.bind(this);
		this.onClickAddress = this.onClickAddress.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	render() {
		const { classes, error } = this.props;

		const { selectedAddress } = this.state;

		return (
			<div>
				<Typography variant="h6">{i18n.t('general.address', { count: 2 })}</Typography>
				
				<form style={{ margin: '16px 0' }} onKeyDown={(e) => { e.keyCode === 13 && this.addAddress(); }}>
					<Grid container spacing={24}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.label')}
								name="label"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.label} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}></Grid>
						<Grid item xs={12} sm={false} md={4}></Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.addresses.line1')}
								name="line1"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.line1} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								autoComplete="off"
								label={i18n.t('general.addresses.line2')}
								name="line2"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.line2} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.addresses.city')}
								name="city"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.city} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.addresses.province')}
								name="province"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.province} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.addresses.country')}
								name="country"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.country} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label={i18n.t('general.addresses.postalCode')}
								name="postalCode"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.postalCode} />
						</Grid>
					</Grid>
				</form>

				<div style={{ marginBottom: 16 }}>
					<Button color="primary" variant="contained" onClick={this.addAddress}>{i18n.t('general.add')} <AddIcon /></Button>
				</div>

				{
					(error) ?
						<Typography color="error">{i18n.t('admins.errors.atLeastOneAddress')}</Typography>
						: null
				}

				{
					this.props.addresses.map((address, i) => (
						<Chip
							key={i}
							color="primary"
							className={classes.phoneChip}
							label={`${address.label}: ${address.line1}`}
							deleteIcon={<DeleteIcon />}
							onDelete={() => this.deleteAddress(i)}
							onClick={(e) => this.onClickAddress(address, e)} />
					))
				}
				<Popover
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.popover}
					anchorEl={this.state.anchorEl}
					onClose={() => this.setState({ popover: false })}>
					
					<div className={classes.popover}>
						{
							(selectedAddress) ?
							<div>
								<Typography variant="subtitle2">{`${selectedAddress.label}:`}</Typography>
								<Typography>{`${selectedAddress.line1}`}</Typography>
								<Typography>{`${selectedAddress.line2}`}</Typography>
								<Typography>{`${selectedAddress.city}, ${selectedAddress.province}, ${selectedAddress.country}`}</Typography>
								<Typography>{`${selectedAddress.postalCode}`}</Typography>
							</div>
							: null
						}
					</div>

				</Popover>
			</div>
		);
	}

	addAddress() {
		const { onChange } = this.props;

		const {
			label,
			line1,
			line2,
			city,
			province,
			country,
			postalCode
		} = this.state;

		if (
			label &&
			line1 &&
			city &&
			province &&
			country &&
			postalCode
		) {
			onChange([
				...this.props.addresses,
				{
					label,
					line1,
					line2,
					city,
					province,
					country,
					postalCode
				}
			]);

			this.setState({
				label: '',
				line1: '',
				line2: '',
				city: '',
				province: '',
				country: '',
				postalCode: ''
			});
		}
	}

	deleteAddress(i) {
		const { addresses, onChange } = this.props;

		onChange([
			...addresses.slice(0, i),
			...addresses.slice(i + 1)
		]);
	}

	onClickAddress(address, e) {
		this.setState({
			anchorEl: e.target,
			popover: true,
			selectedAddress: address
		});
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
}

export default withStyles(styles)(Addresses);