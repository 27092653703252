import gql from 'graphql-tag';
import { client, clients } from 'graph/types';

export const CLIENT = gql`
	query client($id: ID!) {
		client(id: $id) {
			${client}
		}
	}
`;

export const CLIENTS = gql`
	query clients(
		$searchString: String
		$isVerified: Boolean
		$isDeactivated: Boolean
		$hasStripeCustomerId: Boolean
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		clients(
			searchString: $searchString
			isVerified: $isVerified
			isDeactivated: $isDeactivated
			hasStripeCustomerId: $hasStripeCustomerId
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			clients {
				${clients}
			}
			count
		}
	}
`;

export const GET_PAYMENT_METHODS = gql`
	query GetPaymentMethods($id: ID!) {
		getPaymentMethods(id: $id) {
			id
			type
			brand
			last4
			expMonth
			expYear
			name
			isDefault
		}
	}
`;