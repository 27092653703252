import { Query } from 'react-apollo';
import moment from 'moment';
import {
	PageBody,
	CursorTable
 } from 'components';
import { PAYMENTS } from 'graph/queries';

class PaymentHistory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chargeId: '',
			cursor: '',
			page: 0
		}

		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	render() {
		const { classes } = this.props;

		return (
			<Query query={PAYMENTS} variables={{ 
				chargeId: this.state.chargeId,
				cursor: this.state.cursor,
				limit: 10
			 }}>
				{({ loading, error, data }) => {
					this.data = data;

					return (
					<PageBody loading={loading} error={error} padding={0}>
						{
						data.payments ?
						<CursorTable
							hasMore={this.state.cursor === 'ending_before' || ((this.state.cursor === 'starting_after' || this.state.cursor === '') && data.payments.hasMore)}
							hasLess={this.state.page > 0 || (this.state.cursor === 'ending_before' && data.payments.hasMore)}
							onNextPage={this.onNextPage}
							onPreviousPage={this.onPreviousPage}
							rows={
								(data && data.payments && data.payments.payments) ?
								data.payments.payments.map((payment, i) => ({
									createdAt: moment(payment.createdAt).format('YYYY-MM-DD hh:mm A'),
									amount: `$${(payment.amount / 100).toFixed(2)}`,
									liveMode: payment.liveMode ? i18n.t('payments.live') : i18n.t('payments.test'),
									chargeId: <a className="link" href={`https://dashboard.stripe.com/${payment.liveMode ? '' : 'test/'}payments/${payment.chargeId}`} target="_blank">{payment.chargeId}</a>,
									customerId: <a className="link" href={`https://dashboard.stripe.com/${payment.liveMode ? '' : 'test/'}customers/${payment.customerId}`} target="_blank">{payment.customerId}</a>,
									status: payment.status
								}))
								: []
							}
							columns={[{
								key: 'createdAt',
								label: i18n.t('general.created')
							}, {
								key: 'amount',
								label: i18n.t('general.amount')
							}, {
								key: 'liveMode',
								label: i18n.t('payments.liveMode')
							}, {
								key: 'chargeId',
								label: i18n.t('payments.chargeId')
							}, {
								key: 'customerId',
								label: i18n.t('payments.customerId')
							}, {
								key: 'status',
								label: i18n.t('payments.status')
							}]} />: null
						}
					</PageBody>);
				}}
			</Query>
		);
	}

	onNextPage() {
		if (this.data && this.data.payments) {
			this.setState({
				cursor: 'starting_after',
				chargeId: this.data.payments.payments[this.data.payments.payments.length - 1].chargeId,
				page: this.state.page + 1
			});
		}
	}

	onPreviousPage() {
		if (this.data && this.data.payments && this.data.payments.payments.length && this.state.page >= 0) {
			this.setState({
				cursor: 'ending_before',
				chargeId: this.data.payments.payments[0].chargeId,
				page: Math.max(0, this.state.page - 1)
			});
		}
	}
}

export default PaymentHistory;