import { compose, withApollo, Query } from "react-apollo";
import withStyles from "utils/withStyles";
import withUI from "utils/withUI";
import withUser from "utils/withUser";
import { PROJECT_TICKETS } from "graph/queries";
import { Page, Grid, PageBody, TicketCard } from "components";
import { Typography } from "@material-ui/core";
class TicketHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Page>
        <Query
          query={PROJECT_TICKETS}
          fetchPolicy={"network-only"}
          variables={{ project: this.props.projectId }}
        >
          {({ loading, error, data }) => {
            const tickets =
              data && data.projectTickets && data.projectTickets.tickets.length > 0
                ? data.projectTickets.tickets
                : null;
            return (
              <PageBody loading={loading} error={error}>
                {loading ? null : tickets ? (
                  <Grid container spacing={16}>
                    {tickets.map((ticket, i) => (
                      <Grid key={i} item xs={12} md={6} lg={4}>
                        <TicketCard ticket={ticket} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography align="center">No Tickets found for this project</Typography>
                )}
                {}
              </PageBody>
            );
          }}
        </Query>
      </Page>
    );
  }
}

export default compose(
  withUser,
  withApollo,
  withUI,
  withStyles()
)(TicketHistory);
