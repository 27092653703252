import {
	FormControl,
	Input,
	InputLabel,
	Typography,
	withStyles
} from '@material-ui/core';
import Button from 'components/Button/Button';
import { withApollo } from 'react-apollo';

import { FORGOT_PASSWORD } from 'graph/mutations';
import FullPagePaper from 'components/FullPagePaper/FullPagePaper';

const styles = (theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},

	submit: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	},

	errorMessage: {
		marginTop: theme.spacing.unit,
		color: '#e81d24'
	}
});

class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			loading: false,
			done: false,
			error: false
		};
	}

	render() {
		const { classes } = this.props;

		return (
			<FullPagePaper title="My Homesync" subtitle={i18n.t('login.passwordRecovery')}>
				{
					(this.state.done) ?
					<div>
						<br />
						<Typography variant="body2">
							{i18n.t('login.passwordResetSent')}
						</Typography>
					</div>
					:
					<form className={classes.form} onSubmit={this.forgotPassword.bind(this)}>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="email">{i18n.t('login.email')}</InputLabel>
							<Input
								id="email"
								name="email"
								inputProps={{
									autoCapitalize: 'off',
									autoComplete: 'email',
									spellCheck: 'false',
									 autoCorrect: 'off'
								}}
								autoFocus
								value={this.state.email}
								onChange={(e) => this.setState({ email: e.target.value })} />
						</FormControl>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							className={classes.submit}
							loading={this.state.loading}>

							{i18n.t('general.submit')}
						</Button>

						{
							(this.state.error) ?
							<Typography className={classes.errorMessage} variant="caption">{i18n.t('login.emailNotFound')}</Typography>
							: null
						}
					</form>
				}
			</FullPagePaper>
		)
	}

	forgotPassword(e) {
		e.preventDefault();

		if (!this.state.email) {
			return;
		}

		const { client } = this.props;

		this.setState({
			loading: true
		});

		client.mutate({
			mutation: FORGOT_PASSWORD,
			variables: {
				email: this.state.email
			}
		}).then((res) => {
			this.setState({
				loading: false,
				done: true
			});
		}).catch((e) => {
			this.setState({
				loading: false,
				error: true
			});
		});
	}
}

export default withApollo(withStyles(styles)(ForgotPassword));