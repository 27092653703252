import gql from "graphql-tag";
import { serviceCall, serviceCalls } from "graph/types";

export const SERVICE_CALL = gql`
	query serviceCall($id: ID!) {
		serviceCall(id: $id) {
			${serviceCall}
		}
	}
`;

export const TICKET_SERVICE_CALLS = gql`
	query serviceCalls(
		$ticketId: ID
	) {
		serviceCalls(
			ticketId: $ticketId
		) {
			count
			serviceCalls {
				${serviceCalls}
			}
		}
	}
`;

export const SERVICE_CALLS = gql`
	query serviceCalls(
		$status: String
		$sort: String
		$direction: SortDirection
		$page: Int
		$limit: Int
		$startDate: Date
		$endDate: Date
	) {
		serviceCalls(
			status: $status
			sort: $sort
			direction: $direction
			page: $page
			limit: $limit
			startDate: $startDate
			endDate: $endDate
		) {
			count
			serviceCalls {
				${serviceCalls}
			}
		}
	}
`;
