import {
	Paper,
	Typography,
	withStyles
} from '@material-ui/core';

const styles = (theme) => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		transform: 'translateY(64px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	}
});

class FullPagePaper extends Component {
	render() {
		const { classes, title, subtitle } = this.props;

		return (
			<div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Typography component="h1" variant="h5">
							{title}
						</Typography>

						<img src={require('assets/logo-mark.png')} />

						<Typography component="h1" variant="h5">
							{subtitle}
						</Typography>

						{this.props.children}
					</Paper>
				</main>
			</div>
		)
	}
}

export default withStyles(styles)(FullPagePaper);