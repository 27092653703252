export const tech = `
	id
	email
	firstName
	lastName
	language
	company
	isVerified
	isDeactivated
	type
	phones {
		label
		number
	}
	addresses {
		label
		line1
		line2
		city
		province
		country
		postalCode
	}
`;

export const techs = `
	id
	email
	firstName
	lastName
	language
	isVerified
	isDeactivated
	type
	phones {
		label
		number
	}
`;