import {
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Divider,
	Switch,
	RadioGroup,
	Radio,
	FormControlLabel,
	Popover,
	Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import { Mutation, withApollo, compose } from 'react-apollo';
import { Prompt } from 'react-router-dom';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import withQuery from 'utils/withQuery';
import withUser from 'utils/withUser';
import withUI from 'utils/withUI';
import withStyles from 'utils/withStyles';
import getGraphQLError from 'utils/getGraphQLError';
import {
	TextField,
	Section,
	Addresses,
	Phones,
	Button,
	Page,
	PageHeader,
	PageBody,
	Banner,
	SearchBar,
	Chip,
	Snackbar,
	ClientPaymentMethods,
	Grid
} from 'components';
import { RESEND_VERIFICATION_EMAIL, CREATE_CLIENT, EDIT_CLIENT } from 'graph/mutations';
import { CLIENT, SEARCH_PROJECTS } from 'graph/queries';

const styles = (theme) => ({
	paper: {
		margin: theme.spacing.unit,
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap'
	},

	buttonContainer: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginRight: theme.spacing.unit
	},

	formContainer: {
		padding: theme.spacing.unit * 3,
		paddingTop: 0
	},

	input: {
		display: 'block',
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	},

	divider: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	},

	snackbarCloseButton: {
		padding: theme.spacing.unit / 2
	}
});

class _Client extends Component {
	constructor(props) {
		super(props);

		this.client = props.queryResult && props.queryResult.data && props.queryResult.data.client || {};

		const { type, __typename, ...clientFields } = this.client;

		this.state = {
			firstName: clientFields.firstName || '',
			lastName: clientFields.lastName || '',
			email: clientFields.email || '',
			company: clientFields.company || '',
			language: clientFields.language || 'en',
			addresses: clientFields.addresses || [],
			addressError: false,
			phones: clientFields.phones || [],
			phoneError: false,
			isDeactivated: clientFields.isDeactivated,
			tabIndex: 0,
			loading: false,
			verificationEmailSent: false,
			resendingVerificationEmail: false,
			errors: [],
			snackbar: false,
			projectMode: 'new',
			newProjectName: '',
			newProjectLine1: '',
			newProjectLine2: '',
			newProjectCity: '',
			newProjectProvince: '',
			newProjectCountry: '',
			newProjectPostalCode: '',
			projectSearchString: '',
			projects: clientFields.projects || [],
			dirty: false
		};

		this.saveClient = this.saveClient.bind(this);
		this.setAddresses = this.setAddresses.bind(this);
		this.setPhones = this.setPhones.bind(this);
		this.getBannerErrorMessage = this.getBannerErrorMessage.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						this.client && this.client.id ?
						<Button
							color="primary"
							variant="contained"
							icon={AddIcon}
							onClick={() => this.props.history.push('/invoices/create', {
								invoiceClientId: this.client.id
							})}>
							{i18n.t('invoices.invoice')}
						</Button>
						: null
					}
					
					{
						currentUser && currentUser.hasPermission('clients', 1) &&
						<Button
							color="primary"
							variant="contained"
							disabled={!this.state.dirty}
							icon={SaveIcon}
							onClick={this.saveClient}
							loading={this.state.loading}>
							{i18n.t('general.save')}
						</Button>
					}
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<PageBody>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<Section title={i18n.t('admins.generalInfo')}>{this.renderGeneralInfo()}</Section>
						</Grid>

						<Grid item xs={12}>
							<Section title={i18n.t('admins.contactInfo')}>{this.renderContactInfo()}</Section>
						</Grid>

						<Grid item xs={12}>
							<Section title={i18n.t('title.projects')}>{this.client && this.client.id ? this.renderExistingClientProjects() : this.renderNewClientProjects()}</Section>
						</Grid>

						{
							(this.client.id) ?
							<Grid item xs={12}>
								<Section title={i18n.t('payments.paymentMethods')}>{this.renderPaymentMethods()}</Section>
							</Grid>
							: null
						}
					</Grid>
				</PageBody>

				<Snackbar
					message={i18n.t('admins.editAdminComplete')}
					open={this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />
			</Page>
		);
	}

	renderGeneralInfo() {
		const { classes } = this.props;

		return (
			<div className={classes.formContainer}>
				<Grid container spacing={24}>
					<Grid item xs={12} sm={6} lg={4}>
						<TextField
							required
							fullWidth
							className={classes.input}
							label={i18n.t('general.email')}
							type="email"
							name="email"
							variant="outlined"
							inputProps={{
								autoCapitalize: 'off',
								autoComplete: 'off'
							}}
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.email.length}
							helperText={(this.state.errors.length && !this.state.email.length) ? i18n.t('general.required') : ''}
							value={this.state.email} />

						<TextField
							required
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.firstName')}
							name="firstName"
							variant="outlined"
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.firstName.length}
							helperText={(this.state.errors.length && !this.state.firstName.length) ? i18n.t('general.required') : ''}
							value={this.state.firstName} />

						<TextField
							required
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.lastName')}
							name="lastName"
							variant="outlined"
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.lastName.length}
							helperText={(this.state.errors.length && !this.state.lastName.length) ? i18n.t('general.required') : ''}
							value={this.state.lastName} />

						<TextField
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.company')}
							name="company"
							variant="outlined"
							onChange={this.handleInputChange}
							value={this.state.company} />

						<FormControl className={classes.input}>
							<InputLabel>{i18n.t('general.language')}</InputLabel>
							<Select value={this.state.language} onChange={(e) => this.setState({ language: e.target.value, dirty: true })}>
								<MenuItem value="en">English</MenuItem>
								<MenuItem value="fr">Français</MenuItem>
							</Select>
						</FormControl>

						{
							this.client && this.client.id ?
							<FormControl className={classes.input}>
								<FormControlLabel
									label={i18n.t('admins.accountActive')}
									control={
										<Switch checked={!this.state.isDeactivated} onChange={() => this.setState({ isDeactivated: !this.state.isDeactivated, dirty: true })} />
									} />
							</FormControl>
							: null
						}
					</Grid>
				</Grid>

				<Divider className={classes.divider} />

				{
					(this.client.id && !this.client.isVerified) ?
					<Mutation mutation={RESEND_VERIFICATION_EMAIL}>
						{(resendVerificationEmail, { error, data, loading }) => (
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									resendVerificationEmail({
										variables: {
											id: this.client.id
										}
									})
								}}
								loading={loading}
								disabled={data && data.resendVerificationEmail}
								icon={SendIcon}>
								{(data && data.resendVerificationEmail) ? i18n.t('general.sent') : i18n.t('admins.resendAccountVerificationEmail')}
							</Button>
						)}
					</Mutation>
					: null
				}
			</div>
		);
	}

	renderContactInfo() {
		const { classes } = this.props;

		return (
			<div className={classes.formContainer}>
				<Addresses addresses={this.state.addresses} onChange={this.setAddresses} error={this.state.addressError} />

				<Divider />

				<Phones phones={this.state.phones} onChange={this.setPhones} error={this.state.phoneError} />
			</div>
		);
	}

	renderNewClientProjects() {
		const { classes } = this.props;

		return (
			<div className={classes.formContainer}>
				<div>
					<RadioGroup
						className={[classes.flexRow, classes.textField].join(' ')}
						value={this.state.projectMode}
						onChange={(e) => this.setState({ projectMode: e.target.value })}>

						<FormControlLabel
							value="new"
							control={<Radio color="primary" />}
							label={i18n.t('clients.newProject')}
							labelPlacement="end" />

						<FormControlLabel
							value="existing"
							control={<Radio color="primary" />}
							label={i18n.t('clients.existingProjects')}
							labelPlacement="end" />
					</RadioGroup>
				</div>

				{
					(this.state.projectMode === 'new') ?
					<Grid container spacing={24}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.name')}
								name="newProjectName"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectName.length}
								helperText={(this.state.errors.length && !this.state.newProjectName.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectName} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}></Grid>
						<Grid item xs={12} sm={false} md={4}></Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.addresses.line1')}
								name="newProjectLine1"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectLine1.length}
								helperText={(this.state.errors.length && !this.state.newProjectLine1.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectLine1} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								label={i18n.t('general.addresses.line2')}
								name="newProjectLine2"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.newProjectLine2} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.addresses.city')}
								name="newProjectCity"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectCity.length}
								helperText={(this.state.errors.length && !this.state.newProjectCity.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectCity} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.addresses.province')}
								name="newProjectProvince"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectProvince.length}
								helperText={(this.state.errors.length && !this.state.newProjectProvince.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectProvince} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.addresses.country')}
								name="newProjectCountry"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectCountry.length}
								helperText={(this.state.errors.length && !this.state.newProjectCountry.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectCountry} />
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								required
								label={i18n.t('general.addresses.postalCode')}
								name="newProjectPostalCode"
								variant="outlined"
								error={!!this.state.errors.length && !this.state.newProjectPostalCode.length}
								helperText={(this.state.errors.length && !this.state.newProjectPostalCode.length) ? i18n.t('general.required') : ''}
								onChange={this.handleInputChange}
								value={this.state.newProjectPostalCode} />
						</Grid>
					</Grid>
					:
					this.renderExistingClientProjects()
				}
			</div>
		);
	}

	renderExistingClientProjects() {
		const { classes } = this.props;

		return (
			<div style={{ minHeight: 150 }}>
				<SearchBar
					value={this.state.projectSearchString}
					required={true}
					label={i18n.t('general.search')}
					query={SEARCH_PROJECTS}
					onChange={(value) => this.setState({ projectSearchString: value })}
					getQueryResultItemLabel={(project) => project.name}
					onSelect={(project) => this.selectProject(project)} />

				<div className={classes.flexRow}>
					{
						this.state.projects.map((project, i) => {
							return (
								<Chip
									key={i}
									label={project.name}
									onDelete={() => this.removeProject(i)}
									onClick={(e) => this.onClickProjectChip(project, e)} />
							)
						})
					}
				</div>
			</div>
		);
	}

	renderPaymentMethods() {
		return <ClientPaymentMethods hsClient={this.client} />
	}

	selectProject(project) {
		if (_find(this.state.projects, { id: project.id })) {
			return;
		}

		this.setState({
			projects: [
				...this.state.projects,
				project
			],
			dirty: true
		});
	}

	removeProject(index) {
		this.setState({
			projects: [
				...this.state.projects.slice(0, index),
				...this.state.projects.slice(index + 1)
			],
			dirty: true
		});
	}

	onClickProjectChip(project, e) {
		this.props.history.push('/projects/' + project.id, {
			state: { title: project.name }
		});
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}

	saveClient() {
		const { client } = this.props;

		const {
			email,
			firstName,
			lastName,
			company,
			language,
			phones,
			addresses,
			isDeactivated,
			projects,
			projectMode
		} = this.state;

		const errors = [];

		if (!email) errors.push(i18n.t('admins.errors.emailRequired'));
		if (!firstName) errors.push(i18n.t('admins.errors.firstNameRequired'));
		if (!lastName) errors.push(i18n.t('admins.errors.lastNameRequired'));
		if (!addresses.length) errors.push(i18n.t('admins.errors.atLeastOneAddress'));
		if (!phones.length) errors.push(i18n.t('admins.errors.atLeastOnePhone'));
		if ((!this.client || !this.client.id) && projectMode === 'existing' && !projects.length) errors.push(i18n.t('clients.errors.atLeastOneProject'))
		if ((!this.client || !this.client.id) && projectMode === 'new') {
			if (!this.state.newProjectName ||
				!this.state.newProjectLine1 ||
				!this.state.newProjectCity ||
				!this.state.newProjectProvince ||
				!this.state.newProjectCountry ||
				!this.state.newProjectPostalCode) {
					errors.push(i18n.t('clients.errors.projectRequired'))
				}
		}

		if (errors.length) {
			this.setState({
				errors
			});
			return;
		}

		const mutation = (this.client && this.client.id) ? EDIT_CLIENT : CREATE_CLIENT;

		const variables = {
			email,
			firstName,
			lastName,
			company,
			language,
			phones: phones.map((phone) => ({
				label: phone.label,
				number: phone.number
			})),
			addresses: addresses.map((address) => ({
				label: address.label,
				line1: address.line1,
				line2: address.line2,
				city: address.city,
				province: address.province,
				country: address.country,
				postalCode: address.postalCode
			})),
			isDeactivated
		}

		if (this.client && this.client.id) {
			variables.projects = projects.map((p) => p.id);
			variables.id = this.client.id;
		} else {
			if (projectMode === 'new') {
				variables.newProject = {
					name: this.state.newProjectName,
					address: {
						label: '',
						line1: this.state.newProjectLine1,
						line2: this.state.newProjectLine2,
						city: this.state.newProjectCity,
						province: this.state.newProjectProvince,
						country: this.state.newProjectCountry,
						postalCode: this.state.newProjectPostalCode
					}
				};
			} else {
				variables.projects = projects.map((p) => p.id);
			}
		}

		this.setState({
			loading: true,
			errors
		});

		client.mutate({
			mutation,
			variables
		}).then((res) => {
			if (this.client && this.client.id) {
				this.setState({
					loading: false,
					dirty: false,
					snackbar: true
				});
			} else {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/clients', {
						title: i18n.t('title.clients')
					});
				});
			}
		}).catch((e) => {
			this.setState({
				loading: false,
				dirty: false
			})
			this.props.ui.showError(getGraphQLError(e));
		});
	}

	setAddresses(addresses) {
		this.setState({
			addresses,
			addressError: false,
			dirty: true
		});
	}

	setPhones(phones) {
		this.setState({
			phones,
			phoneError: false,
			dirty: true
		});
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			dirty: true
		});
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}
}

const Client = compose(
	withUI,
	withApollo,
	withUser,
	withQuery,
	withStyles(styles)
)(_Client);

export default class ClientContainer extends Component {
	render() {
		const { match } = this.props;
		
		if (match.params && match.params.id) {
			return (
				<Client query={CLIENT} variables={{ id: match.params.id }} {...this.props} />
			);
		} else {
			return (
				<Client {...this.props} />
			);
		}
	}
}