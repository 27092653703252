import PropTypes from 'prop-types';
import _isInteger from 'lodash/isInteger';
import withStyles from 'utils/withStyles';
import {
	Tabs,
	Tab,
	Divider,
	LinearProgress
}  from '@material-ui/core';
import {
	FullScreenError
} from 'components';
import getGraphQLError from 'utils/getGraphQLError';

const styles = (theme) => ({
	container: {
		flex: 1,
		flexDirection: 'column',
		display: 'flex',
		overflow: 'auto',
		background: theme.palette.background.default
	},

	tabBar: {
		background: '#fff'
	},

	content: {
		padding: theme.spacing.unit,
		paddingBottom: 100,
		overflow: 'auto',
		marginTop: 4,
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing.unit * 2,
			paddingBottom: 100
		}
	}
});

class PageBody extends Component {
	render() {
		const { loading, error, padding, flex, tabIndex, tabs, onChangeTab, classes } = this.props;

		let tab = _isInteger(tabIndex) ? tabIndex : 0;

		let tabsNode = null;

		const style = _isInteger(padding) ? { padding } : {};

		if (flex) {
			style.flex = 1;
			style.paddingBottom = 16;
			style.display = 'flex';
		}

		if (loading) {
			style.marginTop = 0;
		}

		if (tabs && tabs.length) {
			tabsNode = (
				<div className={classes.tabBar}>
					<Tabs value={tab} onChange={onChangeTab} variant="scrollable">
						{
							tabs.map((tab, i) => tab ? <Tab key={i} label={tab} /> : null)
						}
					</Tabs>
					<Divider className={classes.sectionPaper__divider} />
				</div>
			);
		}
		
		if (error) {
			return <FullScreenError error={getGraphQLError(error)} />
		} else {
			return (
				<div className={classes.container}>
					{tabsNode}

					{
						loading ?
						<LinearProgress />
						: null
					}
	
					<div className={classes.content} style={style}>
						{this.props.children}
					</div>
				</div>
			);
		}
	}
}

PageBody.propTypes = {
	flex: PropTypes.bool,
	padding: PropTypes.number
};

export default withStyles(styles)(PageBody);