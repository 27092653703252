import PropTypes from 'prop-types';
import {
	Typography,
	Paper,
	Divider,
	IconButton
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from 'utils/withStyles';

const styles = (styles) => ({

})

class TechCard extends Component {
	render() {
		const { tech, onRemove, classes, style } = this.props;

		return (
			<Paper className={classes.card} style={style}>
				<div className={classes.flexRow}>
					<PersonIcon className={classes.icon} />
					<Typography style={{ flex: 1 }} variant="subtitle2">{tech.firstName} {tech.lastName}</Typography>
					{
						onRemove && typeof onRemove === 'function' ?
						<IconButton
							onClick={onRemove}>
							<CloseIcon fontSize="small" />
						</IconButton>
						: null
					}
				</div>
				
				{this.renderBody()}

				{this.renderEmail()}

				{this.renderPhones()}
			</Paper>
		);
	}

	renderBody() {
		const { classes } = this.props;
		
		if (this.props.children) {
			return (
				<React.Fragment>
					<Divider className={classes.divider} />

					{this.props.children}
				</React.Fragment>
			);
		}

		return null;
	}

	renderEmail() {
		const { tech, classes } = this.props;

		if (this.props.showEmail) {
			return (
				<React.Fragment>
					<Divider className={classes.divider} />

					<div className={classes.flexRow}>
						<EmailIcon className={classes.icon} />
						<Typography>{tech.email}</Typography>
					</div>
				</React.Fragment>
			);
		}

		return null;
	}

	renderPhones() {
		const { tech, classes } = this.props;
		
		if (this.props.showPhones) {
			return (
				<div>
					{
						tech.phones.map((phone, i) => (
							<React.Fragment key={i}>
								<Divider className={classes.divider} />

								<div className={classes.flexRow}>
									<PhoneIcon className={classes.icon} />
									<div>
										<Typography variant="subtitle2">{phone.label}</Typography>
										<Typography>{phone.number}</Typography>
									</div>
								</div>
							</React.Fragment>
						))
					}
				</div>
			);
		}

		return null;
	}
}

TechCard.propTypes = {
	showEmail: PropTypes.bool,
	showPhones: PropTypes.bool,
	onRemove: PropTypes.func,
	tech: PropTypes.object.isRequired,
	style: PropTypes.object
};

TechCard.defaultProps = {
	showEmail: true,
	showPhones: true,
	style: {}
};

export default withStyles(styles)(TechCard);