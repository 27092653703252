import PropTypes from 'prop-types';
import {
	Chip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from 'utils/withStyles';

const styles = (theme) => ({
	chip: {
		margin: theme.spacing.unit
	}
})

class _Chip extends React.PureComponent {
	render() {
		const { label, onDelete, onClick, classes } = this.props;

		return (
			<Chip
				color="primary"
				className={classes.chip}
				label={label}
				deleteIcon={onDelete ? <DeleteIcon /> : null}
				onDelete={onDelete}
				onClick={onClick} />
		);
	}
}

_Chip.propTypes = {
	label: PropTypes.string,
	color: PropTypes.string,
	onDelete: PropTypes.func,
	onClick: PropTypes.func
};

_Chip.defaultProps = {
	color: 'primary'
};

export default withStyles(styles)(_Chip);