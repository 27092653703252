import { Page, PageBody, Section, Snackbar, Notes, KeyStore, CheckLists, Grid } from "components";
import { Typography, IconButton, Divider } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import PeopleIcon from "@material-ui/icons/People";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import NavigationIcon from "@material-ui/icons/Navigation";
import { Query } from "react-apollo";
import withStyles from "utils/withStyles";
import { PROJECT } from "graph/queries";

const styles = {};

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0
    };
  }

  render() {
    const { match } = this.props;

    return (
      <Page>
        <Query query={PROJECT} variables={{ id: match.params.id }}>
          {({ loading, error, data }) => {
            const project = data && data.project ? data.project : null;

            return (
              <PageBody
                flex={this.state.tabIndex === 1 || this.state.tabIndex === 2}
                loading={loading}
                error={error}
                tabs={[
                  i18n.t("title.project"),
                  i18n.t("tickets.notes"),
                  i18n.t("tickets.internalNotes"),
                  i18n.t("projects.keyStore"),
                  i18n.t("projects.checklists")
                ]}
                tabIndex={this.state.tabIndex}
                onChangeTab={(e, value) => this.setState({ tabIndex: value })}
              >
                {project && this.state.tabIndex === 0 && this.renderProjectDetails(project)}
                {project && this.state.tabIndex === 1 && this.renderNotes(project)}
                {project && this.state.tabIndex === 2 && this.renderNotes(project, true)}
                {project && this.state.tabIndex === 3 && this.renderKeyStore(project)}
                {project && this.state.tabIndex === 4 && this.renderCheckLists(project)}
              </PageBody>
            );
          }}
        </Query>

        <Snackbar
          message={this.state.snackbar || ""}
          open={!!this.state.snackbar}
          onClose={this.handleCloseSnackbar}
        />
      </Page>
    );
  }

  renderProjectDetails(project) {
    const { classes } = this.props;

    const address = `${project.address.line1}${
      project.address.line2 ? " " + project.address.line2 : ""
    }, ${project.address.city}, ${project.address.postalCode}`;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Section title={project.name}>
            <div className={classes.flexRow}>
              <MapIcon className={classes.icon} />

              <div className={classes.flexFill}>
                <Typography variant="subtitle2">{i18n.t("general.address")}</Typography>
                <Typography>{address}</Typography>
              </div>

              <div style={{ textAlign: "center" }}>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${escape(address)}`}
                  target="_blank"
                >
                  <IconButton color="primary">
                    <NavigationIcon />
                  </IconButton>
                </a>
                <Typography variant="caption">Directions</Typography>
              </div>
            </div>

            {project.clients.map((client, i) => (
              <React.Fragment key={i}>
                <Divider className={classes.divider} />

                <div className={classes.flexRow}>
                  <PeopleIcon className={classes.icon} />

                  <div className={classes.flexFill}>
                    <Typography variant="subtitle2">{i18n.t("general.client")}</Typography>
                    <Typography>{`${client.firstName} ${client.lastName}`}</Typography>
                  </div>
                </div>

                <div className={classes.flexRow} style={{ paddingLeft: 16, marginTop: 8 }}>
                  <MailIcon className={classes.icon} />

                  <div className={classes.flexFill}>
                    <Typography variant="subtitle2">{i18n.t("general.email")}</Typography>
                    <Typography>{client.email}</Typography>
                  </div>
                </div>

                {client.phones.map((phone, i) => (
                  <React.Fragment key={i}>
                    <div className={classes.flexRow} style={{ paddingLeft: 16, marginTop: 8 }}>
                      <PhoneIcon className={classes.icon} />

                      <div className={classes.flexFill}>
                        <Typography variant="subtitle2">{phone.label}</Typography>
                        <Typography>{phone.number}</Typography>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </Section>
        </Grid>
      </Grid>
    );
  }

  renderNotes(project, isInternal) {
    return <Notes parentId={project.id} schemaType="project" isInternal={!!isInternal} />;
  }

  renderKeyStore(project) {
    return <KeyStore projectId={project.id} />;
  }

  renderCheckLists(project) {
    return <CheckLists projectId={project.id} />;
  }
}

export default withStyles(styles)(Project);
