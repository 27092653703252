import {
	Query,
	withApollo,
	compose
} from 'react-apollo';
import { CardElement, Elements, injectStripe } from 'react-stripe-elements';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Checkbox,
	Divider
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import _orderBy from 'lodash/orderBy';
import {
	Button,
	Loading,
	FullScreenError
} from 'components';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';

import { CREATE_PAYMENT_METHOD, SET_DEFAULT_SOURCE } from 'graph/mutations';
import { GET_PAYMENT_METHODS } from 'graph/queries';

const styles = (theme) => ({

});

class _ClientPaymentMethods extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stripeElementsVisible: false,
			cardComplete: false,
			loading: false,
			defaultSource: null,
			defaultSourceLoading: false
		};

		this.elementStyle = {
			base: {
				fontSize: '16px'
			}
		};

		this.savePaymentMethod = this.savePaymentMethod.bind(this);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderStripeElements()}
				<Divider style={{ margin: '16px 0' }} />
				{this.renderPaymentMethods()}
			</React.Fragment>
		);
	}

	renderStripeElements() {
		const { classes } = this.props;

		if (this.state.stripeElementsVisible) {
			return (
				<div className={classes.flexRow}>
					<div style={{ flex: 1, marginRight: 16 }}>
						<CardElement style={this.elementStyle} onChange={(c) => this.setState({ cardComplete: c.complete })} />
					</div>

					<Button
						color="primary"
						variant="contained"
						disabled={!this.state.cardComplete}
						loading={this.state.loading}
						icon={SaveIcon}
						onClick={this.savePaymentMethod}>
						{i18n.t('general.save')}
					</Button>
				</div>
			);
		} else {
			return (
				<div className={classes.flexRow}>
					<div style={{ flex: 1 }}></div>
					<Button
						color="primary"
						variant="contained"
						icon={AddIcon}
						onClick={() => this.setState({ stripeElementsVisible: true })}>
						{i18n.t('payments.addPaymentMethod')}
					</Button>
				</div>
			);
		}
	}

	renderPaymentMethods() {
		const { hsClient, classes } = this.props;

		return (
			<div>
				<Query query={GET_PAYMENT_METHODS} variables={{ id: hsClient.id }}>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loading />
						} else if (error) {
							return <FullScreenError error={getGraphQLError(error)} />
						}
						
						return (
							<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">{i18n.t('payments.brand')}</TableCell>
									<TableCell align="left">{i18n.t('general.name')}</TableCell>
									<TableCell align="left">{i18n.t('payments.cardNumber')}</TableCell>
									<TableCell align="left">{i18n.t('payments.expiry')}</TableCell>
									<TableCell align="center">{i18n.t('payments.isDefault')}</TableCell>
								</TableRow>
							</TableHead>
								<TableBody>
									{
										_orderBy(data.getPaymentMethods, 'last4', 'desc').map((source, i) => (
											<TableRow key={i}>
												<TableCell align="left">{source.brand}</TableCell>
												<TableCell align="left">{source.name}</TableCell>
												<TableCell align="left">****{source.last4}</TableCell>
												<TableCell align="left">{source.expMonth < 10 ? '0' + source.expMonth : source.expMonth}/{source.expYear}</TableCell>
												<TableCell align="center">
													{
														((this.state.defaultSource ? source.id === this.state.defaultSource : source.isDefault) && this.state.defaultSourceLoading) ?
														<div style={{ padding: 14 }}>
															<Loading color="primary" size="small" />
														</div>
														:
														<Checkbox
															color="primary"
															checked={this.state.defaultSource ? source.id === this.state.defaultSource : source.isDefault}
															onChange={(e) => this.setDefaultSource(source, e)} />
													}
												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						)
					}}
				</Query>
			</div>
		);
	}

	savePaymentMethod() {
		const { client, hsClient, stripe } = this.props;

		if (this.state.loading) {
			return;
		}

		this.setState({
			loading: true
		});

		stripe.createToken({
			name: `${hsClient.firstName} ${hsClient.lastName}`,
		}).then((res) => {
			if (res.error) {
				this.setState({
					loading: false,
					error: true
				});
			} else {
				client.mutate({
					mutation: CREATE_PAYMENT_METHOD,
					variables: {
						id: hsClient.id,
						stripeToken: res.token.id
					},
					refetchQueries: [{
						query: GET_PAYMENT_METHODS,
						variables: { id: hsClient.id }
					}],
					awaitRefetchQueries: true
				}).then((res) => {
					this.setState({
						loading: false,
						error: false,
						stripeElementsVisible: false
					});
				}).catch((err) => {
					this.setState({
						loading: false,
						error: true
					});
				});
			}
		});
	}

	setDefaultSource(source, e) {
		if (!e.target.checked) {
			return;
		}

		const { client, hsClient } = this.props;

		const oldDefault = this.state.defaultSource;

		this.setState({
			defaultSource: source.id,
			defaultSourceLoading: true
		});

		client.mutate({
			mutation: SET_DEFAULT_SOURCE,
			variables: {
				id: hsClient.id,
				cardId: source.id
			},
			refetchQueries: [{
				query: GET_PAYMENT_METHODS,
				variables: { id: hsClient.id }
			}],
			awaitRefetchQueries: true
		}).then(() => {
			this.setState({
				defaultSourceLoading: false
			})
		}).catch((e) => {
			this.setState({
				defaultSource: oldDefault,
				defaultSourceLoading: false
			});

			this.props.ui.showError(getGraphQLError(e));
		});
	}
}

const ClientPaymentMethods = compose(
	withApollo,
	withUI,
	withStyles(styles),
	injectStripe
)(_ClientPaymentMethods);

class ClientPaymentMethodsContainer extends Component {
	render() {
		const locale = i18n.default.language ? i18n.default.language.substring(0, 2) + '-CA' : 'en-CA';

		return (
			<Elements locale={locale}>
				<ClientPaymentMethods {...this.props} />
			</Elements>
		)
	}
}

export default ClientPaymentMethodsContainer;