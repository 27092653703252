import KeyStoreItem from './KeyStoreItem';
import KeyStoreTemplate from './KeyStoreTemplate';
import { withApollo, Query, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withUser from 'utils/withUser';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
import {
	LinearProgress,
	Fab,
	withStyles,
	Typography
} from '@material-ui/core';
import {
	Button,
	TextField,
	Snackbar,
	Section,
	Grid
} from 'components';

import { PROJECT_KEYSTORE } from 'graph/queries';
import { CREATE_KEYSTORE } from 'graph/mutations';

const styles = (theme) => ({
	buttonGrid: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'right'
		}
	},

	editButton: {
		position: 'fixed',
		bottom: 16,
		right: 16
	}
});

const sections = [
	'.....',
	'Router',
	'WiFi',
	'Control4',
	'Access Point',
	'Alarm',
	'Garage',
	'DDNS',
	'Crestron',
	'Lutron',
	'NVR',
	'Camera',
	'VPN',
	'Managed Switch',
	'Sonos'
];

const templates = [
	'Router IP',
	'Router Username',
	'Router Password',
	'Router DHCP Range',
	'WiFi SSID',
	'WiFi Security Key',
	'WiFi Security Type',
	'Control4 Account Name',
	'Control4 Email',
	'Control4 Password',
	'Control4 OS Version',
	'Control4 2.8.0+ Automatic Backup (Sunday 4AM) Setup?',
	'Access Point Username',
	'Access Point Password',
	'Access Point 1 IP',
	'Access Point 2 IP',
	'Access Point 3 IP',
	'Access Point 4 IP',
	'Access Point 5 IP',
	'Alarm Code',
	'Garage Door Code',
	'DDNS Management Website',
	'DDNS Resolution Address',
	'DDNS Username',
	'DDNS Password',
	'Crestron Processor IP',
	'Crestron Gateway IP 1',
	'Crestron Gateway IP 2',
	'Crestron Gateway IP 3',
	'Crestron Gateway IP 4',
	'Lutron Processor IP 1',
	'Lutron Processor IP 2',
	'NVR IP',
	'NVR Username',
	'NVR Password',
	'Camera 1 IP',
	'Camera 2 IP',
	'Camera 3 IP',
	'Camera 4 IP',
	'Camera 5 IP',
	'Camera 6 IP',
	'Camera 7 IP',
	'Camera 8 IP',
	'Camera 9 IP',
	'Camera 10 IP',
	'VPN Username',
	'VPN Password',
	'Managed Switch 1 IP',
	'Managed Switch 1 Username',
	'Managed Switch 1 Password',
	'Managed Switch 2 IP',
	'Managed Switch 2 Username',
	'Managed Switch 2 Password',
	'Sonos Username',
	'Sonos Password'
];

class KeyStore extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newKeyLabel: '',
			newKeyValue: '',
			keyStoreLoading: false,
			snackbar: false,
			editMode: false
		};

		this.addKeyStoreItem = this.addKeyStoreItem.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.closeEditMode = this.closeEditMode.bind(this);
		this.onDirty = this.onDirty.bind(this);

		this.dirtyKeys = {};
	}

	render() {
		const { projectId } = this.props;

		return (
			<Query query={PROJECT_KEYSTORE} variables={{ id: projectId }}>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<div className={classes.container}>
								<LinearProgress />
							</div>
						)	
					} else if (error) {
						return <FullScreenError error={getGraphQLError(error)} />
					}

					const keys = data.project.keyStore;
					
					if (this.state.editMode) {
						return this.renderEditView(keys);
					} else {
						return this.renderDefaultView(keys);
					}
				}}
			</Query>
		)
	}

	renderDefaultView(keys) {
		const { projectId, classes, currentUser } = this.props;

		let editingEnabled = false;

		if (currentUser && currentUser.type === 'Admin' && currentUser.hasPermission('projects', 1)) {
			editingEnabled = true;
		} else if(currentUser && currentUser.type === 'Tech') {
			editingEnabled = true;
		}

		const matchedTemplates = _filter(templates, (template) => {
			return !!_find(keys, { label: template });
		}).map((template, i) => {
			const match = _find(keys, { label: template });
			
			return {
				label: template,
				value: match ? match.value : '',
				id: match ? match.id : ''
			};
		});

		const unmatchedItems = _filter(keys, (key) => {
			return !_find(matchedTemplates, { id: key.id });
		});

		let sectionCounter = 0;

		return (
			<React.Fragment>
				<Grid container spacing={16}>
				{
					matchedTemplates.map((template, i) => {
						let sectionTitle = sections[sectionCounter];

						if (template.label.indexOf(sectionTitle) === 0) {
							return (
								<Grid key={i} item xs={12} sm={6} lg={4}>
									<KeyStoreItem item={template} projectId={projectId} />
								</Grid>
							);
						} else {
							sectionCounter++;
							sectionTitle = sections[sectionCounter];

							return (
								<React.Fragment key={i}>
									<Grid item xs={12}>
										<Typography variant="h5">{sectionTitle}</Typography>
									</Grid>

									<Grid item xs={12} sm={6} lg={4}>
									<KeyStoreItem item={template} projectId={projectId} />
									</Grid>
								</React.Fragment>
							);
						}
					})
				}

				{
					unmatchedItems.length ?
					<Grid item xs={12}>
						<Typography variant="h5">Custom</Typography>
					</Grid>
					: null
				}

				{
					unmatchedItems.map((template, i) => {
						return (
							<Grid key={i} item xs={12} sm={6} lg={4}>
								<KeyStoreItem item={template} projectId={projectId} />
							</Grid>
						);
					})
				}
				</Grid>

				{
					editingEnabled ?
					<Fab className={classes.editButton} color="primary" onClick={() => this.setState({ editMode: true })}>
						<EditIcon />
					</Fab>
					: null
				}

				<Snackbar
					message={this.state.snackbar || ''}
					open={!!this.state.snackbar}
					onClose={this.handleCloseSnackbar} />
			</React.Fragment>
		)
	}

	renderEditView(keys) {
		const { classes, projectId } = this.props;

		const matchedTemplates = templates.map((template, i) => {
			const match = _find(keys, { label: template });
			
			return {
				label: template,
				value: match ? match.value : '',
				id: match ? match.id : ''
			};
		});

		const unmatchedItems = _filter(keys, (key) => {
			return !_find(matchedTemplates, { id: key.id });
		});

		let sectionCounter = 0;

		return (
			<React.Fragment>
				<Grid container spacing={16}>
				{
					matchedTemplates.map((template, i) => {
						let sectionTitle = sections[sectionCounter];

						if (template.label.indexOf(sectionTitle) === 0) {
							return (
								<Grid key={i} item xs={12} sm={6} lg={4}>
									<KeyStoreTemplate item={template} projectId={projectId} onDirty={this.onDirty} />
								</Grid>
							);
						} else {
							sectionCounter++;
							sectionTitle = sections[sectionCounter];

							return (
								<React.Fragment key={i}>
									<Grid item xs={12}>
										<Typography variant="h5">{sectionTitle}</Typography>
									</Grid>

									<Grid item xs={12} sm={6} lg={4}>
										<KeyStoreTemplate item={template} projectId={projectId} onDirty={this.onDirty} />
									</Grid>
								</React.Fragment>
							);
						}
					})
				}

				<Grid item xs={12}>
					<Typography variant="h5">Custom</Typography>
				</Grid>

				{
					unmatchedItems.map((template, i) => {
						return (
							<Grid key={i} item xs={12} sm={6} lg={4}>
								<KeyStoreTemplate item={template} projectId={projectId} onDirty={this.onDirty} />
							</Grid>
						);
					})
				}
				</Grid>

				<br />

				<Section>
					<Grid container spacing={16} alignItems="center">
						<Grid item xs={12} sm={4} md={3}>
							<TextField
								label={i18n.t('general.label')}
								fullWidth
								name="newKeyLabel"
								variant="outlined"
								style={{ marginRight: 16 }}
								onChange={(e) => this.setState({ newKeyLabel: e.target.value })}
								value={this.state.newKeyLabel} />
						</Grid>

						<Grid item xs={12} sm={4} md={3}>
							<TextField
								label={i18n.t('general.value')}
								fullWidth
								name="newKeyValue"
								variant="outlined"
								style={{ marginRight: 16 }}
								onChange={(e) => this.setState({ newKeyValue: e.target.value })}
								value={this.state.newKeyValue} />
						</Grid>

						<Grid item xs={12} sm={4} md={3} lg={2} className={classes.buttonGrid}>
							<Button
								color="primary"
								variant="contained"
								loading={this.state.keyStoreLoading}
								icon={AddIcon}
								disabled={!this.state.newKeyValue || !this.state.newKeyLabel}
								onClick={this.addKeyStoreItem}
							>{i18n.t('projects.addCustom')}</Button>
						</Grid>
					</Grid>
				</Section>

				<Fab className={classes.editButton} color="primary" onClick={this.closeEditMode}>
					<ReplyIcon />
				</Fab>
			</React.Fragment>
		)
	}

	addKeyStoreItem() {
		const { client, projectId } = this.props;

		if (this.state.newKeyLabel && this.state.newKeyValue) {
			this.setState({
				keyStoreLoading: true
			});

			client.mutate({
				mutation: CREATE_KEYSTORE,
				variables: {
					projectId,
					label: this.state.newKeyLabel,
					value: this.state.newKeyValue
				}
			}).then((res) => {
				this.setState({
					keyStoreLoading: false,
					snackbar: i18n.t('projects.keyStoreCreated'),
					newKeyLabel: '',
					newKeyValue: ''
				});
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					keyStoreLoading: false
				});
			});
		}
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	closeEditMode() {
		if (Object.keys(this.dirtyKeys).length > 0) {
			if (window.confirm(i18n.t('general.unsavedChangesPrompt'))) {
				this.setState({ editMode: false });
			}
		} else {
			this.setState({ editMode: false });
		}
	}

	onDirty(label, isDirty) {
		const { history } = this.props;

		if (isDirty) {
			this.dirtyKeys[label] = true;

			if (!this.unblock) {
				this.unblock = history.block(i18n.t('general.unsavedChangesPrompt'));
			}
		} else {
			delete this.dirtyKeys[label];

			if (Object.keys(this.dirtyKeys).length === 0) {
				if (typeof this.unblock === 'function') {
					this.unblock();
					this.unblock = null;
				}
			}
		}
	}
}

export default compose(
	withStyles(styles),
	withUI,
	withUser,
	withRouter,
	withApollo,
)(KeyStore);