import { withStyles } from '@material-ui/core/styles';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Typography } from '@material-ui/core';
import _isArray from 'lodash/isArray';

const styles = {
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
};

class FullScreenError extends Component {
	render() {
		const { classes, error } = this.props;

		let errorMessage = i18n.t('general.genericError');

		if (typeof error === 'string') {
			errorMessage = error;
		} else if (_isArray(error)) {
			errorMessage = (
				<ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
					{
						error.map((m, i) => (
							<li key={i}>{m}</li>
						))
					}
				</ul>
			);
		}

		return (
			<div className={classes.container}>
				<ErrorOutline fontSize="large" />
				
				<Typography variant="subtitle1">
					{errorMessage}
				</Typography>
			</div>
		);
	}
}

export default withStyles(styles)(FullScreenError);