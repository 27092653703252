export const admin = `
	id
	email
	firstName
	lastName
	language
	company
	phones {
		label
		number
	}
	addresses {
		label
		line1
		line2
		city
		province
		country
		postalCode
	}
	isVerified
	isDeactivated
	type
	permissions {
		key
		level
	}
`;

export const admins = `
	id
	email
	firstName
	lastName
	language
	company
	isVerified
	isDeactivated
	type
	permissions {
		key
		level
	}
`;