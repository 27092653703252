import gql from 'graphql-tag';
import { checklist } from 'graph/types';

export const CHECKLISTS = gql`
	query checklists($projectId: ID!) {
		checklists(projectId: $projectId) {
			${checklist}
		}
	}
`;

export const CHECKLIST = gql`
	query checklist($id: ID!) {
		checklist(id: $id) {
			${checklist}
		}
	}
`;