import { withApollo, Query, compose } from 'react-apollo';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';

import {
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';

import {
	Loading,
	FullScreenError,
	FullScreenLoading,
	Page,
	PageHeader,
	PageBody,
	Section,
	ClientPaymentMethods,
	Button,
	InvoiceEmail,
	Confirm,
	Grid
} from 'components';

import getGraphQLError from 'utils/getGraphQLError';

import { PAY_INVOICE_FOR_CLIENT } from 'graph/mutations';
import { INVOICE } from 'graph/queries';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({

});

class _PayInvoice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			payInvoiceModalVisible: false,
			payee: null,
			loading: false
		};

		this.payInvoice = this.payInvoice.bind(this);
	}

	render() {
		const { match } = this.props;

		return (
			<Query query={INVOICE} variables={{ id: match.params.id }}>
				{({ loading, error, data }) => {
					if (loading) {
						return <Loading />
					} else if (error) {
						return <FullScreenError error={getGraphQLError(error)} />
					}

					const invoice = data.invoice;

					const invoiceTotal = this.getInvoiceTotal(invoice);
					const stripeFee = 0 // this.getStripeTransactionFee(invoice);
					const finalCharge = stripeFee + invoiceTotal;

					if (invoice.state !== 'sent') {
						return <FullScreenError error={i18n.t('invoices.errors.onlyPaySentInvoice')} />
					}

					if (!invoice.clients || !invoice.clients.length) {
						return <FullScreenError error={i18n.t('invoices.errors.noSelectedClients')} />
					}

					return (
						<Page>
							<PageHeader></PageHeader>

							<PageBody>
								<Grid container spacing={16} style={{ padding: 16 }}>
									<Grid item xs={12}>
										{
											invoice.clients.map((client, i) => {
												return (
													<Section
														key={i}
														title={`${client.firstName} ${client.lastName}`}
														buttons={[
															<Button
																key={1}
																icon={PaymentIcon}
																onClick={() => this.setState({ payInvoiceModalVisible: true, payee: client })}>
																{i18n.t('payments.payWithSelectedCard')}
															</Button>
														]}>
														<ClientPaymentMethods hsClient={client} />
													</Section>
												);
											})
										}
									</Grid>

									<Grid item xs={12}>
										<Section>
											<Grid container>
												<Grid item xs={12} md={6}>
													<Table style={{ marginTop: 16, marginBottom: 16 }}>
														<TableBody>
															<TableRow>
																<TableCell><Typography>{i18n.t('payments.invoiceTotal')}</Typography></TableCell>
																<TableCell align="right"><Typography>${invoiceTotal.toFixed(2)}</Typography></TableCell>
															</TableRow>

															<TableRow>
																<TableCell><Typography style={{ fontWeight: 500 }}>{i18n.t('payments.finalCharge')}</Typography></TableCell>
																<TableCell align="right"><Typography style={{ fontWeight: 500 }}>${finalCharge.toFixed(2)}</Typography></TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</Grid>

												<Grid item xs={12} md={6}>
													<InvoiceEmail
														invoice={invoice}
														project={invoice.project}
														client={invoice.clients[0]} />
												</Grid>
											</Grid>
										</Section>
									</Grid>
								</Grid>
							</PageBody>

							<Confirm
								title={i18n.t('payments.payInvoice')}
								body={this.renderPaymentConfirmBody(invoice)}
								open={this.state.payInvoiceModalVisible}
								confirmButtonText={i18n.t('payments.payInvoice')}
								onConfirm={this.payInvoice}
								onCancel={() => this.setState({ payInvoiceModalVisible: false })} />

							{this.state.loading ? <FullScreenLoading /> : null}

						</Page>
					);
				}}
			</Query>
		);
	}

	renderPaymentConfirmBody(invoice) {
		const client = this.state.payee;

		if (!client) return null;

		const invoiceTotal = this.getInvoiceTotal(invoice);
		const stripeFee = 0 // this.getStripeTransactionFee(invoice);
		const finalCharge = stripeFee + invoiceTotal;

		return (
			<Typography>
				{i18n.t('payments.adminPayInvoice1')}
				{`${client.firstName} ${client.lastName}`}
				{i18n.t('payments.adminPayInvoice2')}
				{`$${finalCharge.toFixed(2)}`}
			</Typography>
		);
	}

	payInvoice() {
		const { client, match } = this.props;

		const payee = this.state.payee;
		const invoiceId = match.params.id;

		if (!payee || !invoiceId) {
			return;
		}

		this.setState({
			payInvoiceModalVisible: false,
			loading: true
		});

		client.mutate({
			mutation: PAY_INVOICE_FOR_CLIENT,
			variables: {
				invoiceId,
				clientId: payee.id
			},
			refetchQueries: [{
				query: INVOICE,
				variables: { id: invoiceId }
			}],
			awaitRefetchQueries: true
		}).then(() => {
			this.props.history.push('/invoices/' + invoiceId);
		}).catch((e) => {
			this.setState({
				loading: false
			});
			this.props.ui.showError(getGraphQLError(e));
		})
	}

	getInvoiceTotal(invoice) {
		let subTotal = 0;

		for (let item of invoice.items) {
			subTotal += item.rate * item.quantity;
		}

		return Math.round((subTotal + (subTotal * 0.05) + (subTotal * 0.09975)) * 100) / 100;
	}

	getStripeTransactionFee(invoice) {
		const total = this.getInvoiceTotal(invoice);

		return Math.round(((total + 0.3) / (1 - 0.029) - total) * 100) / 100;
	}
}

export default compose(
	withStyles(styles),
	withUI,
	withApollo,
)(_PayInvoice);