export const checklist = `
	id
	project {
		id
		name
	}
	createdAt
	name
	items {
		id
		text
		checked
	}
`;
