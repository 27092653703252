import './vendor/normalize.css';
import './style.scss';
import 'es6-promise/auto';
import './locales';

import ReactDOM from 'react-dom';
import App from './app';

import LngDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import en from './i18n/en.json';
import fr from './i18n/fr.json';

const main = document.createElement('div');
main.style.width = '100%';
main.style.height = '100%';
main.style.overflow = 'hidden';
document.body.append(main);

i18n
	.use(LngDetector)
	.init({
		fallbackLng: 'en',
		resources: {
			en,
			fr
		}
	}, function(e) {
		moment.locale(i18n.default.language);
		ReactDOM.render(<App />, main);
	});