import {
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Divider,
	Switch,
	FormControlLabel,
	withStyles,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import { Prompt } from 'react-router-dom';
import { Mutation, withApollo, compose } from 'react-apollo';
import _filter from 'lodash/filter';
import withQuery from 'utils/withQuery';
import withUser from 'utils/withUser';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import {
	TextField,
	Section,
	Permissions,
	Addresses,
	Phones,
	Button,
	Page,
	PageHeader,
	PageBody,
	Banner,
	Snackbar,
	Grid
} from 'components';
import { RESEND_VERIFICATION_EMAIL, CREATE_ADMIN, EDIT_ADMIN } from 'graph/mutations';
import { ADMIN } from 'graph/queries';

const styles = (theme) => ({
	paper: {
		margin: theme.spacing.unit,
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap'
	},

	buttonContainer: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginRight: theme.spacing.unit
	},

	formContainer: {
		padding: theme.spacing.unit * 3,
		paddingTop: 0
	},

	input: {
		display: 'block',
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit
	},

	divider: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	},

	snackbarCloseButton: {
		padding: theme.spacing.unit / 2
	}
});

class _Admin extends Component {
	constructor(props) {
		super(props);

		this.admin = props.queryResult && props.queryResult.data && props.queryResult.data.admin || {};

		const { type, __typename, ...adminFields } = this.admin;

		this.state = {
			firstName: adminFields.firstName || '',
			lastName: adminFields.lastName || '',
			email: adminFields.email || '',
			company: adminFields.company || '',
			language: adminFields.language || 'en',
			addresses: adminFields.addresses || [],
			addressError: false,
			phones: adminFields.phones || [],
			phoneError: false,
			permissions: adminFields.permissions || [],
			isDeactivated: adminFields.isDeactivated,
			tabIndex: 0,
			loading: false,
			verificationEmailSent: false,
			resendingVerificationEmail: false,
			errors: [],
			snackbar: false,
			dirty: false
		};

		this.saveAdmin = this.saveAdmin.bind(this);
		this.setAddresses = this.setAddresses.bind(this);
		this.setPhones = this.setPhones.bind(this);
		this.setPermissions = this.setPermissions.bind(this);
		this.getBannerErrorMessage = this.getBannerErrorMessage.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						currentUser && currentUser.hasPermission('admins', 1) &&
						<div className={classes.buttonContainer}>
							<Button
								color="primary"
								variant="contained"
								disabled={!this.state.dirty}
								icon={SaveIcon}
								onClick={this.saveAdmin}
								loading={this.state.loading}>
								{i18n.t('general.save')}
							</Button>
						</div>
					}
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<PageBody>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<Section title={i18n.t('admins.generalInfo')}>{this.renderGeneralInfo()}</Section>
						</Grid>

						<Grid item xs={12}>
							<Section title={i18n.t('admins.contactInfo')}>{this.renderContactInfo()}</Section>
						</Grid>

						<Grid item xs={12}>
							<Section title={i18n.t('admins.permissions.permissions')}>{this.renderPermissions()}</Section>
						</Grid>
					</Grid>
				</PageBody>

				<Snackbar
					message={i18n.t('admins.editAdminComplete')}
					open={this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />
			</Page>
		);
	}

	renderGeneralInfo() {
		const { classes } = this.props;

		return (
			<div className={classes.formContainer}>
				<Grid container spacing={24}>
					<Grid item xs={12} sm={6} lg={4}>
						<TextField
							required
							fullWidth
							className={classes.input}
							label={i18n.t('general.email')}
							type="email"
							name="email"
							variant="outlined"
							inputProps={{
								autoCapitalize: 'off',
								autoComplete: 'off'
							}}
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.email.length}
							helperText={(this.state.errors.length && !this.state.email.length) ? i18n.t('general.required') : ''}
							value={this.state.email} />

						<TextField
							required
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.firstName')}
							name="firstName"
							variant="outlined"
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.firstName.length}
							helperText={(this.state.errors.length && !this.state.firstName.length) ? i18n.t('general.required') : ''}
							value={this.state.firstName} />

						<TextField
							required
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.lastName')}
							name="lastName"
							variant="outlined"
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.lastName.length}
							helperText={(this.state.errors.length && !this.state.lastName.length) ? i18n.t('general.required') : ''}
							value={this.state.lastName} />

						<TextField
							required
							fullWidth
							className={classes.input}
							autoComplete="off"
							label={i18n.t('general.company')}
							name="company"
							variant="outlined"
							onChange={this.handleInputChange}
							error={!!this.state.errors.length && !this.state.company.length}
							helperText={(this.state.errors.length && !this.state.company.length) ? i18n.t('general.required') : ''}
							value={this.state.company} />

						<FormControl className={classes.input}>
							<InputLabel>{i18n.t('general.language')}</InputLabel>
							<Select value={this.state.language} onChange={(e) => this.setState({ language: e.target.value, dirty: true })}>
								<MenuItem value="en">English</MenuItem>
								<MenuItem value="fr">Français</MenuItem>
							</Select>
						</FormControl>

						{
							this.admin && this.admin.id ?
							<FormControl className={classes.input}>
								<FormControlLabel
									label={i18n.t('admins.accountActive')}
									control={
										<Switch checked={!this.state.isDeactivated} onChange={() => this.setState({ isDeactivated: !this.state.isDeactivated, dirty: true })} />
									} />
							</FormControl>
							: null
						}
					</Grid>
				</Grid>

				<Divider className={classes.divider} />

				{
					(this.admin.id && !this.admin.isVerified) ?
					<Mutation mutation={RESEND_VERIFICATION_EMAIL}>
						{(resendVerificationEmail, { error, data, loading }) => (
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									resendVerificationEmail({
										variables: {
											id: this.admin.id
										}
									})
								}}
								loading={loading}
								disabled={data && data.resendVerificationEmail}
								icon={SendIcon}>
								{(data && data.resendVerificationEmail) ? i18n.t('general.sent') : i18n.t('admins.resendAccountVerificationEmail')}
							</Button>
						)}
					</Mutation>
					: null
				}
			</div>
		);
	}

	renderContactInfo() {
		const { classes } = this.props;

		return (
			<div className={classes.formContainer}>
				<Addresses addresses={this.state.addresses} onChange={this.setAddresses} error={this.state.addressError} />

				<Divider />

				<Phones phones={this.state.phones} onChange={this.setPhones} error={this.state.phoneError} />
			</div>
		);
	}

	renderPermissions() {
		return <Permissions permissions={this.state.permissions} onChange={this.setPermissions} />
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}

	saveAdmin() {
		const { client } = this.props;

		const mutation = (this.admin && this.admin.id) ? EDIT_ADMIN : CREATE_ADMIN;

		const {
			email,
			firstName,
			lastName,
			company,
			language,
			phones,
			addresses,
			permissions,
			isDeactivated
		} = this.state;

		const errors = [];

		if (!email) errors.push(i18n.t('admins.errors.emailRequired'));
		if (!firstName) errors.push(i18n.t('admins.errors.firstNameRequired'));
		if (!lastName) errors.push(i18n.t('admins.errors.lastNameRequired'));
		if (!company) errors.push(i18n.t('admins.errors.companyRequired'));
		if (!addresses.length) errors.push(i18n.t('admins.errors.atLeastOneAddress'));
		if (!phones.length) errors.push(i18n.t('admins.errors.atLeastOnePhone'));

		if (errors.length) {
			this.setState({
				errors
			});
			return;
		}

		this.setState({
			loading: true,
			errors
		});

		client.mutate({
			mutation,
			variables: {
				id: this.admin.id,
				email,
				firstName,
				lastName,
				company,
				language,
				phones: phones.map((phone) => ({
					label: phone.label,
					number: phone.number
				})),
				addresses: addresses.map((address) => ({
					label: address.label,
					line1: address.line1,
					line2: address.line2,
					city: address.city,
					province: address.province,
					country: address.country,
					postalCode: address.postalCode
				})),
				isDeactivated,
				permissions: _filter(permissions, (p) => p.level >= 0).map((permission) => ({
					key: permission.key,
					level: permission.level
				}))
			}
		}).then((res) => {
			if (this.admin && this.admin.id) {
				this.setState({
					loading: false,
					dirty: false,
					snackbar: true
				});
			} else {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/admins', {
						title: i18n.t('title.admins')
					});
				});
			}
		}).catch((e) => {
			this.setState({
				loading: false
			})
			this.props.ui.showError(getGraphQLError(e));
		});
	}

	setAddresses(addresses) {
		this.setState({
			addresses,
			addressError: false,
			dirty: true
		});
	}

	setPhones(phones) {
		this.setState({
			phones,
			phoneError: false,
			dirty: true
		});
	}

	setPermissions(permissions) {
		this.setState({
			permissions,
			dirty: true
		});
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			dirty: true
		});
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}
}

const Admin = compose(
	withUI,
	withApollo,
	withUser,
	withQuery,
	withStyles(styles)
)(_Admin);

export default class AdminContainer extends Component {
	render() {
		const { match } = this.props;
		
		if (match.params && match.params.id) {
			return (
				<Admin query={ADMIN} variables={{ id: match.params.id }} {...this.props} />
			);
		} else {
			return (
				<Admin {...this.props} />
			);
		}
	}
}