import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from 'react-apollo';
import { StripeProvider } from 'react-stripe-elements';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import Router from './router';
import config from './config';
import Session from 'utils/session';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import { UIContext } from 'utils/withUI';

import { UPDATE_TOKEN } from 'graph/mutations';
import { VALIDATE_TOKEN } from 'graph/queries';

const httpLink = createHttpLink({
	uri: config.apollo.URI
});

const authLink = setContext((_, { headers }) => {
	const token = Session.getToken();

	return {
		headers: {
			...headers,
			Authorization: (token) ? `Bearer ${token}` : ''
		}
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		if (graphQLErrors.length) {
			graphQLErrors.message = i18n.t(graphQLErrors[0].message)
			
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
				),
			);
		}
	}
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
	link: errorLink.concat(authLink.concat(httpLink)),
	cache: new InMemoryCache(),
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only'
		}
	}
});

const theme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#3f51b5'
		},
		secondary: {
			main: '#e81d24'
		}
	}
});

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			errorModalOpen: false,
			errorModalMessage: '',
			currentLanguage: i18n.default.language
		};

		this.showError = this.showError.bind(this);
		this.closeErrorModal = this.closeErrorModal.bind(this);

		i18n.on('languageChanged', () => {
			this.setState({ currentLanguage: i18n.default.language });
		});
	}

	componentDidMount() {
		client.query({
			query: VALIDATE_TOKEN
		}).then((res) => {

		}).catch((err) => {
			Session.clearToken();

			this.setState({
				user: null
			});
		}).finally(() => {
			this.setState({
				loading: false
			});

			this.updateTokenInterval = setInterval(() => {
				if (Session.getToken()) {
					client.mutate({
						mutation: UPDATE_TOKEN
					}).then((res) => {
						Session.setToken(res.data.updateToken);
					});
				}
			}, 1000 * 60 * 0.5);
		});
	}

	componentWillUnmount() {
		clearInterval(this.updateTokenInterval);
	}

	render() {
		return (
			<ApolloProvider client={client}>
				<StripeProvider apiKey={config.stripe.publishableKey}>
					<MuiThemeProvider theme={theme}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<UIContext.Provider value={{
								showError: this.showError
							}}>
								{
									(!this.state.loading) ?
									<Router />
									:
									<Loading />
								}

								<Modal
									title={i18n.t('general.error')}
									body={this.state.errorModalMessage}
									open={this.state.errorModalOpen}
									onClose={this.closeErrorModal} />

							</UIContext.Provider>
						</MuiPickersUtilsProvider>
					</MuiThemeProvider>
				</StripeProvider>
			</ApolloProvider>
		);
	}

	showError(message) {
		if (typeof message === 'string') {
			this.setState({
				errorModalOpen: true,
				errorModalMessage: message
			});
		} else {
			this.setState({
				errorModalOpen: true,
				errorModalMessage: (
					<ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
						{
							message.map((m, i) => (
								<li key={i}>{m}</li>
							))
						}
					</ul>
				)
			});
		}
	}

	closeErrorModal() {
		this.setState({
			errorModalOpen: false,
			errorModalMessage: ''
		});
	}
}

export default App;