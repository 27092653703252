import gql from 'graphql-tag';
import { invoice } from 'graph/types';

export const CREATE_INVOICE = gql`
mutation CreateInvoice(
	$project: String!,
	$ticket: String,
	$clients: [String!]!,
	$representative: String!,
	$terms: String!,
	$description: String!,
	$number: Int!,
	$deliveredAt: Date!,
	$sendReminderEmail: Boolean!,
	$reminderDays: Int!,
	$items: [Input_InvoiceItem!]!) {
	createInvoice(
		project: $project,
		ticket: $ticket,
		clients: $clients,
		representative: $representative,
		terms: $terms,
		description: $description,
		number: $number,
		deliveredAt: $deliveredAt,
		sendReminderEmail: $sendReminderEmail,
		reminderDays: $reminderDays,
		items: $items
	) {
		${invoice}
	}
}
`;

export const EDIT_INVOICE = gql`
mutation EditInvoice(
	$id: ID!,
	$number: Int!,
	$representative: String!,
	$terms: String!,
	$description: String!,
	$deliveredAt: Date!,
	$items: [Input_InvoiceItem!]!,
	$clients: [String!]!,
	$sendReminderEmail: Boolean!,
	$reminderDays: Int!) {
	editInvoice(
		id: $id
		number: $number
		representative: $representative
		terms: $terms
		description: $description
		deliveredAt: $deliveredAt
		items: $items
		clients: $clients
		sendReminderEmail: $sendReminderEmail
		reminderDays: $reminderDays
	) {
		${invoice}
	}
}
`;

export const SEND_INVOICE = gql`
mutation SendInvoice($id: ID!) {
	sendInvoice(
		id: $id
	) {
		id
		state
	}
}
`;

export const DISCARD_INVOICE = gql`
mutation DiscardInvoiceDraft($id: ID!) {
	discardInvoiceDraft(
		id: $id
	) {
		id
		state
	}
}
`;

export const VOID_INVOICE = gql`
mutation VoidInvoice($id: ID!) {
	voidInvoice(
		id: $id
	) {
		id
		state
	}
}
`;

export const MARK_INVOICE_PAID = gql`
mutation MarkInvoicePaid(
	$id: ID!,
	$paymentMethod: String!,
	$paymentMethodNotes: String!) {
	markInvoicePaid(
		id: $id
		paymentMethod: $paymentMethod,
		paymentMethodNotes: $paymentMethodNotes
	) {
		id
		state
		paymentMethod
		paymentMethodNotes
	}
}
`;

export const PAY_INVOICE = gql`
mutation PayInvoice(
	$publicId: String!,
	$stripeToken: String!) {
	payInvoice(
		publicId: $publicId
		stripeToken: $stripeToken
	) {
		publicId
	}
}
`;

export const PAY_INVOICE_FOR_CLIENT = gql`
mutation PayInvoiceForClient(
	$clientId: ID!,
	$invoiceId: ID!) {
	payInvoiceForClient(
		clientId: $clientId
		invoiceId: $invoiceId
	) {
		id
	}
}
`;
