import {
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	IconButton,
	Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { withApollo, compose } from 'react-apollo';
import _find from 'lodash/find';
import { Prompt } from 'react-router-dom';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
	Button,
	Page,
	PageHeader,
	PageBody,
	Section,
	Banner,
	SearchBar,
	TextField,
	Snackbar,
	Notes,
	KeyStore,
	CheckLists,
	Grid,
	TicketHistory
} from 'components';
import withStyles from 'utils/withStyles';
import withQuery from 'utils/withQuery';
import withUI from 'utils/withUI';
import withUser from 'utils/withUser';
import getGraphQLError from 'utils/getGraphQLError';
import { SEARCH_CLIENTS, SEARCH_TECHS, PROJECT, PROJECT_TICKETS } from 'graph/queries';
import { CREATE_PROJECT, EDIT_PROJECT } from 'graph/mutations';
import moment from 'moment';
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const styles = (theme) => ({
	warrantyContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: theme.spacing.unit,
		height: 30
	},

	WarrantyButtonContainer: {
		display: 'flex',
		flex: 1,
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginRight: theme.spacing.unit,
		'& button': {
			marginLeft: theme.spacing.unit
		}
	}
	
});

class Project extends Component {
	constructor(props) {
		super(props);

		this.project = props.queryResult && props.queryResult.data && props.queryResult.data.project || { address: {}};

		this.state = {
			name: this.project.name || '',
			label: '',
			line1: this.project.address.line1 || '',
			line2: this.project.address.line2 || '',
			city: this.project.address.city || '',
			province: this.project.address.province || '',
			country: this.project.address.country || 'Canada',
			postalCode: this.project.address.postalCode || '',
			clientSearch: '',
			clientSearchOpen: false,
			clients: this.project.clients || [],
			techSearch: this.project.leadTech ? `${this.project.leadTech.firstName} ${this.project.leadTech.lastName}` : '',
			techSearchOpen: false,
			leadTech: this.project.leadTech || '',
			loading: false,
			dirty: false,
			snackbar: false,
			errors: [],
			tabIndex: 0,
			editingWarranty: false,
			warrantyExpiration: this.project.warrantyExpiration || null,
			warrantyStart: this.project.warrantyStart || null,
			warrantyLength: 12
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.saveProject = this.saveProject.bind(this);
		this.removeLeadTech = this.removeLeadTech.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.getBannerErrorMessage = this.getBannerErrorMessage.bind(this);
		this.getWarrantyStatus = this.getWarrantyStatus.bind(this)

		let warrantyStartTmp;
		let warrantyExpirationTmp;
		let warrantyLength;
	}

	render() {
		const { currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						this.project && this.project.id && currentUser.hasPermission('invoices', 0) ?
						<Button
							color="primary"
							variant="contained"
							icon={AddIcon}
							onClick={() => this.props.history.push('/invoices/create', {
								invoiceProjectId: this.project.id
							})}>
							{i18n.t('invoices.invoice')}
						</Button>
						: null
					}

					<Button
						key={1}
						color="primary"
						variant="contained"
						icon={SaveIcon}
						loading={this.state.loading}
						disabled={!this.state.dirty}
						onClick={this.saveProject}
					>{i18n.t('general.save')}</Button>
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<PageBody
					flex={this.state.tabIndex === 1 || this.state.tabIndex === 2}
					tabs={this.project && this.project.id ? [
						i18n.t('title.project'),
						i18n.t("projects.ticketHistory"),
						i18n.t('tickets.notes'),
						i18n.t('tickets.internalNotes'),
						i18n.t('projects.keyStore'),
						i18n.t('projects.checklists')
					] : null}
					tabIndex={this.state.tabIndex}
					onChangeTab={(e, value) => this.setState({ tabIndex: value })}>
						{this.state.tabIndex === 0 && this.renderProjectDetails()}
						{this.state.tabIndex === 1 && this.renderTicketsHistory()}
						{this.state.tabIndex === 2 && this.renderNotes()}
						{this.state.tabIndex === 3 && this.renderNotes(true)}
						{this.state.tabIndex === 4 && this.renderKeyStore()}
						{this.project && this.state.tabIndex === 4 && this.renderCheckLists()}
				</PageBody>

				<Snackbar
					message={this.state.snackbar || ''}
					open={!!this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />
			</Page>
		);
	}

	renderProjectDetails() {
		const { classes } = this.props;
		

		return (
			<Grid container spacing={16}>
				<Grid item xs={12}>
					<Section title={i18n.t('projects.projectDetails')}>
						<Grid container>
							<Grid item xs={12} md={6}>
								<TextField
									className={classes.textField}
									required
									fullWidth
									autoComplete="off"
									label={i18n.t('projects.projectName')}
									name="name"
									variant="outlined"
									onChange={this.handleInputChange}
									error={(!!this.state.errors.length && !this.state.name.length)}
									helperText={(!!this.state.errors.length && !this.state.name.length) && i18n.t('general.required')}
									value={this.state.name} />
							</Grid>

							<Grid container spacing={16}>
								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										required
										autoComplete="off"
										label={i18n.t('general.addresses.line1')}
										name="line1"
										variant="outlined"
										error={(!!this.state.errors.length && !this.state.line1.length)}
										helperText={(!!this.state.errors.length && !this.state.line1.length) && i18n.t('general.required')}
										onChange={this.handleInputChange}
										value={this.state.line1} />
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										autoComplete="off"
										label={i18n.t('general.addresses.line2')}
										name="line2"
										variant="outlined"
										onChange={this.handleInputChange}
										value={this.state.line2} />
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										required
										autoComplete="off"
										label={i18n.t('general.addresses.city')}
										name="city"
										variant="outlined"
										error={(!!this.state.errors.length && !this.state.city.length)}
										helperText={(!!this.state.errors.length && !this.state.city.length) && i18n.t('general.required')}
										onChange={this.handleInputChange}
										value={this.state.city} />
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										required
										autoComplete="off"
										label={i18n.t('general.addresses.province')}
										name="province"
										variant="outlined"
										error={(!!this.state.errors.length && !this.state.province.length)}
										helperText={(!!this.state.errors.length && !this.state.province.length) && i18n.t('general.required')}
										onChange={this.handleInputChange}
										value={this.state.province} />
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										required
										autoComplete="off"
										label={i18n.t('general.addresses.country')}
										name="country"
										variant="outlined"
										error={(!!this.state.errors.length && !this.state.country.length)}
										helperText={(!!this.state.errors.length && !this.state.country.length) && i18n.t('general.required')}
										onChange={this.handleInputChange}
										value={this.state.country} />
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField
										fullWidth
										required
										autoComplete="off"
										label={i18n.t('general.addresses.postalCode')}
										name="postalCode"
										variant="outlined"
										error={(!!this.state.errors.length && !this.state.postalCode.length)}
										helperText={(!!this.state.errors.length && !this.state.postalCode.length) && i18n.t('general.required')}
										onChange={this.handleInputChange}
										value={this.state.postalCode} />
								</Grid>
							</Grid>
						</Grid>
					</Section>
				</Grid>

				<Grid item xs={12}>
					<Section title={i18n.t('tickets.warranty')} buttons={
						!this.state.editingWarranty ?
							[<IconButton
								key={1}
								style={{ margin: "-6px 0" }}
								onClick={() => {
									this.warrantyExpirationTmp = this.state.warrantyExpiration
									this.warrantyStartTmp = this.state.warrantyStart
									this.setState({
										editingWarranty: true
									})
								}} >
								<EditIcon fontSize="small" />
							</IconButton>] :
							[<IconButton
								key={1}
								style={{ margin: "-6px 0" }}
								onClick={()=>{			
									this.setState({
										dirty: true,
										editingWarranty: false
									})	
								}} >
								<DoneAllIcon fontSize="small" />
							</IconButton>,
							<IconButton
								key={2}
								onClick={()=>{
									this.setState({
										warrantyStart: this.warrantyStartTmp,
										warrantyExpiration: this.warrantyExpirationTmp,
										editingWarranty: false
									})
								}
									}
								><DeleteIcon /></IconButton>
							]							
					}>
						<Grid container direction="column" justify="space-around" alignItems="center" spacing={16}>
								<Grid container direction="row" justify="space-evenly" alignItems="center" spacing={16}>

									<Grid item>

										<Grid container direction="column" alignItems="center">

											<Grid item>
												<Typography variant="subtitle1">{`Started on :`}</Typography>
											</Grid>

											<Grid item>
												{
													this.state.editingWarranty ? 
													<DatePicker
														format="YYYY-MM-DD"
														fullWidth
														variant="outlined"
														required
														label={i18n.t('general.date')}
														value={this.state.warrantyStart}
														style={{ marginBottom: 16 }}
														onChange={(e) => {
															this.setState({
															warrantyStart: e,
															warrantyExpiration:  moment(e).add(this.state.warrantyLength, "months")

														})}} /> :

													<Typography variant="subtitle1">{this.state.warrantyStart ? `${moment(this.state.warrantyStart).format("dddd D MMMM YYYY")}` : `-`}</Typography>

												}
												</Grid>
										</Grid>
									</Grid>
								{
									this.state.editingWarranty ?
									<Grid item>
										<Grid container direction="row" spacing={8}>
											<Grid item>
												<Typography variant="subtitle1">{`And will last :`}</Typography>
											</Grid>
											<Grid item>
												<TextField
													type="number"
													onChange={(e) => {
														const tmp = this.state.warrantyStart ? moment(this.state.warrantyStart).add(e.target.value, "months") : null;
														tmp ? this.setState({
															warrantyLength: e.target.value,
															warrantyExpiration: tmp.toDate()
														}) : this.setState({
																warrantyLength: e.target.value,
															})
													}}
													value={this.state.warrantyLength}
													style={{ width: 40 }} />
											</Grid>
											<Grid item>
													<Typography variant="subtitle1">{`months`}</Typography>
											</Grid>
										</Grid>
									</Grid> : null
									
								}

									<Grid item>
										<Grid container direction="column" alignItems="center">
											<Grid item>
												<Typography variant="subtitle1">{`Expires on :`}</Typography>
											</Grid>
											<Grid item>
											{
												this.state.editingWarranty ?
													<DatePicker
														format="YYYY-MM-DD"
														fullWidth
														variant="outlined"
														required
														label={i18n.t('general.date')}
														value={this.state.warrantyExpiration}
														style={{ marginBottom: 16 }}
														onChange={(e) => {this.setState({
															warrantyExpiration: e
														})}} /> :

													<Typography variant="subtitle1">{this.state.warrantyExpiration ? `${moment(this.state.warrantyExpiration).format("dddd D MMMM YYYY")}` : `-`}</Typography>
											}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							{
								!this.state.editingWarranty ? 
									<Grid item>
										{
											this.getWarrantyStatus()
										}
										
									</Grid> : null
							}
							
						</Grid>						
					</Section>
				</Grid>

				<Grid item xs={12}>
					<Section title={i18n.t('projects.leadTech')}>
						<Grid container spacing={16}>
							<Grid item xs={12} sm={6} md={4}>
								{
									this.state.leadTech ?
									<div className={classes.flexRow}>
										<IconButton onClick={this.removeLeadTech}>
											<ClearIcon />
										</IconButton>

										<Typography>{`${this.state.leadTech.firstName} ${this.state.leadTech.lastName}`}</Typography>
									</div>
									:
									<SearchBar
										value={this.state.techSearch}
										label={i18n.t('general.search')}
										query={SEARCH_TECHS}
										onChange={(value) => this.setState({ techSearch: value })}
										getQueryResultItemLabel={(tech) => `${tech.firstName} ${tech.lastName} (${tech.email})`}
										onSelect={(tech) => this.setLeadTech(tech)} />
								}
							</Grid>
						</Grid>
					</Section>
				</Grid>

				<Grid item xs={12}>
					<Section title={i18n.t('general.client', { count: 2 })}>
						<Grid item xs={12}>
							<Grid container spacing={16}>
								<Grid item xs={12} sm={6} md={4}>
									<SearchBar
										value={this.state.clientSearch}
										required={true}
										label={i18n.t('general.search')}
										query={SEARCH_CLIENTS}
										onChange={(value) => this.setState({ clientSearch: value })}
										getQueryResultItemLabel={(client) => `${client.firstName} ${client.lastName} (${client.email})`}
										onSelect={(client) => this.addClient(client)} />
								</Grid>

								<Grid item xs={12}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>{i18n.t('general.email')}</TableCell>
												<TableCell>{i18n.t('general.firstName')}</TableCell>
												<TableCell>{i18n.t('general.lastName')}</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												this.state.clients.map((client, i) => (
													<TableRow key={i}>
														<TableCell>{client.email}</TableCell>
														<TableCell>{client.firstName}</TableCell>
														<TableCell>{client.lastName}</TableCell>
														<TableCell><IconButton onClick={this.removeClient.bind(this, i)}><DeleteIcon /></IconButton></TableCell>
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</Grid>
					</Section>
				</Grid>
			</Grid>
		)
	}

	renderTicketsHistory () {
		return (
			<TicketHistory projectId={this.project.id}/>
		);
	}

	renderNotes(isInternal) {
		return (
			<Notes parentId={this.project.id} schemaType="project" isInternal={!!isInternal} />
		);
	}

	renderKeyStore() {
		return (
			<KeyStore projectId={this.project.id} />
		)
	}

	renderCheckLists() {
		return <CheckLists projectId={this.project.id} />;
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			dirty: true
		});
	}

	getWarrantyStatus() {
		const now = moment()
		if (!(this.state.warrantyStart && this.state.warrantyExpiration)){
			return <Typography variant="subtitle1">{`The warranty has not been activated`}</Typography>
		} else if (this.state.warrantyExpiration) {
			if ( now > this.state.warrantyExpiration) {
				return <Typography variant="subtitle1" style={{ color: "red" }}>{`Warranty Expired`}</Typography>
			} else {
				return <Typography variant="subtitle1" style={{ color: "green" }}>{`Project under Warranty`}</Typography>
			}
		}
	}

	addClient(client) {
		if (_find(this.state.clients, { id: client.id })) {
			this.setState({
				clientSearchOpen: false
			});

			return;
		}

		this.setState({
			clients: [
				client,
				...this.state.clients
			],
			clientSearchOpen: false,
			clientSearch: '',
			dirty: true
		});
	}

	setLeadTech(tech) {
		this.setState({
			leadTech: tech,
			dirty: true
		});
	}

	removeLeadTech() {
		this.setState({
			leadTech: '',
			dirty: true
		});
	}

	removeClient(index) {
		this.setState({
			clients: [
				...this.state.clients.slice(0, index),
				...this.state.clients.slice(index + 1)
			],
			dirty: true
		});
	}

	saveProject() {
		const { client } = this.props;

		const {
			name,
			line1,
			line2,
			city,
			province,
			country,
			postalCode,
			leadTech,
			clients,
			warrantyStart,
			warrantyExpiration
		} = this.state;

		const errors = [];

		if (!name || !line1 || !city || !province || !country || !postalCode) {
			errors.push(i18n.t('general.enterRequiredFields'))
		}

		if (errors.length) {
			this.setState({
				errors
			});
			return;
		}

		this.setState({
			loading: true,
			formError: false
		});

		let mutation = CREATE_PROJECT;
		let id = '';

		if (this.props.location.state && this.props.location.state.project) {
			mutation = EDIT_PROJECT;
			id = this.props.location.state.project.id;
		} else if (this.project && this.project.id) {
			mutation = EDIT_PROJECT;
			id = this.project.id;
		}

		client.mutate({
			mutation,
			variables: {
				id,
				name,
				address: {
					label: 'Project Address',
					line1,
					line2,
					city,
					province,
					country,
					postalCode
				},
				warrantyExpiration,
				warrantyStart,
				leadTech: leadTech ? leadTech.id : undefined,
				clients: clients.map((c) => c.id)
			}
		}).then((res) => {
			if (id) {
				this.setState({
					loading: false,
					dirty: false,
					snackbar: i18n.t('admins.editAdminComplete')
				})
			} else {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/projects', {
						title: i18n.t('title.projects')
					});
				});
			}
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false,
				dirty: false
			});
		});
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}
}

const ProjectContainer = compose(
	withUI,
	withApollo,
	withUser,
	withStyles(styles),
	withQuery
)(Project);

export default class extends Component {
	render() {
		const { match } = this.props;

		if (match.params && match.params.id) {
			return (
				<ProjectContainer query={PROJECT} variables={{ id: match.params.id }} {...this.props} />
			);
		} else {
			return (
				<ProjectContainer {...this.props} />
			);
		}
	}
}