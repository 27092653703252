import {
	FormControl,
	Input,
	InputLabel,
	Typography,
	withStyles
} from '@material-ui/core';
import Button from 'components/Button/Button';
import { Mutation } from 'react-apollo';
import queryString from 'query-string';
import Session from 'utils/session';

import { VERIFY_ACCOUNT } from 'graph/mutations';
import { VALIDATE_TOKEN } from 'graph/queries';
import FullPagePaper from 'components/FullPagePaper/FullPagePaper';

const styles = (theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

class Verify extends Component {
	constructor(props) {
		super(props);

		this.state = {
			password: '',
			confirmPassword: '',
			loading: false,
			error: ''
		};
	}

	render() {
		const { classes } = this.props;

		return (
			<FullPagePaper title="My Homesync" subtitle={i18n.t('login.verifyAccount')}>
				<Mutation
					mutation={VERIFY_ACCOUNT}
					update={(cache, { data }) => {
						cache.writeQuery({
							query: VALIDATE_TOKEN,
							data: {
								validateToken: data.verifyAccount.user
							}
						});
					}}>
					{(verifyAccount, { data, loading }) => (
						<form className={classes.form} onSubmit={(e) => {
							this.verifyAccount(verifyAccount, e);
						}}>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="email">{i18n.t('login.password')}</InputLabel>
								<Input
									id="password"
									name="password"
									type="password"
									autoFocus
									value={this.state.password}
									onChange={(e) => this.setState({ password: e.target.value })} />
							</FormControl>
	
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="email">{i18n.t('login.confirmPassword')}</InputLabel>
								<Input
									id="confirmPassword"
									name="confirmPassword"
									type="password"
									value={this.state.confirmPassword}
									onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
							</FormControl>
	
							{
								(this.state.error) ?
								<Typography color="error">{this.state.error}</Typography>
								: null
							}
	
							<Button
								type="submit"
								fullWidth
								variant="contained"
								className={classes.submit}
								loading={loading}>
	
								{i18n.t('general.submit')}
							</Button>
						</form>
					)}
				</Mutation>
			</FullPagePaper>
		)
	}

	verifyAccount(verifyAccount, e) {
		e.preventDefault();

		if (!this.state.password || !this.state.confirmPassword) {
			return;
		}

		if (this.state.password !== this.state.confirmPassword) {
			this.setState({
				error: i18n.t('login.passwordsNotMatch')
			});

			return;
		}

		const { location } = this.props;

		const params = queryString.parse(location.search);

		if (!params.token) {
			return;
		}

		this.setState({
			loading: true
		});

		verifyAccount({
			variables: {
				token: params.token,
				password: this.state.password
			}
		}).then((res) => {
			Session.setToken(res.data.verifyAccount.token);

			this.props.history.push('/', {
				title: i18n.t('title.home')
			});
		}).catch((e) => {
			this.setState({
				loading: false,
				error: e.graphQLErrors ? e.graphQLErrors.message : e.message
			});
		});
	}
}

export default withStyles(styles)(Verify);