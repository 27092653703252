import { Query } from 'react-apollo';
import {
	Page,
	PageBody,
	TicketCard,
	Grid
} from 'components';
import { TICKETS } from 'graph/queries';

class Tickets extends Component {
	render() {
		return (
			<Page>
				<Query query={TICKETS} fetchPolicy={'network-only'}>
					{({ loading, error, data }) => {
						const tickets = data && data.tickets ? data.tickets.tickets : null;
						
						return (
							<PageBody loading={loading} error={error}>
								<Grid container spacing={16}>
									{
										tickets ?
										tickets.map((ticket, i) => (
											<Grid key={i} item xs={12} md={6} lg={4}>
												<TicketCard ticket={ticket} />
											</Grid>
										))
										: null
									}
								</Grid>
							</PageBody>
						);
					}}
				</Query>
			</Page>
		);
	}
};

export default Tickets;