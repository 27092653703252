export const client = `
	id
	email
	firstName
	lastName
	language
	company
	isVerified
	isDeactivated
	type
	stripeCustomerId
	phones {
		label
		number
	}
	addresses {
		label
		line1
		line2
		city
		province
		country
		postalCode
	}
	projects {
		id
		name
		address {
			label
			line1
			line2
			city
			province
			country
			postalCode
		}
		clients {
			id
			firstName
			lastName
			email
		}
	}
`;

export const clients = `
	id
	email
	firstName
	lastName
	language
	isVerified
	isDeactivated
	type
	company
	title
	stripeCustomerId
`;