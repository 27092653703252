import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Session from "utils/session";
import Main from "scenes/common/Main/Main";
import Login from "scenes/common/Login/Login";
import ForgotPassword from "scenes/common/ForgotPassword/ForgotPassword";
import ResetPassword from "scenes/common/ResetPassword/ResetPassword";
import Verify from "scenes/common/Verify/Verify";
import _isArray from "lodash/isArray";
import _find from "lodash/find";
import withUser from "utils/withUser";
import InvoicePaymentForm from "components/Stripe/InvoicePaymentForm";

class PR extends Component {
  render() {
    const {
      redirectPath,
      allowedUsers,
      requiredPermissions,
      component,
      onRender,
      currentUser,
      ...passDownProps
    } = this.props;

    const RenderComponent = component;

    return (
      <Route
        {...passDownProps}
        render={props => {
          let redirect = false;
          const user = currentUser;

          if (!Session.getToken() || !user) {
            redirect = "/login";
          }

          if (!redirect && allowedUsers) {
            if (
              typeof allowedUsers === "string" &&
              allowedUsers.toLowerCase() !== user.type.toLowerCase()
            ) {
              redirect = "/";
            } else if (
              _isArray(allowedUsers) &&
              allowedUsers
                .map(au => au.toLowerCase())
                .indexOf(user.type.toLowerCase() < 0)
            ) {
              redirect = "/";
            }
          }

          if (!redirect && requiredPermissions) {
            let hasPermissions = true;

            if (user.permissions && user.permissions.length) {
              if (_isArray(requiredPermissions)) {
                for (let requiredPermission of requiredPermissions) {
                  if (
                    !_find(
                      user.permissions,
                      p =>
                        p.key.toLowerCase() ===
                          requiredPermission.key.toLowerCase() &&
                        p.level >= requiredPermission.level
                    )
                  ) {
                    hasPermissions = false;
                    break;
                  }
                }
              } else {
                hasPermissions = !!_find(
                  user.permissions,
                  p =>
                    p.key.toLowerCase() ===
                      requiredPermissions.key.toLowerCase() &&
                    p.level >= requiredPermissions.level
                );
              }
            } else {
              hasPermissions = false;
            }

            if (!hasPermissions) {
              redirect = "/";
            }
          }

          if (redirect) {
            return (
              <Redirect
                to={{
                  pathname: redirectPath ? "/" + redirectPath : redirect
                }}
              />
            );
          } else {
            return <RenderComponent {...props} />;
          }
        }}
      />
    );
  }
}

export const ProtectedRoute = withUser(PR);

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/verify" component={Verify} />
          <Route path="/invoice/:invoiceId" component={InvoicePaymentForm} />
          <ProtectedRoute component={Main} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
