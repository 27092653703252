import { Modal, Typography, Button, Paper, withStyles } from '@material-ui/core';

const styles = (theme) => ({
	paper: {
		position: 'absolute',
		width: 380,
		maxWidth: '80%',
		padding: theme.spacing.unit * 4,
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},
	buttonRow: {
		marginTop: theme.spacing.unit * 3,
		textAlign: 'right'
	}
});

class Confirm extends React.PureComponent {
	render() {
		const {
			title,
			body,
			open,
			confirmButtonText,
			onConfirm,
			cancelButtonText,
			onCancel,
			additionalButtons,
			classes
		} = this.props;

		return (
			<Modal open={open} onClose={onCancel}>
				<Paper className={classes.paper}>
					<Typography variant="h6">{title}</Typography>
					{
						typeof body === 'string' ?
						<Typography>{body}</Typography>
						:
						body
					}

					<div className={classes.buttonRow}>
						<Button onClick={onConfirm}>{confirmButtonText || i18n.t('general.close')}</Button>
						{
							additionalButtons && additionalButtons.length ?
							additionalButtons.map((button, i) => (
								<Button onClick={button.onClick} key={i}>{button.text}</Button>
							))
							: null
						}
						<Button onClick={onCancel}>{cancelButtonText || i18n.t('general.close')}</Button>
					</div>
				</Paper>
			</Modal>
		);
	}
}

export default withStyles(styles)(Confirm);