import {
	Divider
} from '@material-ui/core';
import withStyles from 'utils/withStyles';
import _isInteger from 'lodash/isInteger';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: theme.spacing.unit,
		height: 38
	},

	buttonContainer: {
		display: 'flex',
		flex: 1,
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginRight: theme.spacing.unit,
		'& button': {
			marginLeft: theme.spacing.unit
		}
	}
});

class PageHeader extends Component {
	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<div className={classes.container}>
					<div className={classes.buttonContainer}>
						{this.props.children}
					</div>
				</div>

				<Divider />
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(PageHeader);