import {
	Paper,
	LinearProgress,
	Typography,
	withStyles
} from '@material-ui/core';
import moment from 'moment';
import { Query } from 'react-apollo';
import { EVENT_LOG } from 'graph/queries';

const styles = (theme) => ({
	paper: {
		margin: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2
	}
});

class EventLog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchString: '',
			sort: 'createdAt',
			dir: 'desc',
			page: 0
		}

		this.sortTable = this.sortTable.bind(this);
	}

	render() {
		const { classes, match } = this.props;

		return (
			<Query query={EVENT_LOG} variables={{
				id: match.params.id
			}}>
				{({ loading, error, data, refetch }) => {
					if (error) {
						return null;
					}

					const eventLog = data.eventLog;

					return (
					<div>
						{
							loading ?
							<LinearProgress />
							:
							<Paper className={classes.paper}>
								<Typography variant="subtitle2">
									User
								</Typography>
								<Typography>{eventLog.user.firstName} {eventLog.user.lastName}</Typography>

								<br />

								<Typography variant="subtitle2">
									IP
								</Typography>
								<Typography>{eventLog.ip}</Typography>

								<br />

								<Typography variant="subtitle2">
									Type
								</Typography>
								<Typography>{eventLog.type}</Typography>

								<br />

								<Typography variant="subtitle2">
									Message
								</Typography>
								<Typography>{eventLog.message}</Typography>

								<br />

								<Typography variant="subtitle2">
									Date
								</Typography>
								<Typography>{moment(eventLog.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
							</Paper>
						}
					</div>
					);
				}}
			</Query>
		);
	}

	search(value, e) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				searchString: value
			});
		}, 500);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
}

export default withStyles(styles)(EventLog);