import gql from 'graphql-tag';

export const UPDATE_TOKEN = gql`
mutation updateToken {
	updateToken
}
`;

export const LOGIN = gql`
mutation Login($email: String!, $password: String!) {
	login(email: $email, password: $password) {
		token
		user {
			id
			email
			firstName
			lastName
			language
			type
			permissions {
				key
				level
			}
		}
	}
}
`;

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
	forgotPassword(email: $email)
}
`;

export const RESET_PASSWORD = gql`
mutation ResetPassword($token: String!, $password: String!) {
	resetPassword(token: $token, password: $password) {
		token
		user {
			id
			email
			firstName
			lastName
			language
			type
			permissions {
				key
				level
			}
		}
	}
}
`;

export const VERIFY_ACCOUNT = gql`
mutation VerifyAccount($token: String!, $password: String!) {
	verifyAccount(token: $token, password: $password) {
		token
		user {
			id
			email
			firstName
			lastName
			language
			type
			permissions {
				key
				level
			}
		}
	}
}
`;

export const RESEND_VERIFICATION_EMAIL = gql`
	mutation resendVerificationEmail($id: ID!) {
		resendVerificationEmail(id: $id)
	}
`;