export const invoice = `
	id
	state
	number
	representative
	terms
	description
	createdAt
	deliveredAt
	sentAt
	updatedAt
	paymentMethod
	paymentMethodNotes
	sendReminderEmail
	reminderDays
	clients {
		id
		firstName
		lastName
		email
		addresses {
			label
			line1
			line2
			city
			province
			postalCode
			country
		}
		phones {
			label
			number
		}
	}
	project {
		id
		name
		address {
			label
			line1
			line2
			city
			province
			country
			postalCode
		}
		clients {
			id
			firstName
			lastName
			email
		}
	}
	items {
		description
		quantity
		rate
		notes
	}
	invoiceReminderEmails {
		sentAt
		to
	}
`;

export const invoices = `
	id
	state
	number
	representative
	terms
	description
	createdAt
	deliveredAt
	sentAt
	updatedAt
	paymentMethod
	paymentMethodNotes
	sendReminderEmail
	reminderDays
	clients {
		id
		firstName
		lastName
		email
	}
	project {
		id
		name
		address {
			label
			line1
			line2
			city
			province
			country
			postalCode
		}
	}
`;