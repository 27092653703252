import {
	Section,
	StateChip,
	Button
} from 'components';
import withStyles from 'utils/withStyles';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _filter from 'lodash/filter';
import {
	Typography,
	Grid,
	Divider
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import { VictoryPie, VictoryLegend } from 'victory';

class TicketsDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			height: 0
		};
	}

	componentDidMount() {
		this.resizeHandler = () => {
			this.setState({
				height: this.listContainer.clientWidth
			});
		}

		window.addEventListener('resize', this.resizeHandler);

		this.resizeHandler();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeHandler);
	}

	render() {
		return (
			<Section title={i18n.t('title.tickets')}>
				<Grid container alignItems="center" spacing={16}>
					<Grid item xs={12} md={12} lg={4}>
						<TicketsStats />
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<TicketsChart />
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<div style={{ height: this.state.height }} ref={ref => this.listContainer = ref}>
							<TicketsList />
						</div>
					</Grid>
				</Grid>
			</Section>
		);
	}
}

export default TicketsDashboard;

class TicketsChart extends Component {
	render() {
		return (
			<Query query={MONTHLY_TICKETS} variables={{
				startDate: moment().startOf('month').toDate(),
				endDate: moment().endOf('month').toDate()
			}}>
				{({ loading, error, data }) => {
					if (loading || error) return null;

					const tickets = data.monthlyTickets;

					const open = _filter(tickets, { status: 'opened' }).length;
					const closed = _filter(tickets, { status: 'closed' }).length;

					const chartData = [];
					const colors = [];

					if (open > 0) {
						chartData.push({ x: 'Open', y: open });
						colors.push('#3f51b5');
					}

					if (closed > 0) {
						chartData.push({ x: 'Closed', y: closed });
						colors.push('#23bf00');
					}

					const legendData = chartData.map((n, i) => {
						return {
							name: n.x + ': ' + n.y,
							symbol: { fill: colors[i] }
						}
					});

					if (closed || open) {
						return (
							<div style={{ position: 'relative' }}>
								<Grid container>
									<Grid item xs={12}>
										<Typography variant="button" align="center" style={{ position: 'absolute', top: 16, width: '100%' }}>{moment().format('MMMM, YYYY')}</Typography>
									</Grid>
									<Grid item xs={12}>
										<VictoryPie
											data={chartData}
											animate={{
												duration: 4000
											}}
											style={{ labels: { display: 'none' } }}
											colorScale={colors} />

										<div style={{ position: 'absolute', top: 0, left: 0 }}>
											<VictoryLegend
												data={legendData}
												width={120}
												height={150}
												style={{ labels: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' } }} />
										</div>
									</Grid>
								</Grid>
							</div>
						);
					} else {
						return (
								<Grid container >
									<Grid item xs={12}>
										<div style={{ padding: '70px' , position: 'relative' }}>
											<Typography variant="button" align="center" >No tickets data for {moment().format('MMMM, YYYY')}</Typography>
										</div>
									</Grid>
								</Grid>
						);
					}
				}}
			</Query>
		);
	}
}

class _TicketsList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Query query={OPEN_TICKETS}>
				{({ loading, error, data }) => {
					if (loading || error) {
						return null;
					}

					if (!data.openTickets.length) {
						return (
							<Section style={{ height: '100%', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
								<Typography variant="caption">{i18n.t('dashboard.noOpenTickets')}</Typography>
								<Button
									color="primary"
									variant="contained"
									icon={AddIcon}
									onClick={() => this.props.history.push('/tickets/create')}
								>{i18n.t('tickets.ticket')}</Button>
							</Section>
						)
					}

					return (
						<Section style={{ height: '100%', boxSizing: 'border-box', overflowY: 'auto' }}>
							{
								data.openTickets.map((ticket, i) => {
									return (
										<div key={i} style={{ marginBottom: 16, cursor: 'pointer' }} onClick={() => this.props.history.push('/tickets/' + ticket.id)}>
											<div className={classes.flexRow}>
												<Typography variant="button">{i18n.t('tickets.ticket')} #{ticket.workOrder}</Typography>
												<div style={{ flex: 1, textAlign: 'right' }}>
													<StateChip state={ticket.state} />
												</div>
											</div>

											<div className={classes.flexRow}>
												<div>
													<StoreIcon className={classes.icon} fontSize="large" />
												</div>
												<div className={classes.flexFill}>
													<Typography>
														{ticket.project.name}
													</Typography>
												</div>
											</div>

											<Typography variant="caption" style={{ margin: '0 16px' }}>{ticket.ticketDescription}</Typography>

											<Divider className={classes.divider} />
										</div>
									);
								})
							}
						</Section>
					)
				}}
			</Query>
		)
	}
}

const TicketsList = withStyles({})(withRouter(_TicketsList));

class TicketsStats extends Component {
	render() {
		return (
			<Query query={TICKETS_STATS}>
				{({ loading, error, data }) => {
					if (loading || error) return null;

					return (
						<Grid container justify="center" spacing={16}>
							<Grid item xs={12} md={4} lg={12}>
								<div style={{ textAlign: 'center', marginTop: 16 }}>
									<Typography variant="button">{i18n.t('dashboard.openTickets')}</Typography>
									<Typography variant="h5">{data.ticketsStats.count}</Typography>
								</div>
							</Grid>

							<Grid item xs={12} md={4} lg={12}>
								<div style={{ textAlign: 'center', marginTop: 16 }}>
									<Typography variant="button">{i18n.t('dashboard.totalTicketTimeEstimate')}</Typography>
									<Typography variant="h5">{data.ticketsStats.total} {i18n.t('dashboard.days')}</Typography>
								</div>
							</Grid>
						</Grid>
					)
				}}
			</Query>
		)
	}
}

const OPEN_TICKETS = gql`
	query openTickets {
		openTickets {
			id
			status
			workOrder
			createdAt
			project {
				id
				name
			}
			client {
				id
				firstName
				lastName
				email
			}
		}
	}
`;

const TICKETS_STATS = gql`
	query ticketsStats {
		ticketsStats {
			count
			total
		}
	}
`;

const MONTHLY_TICKETS = gql`
	query monthlyTickets(
		$startDate: Date!
		$endDate: Date!
	) {
		monthlyTickets(
			startDate: $startDate
			endDate: $endDate
		) {
			id
			status
		}
	}
`;