import PropTypes from 'prop-types';
import moment from 'moment';
import logo from 'assets/logo.jpg';

class InvoiceEmail extends Component {
	constructor(props) {
		super(props);

		this.calculateSubTotal = this.calculateSubTotal.bind(this);
		this.calculateGST = this.calculateGST.bind(this);
		this.calculateQST = this.calculateQST.bind(this);
		this.calculateTotal = this.calculateTotal.bind(this);
	}

	calculateSubTotal() {
		let subTotal = 0;

		if (this.props.invoice && this.props.invoice.items && this.props.invoice.items.length) {
			this.props.invoice.items.map(item => {
				subTotal += item.quantity * item.rate;
			});
		}

		return Math.round(subTotal * 100) / 100;
	}

	calculateGST() {
		// GST (5%)
		const taxRate = 0.05;

		let result = this.calculateSubTotal() * taxRate;

		return Math.round(result * 100) / 100;
	}

	calculateQST() {
		// QST (9.975%)
		const taxRate = 0.09975;

		let result = this.calculateSubTotal() * taxRate;

		return Math.round(result * 100) / 100;
	}

	calculateTotal() {
		let result = this.calculateSubTotal() + this.calculateGST() + this.calculateQST();

		return Math.round(result * 100) / 100;
	}

	render() {
		const {
			client,
			project,
			invoice
		} = this.props;

		let address = null;

		if (client) {
			address = client.addresses && client.addresses.length ? client.addresses[0] : null;
		}

		return (
			<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}><tbody>
				<tr>
					<td colSpan="2">
						<table align="center" border="0" cellPadding="0" cellSpacing="0" style={{ borderBottom: '1px solid #ccc', borderTop: '5px solid #e81d24', maxWidth: 600, width: '100%' }}><tbody>
							<tr><td bgcolor="#ffffff" style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc', paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}><img width="250" alt="Homesync" src={logo} /></td></tr>
							<tr><td bgcolor="#ffffff" style={{ color: '#2a363f', fontWeight: 'bold', fontFamily: 'sans-serif', paddingBottom: 20, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc', textAlign: 'center' }}>{i18n.t('invoices.invoice')} #{invoice.number}</td></tr>
							<tr><td bgcolor="#ffffff" style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 30, fontFamily: 'sans-serif', fontSize: 14, color: '#444', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
								<p>Design Homesync Inc.<br />3480 Rue Griffith<br />Montréal, Québec H4T 1A7<br />T: 514-360-3415<br />RBQ: 5662-4315-01</p>
								{
									(address) ?
										<p><strong>{i18n.t('invoices.billedTo')}</strong><br />{`${client.firstName} ${client.lastName}`}<br />
											{address ? `${address.line1}` : null}
											{address && address.line2 ? ', ' + address.line2 : null}
											{address ? <br /> : null}
											{address ? `${address.city}, ${address.province} ${address.postalCode}` : null}
										</p> : null
								}
								<table align="center" border="1" cellPadding="3" cellSpacing="0" style={{ borderTop: '3px solid black', width: '100%', fontSize: 14 }}><tbody>
									<tr>
										<th>{i18n.t('invoices.representative')}</th>
										<th>{i18n.t('invoices.invoice')} #</th>
										<th>{i18n.t('invoices.deliveryDate')}</th>
										<th>{i18n.t('invoices.pointFOB')}</th>
										<th>{i18n.t('invoices.terms')}</th>
									</tr>
									<tr>
										<td>{invoice.representative}</td>
										<td>{invoice.number}</td>
										<td>{moment(invoice.deliveredAt).format('YYYY-MM-DD')}</td>
										<td>Montréal</td>
										<td>{invoice.terms}</td>
									</tr>
								</tbody></table>
								<br />
								<table align="center" border="0" cellPadding="3" cellSpacing="0" style={{ borderTop: '3px solid black', width: '100%', fontSize: 14 }}><tbody>
									<tr>
										<th style={{ border: '1px solid black', width: 20 }}>{i18n.t('invoices.item')}</th>
										<th style={{ border: '1px solid black' }}>{i18n.t('general.description')}</th>
										<th style={{ border: '1px solid black' }}>{i18n.t('invoices.quantity')}</th>
										<th style={{ border: '1px solid black' }}>{i18n.t('invoices.total')}</th>
									</tr>
									{
										(invoice.items && invoice.items.length)
											? invoice.items.map((item, index) => (
												<tr key={index}>
													<td style={{ border: '1px solid black' }}>{index + 1}</td>
													<td style={{ border: '1px solid black' }}>
														{
															item.notes ?
															`${item.description} - ${item.notes}`
															:
															item.description
														}
													</td>
													<td style={{ border: '1px solid black' }}>{item.quantity}</td>
													<td style={{ textAlign: 'right', border: '1px solid black' }}>${(item.quantity * item.rate).toFixed(2)}</td>
												</tr>
											))
											: null
									}
									<tr>
										<td colSpan="3" style={{ textAlign: 'right', borderRight: '1px solid black', borderTop: '1px solid black' }}>{i18n.t('invoices.subtotal')}</td>
										<td style={{ textAlign: 'right', border: '1px solid black' }}>${this.calculateSubTotal().toFixed(2)}</td>
									</tr>
									<tr>
										<td colSpan="2">{i18n.t('invoices.gst')} # 84192 7858 RT0001</td>
										<td style={{ textAlign: 'right', borderRight: '1px solid black' }}>{i18n.t('invoices.gst')}</td>
										<td style={{ textAlign: 'right', border: '1px solid black' }}>${this.calculateGST().toFixed(2)}</td>
									</tr>
									<tr>
										<td colSpan="2">{i18n.t('invoices.qst')} # 1215732823 TQ0001</td>
										<td style={{ textAlign: 'right', borderRight: '1px solid black' }}>{i18n.t('invoices.qst')}</td>
										<td style={{ textAlign: 'right', border: '1px solid black' }}>${this.calculateQST().toFixed(2)}</td>
									</tr>
									<tr>
										<td colSpan="3" style={{ textAlign: 'right', borderRight: '1px solid black' }}><strong>{i18n.t('invoices.total')}</strong></td>
										<td style={{ textAlign: 'right', border: '1px solid black' }}><strong>${this.calculateTotal().toFixed(2)}</strong></td>
									</tr>
								</tbody></table>
								<p>{i18n.t('invoices.ifQuestions')}</p>
								<p style={{ textAlign: 'center' }}><strong>{i18n.t('invoices.thanks')}</strong></p>
							</td></tr>
						</tbody></table>
					</td>
				</tr>
			</tbody>
			</table>
		);
	}
}

InvoiceEmail.propTypes = {
	invoice: PropTypes.object,
	client: PropTypes.object,
	project: PropTypes.object
};

export default InvoiceEmail;
