import { Query } from 'react-apollo';
import {
	Page,
	PageBody,
	ProjectCard,
	Grid
} from 'components';
import { PROJECTS } from 'graph/queries';

class Projects extends Component {
	render() {
		return (
			<Page>
				<Query query={PROJECTS} fetchPolicy={'network-only'}>
					{({ loading, error, data }) => {
						const projects = data && data.projects ? data.projects.projects : null;
						
						return (
							<PageBody loading={loading} error={error}>
								<Grid container spacing={16}>
									{
										projects ?
										data.projects.projects.map((project, i) => (
											<Grid key={i} item xs={12} md={6} lg={4}>
												<ProjectCard project={project} />
											</Grid>
										))
										: null
									}
								</Grid>
							</PageBody>
						);
					}}
				</Query>
			</Page>
		);
	}
};

export default Projects;