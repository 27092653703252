export const permissionsArray = [
  "clients",
  "techs",
  "admins",
  "projects",
  "tickets",
  "tags",
  "invoices",
  "payments",
  "webhooks",
  "eventLogs"
];

export const LENGTH_MAX_STRING = 100;
