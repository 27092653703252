import gql from 'graphql-tag';

export const EVENT_LOG = gql`
	query eventLog($id: ID!) {
		eventLog(id: $id) {
			id
			ip
			user {
				firstName
				lastName
			}
			message
			createdAt
			type
		}
	}
`;

export const EVENT_LOGS = gql`
	query eventLogs(
		$searchString: String
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		eventLogs(
			searchString: $searchString
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			eventLogs {
				id
				ip
				user {
					firstName
					lastName
				}
				message
				createdAt
				type
			}
			count
		}
	}
`;