import {
	Toolbar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Query, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import withStyles from 'utils/withStyles';
import {
	Table,
	Button,
	Page,
	PageHeader,
	PageBody,
	StateChip
} from 'components';
import withUser from 'utils/withUser';
import { INVOICES } from 'graph/queries';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	popperPaper: {
		width: 300,
		marginTop: theme.spacing.unit
	}
});

class Invoices extends Component {
	constructor(props) {
		super(props);

		let params = {};

		if (props.location && props.location.search)
			params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

		this.state = {
			searchString: params.searchString || '',
			project: params.project || '',
			client: params.client || ''
		};
	}

	render() {
		const { classes, currentUser, location } = this.props;

		let page = 0;
		let searchString = '';
		let project = '';
		let client = '';
		let state = '';
		let sort = 'number';
		let dir = 'desc';

		if (location.search) {
			const params = qs.parse(location.search, { ignoreQueryPrefix: true });

			if (params.p) {
				page = params.p - 1
			}

			searchString = params.searchString || '';
			project = params.project || '';
			client = params.client || '';
			state = params.state || '';
			sort = params.sort || 'number';
			dir = params.dir || 'desc';
		}

		return (
			<Page>
				<PageHeader>
					{
						(currentUser && currentUser.hasPermission('invoices', 1)) ?
							<div style={{ flex: 1, textAlign: 'right' }}>
								<Link className="navlink" to={{
									pathname: '/invoices/create',
									state: { title: i18n.t('title.createInvoice') }
								}}><Button variant="contained" color="primary" icon={AddIcon}>{i18n.t('general.create')}</Button></Link>
							</div>
							: null
					}
				</PageHeader>

				<Query query={INVOICES} fetchPolicy={'network-only'} variables={{
					searchString: searchString,
					state: state,
					project: project,
					client: client,
					sort: sort,
					direction: dir,
					limit: 10,
					page: page
				}}>
					{({ loading, error, data }) => {
						return (
						<PageBody loading={loading} error={error} padding={0}>
							<Toolbar>
								<div className={classes.flexRow}>
									<TextField
										id="outlined-dense"
										label={i18n.t('general.search')}
										color="secondary"
										value={this.state.number}
										onChange={(e) => this.search(e.target.value)}
									/>

									<FormControl className={classes.formControl}>
										<TextField
											id="outlined-dense"
											label={i18n.t('invoices.project')}
											color="secondary"
											value={this.state.project}
											onChange={(e) => this.searchProjects(e.target.value)}
										/>
									</FormControl>

									<FormControl className={classes.formControl}>
										<TextField
											id="outlined-dense"
											label={i18n.t('general.client')}
											color="secondary"
											value={this.state.client}
											onChange={(e) => this.searchClients(e.target.value)}
										/>
									</FormControl>

									<FormControl className={classes.formControl}>
										<InputLabel>{i18n.t('invoices.state')}</InputLabel>
										<Select value={state} onChange={(e) => this.updateQueryString({ state: e.target.value })}>
											<MenuItem value={''}>{i18n.t('general.any')}</MenuItem>
											<MenuItem value={'discarded'}>{i18n.t('invoices.states.discarded')}</MenuItem>
											<MenuItem value={'draft'}>{i18n.t('invoices.states.draft')}</MenuItem>
											<MenuItem value={'sent'}>{i18n.t('invoices.states.sent')}</MenuItem>
											<MenuItem value={'paid'}>{i18n.t('invoices.states.paid')}</MenuItem>
											<MenuItem value={'void'}>{i18n.t('invoices.states.void')}</MenuItem>
										</Select>
									</FormControl>

								</div>
							</Toolbar>

							<Table
								page={page}
								limit={10}
								sort={sort}
								count={data && data.invoices ? data.invoices.count : 0}
								direction={dir}
								onChangeSort={this.sortTable.bind(this)}
								onChangePage={(page) => this.updateQueryString({ p: page + 1 })}
								rows={
									(data && data.invoices && data.invoices.invoices) ?
										data.invoices.invoices.map((invoice, i) => ({
											number: invoice.number,
											state: <StateChip state={invoice.state} />,
											description: invoice.description,
											project: invoice.project.name,
											createdAt: moment(invoice.createdAt).format('YYYY-MM-DD hh:mm A'),
											edit: <Link to={{
												pathname: `/invoices/${invoice.id}`,
												state: { title: i18n.t('invoices.invoice') + ' ' + invoice.number }
											}}><IconButton><EditIcon /></IconButton></Link>
										}))
										: []
								}
								columns={[{
									key: 'number',
									label: i18n.t('invoices.invoiceNumber'),
									sortable: true
								}, {
									key: 'state',
									label: i18n.t('invoices.state'),
									sortable: true
								}, {
									key: 'description',
									label: i18n.t('general.description')
								}, {
									key: 'project',
									label: i18n.t('invoices.project'),
									sortable: true
								}, {
									key: 'createdAt',
									label: i18n.t('general.created'),
									sortable: true
								}, {
									key: 'edit',
									label: ''
								}]} />
						</PageBody>
						);
					}}
				</Query>
			</Page>
		);
	}

	search(value, e) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.updateQueryString({ searchString: value });
		}, 500);

		this.setState({
			searchString: value
		});
	}

	searchProjects(value, e) {
		clearTimeout(this.debounce2);

		this.debounce2 = setTimeout(() => {
			this.updateQueryString({ project: value });
		}, 500);

		this.setState({
			project: value
		});
	}

	searchClients(value, e) {
		clearTimeout(this.debounce3);

		this.debounce3 = setTimeout(() => {
			this.updateQueryString({ client: value });
		}, 500);

		this.setState({
			client: value
		});
	}

	updateQueryString(update) {
		const queryString = {
			...qs.parse(this.props.location.search),
			...update
		};

		for (let key in queryString) {
			if (queryString[key].length === 0) {
				delete queryString[key];
			}
		}

		const search = qs.stringify(queryString);

		if (search.length) {
			this.props.history.push('/invoices?' + search);
		} else {
			this.props.history.push('/invoices');
		}
	}

	sortTable(fieldName, e) {
		const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

		let sort = params.sort || 'number';
		let dir = params.dir || 'desc';

		if (fieldName === sort) {
			dir = (dir === 'asc') ? 'desc' : 'asc';
		}

		this.updateQueryString({
			sort: fieldName,
			dir
		});
	}
};

export default compose(
	withUser,
	withStyles(styles)
)(Invoices);