import { Chip } from '@material-ui/core';
import withStyles from 'utils/withStyles';

const styles = (theme) => ({
	grey: {
		background: 'grey'
	},

	orange: {
		background: '#f08c07'
	},

	blue: {
		background: '#3f51b5'
	},

	green: {
		background: '#23bf00'
	},

	black: {
		background: 'black'
	}
});

class StateChip extends React.PureComponent {
	render() {
		const { style } = this.props;

		return (
			<Chip
				label={this.getStateLabel()}
				color="primary"
				style={(style) ? style : {}}
				classes={{ colorPrimary: this.getStateColor() }} />
		)
	}

	getStateLabel() {
		const { state } = this.props;

		switch (state) {
			case 'draft':
				return i18n.t('invoices.states.draft');
			case 'open':
				return i18n.t('serviceCalls.states.open');
			case 'opened':
				return i18n.t('tickets.states.opened');
			case 'discarded':
				return i18n.t('invoices.states.discarded');
			case 'booked':
				return i18n.t('serviceCalls.states.booked');
			case 'sent':
				return i18n.t('invoices.states.sent');
			case 'complete':
				return i18n.t('serviceCalls.states.complete');
			case 'paid':
				return i18n.t('invoices.states.paid');
			case 'approved':
				return i18n.t('serviceCalls.states.approved');
			case 'closed':
				return i18n.t('tickets.states.closed');
			case 'void':
				return i18n.t('invoices.states.void');
			default:
				return i18n.t('invoices.states.draft');
		}
	}

	getStateColor() {
		const { state, classes } = this.props;

		switch (state) {
			case 'draft':
			case 'open':
			case 'opened':
			case 'discarded':
				return classes.grey;

			case 'booked':
				return classes.orange;

			case 'sent':
			case 'complete':
				return classes.blue;

			case 'paid':
			case 'approved':
			case 'closed':
				return classes.green;

			case 'void':
				return classes.black;
			default:
				return classes.grey;
		}
	}
}

export default withStyles(styles)(StateChip);