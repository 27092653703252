import {
	Page,
	PageBody
} from 'components';
import {
	Grid
} from '@material-ui/core';
import { compose } from 'react-apollo';
import withUser from 'utils/withUser';
import InvoicesDashboard from './InvoicesDashboard';
import TicketsDashboard from './TicketsDashboard';

class Home extends Component {
	render() {
		const { currentUser } = this.props;

		return (
			<Page>
				<PageBody>
					<Grid container spacing={16}>
						{currentUser.hasPermission('invoices', 0) && <Grid item xs={12}><InvoicesDashboard /></Grid>}
						{currentUser.hasPermission('tickets', 0) && <Grid item xs={12}><TicketsDashboard /></Grid>}
					</Grid>
				</PageBody>
			</Page>
		)
	}
}

export default compose(
	withUser,
)(Home);
