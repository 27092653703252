import { compose } from "react-apollo";
import moment from "moment";
import _reverse from "lodash/reverse";
import _sortBy from "lodash/sortBy";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import withUI from "utils/withUI";
import withStyles from "utils/withStyles";
import { withQueries } from "utils/withQuery";
import { Typography, IconButton } from "@material-ui/core";
import { Section, Confirm, StateChip, Grid } from "components";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { TICKET_SERVICE_CALLS } from "graph/queries";
import { DELETE_SERVICE_CALL } from "graph/mutations";

const styles = theme => ({
  workItemsTable: {
    width: "100%",
    borderCollapse: "collapse",
    "& td": {
      borderBottom: "1px solid #ccc",
      padding: theme.spacing.unit
    },
    "& td:last-child": {
      textAlign: "right"
    }
  }
});

class TicketServiceCalls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteConfirmOpen: false,
      serviceCallToDelete: null
    };

    this.serviceCalls = _sortBy(
      props.queryResult.serviceCalls.serviceCalls.serviceCalls,
      "date"
    );

    this.deleteServiceCall = this.deleteServiceCall.bind(this);
    this.getButtons = this.getButtons.bind(this);
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={16}>
        {this.serviceCalls.map((serviceCall, i) => (
          <Grid key={i} item xs={12} md={6} lg={4}>
            <Section
              title={moment(serviceCall.date).format("YYYY-MM-DD")}
              buttons={this.getButtons(serviceCall)}
            >
              <StateChip state={serviceCall.status} />
              <br />
              <br />
              <Typography variant="subtitle2">
                {i18n.t("serviceCalls.workItems")}:
              </Typography>
              <table className={classes.workItemsTable}>
                <tbody>
                  {serviceCall.workItems.map((item, n) => (
                    <tr key={n}>
                      <td>
                        <Typography>{`${item.tech.firstName} ${item.tech.lastName}`}</Typography>
                      </td>
                      <td>
                        <Typography variant="caption">{`${moment(
                          item.startTime
                        ).format("HH:mm")} - ${moment(item.endTime).format(
                          "HH:mm"
                        )}`}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Section>
          </Grid>
        ))}

        <Confirm
          title={i18n.t("serviceCalls.deleteServiceCallTitle")}
          body={i18n.t("serviceCalls.confirmDeleteServiceCall")}
          open={this.state.deleteConfirmOpen}
          confirmButtonText={i18n.t("general.delete")}
          onConfirm={this.deleteServiceCall}
          onCancel={() => this.setState({ deleteConfirmOpen: false })}
        />
      </Grid>
    );
  }

  deleteServiceCall() {
    const { client, ticketId } = this.props;

    this.setState({
      loading: true,
      deleteConfirmOpen: false
    });

    if (this.state.serviceCallToDelete) {
      client
        .mutate({
          mutation: DELETE_SERVICE_CALL,
          variables: {
            id: this.state.serviceCallToDelete
          },
          refetchQueries: [
            {
              query: TICKET_SERVICE_CALLS,
              variables: {
                ticketId
              }
            }
          ],
          awaitRefetchQueries: true
        })
        .then(() => {
          this.setState({
            loading: false,
            serviceCallToDelete: null
          });

          this.props.refetchQueries();
        })
        .catch(e => {
          this.props.ui.showError(getGraphQLError(e));
          this.setState({
            loading: false,
            serviceCallToDelete: null
          });
        });
    }
  }

  getButtons(serviceCall) {
    const buttons = [
      <IconButton
        key={1}
        style={{ margin: "-6px 0" }}
        onClick={() => {
          this.props.history.push(`/serviceCalls/${serviceCall.id}`, {
            title: i18n.t("title.serviceCall")
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    ];

    if (serviceCall.status === "open" || serviceCall.status === "booked") {
      buttons.push(
        <IconButton
          key={2}
          style={{ margin: "-6px 0" }}
          onClick={() =>
            this.setState({
              deleteConfirmOpen: true,
              serviceCallToDelete: serviceCall.id
            })
          }
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      );
    }

    return buttons;
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withUI,
  withQueries(function() {
    const { ticketId } = this.props;

    return {
      serviceCalls: {
        query: TICKET_SERVICE_CALLS,
        variables: {
          ticketId
        }
      }
    };
  }),
  withApollo
)(TicketServiceCalls);
