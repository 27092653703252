import {
	Toolbar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Query, compose } from 'react-apollo';
import withUser from 'utils/withUser';
import withStyles from 'utils/withStyles';
import {
	Table,
	Button,
	Page,
	PageHeader,
	PageBody,
	StateChip
} from 'components';
import { TICKETS } from 'graph/queries';
import { LENGTH_MAX_STRING } from 'utils/constants';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	paper: {
		margin: theme.spacing.unit,
		overflowX: 'auto'
	},
	center: {
		textAlign: 'center'
	},
	green: {
		color: 'green'
	},
	red: {
		color: 'red'
	}
});

class Tickets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			workOrderSearchString: 0,
			clientSearchString: '',
			projectSearchString: '',
			statusSearchString: '',
			sort: 'workOrder',
			dir: 'desc',
			page: 0
		}
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						(currentUser && currentUser.hasPermission('tickets', 1)) ?
							<div style={{ flex: 1, textAlign: 'right' }}>
								<Link className="navlink" to={{
									pathname: '/tickets/create',
									state: { title: i18n.t('tickets.createTicket') }
								}}><Button variant="contained" color="primary" icon={AddIcon}>{i18n.t('general.create')}</Button></Link>
							</div>
							: null
					}
				</PageHeader>
				
				<Query query={TICKETS} fetchPolicy={'network-only'} variables={{
					workOrderSearchString: this.state.workOrderSearchString,
					clientSearchString: this.state.clientSearchString,
					projectSearchString: this.state.projectSearchString,
					statusSearchString: this.state.statusSearchString,
					sort: this.state.sort,
					direction: this.state.dir,
					limit: 10,
					page: this.state.page
				}}>
					{({ loading, error, data }) => {
						return (
						<PageBody loading={loading} error={error} padding={0}>
							<Toolbar>
								<div className={classes.flexRow}>
									<TextField
										label={i18n.t('tickets.workOrder')}
										color="secondary"
										onChange={(e) => this.search(e.target.value, 'workOrderSearch')} />

									<FormControl className={classes.formControl}>
										<TextField
											label={i18n.t('general.client')}
											color="secondary"
											onChange={(e) => this.search(e.target.value, 'clientSearch')}
										/>
									</FormControl>

									<FormControl className={classes.formControl}>
										<TextField
											label={i18n.t('title.projects')}
											color="secondary"
											onChange={(e) => this.search(e.target.value, 'projectSearch')}
										/>
									</FormControl>

									<FormControl className={classes.formControl}>
										<InputLabel>{i18n.t('tickets.status')}</InputLabel>
										<Select value={this.state.statusSearchString} onChange={(e) => this.setState({ statusSearchString: e.target.value })}>
											<MenuItem value={'Any'}>{i18n.t('general.any')}</MenuItem>
											<MenuItem value={'opened'}>{i18n.t('tickets.states.opened')}</MenuItem>
											<MenuItem value={'closed'}>{i18n.t('tickets.states.closed')}</MenuItem>
										</Select>
									</FormControl>
								</div>
							</Toolbar>

							
							<Table 
								page={this.state.page}
								limit={10}
								sort={this.state.sort}
								count={data && data.tickets ? data.tickets.count : 0}
								direction={this.state.dir}
								onChangeSort={this.sortTable.bind(this)}
								onChangePage={(page) => this.setState({ page })}
								rows={
									(data && data.tickets && data.tickets.tickets) ?
										data.tickets.tickets.map((ticket, i) => ({
											workOrder: ticket.workOrder,
											
											categories: ticket.descriptions && ticket.descriptions.length >0 ? this.formatedDescriptionCategories(ticket) : "No Descriptions", 
											
											project: ticket.project.name,
											
											client: ticket.client.firstName + ' ' + ticket.client.lastName,
											
											invoice: this.getInvoiceCell(ticket),
											
											status: <StateChip state={ticket.status} />,

											createdAt: moment(ticket.createdAt).format(i18n.t('formats.dateTime')),
									
											edit: <Link to={{
												pathname: `/tickets/${ticket.id}`,
												state: { title: `Ticket #${ticket.workOrder}` }
											}}><IconButton><EditIcon /></IconButton></Link>
										}))
										: []
								}
								columns={[{
									key: 'workOrder',
									label: i18n.t('tickets.workOrder'),
									sortable: true
								}, {
									key: 'status',
									label: i18n.t('tickets.status'),
									sortable: true,
									center: true
								}, {
									key: 'categories',
									label: i18n.t('tickets.categories')
								}, {
									key: 'project',
									label: i18n.t('title.project'),
									sortable: true
								}, {
									key: 'client',
									label: i18n.t('tickets.client'),
									sortable: true
								}, {
									key: 'invoice',
									label: i18n.t('invoices.invoice'),
									sortable: true
								}, {
									key: 'createdAt',
									label: i18n.t('general.created'),
									sortable: true
								}, {
									key: 'edit',
									label: ''
								}]} />
						</PageBody>);
					}}
				</Query>
			</Page>
		);
	}

	formatedDescriptionCategories (ticket) {
		if (ticket.descriptions && ticket.descriptions.length > 0) {
			let text = "";

			ticket.descriptions.map((desc, i) => {
				text = text + `${i18n.t(`tickets.descriptionCategories.${desc.category}`)}, `;
			});

			text = text.substring(0,text.length -2)
			if(text.length > LENGTH_MAX_STRING) {
				return text.substring(0, LENGTH_MAX_STRING)
			} else return text
		} else return ""
	}

	getInvoiceCell(ticket) {
		const { currentUser } = this.props;

		const hasInvoicePermission = currentUser.hasPermission('invoices', 0);

		if (ticket.warranty) {
			return i18n.t('tickets.warranty');
		}

		if (!ticket.invoice) {
			if (hasInvoicePermission) {
				return (
					<Button
						size="small"
						color="primary"
						variant="contained"
						icon={AddIcon}
						onClick={() => this.props.history.push('/invoices/create', {
							invoiceTicketId: ticket.id
						})}
					>{i18n.t('invoices.invoice')}</Button>
				);
			} else {
				return null;
			}
		} else {
			if (hasInvoicePermission) {
				return <Link to={`/invoices/${ticket.invoice.id}`}>#{ticket.invoice.number}</Link>
			} else {
				return '#' + ticket.invoice.number;
			}
		}
	}

	search(value, fieldName) {
		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			if (fieldName === "workOrderSearch") {
				let v = parseInt(value);
				if (isNaN(v)) {
					v = 0;
				} else if (v < 0) {
					v = 0;
				}

				this.setState({
					workOrderSearchString: v
				});
			}
			if (fieldName === "clientSearch") {
				this.setState({
					clientSearchString: value
				});
			}
			if (fieldName === "projectSearch") {
				this.setState({
					projectSearchString: value
				});
			}
		}, 500);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
};

export default compose(
	withUser,
	withStyles(styles)
)(Tickets);