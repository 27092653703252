import gql from "graphql-tag";
import { project, projectNotes } from "graph/types";

export const CREATE_PROJECT = gql`
mutation CreateProject(
	$name: String!
	$clients: [String!]!
	$leadTech: String
	$warrantyStart: Date
	$warrantyExpiration: Date
	$address: Input_Address!) {
	createProject(
		name: $name
		address: $address
		warrantyStart: $warrantyStart
		warrantyExpiration: $warrantyExpiration
		clients: $clients
		leadTech: $leadTech
	) {
		${project}
	}
}
`;

export const EDIT_PROJECT = gql`
mutation EditProject(
	$id: ID!
	$name: String!
	$clients: [String!]!
	$leadTech: String
	$warrantyStart: Date
	$warrantyExpiration: Date
	$address: Input_Address!) {
	editProject(
		id: $id
		name: $name
		warrantyStart: $warrantyStart
		warrantyExpiration: $warrantyExpiration
		clients: $clients
		leadTech: $leadTech
		address: $address
	) {
		${project}
	}
}
`;

export const ADD_PROJECT_NOTE = gql`
mutation addProjectNote(
	$id: ID!
	$note: String!
	$isInternal: Boolean!) {
	addProjectNote(
		id: $id
		note: $note
		isInternal: $isInternal
	) {
		${projectNotes}
	}
}
`;

export const REMOVE_PROJECT_NOTE = gql`
mutation removeProjectNote(
	$id: ID!
	$noteId: ID!
	$isInternal: Boolean!) {
	removeProjectNote(
		id: $id
		noteId: $noteId
		isInternal: $isInternal
	) {
		${projectNotes}
	}
}
`;

export const EDIT_PROJECT_NOTE = gql`
mutation editProjectNote(
	$id: ID!
	$noteId: ID!
	$note: String!
	$isInternal: Boolean!) {
	editProjectNote(
		id: $id
		noteId: $noteId
		note: $note
		isInternal: $isInternal
	) {
		${projectNotes}
	}
}
`;
