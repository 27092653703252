import { compose, withApollo } from "react-apollo";
import withStyles from "utils/withStyles";
import withUI from "utils/withUI";
import withUser from "utils/withUser";
import { Section, Button } from "components";
import {
  Menu,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Divider,
  Grid
} from "@material-ui/core";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import VideocamIcon from "@material-ui/icons/Videocam";
import RouterIcon from "@material-ui/icons/Router";
import SpeakerIcon from "@material-ui/icons/Speaker";
import TheatersIcon from "@material-ui/icons/Theaters";
import AcUnitIcon from "@material-ui/icons/AcUnit";

import MoreIcon from "@material-ui/icons/MoreVert";
import ReactQuill from "react-quill";
import { EDIT_TICKET_DESCRIPTION, REMOVE_TICKET_DESCRIPTION } from "graph/mutations";
import momenttz from "moment-timezone";
import PersonIcon from "@material-ui/icons/Person";


const styles = theme => ({
  description: {
    marginBottom: theme.spacing.unit * 2,
    "& .ql-container": {
      border: "none"
    }
  },

  forceBorder: {
    "& .ql-container": {
      border: "1px solid #ccc"
    }
  }
});

const descriptionStatus = {
  video: "Video",
  audio: "Audio",
  network: "Network",
  camera: "Camera",
  blinds: "Blinds",
  lighting: "Lighting",
  HVAC: "HVAC"
};
class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      descMenuOpen: false,
      descText: "",
      descRawText: "",
      categoryEdit: "",
      descEditing: false,

      solveDescMenuOpen: false,
      solveDescText: "",
      solveDescRawText: "",
      solveDescEditing: false
    };

    this.openDescriptionMenu = this.openDescriptionMenu.bind(this);
    this.closeDescriptionMenu = this.closeDescriptionMenu.bind(this);
    this.editDescription = this.editDescription.bind(this);
    this.cancelDescEdit = this.cancelDescEdit.bind(this);
    this.removeDescription = this.removeDescription.bind(this);
    this.finishDescEdit = this.finishDescEdit.bind(this);

    this.openSolveDescriptionMenu = this.openSolveDescriptionMenu.bind(this);
    this.closeSolveDescriptionMenu = this.closeSolveDescriptionMenu.bind(this);
    this.editSolveDescription = this.editSolveDescription.bind(this);
    this.cancelSolveDescEdit = this.cancelSolveDescEdit.bind(this);
    this.removeSolveDescription = this.removeSolveDescription.bind(this);
    this.finishSolveDescEdit = this.finishSolveDescEdit.bind(this);

    this.changeSolve = this.changeSolve.bind(this);
  }

  render() {
    const { classes, description, currentUser } = this.props;
    let descEditingEnabled = true;
    let descRemovingEnabled = true;
    let solveDescEditingEnabled = true;
    let solveDescRemovingEnabled = true;
    let allowUnsolve = false;
    let allowSolve = false;

    if (currentUser.type === "Admin" && currentUser.hasPermission("tickets", 1)) {
      descEditingEnabled = true;
      descRemovingEnabled = true;
      solveDescEditingEnabled = true;
      solveDescRemovingEnabled = true;
      allowUnsolve = true;
      allowSolve = true;
    }

    if (currentUser.type === "Tech") {
      descEditingEnabled = false;
      descRemovingEnabled = false;
      allowSolve = true;
      allowUnsolve = description.solvedBy.id === currentUser.id ? true : false;
      solveDescEditingEnabled =
        description.solved && description.solvedBy.id === currentUser.id ? true : false;
      solveDescRemovingEnabled =
        description.solved && description.solvedBy.id === currentUser.id ? true : false;
    }

    return (
      <Section className={classes.description}>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.descMenuOpen}
          onClose={this.closeDescriptionMenu}
        >
          {descEditingEnabled ? (
            <MenuItem onClick={this.editDescription}>{i18n.t("general.edit")}</MenuItem>
          ) : null}

          {descRemovingEnabled ? (
            <MenuItem onClick={this.removeDescription}>{i18n.t("general.remove")}</MenuItem>
          ) : null}
        </Menu>

        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.solveDescMenuOpen}
          onClose={this.closeSolveDescriptionMenu}
        >
          {solveDescEditingEnabled ? (
            <MenuItem onClick={this.editSolveDescription}>{i18n.t("general.edit")}</MenuItem>
          ) : null}

          {solveDescRemovingEnabled ? (
            <MenuItem onClick={this.removeSolveDescription}>{i18n.t("general.remove")}</MenuItem>
          ) : null}
        </Menu>

        <div className={classes.flexRow}>
          {!this.state.descEditing ? (
            <div>
              {description.category === "lighting" ? (
                <WbIncandescentIcon className={classes.icon} />
              ) : description.category === "camera" ? (
                <VideocamIcon className={classes.icon} />
              ) : description.category === "network" ? (
                <RouterIcon className={classes.icon} />
              ) : description.category === "audio" ? (
                <SpeakerIcon className={classes.icon} />
              ) : description.category === "video" ? (
                <TheatersIcon className={classes.icon} />
              ) : description.category === "HVAC" ? (
                <AcUnitIcon className={classes.icon} />
              ) : description.category === "blinds" ? null : null}
            </div>
          ) : null}

          <div style={{ flex: 1 }}>
            {this.state.descEditing ? (
              <FormControl variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  autoWidth
                  value={this.state.categoryEdit}
                  style={{ width: 150, marginRight: 16 }}
                  onChange={e => {
                    this.setState({
                      categoryEdit: e.target.value
                    });
                  }}
                >
                  <MenuItem value={"lighting"}>Lighting</MenuItem>
                  <MenuItem value={"audio"}>Audio</MenuItem>
                  <MenuItem value={"video"}>Video</MenuItem>
                  <MenuItem value={"network"}>Network</MenuItem>
                  <MenuItem value={"camera"}>Camera</MenuItem>
                  <MenuItem value={"hvac"}>HVAC</MenuItem>
                  <MenuItem value={"blinds"}>Blinds</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <React.Fragment>
                <Typography variant="subtitle2">{`Category : ${
                  descriptionStatus[description.category]
                }`}</Typography>
                {description.solved ? (
                  <div className={classes.flexRow}>
                    <div>
                      <Typography variant="caption" style={{ color: "green" }}>
                        {"Solved"}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "4px" }}>
                      <Typography variant="caption">
                        {`by : ${description.solvedBy.firstName} ${
                          description.solvedBy.lastName
                        } (${momenttz.tz(description.solvedAt, "America/Toronto")})`}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {"Unsolved"}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </div>

          {descEditingEnabled || descRemovingEnabled || allowSolve ? (
            this.state.descEditing ? (
              <React.Fragment>
                <Button color="primary" onClick={this.finishDescEdit}>
                  {i18n.t("general.done")}
                </Button>
                <Button
                  variant="text"
                  color="default"
                  style={{ marginLeft: 8 }}
                  onClick={this.cancelDescEdit}
                >
                  {i18n.t("general.cancel")}
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {allowUnsolve && description.solved ? (
                  <Button
                    variant="text"
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 8 }}
                    onClick={this.changeSolve}
                  >
                    {i18n.t("general.unsolve")}
                  </Button>
                ) : allowSolve && !description.solved ? (
                  <Button
                    variant="text"
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 8 }}
                    onClick={this.changeSolve}
                  >
                    {i18n.t("general.solve")}
                  </Button>
                ) : null}
                {descRemovingEnabled || descEditingEnabled ? (
                  <IconButton onClick={e => this.openDescriptionMenu(e)}>
                    <MoreIcon />
                  </IconButton>
                ) : null}
              </React.Fragment>
            )
          ) : null}
        </div>

        <Divider style={{ margin: "8px 0" }} />

        {this.state.descEditing ? (
          <ReactQuill
            className={classes.forceBorder}
            value={this.state.descText}
            onChange={(content, delta, source, editor) =>
              this.setState({
                descText: content,
                descRawText: editor.getText()
              })
            }
          />
        ) : (
          <ReactQuill
            value={`Description : ${description.description}`}
            readOnly
            modules={{
              toolbar: false
            }}
          />
        )}

        {description.solved ? (
          <React.Fragment>
            <Divider style={{ margin: "8px 0" }} />
            {this.state.solveDescEditing ? (
              <Grid container direction="row" justify="flex-end" alignItems="center" spacing={8}>
                <Grid item xs={12}>
                  <ReactQuill
                    className={classes.forceBorder}
                    value={this.state.solveDescText}
                    onChange={(content, delta, source, editor) =>
                      this.setState({
                        solveDescText: content,
                        solveDescRawText: editor.getText()
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <React.Fragment>
                    <Button color="primary" onClick={this.finishSolveDescEdit}>
                      {i18n.t("general.done")}
                    </Button>
                    <Button
                      variant="text"
                      color="default"
                      style={{ marginLeft: 8 }}
                      onClick={this.cancelSolveDescEdit}
                    >
                      {i18n.t("general.cancel")}
                    </Button>
                  </React.Fragment>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <ReactQuill
                    value={`What has been done : ${
                      description.solveDescription ? description.solveDescription : ""
                    }`}
                    readOnly
                    modules={{
                      toolbar: false
                    }}
                  />
                </Grid>
                {solveDescRemovingEnabled || solveDescEditingEnabled ? (
                  <Grid item>
                    <IconButton onClick={e => this.openSolveDescriptionMenu(e)}>
                      <MoreIcon />
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            )}
          </React.Fragment>
        ) : null}
      </Section>
    );
  }

  changeSolve() {
    const { client, description, ticketId } = this.props;
    const next = !description.solved;
    client
      .mutate({
        mutation: EDIT_TICKET_DESCRIPTION,
        variables: {
          id: ticketId,
          descriptionId: description.id,
          solved: next
        }
      })
      .then(() => {
        if (next === false) {
          this.setState({
            solved: next,
            solveDescRawText: "",
            solveDescText: "",
            solveDescEditing: false
          });
        } else {
          this.setState({
            solved: next
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false
        });

        this.props.ui.showError(getGraphQLError(e));
      });
  }

  openDescriptionMenu(e) {
    this.setState({
      descMenuOpen: true,
      anchorEl: e.target
    });
  }

  closeDescriptionMenu() {
    this.setState({
      descMenuOpen: false,
      anchorEl: null
    });
  }

  openSolveDescriptionMenu(e) {
    this.setState({
      solveDescMenuOpen: true,
      anchorEl: e.target
    });
  }

  closeSolveDescriptionMenu() {
    this.setState({
      solveDescMenuOpen: false,
      anchorEl: null
    });
  }

  editDescription() {
    this.setState({
      descEditing: true,
      descText: this.props.description.description,
      descRawText: this.props.description.description,
      categoryEdit: this.props.description.category
    });
    this.closeDescriptionMenu();
  }

  editSolveDescription() {
    this.setState({
      solveDescEditing: true,
      solveDescText: this.props.description.solveDescription,
      solveDescRawText: this.props.description.solveDescription
    });
    this.closeSolveDescriptionMenu();
  }

  removeDescription() {
    const { client, description, ticketId } = this.props;

    this.setState({
      descMenuOpen: false
    });

    client
      .mutate({
        mutation: REMOVE_TICKET_DESCRIPTION,
        variables: {
          id: ticketId,
          descriptionId: description.id
        }
      })
      .catch(() => {
        this.setState({
          loading: false
        });

        this.props.ui.showError(getGraphQLError(e));
      });
  }

  removeSolveDescription() {
    const { client, description, ticketId } = this.props;

    this.setState({
      solveDescMenuOpen: false
    });

    if (this.state.solveDescText && this.state.solveDescText.length) {
      client
        .mutate({
          mutation: EDIT_TICKET_DESCRIPTION,
          variables: {
            id: ticketId,
            descriptionId: description.id,
            solveDescription: this.state.solveDescText
          }
        })
        .catch(() => {
          this.setState({
            loading: false
          });

          this.props.ui.showError(getGraphQLError(e));
        });
    }
  }

  finishDescEdit() {
    const { client, description, ticketId } = this.props;

    if (this.state.descText && this.state.descText.length) {
      client
        .mutate({
          mutation: EDIT_TICKET_DESCRIPTION,
          variables: {
            id: ticketId,
            descriptionId: description.id,
            description: this.state.descText,
            category: this.state.categoryEdit
          }
        })
        .then(() => {
          this.setState({
            descText: "",
            descRawText: "",
            descEditing: false
          });
        })
        .catch(e => {
          this.setState({
            loading: false
          });

          this.props.ui.showError(getGraphQLError(e));
        });
    }
  }

  finishSolveDescEdit() {
    const { client, description, ticketId } = this.props;

    if (this.state.solveDescText && this.state.solveDescText.length) {
      client
        .mutate({
          mutation: EDIT_TICKET_DESCRIPTION,
          variables: {
            id: ticketId,
            descriptionId: description.id,
            solveDescription: this.state.solveDescText
          }
        })
        .then(() => {
          this.setState({
            solveDescText: "",
            solveDescRawText: "",
            solveDescEditing: false
          });
        })
        .catch(e => {
          this.setState({
            loading: false
          });

          this.props.ui.showError(getGraphQLError(e));
        });
    }
  }

  cancelDescEdit() {
    this.setState({
      descEditing: false,
      descRawText: "",
      descText: ""
    });
  }

  cancelSolveDescEdit() {
    this.setState({
      solveDescEditing: false,
      solveDescRawText: "",
      solveDescText: ""
    });
  }
}

export default compose(
  withUser,
  withApollo,
  withUI,
  withStyles(styles)
)(Description);
