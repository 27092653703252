import { Query } from 'react-apollo';
import { GET_CURRENT_USER } from 'graph/queries';
import _find from 'lodash/find';

export default function withUser(WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<Query query={GET_CURRENT_USER}>
					{(res) => {
						if (res && res.data && res.data.validateToken) {
							const currentUser = res.data.validateToken
							currentUser.hasPermission = hasPermission.bind(currentUser);

							return <WrappedComponent currentUser={currentUser} {...this.props} />
						} else {
							return <WrappedComponent currentUser={null} {...this.props} />
						}
					}}
				</Query>
			);
		}
	}
}

function hasPermission(permission, minLevel) {
	if (!this || !this.permissions || !this.permissions.length) {
		return false;
	}

	const p = _find(this.permissions, { key: permission });

	if (!p) {
		return false;
	}

	if (typeof minLevel === 'number') {
		return p.level >= minLevel;
	}

	return true;
}