import { Typography, IconButton, LinearProgress } from "@material-ui/core";
import { FullScreenError } from "components";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import moment from "moment";
import withStyles from "utils/withStyles";
import getGraphQLError from "utils/getGraphQLError";
import { SERVICE_CALLS } from "graph/queries";

const styles = theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },

  loading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },

  calendarHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },

  daysHeader: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center"
  },

  dayHeader: {
    flex: 1
  },

  weekRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },

  dayCell: {
    flex: 1,
    padding: 2,
    border: "1px solid #ccc",
    overflowY: "auto",
    maxHeight: "100%",
    "& a": {
      textDecoration: "none"
    }
  },

  blankDay: {
    background: "#e5e5e5"
  },

  calendarEvent: {
    borderRadius: 4,
    marginBottom: 4,
    padding: "0 4px"
  },

  grey: {
    background: "grey"
  },

  orange: {
    background: "#f08c07"
  },

  blue: {
    background: "#3f51b5"
  },

  green: {
    background: "#23bf00"
  }
});

class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monthStart: moment().startOf("month")
    };

    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.currentMonth = this.currentMonth.bind(this);
  }

  render() {
    const { classes } = this.props;
    const { monthStart } = this.state;

    return (
      <div className={classes.container}>
        <Query
          query={SERVICE_CALLS}
          variables={{
            startDate: monthStart.toDate(),
            endDate: moment(monthStart)
              .endOf("month")
              .toDate()
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (error) {
              return <FullScreenError error={getGraphQLError(error)} />;
            }

            this.serviceCalls = loading ? [] : data.serviceCalls.serviceCalls;

            return this.renderCalendar(loading);
          }}
        </Query>
      </div>
    );
  }

  renderCalendar(loading) {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {loading ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
        {this.renderCalendarHeader()}
        {[...Array(6)].map((n, i) => this.renderWeekRow(i))}
      </React.Fragment>
    );
  }

  renderCalendarHeader() {
    const { classes } = this.props;

    return (
      <div className={classes.calendarHeader}>
        <IconButton onClick={this.previousMonth}>
          <NavigateBeforeIcon />
        </IconButton>

        <Typography
          variant="h5"
          style={{ cursor: "pointer" }}
          onClick={this.currentMonth}
        >
          {this.state.monthStart.format("MMM YYYY")}
        </Typography>

        <IconButton onClick={this.nextMonth}>
          <NavigateNextIcon />
        </IconButton>
      </div>
    );
  }

  renderWeekRow(index) {
    const { classes } = this.props;

    if (index === 0) {
      return (
        <React.Fragment key={index}>
          <div className={classes.daysHeader}>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.sunday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.monday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.tuesday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.wednesday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.thursday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.friday")}</Typography>
            </div>
            <div className={classes.dayHeader}>
              <Typography>{i18n.t("general.saturday")}</Typography>
            </div>
          </div>
          <div className={classes.weekRow}>
            {[...Array(7)].map((n, i) => this.renderDayCell(i, index))}
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={classes.weekRow} key={index}>
        {[...Array(7)].map((n, i) => this.renderDayCell(i, index))}
      </div>
    );
  }

  renderDayCell(index, week) {
    const { classes } = this.props;
    const { monthStart } = this.state;

    const currentDayIndex = index + 7 * week;
    const monthStartIndex = monthStart.day();
    const monthEndIndex = monthStart.daysInMonth() + monthStartIndex;

    const date = moment(monthStart).set(
      "date",
      currentDayIndex - monthStartIndex + 1
    );

    const isToday = date.isSame(moment(), "day");

    let renderContent = true;

    const c = [classes.dayCell];

    if (currentDayIndex < monthStartIndex || currentDayIndex >= monthEndIndex) {
      c.push(classes.blankDay);
      renderContent = false;
    }

    return (
      <div className={c.join(" ")} key={index}>
        {renderContent ? (
          <React.Fragment>
            <Typography style={isToday ? { fontWeight: "bold" } : {}}>
              {currentDayIndex - monthStartIndex + 1}
            </Typography>
            {this.renderEvents(date)}
          </React.Fragment>
        ) : null}
      </div>
    );
  }

  renderEvents(date) {
    const { classes } = this.props;
    const { monthStart } = this.state;

    const events = this.serviceCalls.filter(sc => {
      return moment(sc.date).isSame(date, "day");
    });

    return (
      <React.Fragment>
        {events.map((serviceCall, i) => (
          <CalendarEvent serviceCall={serviceCall} key={i} />
        ))}
      </React.Fragment>
    );
  }

  previousMonth() {
    this.setState({
      monthStart: moment(this.state.monthStart).add(-1, "month")
    });
  }

  nextMonth() {
    this.setState({
      monthStart: moment(this.state.monthStart).add(1, "month")
    });
  }

  currentMonth() {
    this.setState({
      monthStart: moment().startOf("month")
    });
  }
}

class _CalendarEvent extends Component {
  render() {
    const { serviceCall, classes } = this.props;

    const c = [classes.calendarEvent];

    if (serviceCall.status === "open") {
      c.push(classes.grey);
    } else if (serviceCall.status === "booked") {
      c.push(classes.orange);
    } else if (serviceCall.status === "complete") {
      c.push(classes.blue);
    } else if (serviceCall.status === "approved") {
      c.push(classes.green);
    }

    const techs = serviceCall.workItems.map(
      wi => `${wi.tech.firstName[0]}${wi.tech.lastName[0]}`
    );

    return (
      <Link
        to={{
          pathname: `/serviceCalls/${serviceCall.id}`,
          state: { title: i18n.t("title.serviceCall") }
        }}
      >
        <div className={c.join(" ")}>
          <Typography style={{ color: "#fff" }}>{`${
            serviceCall.ticket.project.name
          } (${techs.join("+")})`}</Typography>
        </div>
      </Link>
    );
  }
}

const CalendarEvent = withStyles(styles)(_CalendarEvent);

export default withStyles(styles)(MonthlyCalendar);
