import {
	Typography,
	TextField,
	Chip,
	Popover,
	withStyles
} from '@material-ui/core';
import {
	Grid
} from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from 'components/Button/Button';

const styles = (theme) => ({
	phoneChip: {
		margin: theme.spacing.unit
	},
	popover: {
		padding: theme.spacing.unit * 2
	}
});

class Phones extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			popover: false,
			anchorEl: null,
			label: '',
			number: '',
			selectedPhone: null
		};

		this.addPhone = this.addPhone.bind(this);
		this.deletePhone = this.deletePhone.bind(this);
		this.onClickPhone = this.onClickPhone.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	render() {
		const { classes, phones, error } = this.props;

		const { selectedPhone } = this.state;

		return (
			<div>
				<form style={{ margin: '16px 0' }} onKeyDown={(e) => { e.keyCode === 13 && this.addPhone(); }}>
					<Typography variant="h6">{i18n.t('general.phone', { count: 2 })}</Typography>
					<Grid container spacing={24}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								className={classes.input}
								autoComplete="off"
								label={i18n.t('general.label')}
								name="label"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.label} />
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth
								className={classes.input}
								autoComplete="off"
								label={i18n.t('general.phoneNumber')}
								name="number"
								variant="outlined"
								onChange={this.handleInputChange}
								value={this.state.number} />
						</Grid>
					</Grid>
				</form>

				<div style={{ marginBottom: 16 }}>
					<Button color="primary" variant="contained" onClick={this.addPhone}>{i18n.t('general.add')} <AddIcon /></Button>
				</div>

				{
					(error) ?
						<Typography color="error">{i18n.t('admins.errors.atLeastOnePhone')}</Typography>
						: null
				}

				{
					phones.map((phone, i) => (
						<Chip
							key={i}
							color="primary"
							className={classes.phoneChip}
							label={`${phone.label}: ${phone.number}`}
							deleteIcon={<DeleteIcon />}
							onDelete={() => this.deletePhone(i)}
							onClick={(e) => this.onClickPhone(phone, e)} />
					))
				}
				<Popover
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.popover}
					anchorEl={this.state.anchorEl}
					onClose={() => this.setState({ popover: false })}>
					
					<div className={classes.popover}>
						{
							(selectedPhone) ?
							<div>
								<Typography variant="subtitle2">{`${selectedPhone.label}:`}</Typography>
								<Typography>{`${selectedPhone.number}`}</Typography>
							</div>
							: null
						}
					</div>

				</Popover>
			</div>
		);
	}

	addPhone() {
		if (this.state.label && this.state.number) {
			this.props.onChange([
					...this.props.phones,
					{
						label: this.state.label,
						number: this.state.number
					}
			]);

			this.setState({
				label: '',
				number: ''
			});
		}
	}

	deletePhone(i) {
		const { phones, onChange } = this.props;
		onChange([
			...phones.slice(0, i),
			...phones.slice(i + 1)
		]);
	}

	onClickPhone(phone, e) {
		this.setState({
			anchorEl: e.target,
			popover: true,
			selectedPhone: phone
		});
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
}

export default withStyles(styles)(Phones);