import PropTypes from 'prop-types';
import {
	Grid
} from 'components';
import {
	Paper,
	Hidden,
	Avatar,
	Divider,
	Typography,
	Button,
	withStyles
} from '@material-ui/core';

const styles = (theme) => ({
	paper: {
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
	},

	paperElevated: {
		padding: theme.spacing.unit * 2
	},

	icon: {
		backgroundColor: theme.palette.secondary.main
	}
});

class Banner extends Component {
	render() {
		const { visible } = this.props;

		return visible ? this.renderContainer() : null;
	}

	renderContainer() {
		const { elevated, classes } = this.props;

		if (elevated) {
			return (
				<div style={{ margin: '16px 0' }}>
					<Paper elevation={1} className={classes.paperElevated}>
						{this.renderContents()}
					</Paper>
				</div>
			);
		} else {
			return (
				<React.Fragment>
					<Paper elevation={0} className={classes.paper}>
						{this.renderContents()}
					</Paper>
					<Divider />
				</React.Fragment>
			);
		}
		
	}

	renderContents() {
		const { icon, message, actions, classes } = this.props;

		return (
			<React.Fragment>
				<Hidden smDown>
					<Grid container spacing={16} alignItems="center" justify="center" wrap="nowrap">
						{
							icon ?
							<Grid item>
								<Avatar className={classes.icon}>
									{icon}
								</Avatar>
							</Grid>
							: null
						}

						<Grid item>
							<Typography variant="body2" component="div">{message}</Typography>
						</Grid>

						<Grid item>
							{
								actions ?
								actions.map((a, i) => (
									<Grid item key={i}>
										<Button color="secondary" onClick={a.onClick}>{a.label}</Button>
									</Grid>
								))
								: null
							}
						</Grid>
					</Grid>
				</Hidden>

				<Hidden mdUp>
					<Grid container spacing={16} alignItems="center" wrap="nowrap">
						{
							icon ?
							<Grid item>
								<Avatar className={classes.icon}>
									{icon}
								</Avatar>
							</Grid>
							: null
						}

						<Grid item>
							<Typography variant="body2" component="div">{message}</Typography>
						</Grid>
					</Grid>

					<Grid container justify="flex-end" spacing={8}>
						{
							actions ?
							actions.map((a, i) => (
								<Grid item key={i}>
									<Button color="secondary" onClick={a.onClick}>{a.label}</Button>
								</Grid>
							))
							: null
						}
					</Grid>
				</Hidden>
			</React.Fragment>
		);
	}
}

Banner.propTypes = {
	visible: PropTypes.bool,
	elevated: PropTypes.bool,
	icon: PropTypes.node,
	message: PropTypes.node,
	actions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		onClick: PropTypes.func
	}))
};

export default withStyles(styles)(Banner);
