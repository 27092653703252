export const UIContext = React.createContext({
	showError: () => {}
});

export default function withUI(WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<UIContext.Consumer>
					{(ui) => (
						<WrappedComponent ui={ui} {...this.props} />
					)}
				</UIContext.Consumer>
			);
		}
	}
}
