import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Switch,
	withStyles
} from '@material-ui/core';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { permissionsArray } from 'utils/constants';

const styles = (theme) => ({
	permissionTable: {
		'& td:first-child': {
			width: '90%'
		},
		'& thead th:nth-child(n+1)': {
			textAlign: 'center'
		}
	}
});

class Permissions extends React.PureComponent {
	render() {
		const { classes, permissions } = this.props;

		return (
			<Table className={classes.permissionTable}>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>{i18n.t('admins.read')}</TableCell>
						<TableCell>{i18n.t('admins.write')}</TableCell>
						<TableCell>{i18n.t('admins.delete')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						permissionsArray.map((p) => {
							const permission = _find(permissions, { key: p }) || { key: p, level: -1 };

							return (
								<TableRow key={p}>
									<TableCell>{i18n.t('admins.permissions.' + p)}</TableCell>
									<TableCell>
										<Switch
											color="primary"
											checked={permission && permission.level >= 0}
											onChange={(e) => this.togglePermission(p, 0, e.target.checked)} />
									</TableCell>
									<TableCell>
										<Switch
											color="primary"
											checked={permission && permission.level >= 1}
											onChange={(e) => this.togglePermission(p, 1, e.target.checked)} />
									</TableCell>
									<TableCell>
										<Switch
											color="primary"
											checked={permission && permission.level >= 2}
											onChange={(e) => this.togglePermission(p, 2, e.target.checked)} />
									</TableCell>
								</TableRow>
							);
						})
					}
				</TableBody>
			</Table>
		);
	}

	togglePermission(permission, value, checked) {
		const { permissions, onChange } = this.props;

		const pIndex = _findIndex(permissions, { key: permission });

		if (pIndex >= 0) {
			onChange([
				...permissions.slice(0, pIndex),
				{
					key: permission,
					level: (checked) ? value : value - 1
				},
				...permissions.slice(pIndex + 1)
			])
		} else {
			onChange([
				...permissions,
				{
					key: permission,
					level: (checked) ? value : value - 1
				}
			]);
		}
	}
}

export default withStyles(styles)(Permissions);
