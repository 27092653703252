import PropTypes from 'prop-types';
import { Button as MUIButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

class Button extends React.PureComponent {
	render() {
		const { children, icon, loading, disabled, color, classes, ...passDownProps } = this.props;
		const Icon = icon;

		return (
			<MUIButton color={color || 'secondary'} {...passDownProps} disabled={loading || disabled}>
				{
					(loading) ?
					<CircularProgress size={24} />
					:
					children
				}

				{
					Icon ?
					<Icon style={{ marginLeft: 8 }} />
					: null
				}
			</MUIButton>
		)
	}
}

Button.propTypes = {
	variant: PropTypes.string,
	color: PropTypes.string,
	icon: PropTypes.func,
	loading: PropTypes.bool,
	disabled: PropTypes.bool
};

Button.defaultProps = {
	variant: 'contained',
	color: 'primary'
}

export default Button;