export default function(error) {
	const errorMessages = [];

	if (error && error.graphQLErrors && error.graphQLErrors.length) {
		const e = error.graphQLErrors[0];

		if (e.extensions && e.extensions.exception && e.extensions.exception.errors) {
			const elist = e.extensions.exception.errors;

			for (let err in elist) {
				if (elist[err].message.indexOf('ERR__') === 0) {
					errorMessages.push(i18n.t(elist[err].message));
				}
			}
		}

		if (errorMessages.length) {
			return errorMessages;
		}

		return i18n.t(error.graphQLErrors[0].message);
	}

	return i18n.t('general.genericError');
};