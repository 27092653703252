import { withApollo, compose } from 'react-apollo';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from '../../../router';
import withUser from 'utils/withUser';
import moment from 'moment';

// Admin Scenes
import Home from 'scenes/admin/Home/Home';
import Admins from 'scenes/admin/Admins/Admins';
import Admin from 'scenes/admin/Admins/Admin';
import Techs from 'scenes/admin/Techs/Techs';
import Tech from 'scenes/admin/Techs/Tech';
import Clients from 'scenes/admin/Clients/Clients';
import Client from 'scenes/admin/Clients/Client';
import Projects from 'scenes/admin/Projects/Projects';
import Project from 'scenes/admin/Projects/Project';
import EventLogs from 'scenes/admin/EventLogs/EventLogs';
import EventLog from 'scenes/admin/EventLogs/EventLog';
import PaymentHistory from 'scenes/admin/PaymentHistory/PaymentHistory';
import Invoices from 'scenes/admin/Invoices/Invoices';
import Invoice from 'scenes/admin/Invoices/Invoice';
import PayInvoice from 'scenes/admin/Invoices/PayInvoice';
import Tickets from 'scenes/admin/Tickets/Tickets';
import Ticket from 'scenes/admin/Tickets/Ticket';
import ServiceCalls from 'scenes/admin/ServiceCalls/ServiceCalls';
import ServiceCall from 'scenes/admin/ServiceCalls/ServiceCall';
import Webhooks from 'scenes/admin/Webhooks/Webhooks';

// Tech Scenes
import TechTickets from 'scenes/tech/Tickets/Tickets';
import TechTicket from 'scenes/tech/Tickets/Ticket';
import TechServiceCalls from 'scenes/tech/ServiceCalls/ServiceCalls';
import TechServiceCall from 'scenes/tech/ServiceCalls/ServiceCall';
import TechProjects from 'scenes/tech/Projects/Projects';
import TechProject from 'scenes/tech/Projects/Project';

// Client Scenes
import ClientProjects from 'scenes/client/Projects/Projects';
import ClientTickets from 'scenes/client/Tickets/Tickets';

import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Hidden,
	withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import ExitToApp from '@material-ui/icons/ExitToApp'
import AccountCircle from '@material-ui/icons/AccountCircle';
import Session from 'utils/session';
import SideMenu from './SideMenu';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflow: 'hidden'
	},
	body: {
		display: 'flex',
		flexGrow: 1,
		overflow: 'hidden'
	},
	content: {
		display: 'flex',
		flexGrow: 1,
		overflow: 'hidden',
		flexDirection: 'column'
	},
	header: {
		flexShrink: 1
	},
	title: {
		flexGrow: 1,
		[theme.breakpoints.up('md')]: {
			marginLeft: 240
		}
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	}
});

class Main extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: '',
			open: false,
			menuOpen: false
		}

		this.openUserMenu = this.openUserMenu.bind(this);
		this.closeUserMenu = this.closeUserMenu.bind(this);
		this.toggleLanguage = this.toggleLanguage.bind(this);
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		this.setTitle();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setTitle();
		}
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.header}>
					<AppBar position="static" color="secondary">
						<Toolbar>
							<Hidden mdUp implementation="css">
								<IconButton className={classes.menuButton} color="inherit" onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}>
									<MenuIcon />
								</IconButton>
							</Hidden>

							<Typography variant="h6" color="inherit" className={classes.title}>{this.state.title}</Typography>

							<div>
								<IconButton color="inherit" onClick={this.openUserMenu}>
									<AccountCircle />
								</IconButton>

								<Menu
									anchorEl={this.state.anchorEl}
									open={this.state.open}
									onClose={this.closeUserMenu}>
									
									<MenuItem onClick={this.toggleLanguage}>
										{i18n.default.language.substr(0, 2) === 'fr' ? 'English' : 'Français'}
									</MenuItem>

									<MenuItem onClick={this.logout}>
										<ListItemIcon>
											<ExitToApp />
										</ListItemIcon>
										{i18n.t('login.logout')}
									</MenuItem>
								</Menu>
							</div>
						</Toolbar>
					</AppBar>
				</div>

				<div className={classes.body}>
					<SideMenu container={this.body} open={this.state.menuOpen} closeDrawer={() => this.setState({ menuOpen: false })} />
					
					<div className={classes.content}>
						{
							currentUser.type === 'Admin' ?
							this.renderAdminRoutes() :
							currentUser.type === 'Tech' ?
							this.renderTechRoutes() :
							currentUser.type === 'Client' ?
							this.renderClientRoutes() :
							null
						}
					</div>
				</div>
			</div>
		);
	}

	renderAdminRoutes() {
		return (
			<Switch>
				<ProtectedRoute exact path="/" component={Home} />
				<ProtectedRoute path="/admins" exact allowedUsers="admin" requiredPermissions={[{ key: 'admins', level: 0 }]} component={Admins} />
				<ProtectedRoute path="/admins/create" allowedUsers="admin" requiredPermissions={[{ key: 'admins', level: 1 }]} component={Admin} />
				<ProtectedRoute path="/admins/:id" allowedUsers="admin" requiredPermissions={[{ key: 'admins', level: 0 }]} component={Admin} />
				<ProtectedRoute path="/techs" exact allowedUsers="admin" requiredPermissions={[{ key: 'techs', level: 0 }]} component={Techs} />
				<ProtectedRoute path="/techs/create" allowedUsers="admin" requiredPermissions={[{ key: 'techs', level: 1 }]} component={Tech} />
				<ProtectedRoute path="/techs/:id" allowedUsers="admin" requiredPermissions={[{ key: 'techs', level: 0 }]} component={Tech} />
				<ProtectedRoute path="/clients" exact allowedUsers="admin" requiredPermissions={[{ key: 'clients', level: 0 }]} component={Clients} />
				<ProtectedRoute path="/clients/create" allowedUsers="admin" requiredPermissions={[{ key: 'clients', level: 1 }]} component={Client} />
				<ProtectedRoute path="/clients/:id" allowedUsers="admin" requiredPermissions={[{ key: 'clients', level: 0 }]} component={Client} />
				<ProtectedRoute path="/projects" exact allowedUsers="admin" requiredPermissions={[{ key: 'projects', level: 0 }]} component={Projects} />
				<ProtectedRoute path="/projects/create" allowedUsers="admin" requiredPermissions={[{ key: 'projects', level: 1 }]} component={Project} />
				<ProtectedRoute path="/projects/:id" allowedUsers="admin" requiredPermissions={[{ key: 'projects', level: 0 }]} component={Project} />
				<ProtectedRoute path="/eventLogs" exact allowedUsers="admin" requiredPermissions={[{ key: 'eventLogs', level: 0 }]} component={EventLogs} />
				<ProtectedRoute path="/eventLogs/:id" allowedUsers="admin" requiredPermissions={[{ key: 'eventLogs', level: 0 }]} component={EventLog} />
				<ProtectedRoute path="/payments" allowedUsers="admin" requiredPermissions={[{ key: 'payments', level: 0 }]} component={PaymentHistory} />
				<ProtectedRoute path="/invoices" exact allowedUsers="admin" requiredPermissions={[{ key: 'invoices', level: 0 }]} component={Invoices} />
				<ProtectedRoute path="/invoices/create" allowedUsers="admin" requiredPermissions={[{ key: 'invoices', level: 1 }]} component={Invoice} />
				<ProtectedRoute path="/invoices/:id/pay" allowedUsers="admin" requiredPermissions={[{ key: 'invoices', level: 1 }]} component={PayInvoice} />
				<ProtectedRoute path="/invoices/:id" allowedUsers="admin" requiredPermissions={[{ key: 'invoices', level: 0 }]} component={Invoice} />
				<ProtectedRoute path="/tickets" exact allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 0 }]} component={Tickets} />
				<ProtectedRoute path="/tickets/create" allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 1 }]} component={Ticket} />
				<ProtectedRoute path="/tickets/:id" allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 1 }]} component={Ticket} />	
				<ProtectedRoute path="/serviceCalls" exact allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 0 }]} component={ServiceCalls} />
				<ProtectedRoute path="/serviceCalls/create" allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 1 }]} component={ServiceCall} />
				<ProtectedRoute path="/serviceCalls/:id" allowedUsers="admin" requiredPermissions={[{ key: 'tickets', level: 1 }]} component={ServiceCall} />	
				<ProtectedRoute path="/webhooks" allowedUsers="admin" requiredPermissions={[{ key: 'webhooks', level: 0 }]} component={Webhooks} />	
			</Switch>
		)
	}

	renderTechRoutes() {
		return (
			<Switch>
				<ProtectedRoute exact path="/" component={TechServiceCalls} />} />
				<ProtectedRoute path="/tickets" exact allowedUsers="tech" component={TechTickets} />
				<ProtectedRoute path="/tickets/:id" exact allowedUsers="tech" component={TechTicket} />
				<ProtectedRoute path="/serviceCalls" exact allowedUsers="tech" component={TechServiceCalls} />
				<ProtectedRoute path="/serviceCalls/:id" exact allowedUsers="tech" component={TechServiceCall} />
				<ProtectedRoute path="/projects" exact allowedUsers="tech" component={TechProjects} />
				<ProtectedRoute path="/projects/:id" exact allowedUsers="tech" component={TechProject} />
			</Switch>
		)
	}

	renderClientRoutes() {
		return (
			<Switch>
				<ProtectedRoute exact path="/" component={ClientProjects} />} />
				<ProtectedRoute path="/projects" exact allowedUsers="client" component={ClientProjects} />
				<ProtectedRoute path="/tickets" exact allowedUsers="client" component={ClientTickets} />
			</Switch>
		);
	}

	setTitle() {
		const { location } = this.props;

		this.setState({
			title: (location.state && location.state.title) ? location.state.title : 'Homesync'
		});
	}

	openUserMenu(e) {
		this.setState({ open: true, anchorEl: e.currentTarget });
	}

	closeUserMenu() {
		this.setState({ open: false, anchorEl: null });
	}

	toggleLanguage() {
		if (i18n.default.language.substr(0, 2) === 'en') {
			i18n.changeLanguage('fr');
			moment.locale('fr');
		} else {
			i18n.changeLanguage('en');
			moment.locale('en');
		}
	}

	logout() {
		Session.clearToken();
		this.props.client.clearStore();
		this.props.history.push('/login');
	}
};

export default compose(
	withStyles(styles),
	withApollo,
	withRouter,
	withUser
)(Main);