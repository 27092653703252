import withStyles from 'utils/withStyles';

const styles = (theme) => {
	return {
		page: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflow: 'hidden'
		}
	};
};

class Page extends Component {
	render() {
		const { classes } = this.props;

		return <div className={classes.page}>{this.props.children}</div>;
	}
}

export default withStyles(styles)(Page);