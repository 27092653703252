import { Section, StateChip, Button } from "components";
import { Typography, Divider } from "@material-ui/core";
import PriorityIcon from "@material-ui/icons/PriorityHigh";
import ChatIcon from "@material-ui/icons/Chat";
import StoreIcon from "@material-ui/icons/Store";
import PeopleIcon from "@material-ui/icons/People";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import withStyles from "utils/withStyles";
import momenttz from "moment-timezone";
import { withRouter, Link } from "react-router-dom";
import { LENGTH_MAX_STRING } from "utils/constants";

const styles = {};

class TicketCard extends Component {
  constructor(props) {
    super(props);
    this.formatedDescriptionCategories = this.formatedDescriptionCategories.bind(this);
  }

  render() {
    const { ticket, classes, history } = this.props;

    return (
      <Section title={`${i18n.t("tickets.ticket")} #${ticket.workOrder}`}>
        <div style={{ marginBottom: 16 }}>
          <StateChip state={ticket.status} />
        </div>

        <div className={classes.flexRow}>
          <div>
            <PriorityIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.priority")}</Typography>
            <Typography>{ticket.priority}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <WatchLaterIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.createdAt")}</Typography>
            <Typography>{`${momenttz.tz(ticket.createdAt, "America/Toronto")}`}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <ChatIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.categories")}</Typography>
            <Typography>{this.formatedDescriptionCategories(ticket)}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <StoreIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("tickets.project")}</Typography>
            <Link
              to={{
                pathname: `/projects/${ticket.project.id}`,
                state: { title: ticket.project.name }
              }}
            >
              <Typography>{ticket.project.name}</Typography>
            </Link>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.flexRow}>
          <div>
            <PeopleIcon className={classes.icon} fontSize="large" />
          </div>
          <div className={classes.flexFill}>
            <Typography variant="subtitle2">{i18n.t("general.client")}</Typography>
            <Typography>{`${ticket.client.firstName} ${ticket.client.lastName}`}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ textAlign: "center" }}>
          <Button
            variant="text"
            onClick={() => {
              history.push(`/tickets/${ticket.id}`, {
                title: `${i18n.t("tickets.ticket")} #${ticket.workOrder}`
              });
            }}
          >
            {i18n.t("tickets.viewFullTicket")}
          </Button>
        </div>
      </Section>
    );
  }

  formatedDescriptionCategories(ticket) {
    if (ticket.descriptions && ticket.descriptions.length > 0) {
      let text = "";

      ticket.descriptions.map((desc, i) => {
        text = text + `${i18n.t(`tickets.descriptionCategories.${desc.category}`)}, `;
      });

      text = text.substring(0, text.length - 2);
      if (text.length > LENGTH_MAX_STRING) {
        return text.substring(0, LENGTH_MAX_STRING);
      } else return text;
    } else return "";
  }
}
export default withRouter(withStyles(styles)(TicketCard));
