import gql from 'graphql-tag';
import { client } from 'graph/types';

export const CREATE_CLIENT = gql`
mutation CreateClient(
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String,
	$language: String!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!
	$projects: [String!]
	$newProject: Input_NewProject) {
	createClient(
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		phones: $phones,
		addresses: $addresses,
		projects: $projects,
		newProject: $newProject
	) {
		${client}
	}
}
`;

export const EDIT_CLIENT = gql`
mutation EditClient(
	$id: ID!,
	$email: String!,
	$firstName: String!,
	$lastName: String!,
	$company: String,
	$language: String!,
	$isDeactivated: Boolean!,
	$phones: [Input_Phone!]!,
	$addresses: [Input_Address!]!,
	$stripeCustomerId: String
	$projects: [String!]!) {
	editClient(
		id: $id,
		email: $email,
		firstName: $firstName,
		lastName: $lastName,
		company: $company,
		language: $language,
		isDeactivated: $isDeactivated,
		phones: $phones,
		addresses: $addresses,
		stripeCustomerId: $stripeCustomerId,
		projects: $projects
	) {
		${client}
	}
}
`;

export const CREATE_PAYMENT_METHOD = gql`
mutation CreatePaymentMethod(
	$id: ID!,
	$stripeToken: String!) {
		createPaymentMethod(
		id: $id,
		stripeToken: $stripeToken
	)
}
`;

export const SET_DEFAULT_SOURCE = gql`
mutation SetDefaultSource(
	$id: ID!,
	$cardId: String!) {
		setDefaultSource(
		id: $id,
		cardId: $cardId
	)
}
`;