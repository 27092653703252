import {
	Typography,
	IconButton,
	FormGroup,
	Checkbox,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import DateMomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { compose, withApollo } from 'react-apollo';
import { Prompt } from 'react-router-dom';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import {
	TextField,
	Section,
	Button,
	Page,
	PageHeader,
	PageBody,
	Banner,
	SearchBar,
	Snackbar,
	Confirm,
	InvoiceEmail,
	StateChip,
	Grid
} from 'components';
import withUser from 'utils/withUser';
import { withQueries } from 'utils/withQuery';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import withStyles from 'utils/withStyles';
import { SEARCH_PROJECTS, INVOICE, GET_INVOICE_NUMBER, CLIENT, PROJECT, TICKET, TICKET_SERVICE_CALLS } from 'graph/queries';
import { CREATE_INVOICE, EDIT_INVOICE, SEND_INVOICE, DISCARD_INVOICE, VOID_INVOICE, MARK_INVOICE_PAID } from 'graph/mutations';

const styles = (theme) => ({
	invoiceItem: {
		marginBottom: theme.spacing.unit * 2,
		display: 'flex',
		flexDirection: 'row',
		'& > *': {
			marginRight: 4
		}
	},

	actionsButtonRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginBottom: theme.spacing.unit,
		'& button': {
			marginRight: theme.spacing.unit
		}
	},

	selectMenu: {
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit * 2
	},

	totalTable: {
		display: 'inline-table',
		marginRight: theme.spacing.unit * 2,
		fontSize: 16,
		borderCollapse: 'collapse',
		'& td': {
			paddingLeft: theme.spacing.unit * 2
		}
	},

	money: {
		fontFamily: 'monospace'
	}
});

const invoiceItemDescriptionArray = [
	'Travail',
	'Transport',
	'Équipement Control4',
	'Équipement Audio',
	'Équipement Vidéo',
	'Équipement',
	'Garantie de Service',
	'Crédit'
];

class _Invoice extends Component {
	constructor(props) {
		super(props);

		const baseState = {
			tabIndex: 0,
			errors: [],
			dirty: false,
			sendEmailConfirmOpen: false,
			voidConfirmOpen: false,
			discardConfirmOpen: false,
			markPaidConfirmOpen: false,
			projectSearchString: '',
			snackbar: false
		};

		let { invoice, invoiceClient, invoiceProject, getInvoiceNumber, invoiceTicket, invoiceServiceCalls } = props.queryResult;

		invoice = invoice ? invoice.invoice : null;

		invoiceClient = invoiceClient ? invoiceClient.client : null;

		invoiceProject = invoiceProject ? invoiceProject.project : null;

		invoiceTicket = invoiceTicket ? invoiceTicket.ticket : null;

		invoiceServiceCalls = invoiceServiceCalls ? invoiceServiceCalls.serviceCalls.serviceCalls : null;

		const invoiceNumber = getInvoiceNumber ? getInvoiceNumber.getInvoiceNumber : '';

		this.invoice = invoice;

		if (invoice) {
			this.state = {
				...baseState,
				invoiceId: invoice.id,
				selectedProject: invoice.project,
				representative: invoice.representative,
				terms: invoice.terms,
				deliveredAt: invoice.deliveredAt,
				description: invoice.description,
				number: invoice.number,
				items: invoice.items,
				clients: invoice.clients,
				sendReminderEmail: invoice.sendReminderEmail,
				reminderDays: invoice.reminderDays,
				invoiceReminderEmails: invoice.invoiceReminderEmails || [],
				isPaid: invoice.state === 'paid',
				paymentMethod: invoice.paymentMethod || '',
				paymentMethodNotes: invoice.paymentMethodNotes || '',
				state: invoice.state
			};
		} else {
			this.state = {
				...baseState,
				invoiceId: '',
				selectedProject: null,
				representative: props.currentUser.firstName + ' ' + props.currentUser.lastName,
				terms: 'Sur réception',
				deliveredAt: new Date(),
				description: '',
				number: invoiceNumber,
				items: [{
					description: invoiceItemDescriptionArray[0],
					quantity: 1,
					rate: 90,
					notes: ''
				}, {
					description: invoiceItemDescriptionArray[1],
					quantity: 1,
					rate: 50,
					notes: ''
				}],
				clients: [],
				sendReminderEmail: true,
				reminderDays: 14,
				invoiceReminderEmails: [],
				isPaid: false,
				paymentMethod: '',
				paymentMethodNotes: '',
				state: ''
			};

			if (invoiceClient) {
				this.state.clients = [invoiceClient];

				if (invoiceClient.projects && invoiceClient.projects.length) {
					this.state.selectedProject = invoiceClient.projects[0];
				}
			} else if (invoiceProject) {
				this.state.selectedProject = invoiceProject;
				this.state.clients = invoiceProject.clients;
			} else if (invoiceTicket) {
				this.state.selectedProject = invoiceTicket.project;
				this.state.clients = [invoiceTicket.client];
				this.state.ticketRefId = invoiceTicket.id;
				this.state.description = `Tiquet no ${invoiceTicket.workOrder}`;
				
				if (invoiceServiceCalls && invoiceServiceCalls.length) {
					const transportItems = [];
					const labourItems = [];
					const equipmentItems = [];

					invoiceServiceCalls.forEach((serviceCall, i) => {
						if (serviceCall.totalHours) {
							labourItems.push({
								description: 'Travail',
								rate: 90,
								quantity: serviceCall.totalHours,
								notes: moment(serviceCall.date).format('YYYY-MM-DD')
							});
						}

						if (!_find(transportItems, { date: moment(serviceCall.date).format('YYYY-MM-DD') })) {
							transportItems.push({
								date: moment(serviceCall.date).format('YYYY-MM-DD'),
								item: {
									description: 'Transport',
									rate: 50,
									quantity: 1,
									notes: moment(serviceCall.date).format('YYYY-MM-DD')
								}
							});
						}
						
						if (serviceCall.equipmentUsed && serviceCall.equipmentUsed.length) {
							serviceCall.equipmentUsed.forEach((equipment, i) => {
								equipmentItems.push({
									description: 'Équipement',
									rate: equipment.price,
									quantity: equipment.quantity || 1,
									notes: equipment.name
								});
							});
						}
					});

					const sortedArray = _orderBy([
						...labourItems,
						...transportItems.map((i) => i.item),
					], 'notes');
					
					this.state.items = [
						...sortedArray,
						...equipmentItems
					];
				}
			}
		}

		this.selectProject = this.selectProject.bind(this);
		this.setInvoiceItemValue = this.setInvoiceItemValue.bind(this);
		this.validateInvoiceItemRate = this.validateInvoiceItemRate.bind(this);
		this.addInvoiceItem = this.addInvoiceItem.bind(this);
		this.removeInvoiceItem = this.removeInvoiceItem.bind(this);
		this.selectClient = this.selectClient.bind(this);
		this.saveInvoice = this.saveInvoice.bind(this);
		this.performInvoiceAction = this.performInvoiceAction.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
	}

	render() {
		const { classes } = this.props;

		const editingDisabled = this.state.invoiceId ? (this.state.state === 'paid' || this.state.state === 'void') : false;

		return (
			<Page>
				<PageHeader>
					<Button
						color="primary"
						variant="contained"
						disabled={this.state.state !== 'sent' || this.state.loading}
						icon={PaymentIcon}
						onClick={() => {
							this.props.history.push(`/invoices/${this.invoice.id}/pay`, {
								title: i18n.t('invoices.invoice') + ' ' + this.invoice.number
							});
						}}>
						{i18n.t('payments.payInvoice')}
					</Button>
					
					{
						(editingDisabled) ?
						null
						:
						<Button
							color="primary"
							variant="contained"
							disabled={!!this.state.invoiceId && !this.state.dirty}
							icon={SaveIcon}
							onClick={this.saveInvoice}
							loading={this.state.loading}>
							{i18n.t('general.save')}
						</Button>
					}
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<PageBody
					tabs={[
						i18n.t('invoices.invoiceDetails'),
						i18n.t('invoices.emailSettings')
					]}
					tabIndex={this.state.tabIndex}
					onChangeTab={(e, value) => this.setState({ tabIndex: value })}>
						{this.state.tabIndex === 0 && this.renderInvoiceDetails()}
						{this.state.tabIndex === 1 && this.renderEmailSettings()}
				</PageBody>

				<Confirm
					title={i18n.t('invoices.actions.send')}
					body={(this.state.state === 'sent') ? i18n.t('invoices.confirm.sentInvoiceSendEmailBody') : i18n.t('invoices.confirm.draftInvoiceSendEmailBody')}
					open={this.state.sendEmailConfirmOpen}
					confirmButtonText={i18n.t('invoices.sendEmail')}
					onConfirm={() => this.performInvoiceAction('send')}
					onCancel={() => this.setState({ sendEmailConfirmOpen: false })} />

				<Confirm
					title={i18n.t('invoices.actions.void')}
					body={i18n.t('invoices.confirm.voidBody')}
					open={this.state.voidConfirmOpen}
					confirmButtonText={i18n.t('invoices.actions.void')}
					onConfirm={() => this.performInvoiceAction('void')}
					onCancel={() => this.setState({ voidConfirmOpen: false })} />

				<Confirm
					title={i18n.t('invoices.actions.send')}
					body={i18n.t('invoices.confirm.discardBody')}
					open={this.state.discardConfirmOpen}
					confirmButtonText={i18n.t('invoices.actions.discard')}
					onConfirm={() => this.performInvoiceAction('discard')}
					onCancel={() => this.setState({ discardConfirmOpen: false })} />

				<Confirm
					title={i18n.t('invoices.actions.markPaid')}
					body={
						<div>
							<Select
								label={i18n.t('invoices.paymentMethod')}
								className={classes.textField}
								fullWidth
								value={this.state.paymentMethod}
								onClose={(e) => {e.preventDefault();e.stopPropagation()}}
								onChange={(e) => this.setState({ paymentMethod: e.target.value })}>
								<MenuItem value="wireTransfer">{i18n.t('invoices.wireTransfer')}</MenuItem>
								<MenuItem value="cheque">{i18n.t('invoices.cheque')}</MenuItem>
								<MenuItem value="cash">{i18n.t('invoices.cash')}</MenuItem>
								<MenuItem value="creditCard">{i18n.t('invoices.creditCard')}</MenuItem>
								<MenuItem value="other">{i18n.t('invoices.other')}</MenuItem>
							</Select>

							<TextField
								fullWidth
								required
								multiline
								autoComplete="off"
								label={i18n.t('invoices.paymentMethodNotes')}
								value={this.state.paymentMethodNotes}
								className={classes.textField}
								onChange={(e) => this.setState({ paymentMethodNotes: e.target.value })} />
						</div>
					}
					open={this.state.markPaidConfirmOpen}
					confirmButtonText={i18n.t('invoices.actions.markPaid')}
					onConfirm={() => this.performInvoiceAction('markPaid')}
					onCancel={() => this.setState({ markPaidConfirmOpen: false })} />

				<Snackbar
					message={i18n.t('admins.editAdminComplete')}
					open={this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />
			</Page>
		);
	}

	renderInvoiceDetails() {
		const { classes } = this.props;
		const { invoiceId, selectedProject, items } = this.state;

		const editingDisabled = this.state.invoiceId ? (this.state.state === 'paid' || this.state.state === 'void') : false;

		return (
			<Grid container spacing={16}>
				<Grid item xs={12}>
					<Section title={
						<React.Fragment>
							{i18n.t('invoices.actionsHeader')}
							<StateChip
								state={this.state.state}
								style={{ verticalAlign: 'top', marginLeft: 10, marginBottom: 8 }} />
						</React.Fragment>
					}>
						{
							(this.state.invoiceId) ?
							<div>
								<div className={classes.actionsButtonRow}>
									<Button
										color="primary"
										variant="contained"
										disabled={this.state.loading || (this.state.state !== 'draft' && this.state.state !== 'sent')}
										icon={SendIcon}
										onClick={(e) => this.setState({ sendEmailConfirmOpen: true })}>
										{i18n.t('invoices.actions.send')}
									</Button>

									{
										(this.state.state !== 'draft' && this.state.state !== 'sent') ?
										<Typography variant="caption">
											{i18n.t('invoices.actions.send_desc')}
										</Typography>
										: null
									}
								</div>

								<div className={classes.actionsButtonRow}>
									<Button
										color="primary"
										variant="contained"
										disabled={this.state.state !== 'sent'}
										icon={CheckIcon}
										loading={this.state.loading}
										onClick={(e) => this.setState({ markPaidConfirmOpen: true })}>
										{i18n.t('invoices.actions.markPaid')}
									</Button>

									{
										(this.state.state !== 'draft' && this.state.state !== 'sent') ?
										<Typography variant="caption">
											{i18n.t('invoices.actions.markPaid_desc')}
										</Typography>
										: null
									}
								</div>

								<div className={classes.actionsButtonRow}>
									<Button
										color="primary"
										variant="contained"
										disabled={this.state.state !== 'draft'}
										icon={DeleteIcon}
										loading={this.state.loading}
										onClick={(e) => this.setState({ discardConfirmOpen: true })}>
										{i18n.t('invoices.actions.discard')}
									</Button>

									{
										(this.state.state !== 'draft') ?
										<Typography variant="caption">
											{i18n.t('invoices.actions.discard_desc')}
										</Typography>
										: null
									}
								</div>

								<div className={classes.actionsButtonRow}>
									<Button
										color="primary"
										variant="contained"
										disabled={this.state.state !== 'sent'}
										icon={BlockIcon}
										loading={this.state.loading}
										onClick={(e) => this.setState({ voidConfirmOpen: true })}>
										{i18n.t('invoices.actions.void')}
									</Button>

									{
										(this.state.state !== 'sent') ?
										<Typography variant="caption">
											{i18n.t('invoices.actions.void_desc')}
										</Typography>
										: null
									}
								</div>
							</div>
							:
							<Typography variant="caption">
								{i18n.t('invoices.actions.newInvoice')}
							</Typography>
						}
					</Section>
				</Grid>
				
				<Grid item xs={12} md={6}>
					<Section title={i18n.t('invoices.invoice')}>
					<TextField
							fullWidth
							required
							error={!!this.state.errors.length && !this.state.number}
							disabled={editingDisabled}
							autoComplete="off"
							label={i18n.t('invoices.invoiceNumber')}
							value={this.state.number}
							className={classes.textField}
							onChange={(e) => this.setState({ number: e.target.value, dirty: true })} />
						
						<TextField
							fullWidth
							required
							error={!!this.state.errors.length && !this.state.representative}
							disabled={editingDisabled}
							autoComplete="off"
							label={i18n.t('invoices.representative')}
							value={this.state.representative}
							className={classes.textField}
							onChange={(e) => this.setState({ representative: e.target.value, dirty: true })} />

						<TextField
							fullWidth
							required
							error={!!this.state.errors.length && !this.state.terms}
							disabled={editingDisabled}
							autoComplete="off"
							label={i18n.t('invoices.terms')}
							value={this.state.terms}
							className={classes.textField}
							onChange={(e) => this.setState({ terms: e.target.value, dirty: true })} />

						<MuiPickersUtilsProvider utils={DateMomentUtils}>
							<DatePicker
								format="YYYY-MM-DD"
								fullWidth
								required
								disabled={editingDisabled}
								className={classes.textField}
								label={i18n.t('invoices.dateOfDelivery')}
								value={this.state.deliveredAt}
								onChange={(e) => this.setState({ deliveredAt: e.toDate(), dirty: true })} />
						</MuiPickersUtilsProvider>

						<TextField
							fullWidth
							required
							error={!!this.state.errors.length && !this.state.description}
							disabled={editingDisabled}
							autoComplete="off"
							label={i18n.t('general.description')}
							value={this.state.number + ' - ' + this.state.description}
							className={classes.textField}
							onChange={(e) => 
								this.setState({
									description: e.target.value.replace(this.state.number + ' - ', '').replace(this.state.number + ' -', ''),
									dirty: true
								})
							} />

						{
							(this.state.state === 'paid') ?
							<div>
								<TextField
									fullWidth
									label={i18n.t('invoices.paymentMethod')}
									disabled={editingDisabled}
									value={i18n.t(`invoices.${this.state.paymentMethod}`)}
									className={classes.textField} />

								<TextField
									fullWidth
									label={i18n.t('invoices.paymentMethodNotes')}
									multiline
									disabled={editingDisabled}
									value={this.state.paymentMethodNotes}
									className={classes.textField} />
							</div>
							: null
						}
					</Section>
				</Grid>

				<Grid item xs={12} md={6}>
					<Section title={i18n.t('clients.project')}>
					{
							(!invoiceId) ?
							<SearchBar
								value={this.state.projectSearchString}
								required={true}
								label={i18n.t('general.search')}
								query={SEARCH_PROJECTS}
								onChange={(value) => this.setState({ projectSearchString: value })}
								getQueryResultItemLabel={(project) => project.name}
								onSelect={(project) => this.selectProject(project)} />
							: null
						}

						{
							(selectedProject) ?
							<div>
								<Typography variant="subtitle2">{i18n.t('projects.projectName')}</Typography>
								<Typography>{selectedProject.name}</Typography>
								<br />

								<Typography variant="subtitle2">{i18n.t('general.address')}</Typography>
								<Typography>{selectedProject.address.line1}{selectedProject.address.line2 ? `, ${selectedProject.address.line2}` : ''}</Typography>
								<Typography>{selectedProject.address.city}, {selectedProject.address.province}</Typography>
								<Typography>{selectedProject.address.postalCode}</Typography>
								<br />

								<Typography variant="subtitle2">{i18n.t('general.client', { count: 2 })}</Typography>
								<Typography variant="caption">{i18n.t('invoices.checkWhichClients')}</Typography>
								<FormGroup>
									{
										selectedProject.clients.map((c, i) => (
											<FormControlLabel
												key={i}
												label={`${c.firstName} ${c.lastName} (${c.email})`}
												control={<Checkbox
																color="primary"
																disabled={editingDisabled}
																checked={!!_find(this.state.clients, { id: c.id })}
																onChange={(e) => this.selectClient(c.id, e.target.checked)} />} />
										))
									}
								</FormGroup>
							</div>
							: null
						}
					</Section>
				</Grid>

				<Grid item xs={12}>
					<Section title={i18n.t('invoices.items')}>
					{
							items.map((item, i) => (
								<div key={i} className={classes.invoiceItem}>
									{
										item.customDescription || (item.description.length && invoiceItemDescriptionArray.indexOf(item.description) < 0) ?
										<TextField
											required
											autoFocus={item.customDescription}
											error={!!this.state.errors.length && !item.description}
											disabled={editingDisabled}
											style={{ flex: 3 }}
											autoComplete="off"
											label={i18n.t('general.description')}
											value={item.description}
											onChange={(e) => this.setInvoiceItemValue(i, 'description', e.target.value)} />
										:
										<FormControl style={{ flex: 3 }}>
											<InputLabel>{i18n.t('general.description')}</InputLabel>

											<Select
												required
												error={!!this.state.errors.length && !item.description}
												disabled={editingDisabled}
												value={item.description}
												onChange={(e) => this.setInvoiceItemValue(i, 'description', e.target.value)}>
												{
													invoiceItemDescriptionArray.map((desc, i) => (
														<MenuItem value={desc} key={i}>{desc}</MenuItem>
													))
												}
												<MenuItem value="CUSTOM_DESCRIPTION">Other</MenuItem>
											</Select>
										</FormControl>
									}

									<TextField
										required
										style={{ flex: 1 }}
										disabled={editingDisabled}
										type="number"
										autoComplete="off"
										label={i18n.t('invoices.quantity')}
										value={item.quantity}
										onChange={(e) => this.setInvoiceItemValue(i, 'quantity', e.target.value)}
										onBlur={(e) => this.validateInvoiceItemQuantity(i, e.target.value)} />

									<TextField
										required
										style={{ flex: 1 }}
										disabled={editingDisabled}
										type="number"
										autoComplete="off"
										label={i18n.t('invoices.rate')}
										value={item.rate}
										onChange={(e) => this.setInvoiceItemValue(i, 'rate', e.target.value)}
										onBlur={(e) => this.validateInvoiceItemRate(i, e.target.value)} />

									<TextField
										style={{ flex: 1 }}
										disabled={editingDisabled}
										type="number"
										autoComplete="off"
										label={i18n.t('invoices.total')}
										onChange={e => {}}
										value={(item.rate * item.quantity)} />

									<TextField
										style={{ flex: 2 }}
										disabled={editingDisabled}
										autoComplete="off"
										label={i18n.t('invoices.notes')}
										value={item.notes || ''}
										onChange={(e) => this.setInvoiceItemValue(i, 'notes', e.target.value)} />

									{
										(editingDisabled) ?
										null
										:
										<IconButton
											onClick={() => this.removeInvoiceItem(i)}>
											<DeleteIcon />
										</IconButton>
									}
									
								</div>
							))
						}

						{
							editingDisabled ?
							null
							:
							<Button
								color="primary"
								variant="contained"
								icon={AddIcon}
								onClick={this.addInvoiceItem}>
								{i18n.t('invoices.item')}
							</Button>
						}

						<div style={{ textAlign: 'right' }}>
							<table className={classes.totalTable} >
								<tbody>
									<tr>
										<td><Typography variant="subtitle1">{i18n.t('invoices.subtotal')}:</Typography></td>
										<td className={classes.money}>${this.getSubtotal().toFixed(2)}</td>
									</tr>
									<tr>
										<td><Typography variant="subtitle1">{i18n.t('invoices.gst')}:</Typography></td>
										<td className={classes.money}>${this.getGST().toFixed(2)}</td>
									</tr>
									<tr>
										<td><Typography variant="subtitle1">{i18n.t('invoices.qst')}:</Typography></td>
										<td className={classes.money}>${this.getQST().toFixed(2)}</td>
									</tr>
									<tr>
										<td style={{ borderTop: '1px solid rgba(0, 0, 0, 0.45)' }}><Typography variant="subtitle1">{i18n.t('invoices.total')}:</Typography></td>
										<td className={classes.money} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.45)' }}>${this.getTotal().toFixed(2)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Section>
				</Grid>
			</Grid>
		)
	}

	renderEmailSettings() {
		const { classes } = this.props;

		const editingDisabled = this.state.invoiceId ? (this.state.state === 'paid' || this.state.state === 'void') : false;

		let client = {
			firstName: 'John',
			lastName: 'Doe',
			addresses: [{
				label: 'Default',
				line1: '3480 Rue Griffith',
				city: 'Montréal',
				province: 'Québec',
				postalCode: 'H4T 1A7'
			}]
		};

		let project = {
			name: 'HS101',
			address: {
				label: 'Default',
				line1: '3480 Rue Griffith',
				city: 'Montréal',
				province: 'Québec',
				postalCode: 'H4T 1A7'
			}
		};

		if (this.state.clients.length) {
			client = this.state.clients[0];
		}

		if (this.state.selectedProject) {
			project = this.state.selectedProject;
		}

		const invoice = {
			number: this.state.number,
			representative: this.state.representative,
			deliveredAt: this.state.deliveredAt,
			terms: this.state.terms,
			items: this.state.items
		};

		return (
			<Grid container spacing={16}>
				<Grid item xs={12} md={6}>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<Section title={i18n.t('invoices.emailSettings')}>
								<FormControlLabel
									label={i18n.t('invoices.sendReminderEmails')}
									control={
										<Checkbox
											color="primary"
											disabled={editingDisabled}
											checked={this.state.sendReminderEmail}
											onChange={(e) => this.setState({ dirty: true, sendReminderEmail: e.target.checked })} />
									} />

								<br />

								<Typography style={{ display: 'inline' }}>{i18n.t('invoices.reminderDays1')}</Typography>
								
								<Select
									disabled={editingDisabled}
									classes={{ selectMenu: classes.selectMenu }}
									value={this.state.reminderDays}
									onChange={(e) => this.setState({ dirty: true, reminderDays: e.target.value })}>
									<MenuItem value={1}>1</MenuItem>
									<MenuItem value={3}>3</MenuItem>
									<MenuItem value={7}>7</MenuItem>
									<MenuItem value={14}>14</MenuItem>
								</Select>
								<Typography style={{ display: 'inline' }}>{i18n.t('invoices.reminderDays2')}</Typography>

								<br />
							</Section>
						</Grid>

						<Grid item xs={12}>
							<Section title={i18n.t('invoices.emailHistory')}>
								<div style={{ overflowY: 'auto', maxHeight: 300 }}>
									<Table padding="none">
										<TableBody>
											{
												_orderBy(this.state.invoiceReminderEmails, 'sentAt', 'desc').map((email, i) => (
													<TableRow key={i}>
														<TableCell align="left">{email.to}</TableCell>
														<TableCell align="right">{moment(email.sentAt).format('YYYY-MM-DD HH:mm')} ({moment(email.sentAt).fromNow()})</TableCell>
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</div>
							</Section>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} md={6}>
					<InvoiceEmail
						invoice={invoice}
						project={project}
						client={client} />
				</Grid>
			</Grid>
		)
	}

	selectProject(project) {
		this.setState({
			projectSearchString: project.name,
			selectedProject: project,
			clients: project.clients,
			dirty: true
		});
	}

	selectClient(id, checked) {
		if (checked) {
			const client = _find(this.state.selectedProject.clients, { id });
			
			if (client) {
				this.setState({
					clients: [
						...this.state.clients,
						client
					],
					dirty: true
				})
			}
		} else {
			const index = _findIndex(this.state.clients, { id });

			if (index > -1) {
				this.setState({
					clients: [
						...this.state.clients.slice(0, index),
						...this.state.clients.slice(index + 1)
					],
					dirty: true
				});
			}
		}
	}

	setInvoiceItemValue(i, field, value) {
		const { items } = this.state;

		let v = value;

		if (field === 'description' && v === 'CUSTOM_DESCRIPTION') {
			this.setState({
				items: [
					...items.slice(0, i),
					{
						...items[i],
						description: '',
						customDescription: true
					},
					...items.slice(i + 1)
				],
				dirty: true
			});
		} else {
			this.setState({
				items: [
					...items.slice(0, i),
					{
						...items[i],
						[field]: v
					},
					...items.slice(i + 1)
				],
				dirty: true
			});
		}
	}

	validateInvoiceItemRate(i, value) {
		const { items } = this.state;

		let v = parseFloat(value);

		if (isNaN(v)) {
			v = 0;
		}

		v = Math.round(v * 100) / 100;

		this.setState({
			items: [
				...items.slice(0, i),
				{
					...items[i],
					rate: v
				},
				...items.slice(i + 1)
			],
			dirty: true
		});
	}

	validateInvoiceItemQuantity(i, value) {
		const { items } = this.state;

		let v = parseFloat(value);

		if (isNaN(v) || v <= 0) {
			v = 0;
		}

		v = Math.round(v * 10) / 10;

		this.setState({
			items: [
				...items.slice(0, i),
				{
					...items[i],
					quantity: v
				},
				...items.slice(i + 1)
			],
			dirty: true
		});
	}

	addInvoiceItem() {
		this.setState({
			items: [
				...this.state.items,
				{
					description: invoiceItemDescriptionArray[0],
					rate: 90,
					quantity: 1
				}
			],
			dirty: true
		})
	}

	removeInvoiceItem(i) {
		this.setState({
			items: [
				...this.state.items.slice(0, i),
				...this.state.items.slice(i + 1)
			],
			dirty: true
		})
	}

	validateForm() {
		const errors = [];
		let error = false;

		if (!this.state.number) {
			errors.push(i18n.t('invoices.errors.numberRequired'));
			error = true;
		}

		if (!this.state.representative) {
			errors.push(i18n.t('invoices.errors.representativeRequired'));
			error = true;
		}

		if (!this.state.terms) {
			errors.push(i18n.t('invoices.errors.termsRequired'));
			error = true;
		}

		if (!this.state.deliveredAt) {
			errors.push(i18n.t('invoices.errors.deliveredAtRequired'));
			error = true;
		}

		if (!this.state.description) {
			errors.push(i18n.t('invoices.errors.descriptionRequired'));
			error = true;
		}

		if (!this.state.selectedProject) {
			errors.push(i18n.t('invoices.errors.projectRequired'));
			error = true;
		}

		if (this.state.isPaid && !this.state.paymentMethod) {
			errors.push(i18n.t('invoices.errors.paymentMethodRequired'));
			error = true;
		}

		if (!this.state.items || !this.state.items.length) {
			errors.push(i18n.t('invoices.errors.itemsRequired'));
			error = true;
		}

		let sum = 0;

		for (let item of this.state.items) {
			if (!item.description) {
				errors.push(i18n.t('invoices.errors.itemDescriptionRequired'));
				error = true;
				break;
			}

			sum += item.rate * item.quantity;
		}

		if (sum < 0) {
			errors.push(i18n.t('invoices.errors.negativeInvoiceValue'));
			error = true;
		}

		this.setState({
			errors
		});

		return !error;
	}

	saveInvoice() {
		if (!this.validateForm()) return;

		const { client } = this.props;

		if (!this.state.invoiceId) {
			// Create a new invoice
			const {
				selectedProject,
				representative,
				terms,
				description,
				number,
				deliveredAt,
				items,
				clients,
				sendReminderEmail,
				reminderDays,
				ticketRefId
			} = this.state;

			this.setState({
				loading: true
			});

			client.mutate({
				mutation: CREATE_INVOICE,
				variables: {
					project: selectedProject.id,
					clients: clients.map((c) => c.id),
					ticket: ticketRefId,
					representative,
					terms,
					description,
					number: parseInt(number),
					deliveredAt: deliveredAt,
					sendReminderEmail,
					reminderDays,
					items: items.map((i) => ({ description: i.description, quantity: i.quantity, notes: i.notes, rate: parseFloat(i.rate) }))
				}
			}).then((res) => {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/invoices/' + res.data.createInvoice.id, {
						title: i18n.t('invoices.invoice') + ' ' + this.state.number
					});
				});
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					loading: false
				});
			});
		} else {
			// Modify existing invoice
			const {
				invoiceId,
				number,
				representative,
				terms,
				description,
				deliveredAt,
				items,
				clients,
				sendReminderEmail,
				reminderDays
			} = this.state;

			this.setState({
				loading: true
			});

			client.mutate({
				mutation: EDIT_INVOICE,
				variables: {
					id: invoiceId,
					number: parseInt(number),
					representative,
					terms,
					description,
					deliveredAt: moment(deliveredAt).toDate(),
					items: items.map((i) => ({ description: i.description, quantity: i.quantity, notes: i.notes, rate: parseFloat(i.rate) })),
					clients: clients.map((c) => c.id),
					sendReminderEmail,
					reminderDays
				}
			}).then((res) => {
				this.setState({
					loading: false,
					snackbar: true,
					dirty: false
				});
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					loading: false,
					dirty: false
				});
			});
		}
	}

	getSubtotal() {
		const { items } = this.state;

		let sum = 0;
		for (let item of items) {
			sum += item.rate * item.quantity;
		}

		return Math.round(sum * 100) / 100;
	}

	getGST() {
		const subtotal = this.getSubtotal();
		return Math.round(subtotal * 0.05 * 100) / 100;
	}

	getQST() {
		const subtotal = this.getSubtotal();
		return Math.round(subtotal * 0.09975 * 100) / 100;
	}

	getTotal() {
		return this.getSubtotal() + this.getGST() + this.getQST();
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	performInvoiceAction(action) {
		const { invoiceId, paymentMethod, paymentMethodNotes } = this.state;

		const { client } = this.props;

		const variables = {
			id: invoiceId
		};
		
		let mutation = SEND_INVOICE;
		let mutationKey = 'sendInvoice';

		if (action === 'void') {
			mutation = VOID_INVOICE;
			mutationKey = 'voidInvoice';
		} else if (action === 'discard') {
			mutation = DISCARD_INVOICE;
			mutationKey = 'discardInvoiceDraft';
		} else if (action === 'markPaid') {
			mutation = MARK_INVOICE_PAID;
			mutationKey = 'markInvoicePaid';
			variables.paymentMethod = paymentMethod;
			variables.paymentMethodNotes = paymentMethodNotes;

			if (!variables.paymentMethod || !variables.paymentMethodNotes) {
				return;
			}
		}

		this.setState({
			loading: true
		});

		client.mutate({
			mutation,
			variables: variables
		}).then((res) => {
			this.setState({
				loading: false,
				sendEmailConfirmOpen: false,
				voidConfirmOpen: false,
				discardConfirmOpen: false,
				markPaidConfirmOpen: false,
				state: res.data[mutationKey].state
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false,
				sendEmailConfirmOpen: false,
				voidConfirmOpen: false,
				discardConfirmOpen: false,
				markPaidConfirmOpen: false
			});
		});
	}
}

export default compose(
	withStyles(styles),
	withUser,
	withQueries(function() {
		const { match, location } = this.props;

		const queries = {
			getInvoiceNumber: {
				query: GET_INVOICE_NUMBER,
				fetchPolicy: 'network-only'
			}
		};

		if (match.params.id) {
			queries.invoice = {
				query: INVOICE,
				variables: {
					id: match.params.id
				}
			}
		}

		if (location && location.state) {
			if (location.state.invoiceClientId) {
				queries.invoiceClient = {
					query: CLIENT,
					variables: {
						id: location.state.invoiceClientId
					}
				};
			} else if (location.state.invoiceProjectId) {
				queries.invoiceProject = {
					query: PROJECT,
					variables: {
						id: location.state.invoiceProjectId
					}
				};
			} else if (location.state.invoiceTicketId) {
				queries.invoiceTicket = {
					query: TICKET,
					variables: {
						id: location.state.invoiceTicketId
					}
				};

				queries.invoiceServiceCalls = {
					query: TICKET_SERVICE_CALLS,
					variables: {
						ticketId: location.state.invoiceTicketId
					}
				};
			}
		}

		return queries;
	}),
	withApollo,
	withUI
)(_Invoice);
