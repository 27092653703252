import { withStyles as withStylesMUI } from "@material-ui/core";
import _isObject from "lodash/isObject";

export default function withStyles(styles) {
  return function(WrappedComponent) {
    const mergedStyles = theme => {
      let componentStyles = {};

      if (styles) {
        if (typeof styles === "function") {
          componentStyles = styles(theme);
        } else if (_isObject(styles)) {
          componentStyles = styles;
        }
      }

      return {
        pagePaper: {
          margin: theme.spacing.unit
        },

        flexRow: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap"
        },

        flexRowEnd: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "flex-end"
        },

        flexSpacedRow: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between"
        },

        flexColumn: {
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.unit
        },

        flexFill: {
          flex: 1
        },

        divider: {
          marginTop: theme.spacing.unit * 2,
          marginBottom: theme.spacing.unit * 2
        },

        textField: {
          marginBottom: theme.spacing.unit * 2
        },

        margin1: {
          margin: theme.spacing.unit
        },

        margin2: {
          margin: theme.spacing.unit * 2
        },

        margin3: {
          margin: theme.spacing.unit * 3
        },

        margin4: {
          margin: theme.spacing.unit * 4
        },

        icon: {
          color: "rgba(0, 0, 0, 0.54)",
          margin: "0 8px"
        },

        card: {
          padding: theme.spacing.unit * 2,
          "& > hr:first-of-type": {
            marginTop: theme.spacing.unit
          }
        },

        ...componentStyles
      };
    };

    return withStylesMUI(mergedStyles)(
      class extends Component {
        render() {
          return <WrappedComponent {...this.props} />;
        }
      }
    );
  };
}
