import gql from "graphql-tag";

export const SEARCH_CLIENTS = gql`
  query searchClients($searchString: String!) {
    searchClients(searchString: $searchString) {
      id
      email
      firstName
      lastName
      type
    }
  }
`;

export const SEARCH_TECHS = gql`
  query searchTechs($searchString: String!) {
    searchTechs(searchString: $searchString) {
      id
      email
      firstName
      lastName
      type
    }
  }
`;

export const SEARCH_PROJECTS = gql`
  query searchProjects($searchString: String!) {
    searchProjects(searchString: $searchString) {
      id
      name
      warrantyExpiration
      address {
        label
        line1
        line2
        city
        province
        country
        postalCode
      }
      clients {
        id
        email
        firstName
        lastName
        type
      }
    }
  }
`;
