import {
	Toolbar,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Hidden
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Query, compose } from 'react-apollo';
import withStyles from 'utils/withStyles';
import {
	Table,
	Page,
	PageHeader,
	PageBody,
	StateChip,
	Button,
	MonthlyCalendar,
	WeeklyCalendar
} from 'components';
import _uniqBy from 'lodash/uniqBy';
import { SERVICE_CALLS } from 'graph/queries';

const styles = (theme) => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
});

class ServiceCalls extends Component {
	constructor(props) {
		super(props);

		this.state = {
			status: '',
			sort: 'date',
			dir: 'desc',
			page: 0,
			viewMode: 'calendar'
		}
	}

	render() {
		return (
			<Page>
				<PageHeader>
					{
						this.state.viewMode === 'calendar' ?
						<div style={{ flex: 1, textAlign: 'right' }}>
							<Button
								variant="contained"
								color="primary"
								icon={ListIcon}
								onClick={() => this.setState({ viewMode: 'list' })}>
								{i18n.t('serviceCalls.list')}
							</Button>
						</div>
						:
						<div style={{ flex: 1, textAlign: 'right' }}>
							<Button
								variant="contained"
								color="primary"
								icon={CalendarIcon}
								onClick={() => this.setState({ viewMode: 'calendar' })}>
								{i18n.t('serviceCalls.calendar')}
							</Button>
						</div>
					}
				</PageHeader>

				{
					this.state.viewMode === 'list' ?
					this.renderListView()
					:
					this.renderCalendarView()
				}
			</Page>
		);
	}

	renderListView() {
		const { classes } = this.props;

		return (
			<Query query={SERVICE_CALLS} fetchPolicy={'network-only'} variables={{
				status: this.state.status,
				sort: this.state.sort,
				direction: this.state.dir,
				limit: 10,
				page: this.state.page
			}}>
				{({ loading, error, data }) => {
					return (
					<PageBody loading={loading} error={error} padding={0}>
						<Toolbar>
							<div className={classes.flexRow}>
								<FormControl className={classes.formControl}>
									<InputLabel>{i18n.t('tickets.status')}</InputLabel>
									<Select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
										<MenuItem value={''}>{i18n.t('general.any')}</MenuItem>
										<MenuItem value={'open'}>{i18n.t('serviceCalls.states.open')}</MenuItem>
										<MenuItem value={'booked'}>{i18n.t('serviceCalls.states.booked')}</MenuItem>
										<MenuItem value={'complete'}>{i18n.t('serviceCalls.states.complete')}</MenuItem>
										<MenuItem value={'approved'}>{i18n.t('serviceCalls.states.approved')}</MenuItem>
									</Select>
								</FormControl>
							</div>
						</Toolbar>
						
						<Table 
							page={this.state.page}
							limit={10}
							sort={this.state.sort}
							count={data && data.serviceCalls ? data.serviceCalls.count : 0}
							direction={this.state.dir}
							onChangeSort={this.sortTable.bind(this)}
							onChangePage={(page) => this.setState({ page })}
							rows={
								(data && data.serviceCalls && data.serviceCalls.serviceCalls) ?
									data.serviceCalls.serviceCalls.map((serviceCall, i) => {
										const techs =
											_uniqBy(serviceCall.workItems, (wi) => wi.tech.id)
											.map((wi) => wi.tech);

										return {
											date: moment(serviceCall.date).format(i18n.t('formats.dateFriendly')),

											workOrder: <Link to={{
												pathname: `/tickets/${serviceCall.ticket.id}`,
												state: { title: `Ticket #${serviceCall.ticket.workOrder}`}
											}}>{serviceCall.ticket.workOrder}</Link>,
											
											project: <Link to={{
												pathname: `/projects/${serviceCall.ticket.project.id}`,
												state: { title: serviceCall.ticket.project.name}
											}}>{serviceCall.ticket.project.name}</Link>,
											
											client: <Link to={{
												pathname: `/clients/${serviceCall.ticket.client.id}`,
												state: { title: serviceCall.ticket.client.firstName + ' ' + serviceCall.ticket.client.lastName}
											}}>{serviceCall.ticket.client.firstName + ' ' + serviceCall.ticket.client.lastName}</Link>,

											techs: <div>
												{
													techs.map((tech, i) => (
														<Link style={{ marginRight: 4 }} key={i} to={{
															pathname: `/techs/${tech.id}`,
															state: { title: tech.firstName + ' ' + tech.lastName}
														}}>{tech.firstName + ' ' + tech.lastName}</Link>
													))
												}
											</div>,
											
											state: <StateChip state={serviceCall.status} />,

											createdAt: moment(serviceCall.createdAt).format(i18n.t('formats.dateTime')),
									
											edit: <Link to={{
												pathname: `/serviceCalls/${serviceCall.id}`,
												state: { title: i18n.t('title.serviceCall') }
											}}><IconButton><EditIcon /></IconButton></Link>
										}
									})
									: []
							}
							columns={[{
								key: 'date',
								label: i18n.t('general.date'),
								sortable: true
							}, {
								key: 'workOrder',
								label: i18n.t('tickets.workOrder'),
								sortable: true
							}, {
								key: 'state',
								label: i18n.t('tickets.status'),
								sortable: true,
								center: true
							}, {
								key: 'project',
								label: i18n.t('title.project')
							}, {
								key: 'client',
								label: i18n.t('tickets.client')
							}, {
								key: 'techs',
								label: i18n.t('title.techs')
							}, {
								key: 'createdAt',
								label: i18n.t('general.created'),
								sortable: true
							}, {
								key: 'edit',
								label: ''
							}]} />
					</PageBody>);
				}}
			</Query>
		)
	}

	renderCalendarView() {
		return (
			<React.Fragment>
				<Hidden lgUp>
					<WeeklyCalendar />
				</Hidden>
				<Hidden mdDown>
					<MonthlyCalendar />
				</Hidden>
			</React.Fragment>
		);
	}

	sortTable(fieldName, e) {
		let sortDir = 'asc';

		if (fieldName === this.state.sort) {
			sortDir = (this.state.dir === 'asc') ? 'desc' : 'asc';
		}

		this.setState({
			sort: fieldName,
			dir: sortDir
		});
	}
};

export default compose(
	withStyles(styles)
)(ServiceCalls);