import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import { withApollo, compose } from 'react-apollo';
import {
	Typography
} from '@material-ui/core';
import {
	Section,
	TextField,
	Button,
	Snackbar
} from 'components';

import { EDIT_KEYSTORE, CREATE_KEYSTORE } from 'graph/mutations';

class KeyStoreTemplate extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: props.item.value,
			loading: false,
			snackbar: ''
		};

		this.onSave = this.onSave.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.generatePassword = this.generatePassword.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.undo = this.undo.bind(this);
	}

	render() {
		const { item } = this.props;
		const { value, loading } = this.state;
		const { label } = item;

		return (
			<Section style={{ paddingBottom: 4 }}>
				<Typography variant="caption">{label}</Typography>
				
				<TextField
					fullWidth
					value={value}
					style={{ flex: 1 }}
					InputProps={{
						style: {
							fontFamily: 'monospace',
							fontSize: 14
						}
					}}
					onKeyDown={(e) => e.keyCode === 13 && this.onSave()}
					onChange={this.handleChange} />
				
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
					{
						label.toLowerCase().indexOf('password') >= 0 ?
						<div style={{ flex: 1, textAlign: 'left' }}>
							<Button
								variant="text"
								disabled={loading}
								onClick={this.generatePassword}
								color="default">{i18n.t('projects.generatePassword')}</Button>
						</div>
						: null
					}
					
					<Button
						variant="text"
						loading={loading}
						disabled={!value || value === item.value}
						onClick={this.onSave}
						color="default">{i18n.t('general.save')}</Button>

					<Button
						variant="text"
						disabled={item.value === value || loading}
						onClick={this.undo}
						color="default">{i18n.t('general.undo')}</Button>
				</div>

				<Snackbar
					message={this.state.snackbar || ''}
					open={!!this.state.snackbar}
					onClose={this.handleCloseSnackbar} />
			</Section>
		)
	}

	onSave() {
		const { client, projectId, item } = this.props;

		if (!this.state.value) return;

		if (item.id) {
			this.setState({
				loading: true
			});
	
			client.mutate({
				mutation: EDIT_KEYSTORE,
				variables: {
					projectId,
					keyStoreId: item.id,
					value: this.state.value
				}
			}).then((res) => {
				this.setState({
					loading: false,
					snackbar: i18n.t('projects.keyStoreCreated')
				});

				this.unblockNavigation();
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					loading: false
				});
			});
		} else {
			this.setState({
				loading: true
			});

			client.mutate({
				mutation: CREATE_KEYSTORE,
				variables: {
					projectId,
					label: item.label,
					value: this.state.value
				}
			}).then((res) => {
				this.setState({
					loading: false,
					snackbar: i18n.t('projects.keyStoreCreated')
				});

				this.unblockNavigation();
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					loading: false
				});
			});
		}
	}

	generatePassword() {
		function dec2hex(dec) {
			return ('0' + dec.toString(16)).substr(-2);
		}

		try {
			var arr = new Uint8Array((8) / 2);
		
			window.crypto.getRandomValues(arr);

			this.setState({
				value: Array.from(arr, dec2hex).join('')
			});

			this.blockNavigation();
		} catch(e) {}
	}
	
	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	handleChange(e) {
		const { item } = this.props;
		
		this.blockNavigation();

		this.setState({
			value: e.target.value
		});
	}

	undo() {
		const { item } = this.props;
		this.unblockNavigation();
		this.setState({ value: item.value });
	}

	blockNavigation() {
		const { item, onDirty } = this.props;

		onDirty(item.label, true);
	}

	unblockNavigation() {
		const { item, onDirty } = this.props;

		onDirty(item.label, false);
	}
}

export default compose(
	withUI,
	withApollo
)(KeyStoreTemplate);