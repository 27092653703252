import gql from 'graphql-tag';

export const STRIPE_WEBHOOKS = gql`
	query stripeWebhooks(
		$searchString: String
		$livemode: Boolean
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		stripeWebhooks(
			searchString: $searchString
			livemode: $livemode
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			stripeWebhooks {
				id
				eventId
				createdAt
				type
				livemode
			}
		}
	}
`;

export const MAILGUN_WEBHOOKS = gql`
	query mailgunWebhooks(
		$searchString: String
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		mailgunWebhooks(
			searchString: $searchString
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			mailgunWebhooks {
				id
				event
				recipient
				domain
				subject
				createdAt
			}
		}
	}
`;