import gql from "graphql-tag";
import { ticket, ticketNotes, ticketDescriptions } from "graph/types";
// $ticketDescription: String!
// ticketDescription: $ticketDescription
// for CreateTicket et EditTicket
export const CREATE_TICKET = gql`
mutation CreateTicket(
	$client: String!
	$project: String!
	$timeEstimate: Float!
	$tags: [String!]!
	$status: String!
	$priority: String!) {
	createTicket(
		client: $client
		project: $project
		timeEstimate: $timeEstimate
		tags: $tags
		status: $status
		priority: $priority
	) {
		${ticket}
	}
}
`;

export const EDIT_TICKET = gql`
mutation EditTicket(
	$id: ID!
	$client: String!
	$timeEstimate: Float!
	$tags: [String!]!
	$status: String!
	$priority: String!) {
	editTicket(
		id: $id
		client: $client
		timeEstimate: $timeEstimate
		tags: $tags
		status: $status
		priority: $priority
	) {
		${ticket}
  }
}
`;

export const ADD_TICKET_NOTE = gql`
mutation addTicketNote(
	$id: ID!
	$note: String!
	$isInternal: Boolean!) {
	addTicketNote(
		id: $id
		note: $note
		isInternal: $isInternal
	) {
		${ticketNotes}
	}
}
`;

export const REMOVE_TICKET_NOTE = gql`
mutation removeTicketNote(
	$id: ID!
	$noteId: ID!
	$isInternal: Boolean!) {
	removeTicketNote(
		id: $id
		noteId: $noteId
		isInternal: $isInternal
	) {
		${ticketNotes}
	}
}
`;

export const EDIT_TICKET_NOTE = gql`
mutation editTicketNote(
	$id: ID!
	$noteId: ID!
	$note: String!
	$isInternal: Boolean!) {
	editTicketNote(
		id: $id
		noteId: $noteId
		note: $note
		isInternal: $isInternal
	) {
		${ticketNotes}
	}
}
`;

export const ADD_TICKET_DESCRIPTION = gql`
mutation addTicketDescription(
	$id: ID!
	$category: String!
	$description: String!) {
	addTicketDescription(
		id: $id
		category: $category
		description: $description
	) {
		${ticketDescriptions}
	}
}
`;

export const REMOVE_TICKET_DESCRIPTION = gql`
mutation removeTicketDescription(
	$id: ID!
	$descriptionId: ID! ) {
	removeTicketDescription(
		id: $id
		descriptionId: $descriptionId
	) {
		${ticketDescriptions}
	}
}
`;

export const EDIT_TICKET_DESCRIPTION = gql`
mutation editTicketDescription(
	$id: ID!
	$descriptionId: ID!
	$category: String
	$description: String
	$solved: Boolean
	$solveDescription: String) {
	editTicketDescription(
		id: $id
		descriptionId: $descriptionId
		category: $category
		description: $description
		solved: $solved
		solveDescription: $solveDescription
	) {
		${ticketDescriptions}
	}
}
`;
