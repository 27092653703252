import {
	Loading,
	FullScreenError,
	Section,
	TextField,
	Button,
	Grid
} from 'components';
import CheckList from './CheckList';
import {
	List,
	ListItem,
	ListItemText
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import { Query, compose, withApollo } from 'react-apollo';
import { CHECKLISTS } from 'graph/queries';
import { CREATE_CHECKLIST } from 'graph/mutations';

const styles = (theme) => ({
	
});

class CheckLists extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openChecklistIndex: null,
			newChecklistName: '',
			newChecklistLoading: false
		};

		this.createChecklist = this.createChecklist.bind(this);
	}

	render() {
		const { projectId, classes } = this.props;
		const { openChecklistIndex, newChecklistName, newChecklistLoading } = this.state;

		return (
			<Query query={CHECKLISTS} variables={{ projectId }} fetchPolicy="network-only">
				{({ loading, error, data }) => {
					if (loading) {
						return <Loading />
					}

					if (error) {
						return <FullScreenError error={getGraphQLError(error)} />
					}

					const checklists = data.checklists;

					if (typeof openChecklistIndex === 'number') {
						return (
							<CheckList checklist={checklists[openChecklistIndex]} onClose={() => this.setState({ openChecklistIndex: null })} />
						)
					} else {
						return (
							<Grid container spacing={16}>
								<Grid item xs={12}>
									<Section>
										<div className={classes.flexRow}>
											<div style={{ flex: 1 }}>
												<TextField
													required
													fullWidth
													autoComplete="off"
													label={i18n.t('general.name')}
													variant="outlined"
													onKeyDown={(e) => e.keyCode === 13 && this.createChecklist()}
													onChange={(e) => this.setState({ newChecklistName: e.target.value })}
													value={newChecklistName} />
											</div>

											<div style={{ marginLeft: 16 }}>
												<Button
													color="primary"
													variant="contained"
													icon={AddIcon}
													disabled={!newChecklistName}
													loading={newChecklistLoading}
													onClick={this.createChecklist}>
													{i18n.t('general.create')}
												</Button>
											</div>
										</div>
									</Section>
								</Grid>

								<Grid item xs={12}>
									<Section>
										<List>
											{
												checklists.map((checklist, i) => (
													<ListItem key={i} button onClick={() => this.setState({ openChecklistIndex: i })}>
														<ListItemText>{checklist.name}</ListItemText>
													</ListItem>
												))
											}
										</List>
									</Section>
								</Grid>
							</Grid>
						)
					}
				}}
			</Query>
		);
	}

	createChecklist() {
		const { client, projectId } = this.props;
		const { newChecklistName } = this.state;

		if (!newChecklistName) return;
		
		this.setState({
			newChecklistLoading: true
		});

		client.mutate({
			mutation: CREATE_CHECKLIST,
			variables: {
				projectId,
				name: newChecklistName
			},
			refetchQueries: [{
				query: CHECKLISTS,
				variables: { projectId }
			}],
			awaitRefetchQueries: true
		}).then((res) => {
			this.setState({
				newChecklistLoading: false,
				newChecklistName: ''
			});
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				newChecklistLoading: false
			});
		});
	}
}

export default compose(
	withApollo,
	withUI,
	withStyles(styles)
)(CheckLists);
