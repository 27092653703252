import { Typography, Menu, MenuItem, Divider, IconButton } from "@material-ui/core";
import { Button, Section } from "components";
import withUser from "utils/withUser";
import withStyles from "utils/withStyles";
import withUI from "utils/withUI";
import getGraphQLError from "utils/getGraphQLError";
import { compose, withApollo } from "react-apollo";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MoreIcon from "@material-ui/icons/MoreVert";

import {
  EDIT_PROJECT_NOTE,
  EDIT_TICKET_NOTE,
  REMOVE_PROJECT_NOTE,
  REMOVE_TICKET_NOTE
} from "graph/mutations";

const styles = theme => ({
  note: {
    marginBottom: theme.spacing.unit * 2,
    "& .ql-container": {
      border: "none"
    }
  },

  forceBorder: {
    "& .ql-container": {
      border: "1px solid #ccc"
    }
  }
});

class Note extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      rawText: "",
      editing: false,
      menuOpen: false,
      loading: false
    };

    this.editNote = this.editNote.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.removeNote = this.removeNote.bind(this);
    this.finishEdit = this.finishEdit.bind(this);
    this.openNoteMenu = this.openNoteMenu.bind(this);
    this.closeNoteMenu = this.closeNoteMenu.bind(this);
  }

  render() {
    const { classes, note, currentUser, isProjectNote } = this.props;

    let editingEnabled = false;
    let removingEnabled = false;

    if (
      currentUser &&
      currentUser.type === "Admin" &&
      currentUser.hasPermission(isProjectNote ? "projects" : "tickets", 1)
    ) {
      editingEnabled = true;
      removingEnabled = true;
    } else if (currentUser && currentUser.id === note.by.id) {
      editingEnabled = true;
      removingEnabled = true;
    }

    return (
      <Section className={classes.note}>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.menuOpen}
          onClose={this.closeNoteMenu}
        >
          {editingEnabled ? (
            <MenuItem onClick={this.editNote}>{i18n.t("general.edit")}</MenuItem>
          ) : null}

          {removingEnabled ? (
            <MenuItem onClick={this.removeNote}>{i18n.t("general.remove")}</MenuItem>
          ) : null}
        </Menu>

        <div className={classes.flexRow}>
          <div>
            {note.by.type === "Client" ? (
              <PersonIcon className={classes.icon} />
            ) : note.by.type === "Tech" ? (
              <BuildIcon className={classes.icon} />
            ) : note.by.type === "Admin" ? (
              <VerifiedUserIcon className={classes.icon} />
            ) : null}
          </div>

          <div style={{ flex: 1 }}>
            <Typography variant="subtitle2">{`${note.by.firstName} ${note.by.lastName} - ${note.by.type}`}</Typography>
            <Typography variant="caption">
              {moment(note.createdAt).format("YYYY-MM-DD HH:mm")}
            </Typography>
          </div>

          {editingEnabled || removingEnabled ? (
            this.state.editing ? (
              <React.Fragment>
                <Button color="primary" onClick={this.finishEdit}>
                  {i18n.t("general.done")}
                </Button>
                <Button
                  variant="text"
                  color="default"
                  style={{ marginLeft: 8 }}
                  onClick={this.cancelEdit}
                >
                  {i18n.t("general.cancel")}
                </Button>
              </React.Fragment>
            ) : (
              <IconButton onClick={e => this.openNoteMenu(e)}>
                <MoreIcon />
              </IconButton>
            )
          ) : null}
        </div>

        <Divider style={{ margin: "8px 0" }} />

        {this.state.editing ? (
          <ReactQuill
            className={classes.forceBorder}
            value={this.state.text}
            onChange={(content, delta, source, editor) =>
              this.setState({ text: content, rawText: editor.getText() })
            }
          />
        ) : (
          <ReactQuill
            value={note.note}
            readOnly
            modules={{
              toolbar: false
            }}
          />
        )}
      </Section>
    );
  }

  editNote() {
    this.setState({
      editing: true,
      text: this.props.note.note,
      rawText: this.props.note.note
    });

    this.closeNoteMenu();
  }

  cancelEdit() {
    this.setState({
      editing: false,
      text: "",
      rawText: ""
    });
  }

  removeNote() {
    const { client, note, parentId, isInternal, schemaType } = this.props;

    let mutation = null;

    if (schemaType === "project") {
      mutation = REMOVE_PROJECT_NOTE;
    } else if (schemaType === "ticket") {
      mutation = REMOVE_TICKET_NOTE;
    }

    this.setState({
      menuOpen: false
    });

    client
      .mutate({
        mutation,
        variables: {
          id: parentId,
          noteId: note.id,
          isInternal
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });

        this.props.ui.showError(getGraphQLError(e));
      });
  }

  finishEdit() {
    const { client, note, parentId, schemaType, isInternal } = this.props;

    let mutation = null;

    if (schemaType === "project") {
      mutation = EDIT_PROJECT_NOTE;
    } else if (schemaType === "ticket") {
      mutation = EDIT_TICKET_NOTE;
    }

    if (this.state.text && this.state.text.length) {
      client
        .mutate({
          mutation,
          variables: {
            id: parentId,
            noteId: note.id,
            isInternal,
            note: this.state.text
          }
        })
        .then(() => {
          this.setState({
            loading: false,
            text: "",
            rawText: "",
            editing: false
          });
        })
        .catch(e => {
          this.setState({
            loading: false
          });

          this.props.ui.showError(getGraphQLError(e));
        });
    }
  }

  openNoteMenu(e) {
    this.setState({
      menuOpen: true,
      anchorEl: e.target
    });
  }

  closeNoteMenu() {
    this.setState({
      menuOpen: false,
      anchorEl: null
    });
  }
}

export default compose(
  withUser,
  withApollo,
  withUI,
  withStyles(styles)
)(Note);
