import gql from "graphql-tag";
import { ticket, tickets, ticketNotes, ticketDescriptions } from "graph/types";

export const TICKET = gql`
	query ticket($id: ID!) {
		ticket(id: $id) {
			${ticket}
		}
	}
`;

export const TICKETS = gql`
	query tickets(
		$workOrderSearchString: Int
		$clientSearchString: String
		$projectSearchString: String
		$status: String
		$sort: String
		$from: Date
		$to: Date
		$direction: SortDirection
		$page: Int
		$limit: Int
	) {
		tickets(
			workOrderSearchString: $workOrderSearchString
			clientSearchString: $clientSearchString
			projectSearchString: $projectSearchString
			status: $status
			sort: $sort
			from: $from
			to: $to
			direction: $direction
			page: $page
			limit: $limit
		) {
			count
			tickets {
				${tickets}
			}
		}
	}
`;

export const PROJECT_TICKETS = gql`
	query projectTickets(
		$project: String
		$sort: String
		$direction: SortDirection
		$page: Int
		$limit: Int
	) {
		projectTickets(
			project: $project
			sort: $sort
			direction: $direction
			page: $page
			limit: $limit
		) {
			count
			tickets {
				${tickets}
			}
		}
	}
`;

export const TICKET_NOTES = gql`
	query ticket($id: ID!) {
		ticket(id: $id) {
			${ticketNotes}
		}
	}
`;

export const TICKET_DESCRIPTIONS = gql`
	query ticket($id: ID!) {
		ticket(id: $id) {
			${ticketDescriptions}
		}
	}
`;
