import {
	Section,
	TextField,
	Button,
	TechCard,
	SignaturePad,
	Snackbar,
	Grid
} from 'components';
import {
	IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';
import moment from 'moment';
import { TimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateMomentUtils from '@date-io/moment';
import { compose, withApollo } from 'react-apollo';
import withStyles from 'utils/withStyles';
import withUI from 'utils/withUI';
import withUser from 'utils/withUser';
import getGraphQLError from 'utils/getGraphQLError';

import { TECH_EDIT_SERVICE_CALL } from 'graph/mutations';

const styles = {
	signaturePadContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 12000,
		background: '#ccc',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end'
	}
};

class SignoffTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			signaturePadVisible: false,
			signee: '',
			newSignature: [],
			dirty: false,
			snackbar: false
		};

		this.showSignaturePad = this.showSignaturePad.bind(this);
		this.hideSignaturePad = this.hideSignaturePad.bind(this);
		this.confirmSignature = this.confirmSignature.bind(this);
		this.clearSignature = this.clearSignature.bind(this);
		this.completeServiceCall = this.completeServiceCall.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
	}

	render() {
		const {
			serviceCall,
			techNotes,
			workItems,
			signingTechName,
			signingClientName,
			techSignature,
			clientSignature,
			classes
		} = this.props;

		return (
			<MuiPickersUtilsProvider utils={DateMomentUtils}>
				<Grid container spacing={16}>
					{
						workItems.map((wi, i) => {
							const startTime = wi.actualStartTime || wi.startTime;
							const endTime = wi.actualEndTime || wi.endTime;

							return (
								<Grid key={i} item xs={12} md={6} lg={4}>
									<TechCard tech={wi.tech} showEmail={false} showPhones={false}>
										<TimePicker
											format="HH:mm"
											fullWidth
											variant="outlined"
											required
											minutesStep={5}
											ampm={false}
											label={i18n.t('serviceCalls.actualStartTime')}
											value={startTime}
											className={classes.textField}
											onChange={(e) => this.setWorkItemTime('actualStartTime', e, i)} />

										<TimePicker
											format="HH:mm"
											fullWidth
											variant="outlined"
											required
											minutesStep={5}
											ampm={false}
											label={i18n.t('serviceCalls.actualEndTime')}
											value={endTime}
											className={classes.textField}
											onChange={(e) => this.setWorkItemTime('actualEndTime', e, i)} />

										<TextField
											fullWidth
											type="number"
											inputProps={{ min: 0, step: 0.25 }}
											variant="outlined"
											autoComplete="off"
											label={i18n.t('serviceCalls.breakTime')}
											value={wi.breakTime}
											onChange={(e) => this.setWorkItemBreakTime(e.target.value, i)}
											onBlur={(e) => this.validateBreakTime(e.target.value, i)} />
									</TechCard>
								</Grid>
							);
						})
					}

					<Grid item xs={12}>
						<Section title={i18n.t('general.notes')}>
							<TextField
								fullWidth
								variant="outlined"
								multiline
								rows={5}
								rowsMax={5}
								autoComplete="off"
								label={i18n.t('serviceCalls.techNotes')}
								value={techNotes}
								onChange={(e) => this.onChange('techNotes', e.target.value)} />
						</Section>
					</Grid>

					<Grid item xs={12}>
						<Section title={i18n.t('serviceCalls.signatures')}>
							<Grid container spacing={16}>
								<Grid item xs={12} md={6}>
									<TextField
										required
										fullWidth
										variant="outlined"
										autoComplete="off"
										label={i18n.t('serviceCalls.signingTechName')}
										value={signingTechName}
										onChange={(e) => this.onChange('signingTechName', e.target.value)} />

									<br />

									<div id="techSignatureReadOnly" style={{ marginTop: 8, position: 'relative' }}>
										<SignaturePad readonly path={techSignature} parentId="techSignatureReadOnly" />

										<Button
											style={{
												position: 'absolute',
												bottom: 8,
												right: 8
											}}
											onClick={() => this.showSignaturePad('tech')}>
											{i18n.t('serviceCalls.sign')}
										</Button>
									</div>
								</Grid>

								<Grid item xs={12} md={6}>
									<TextField
										required
										fullWidth
										variant="outlined"
										autoComplete="off"
										label={i18n.t('serviceCalls.signingClientName')}
										value={signingClientName}
										onChange={(e) => this.onChange('signingClientName', e.target.value)} />

									<br />

									<div id="clientSignatureReadOnly" style={{ marginTop: 8, position: 'relative' }}>
										<SignaturePad readonly path={clientSignature} parentId="clientSignatureReadOnly" />
										<Button
											style={{
												position: 'absolute',
												bottom: 8,
												right: 8
											}}
											onClick={() => this.showSignaturePad('client')}>
											{i18n.t('serviceCalls.sign')}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Section>
					</Grid>
					{
						this.state.signaturePadVisible ?
							<div className={classes.signaturePadContainer} id="signaturePad">
								<SignaturePad path={this.state.newSignature} parentId="signaturePad" onChange={(newSignature) => this.setState({ newSignature })} />

								<div className={classes.signaturePadButton}>
									<IconButton
										onClick={this.confirmSignature}>
										<CheckIcon />
									</IconButton>

									<IconButton
										onClick={this.clearSignature}>
										<UndoIcon />
									</IconButton>

									<IconButton
										onClick={this.hideSignaturePad}>
										<ClearIcon />
									</IconButton>
								</div>
							</div>
							: null
					}

					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Button
							loading={this.state.loading}
							disabled={serviceCall.status === 'complete' && !this.state.dirty}
							onClick={this.completeServiceCall}>
							{
								serviceCall.status === 'booked' ?
								i18n.t('serviceCalls.completeServiceCall')
								:
								i18n.t('serviceCalls.saveChanges')
							}
						</Button>
					</Grid>

					<Snackbar
						message={i18n.t('admins.editAdminComplete')}
						open={this.state.snackbar}
						onClose={this.handleCloseSnackbar} />
				</Grid>
			</MuiPickersUtilsProvider>
		);
	}

	onChange(fieldName, value) {
		this.props.onChange(fieldName, value);
		this.setState({
			dirty: true
		});
	}

	validateBreakTime(value, index) {
		let v = parseFloat(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		v = Math.round(v * 4) / 4;

		this.setWorkItemBreakTime(v, index);
	}

	setWorkItemBreakTime(hours, index) {
		const workItems = this.props.workItems;

		this.props.onChange('workItems', [
			...workItems.slice(0, index),
			{
				...workItems[index],
				breakTime: hours
			},
			...workItems.slice(index + 1)
		]);

		this.setState({
			dirty: true
		});
	}

	setWorkItemTime(startOrEnd, time, index) {
		const workItems = this.props.workItems;
		const workItem = this.props.workItems[index];

		if (
			startOrEnd === 'actualStartTime' && time.isAfter(workItem.actualEndTime) ||
			startOrEnd === 'actualEndTime' && time.isBefore(workItem.actualStartTime)
		) {
			this.props.onChange('workItems', [
				...workItems.slice(0, index),
				{
					...workItems[index],
					actualStartTime: time.toDate(),
					actualEndTime: time.toDate()
				},
				...workItems.slice(index + 1)
			]);
		} else {
			this.props.onChange('workItems', [
				...workItems.slice(0, index),
				{
					...workItems[index],
					[startOrEnd]: time.toDate()
				},
				...workItems.slice(index + 1)
			]);
		}

		this.setState({
			dirty: true
		});
	}

	showSignaturePad(techOrClient) {
		this.setState({
			signaturePadVisible: true,
			signee: techOrClient,
			newSignature: []
		});
	}

	hideSignaturePad() {
		this.setState({
			signaturePadVisible: false
		});
	}

	confirmSignature() {
		if (this.state.signee === 'tech') {
			this.onChange('techSignature', this.state.newSignature);
			this.setState({
				signaturePadVisible: false,
				newSignature: []
			});
		} else {
			this.onChange('clientSignature', this.state.newSignature);
			this.setState({
				signaturePadVisible: false,
				newSignature: []
			});
		}

		this.setState({
			dirty: true
		});
	}

	clearSignature() {
		this.setState({
			newSignature: []
		});
	}

	completeServiceCall() {
		const {
			serviceCall,
			workItems,
			techNotes,
			signingTechName,
			signingClientName,
			techSignature,
			clientSignature,
			client
		} = this.props;

		const errors = [];

		if (!signingTechName) {
			errors.push(i18n.t('serviceCalls.errors.signingTechNameRequired'));
		}

		if (!signingClientName) {
			errors.push(i18n.t('serviceCalls.errors.signingClientNameRequired'));
		}

		if (!techSignature || !techSignature.length) {
			errors.push(i18n.t('serviceCalls.errors.techSignatureRequired'));
		}

		if (!clientSignature || !clientSignature.length) {
			errors.push(i18n.t('serviceCalls.errors.clientSignatureRequired'));
		}

		if (errors.length) {
			this.setState({
				errors
			});

			this.props.ui.showError(errors);

			return;
		}

		this.setState({
			loading: true,
			errors: []
		});

		client.mutate({
			mutation: TECH_EDIT_SERVICE_CALL,
			variables: {
				id: serviceCall.id,
				workItems: workItems.map((wi) => {
					const actualStartTime = wi.actualStartTime ? moment(wi.actualStartTime).toDate() : moment(wi.startTime).toDate();
					const actualEndTime = wi.actualEndTime ? moment(wi.actualEndTime).toDate() : moment(wi.endTime).toDate();

					return {
						tech: wi.tech.id,
						startTime: moment(wi.startTime).toDate(),
						endTime: moment(wi.endTime).toDate(),
						actualStartTime,
						actualEndTime,
						breakTime: wi.breakTime,
						notes: wi.notes
					};
				}),
				techNotes,
				signingClientName,
				signingTechName,
				techSignature: JSON.stringify(techSignature),
				clientSignature: JSON.stringify(clientSignature)
			}
		}).then((res) => {
			this.setState({
				loading: false,
				snackbar: true
			});

			this.props.refetchQueries();
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false
			});
		});
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}
}

export default compose(
	withUI,
	withApollo,
	withUser,
	withStyles(styles)
)(SignoffTab);