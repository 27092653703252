import {
	Page,
	PageBody,
	Section,
	Snackbar,
	Notes,
	KeyStore,
	Grid
} from 'components';
import {
	Typography,
	IconButton,
	Divider
} from '@material-ui/core';
import PriorityIcon from '@material-ui/icons/PriorityHigh';
import ChatIcon from '@material-ui/icons/Chat';
import MapIcon from '@material-ui/icons/Map';
import NavigationIcon from '@material-ui/icons/Navigation';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import withStyles from 'utils/withStyles';
import { TICKET } from 'graph/queries';

const styles = {};

class Ticket extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tabIndex: 0
		};
	}

	render() {
		const { match } = this.props;

		return (
			<Page>
				<Query query={TICKET} variables={{ id: match.params.id }}>
				{({ loading, error, data }) => {
					const ticket = data && data.ticket ? data.ticket : null;

					return (
						<PageBody
							flex={this.state.tabIndex === 1 || this.state.tabIndex === 2}
							loading={loading}
							error={error}
							tabs={[
								i18n.t('tickets.ticket'),
								i18n.t('tickets.notes'),
								i18n.t('tickets.internalNotes')
							]}
							tabIndex={this.state.tabIndex}
							onChangeTab={(e, value) => this.setState({ tabIndex: value })}>
								{ticket && this.state.tabIndex === 0 && this.renderTicketTab(ticket)}
								{ticket && this.state.tabIndex === 1 && this.renderNotes(ticket)}
								{ticket && this.state.tabIndex === 2 && this.renderNotes(ticket, true)}
						</PageBody>
					);
				}}
				</Query>

				<Snackbar
					message={this.state.snackbar || ''}
					open={!!this.state.snackbar}
					onClose={this.handleCloseSnackbar} />
			</Page>
		)
	}

	renderTicketTab(ticket) {
		return (
			<Grid container spacing={16}>
				<Grid item xs={12} md={6}>
					{this.renderTicketDetails(ticket)}
				</Grid>
				<Grid item xs={12} md={6}>
					{this.renderProjectDetails(ticket)}
				</Grid>
			</Grid>
		)
	}

	renderTicketDetails(ticket) {
		const { classes } = this.props;

		return (
			<Section title={i18n.t('tickets.ticket')}>
				<div className={classes.flexRow}>
					<div>
						<PriorityIcon className={classes.icon} fontSize="large" />
					</div>
					<div className={classes.flexFill}>
						<Typography variant="subtitle2">{i18n.t('tickets.priority')}</Typography>
						<Typography>{ticket.priority}</Typography>
					</div>
				</div>

				<Divider className={classes.divider} />

				<div className={classes.flexRow}>
					<div>
						<ChatIcon className={classes.icon} fontSize="large" />
					</div>
					{/* <div className={classes.flexFill}>
						<Typography variant="subtitle2">{i18n.t('general.description')}</Typography>
						<Typography>{ticket.ticketDescription}</Typography>
					</div> */}
				</div>
			</Section>
		);
	}

	renderProjectDetails(ticket) {
		const { classes } = this.props;

		const project = ticket.project;

		const address = `${project.address.line1}${project.address.line2 ? ' ' + project.address.line2 : ''}, ${project.address.city}, ${project.address.postalCode}`

		return (
			<Section title={i18n.t('title.project')}>
				<div className={classes.flexRow}>
					<div>
						<StoreIcon className={classes.icon} fontSize="large" />
					</div>
					<div className={classes.flexFill}>
						<Typography variant="subtitle2">{i18n.t('projects.projectName')}</Typography>
						<Link to={{
							pathname: `/projects/${ticket.project.id}`,
							state: { title: ticket.project.name }
						}}><Typography>{ticket.project.name}</Typography></Link>
					</div>
				</div>

				<Divider className={classes.divider} />

				<div className={classes.flexRow}>
					<MapIcon className={classes.icon} />

					<div className={classes.flexFill}>
						<Typography variant="subtitle2">{i18n.t('general.address')}</Typography>
						<Typography>
							{address}
						</Typography>
					</div>

					<div style={{ textAlign: 'center' }}>
						<a href={`https://www.google.com/maps/dir/?api=1&destination=${escape(address)}`} target="_blank">
							<IconButton color="primary">
								<NavigationIcon />
							</IconButton>
						</a>
						<Typography variant="caption">Directions</Typography>
					</div>
				</div>

				{
					project.clients.map((client, i) => (
						<React.Fragment key={i}>
							<Divider className={classes.divider} />

							<div className={classes.flexRow}>
								<PeopleIcon className={classes.icon} />

								<div className={classes.flexFill}>
									<Typography variant="subtitle2">{i18n.t('general.client')}</Typography>
									<Typography>
										{`${client.firstName} ${client.lastName}`}
									</Typography>
								</div>
							</div>
						</React.Fragment>
					))
				}
			</Section>
		)
	}

	renderNotes(ticket, isInternal) {
		return (
			<Notes parentId={ticket.id} schemaType="ticket" isInternal={!!isInternal} />
		);
	}

	renderKeyStore(project) {
		return (
			<KeyStore projectId={project.id} />
		);
	}
}

export default withStyles(styles)(Ticket);