import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel,
	RadioGroup,
	Radio,
	Checkbox,
	IconButton,
	Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import { Query, withApollo, compose } from 'react-apollo';
import { Prompt } from 'react-router-dom';
import {
	Page,
	PageHeader,
	PageBody,
	TextField,
	Section,
	Banner,
	Snackbar,
	Button,
	SearchBar,
	Chip,
	Confirm,
	Notes,
	Grid,
	Descriptions
} from 'components';
import TicketServiceCalls from './TicketServiceCalls';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import withQuery from 'utils/withQuery';
import withUI from 'utils/withUI';
import withStyles from 'utils/withStyles'
import getGraphQLError from 'utils/getGraphQLError';
import { SEARCH_PROJECTS, TICKET, TAGS } from 'graph/queries';
import { CREATE_TICKET, EDIT_TICKET, CREATE_TAG } from 'graph/mutations';
import withUser from 'utils/withUser';
import moment from 'moment';


const styles = () => ({

});

class _Ticket extends Component {

	constructor(props) {
		super(props);

		this.ticket = props.queryResult && props.queryResult.data && props.queryResult.data.ticket || {}

		const { type, __typename, ...ticketFields } = this.ticket

		this.state = {
			admin: ticketFields.admin || null,
			project: ticketFields.project || null,
			ticketClient: ticketFields.client ? ticketFields.client.id : '',
			timeEstimate: ticketFields.timeEstimate || 0,
			descriptions: ticketFields.descriptions || [],
			status: ticketFields.status || 'opened',
			priority: ticketFields.priority || 'medium',
			tags: ticketFields.tags || [],
			newTagEN: '',
			newTagFR: '',
			projectSearchString: ticketFields.project ? ticketFields.project.name : '',
			tagSearchString: '',
			loading: false,
			showClientList: !!ticketFields.project,
			open: false,
			errors: [],
			open: false,
			dirty: false,
			tagModalVisible: false,
			tabIndex: 0,
			warrantyExpiration: ticketFields.project && ticketFields.project.warrantyExpiration ? ticketFields.project.warrantyExpiration : null,
			warrantyStart: ticketFields.project && ticketFields.project.warrantyExpiration ? ticketFields.project.warrantyStart : null,
			ticketDescriptions: ticketFields.ticketDescriptions || []
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.saveTicket = this.saveTicket.bind(this);
		this.onSearchProjects = this.onSearchProjects.bind(this);
		this.onSelectProject = this.onSelectProject.bind(this);
		this.getBannerErrorMessage = this.getBannerErrorMessage.bind(this);
		this.onDropDownChanged = this.onDropDownChanged.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.saveTag = this.saveTag.bind(this);
		this.setTicketDescriptions = this.setTicketDescriptions.bind(this);
		this.checkWarranty = this.checkWarranty.bind(this)
	}

	render() {
		const { classes, currentUser } = this.props;

		return (
			<Page>
				<PageHeader>
					{
						this.ticket && this.ticket.id && !this.ticket.invoice && currentUser.hasPermission('invoices', 0) ?
						<Button
							key={2}
							color="primary"
							variant="contained"
							icon={AddIcon}
							onClick={() => this.props.history.push('/invoices/create', {
								invoiceTicketId: this.ticket.id
							})}
						>{i18n.t('invoices.invoice')}</Button>
						: null
					}
					
					{
						this.ticket && this.ticket.id ?
						<Button
							key={0}
							color="primary"
							variant="contained"
							icon={AddIcon}
							onClick={() => this.props.history.push('/serviceCalls/create', {
								title: i18n.t('title.serviceCall'),
								ticketId: this.ticket.id
							})}
						>{i18n.t('serviceCalls.serviceCall')}</Button>
						: null
					}

					<Button
						key={1}
						color="primary"
						variant="contained"
						icon={SaveIcon}
						loading={this.state.loading}
						disabled={!this.state.dirty}
						onClick={this.saveTicket}
					>{i18n.t('general.save')}</Button>
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<PageBody
					flex={this.state.tabIndex === 1 || this.state.tabIndex === 2}
					tabs={this.ticket && this.ticket.id ? [
						i18n.t('tickets.ticket'),
						i18n.t('tickets.descriptions'),
						i18n.t('tickets.notes'),
						i18n.t('tickets.internalNotes'),
						i18n.t('tickets.serviceCalls')
					] : null}
					tabIndex={this.state.tabIndex}
					onChangeTab={(e, value) => this.setState({ tabIndex: value })}>
						{this.state.tabIndex === 0 && this.renderTicketTab()}
						{this.state.tabIndex === 1 && this.renderDescriptionTab()}
						{this.state.tabIndex === 2 && this.renderNotes()}
						{this.state.tabIndex === 3 && this.renderNotes(true)}
						{this.state.tabIndex === 4 && this.renderServiceCallsTab()}
						
				</PageBody>

				<Snackbar
					message={i18n.t('admins.editAdminComplete')}
					open={this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />

				<Confirm
					title={i18n.t('tickets.newTag')}
					body={
						<div>
							<TextField
								fullWidth
								required
								autoComplete="off"
								label="English"
								value={this.state.newTagEN}
								className={classes.textField}
								onChange={(e) => this.setState({ newTagEN: e.target.value })} />

							<TextField
								fullWidth
								required
								autoComplete="off"
								label={"Français"}
								value={this.state.newTagFR}
								className={classes.textField}
								onChange={(e) => this.setState({ newTagFR: e.target.value })} />
						</div>
					}
					open={this.state.tagModalVisible}
					confirmButtonText={i18n.t('general.save')}
					onConfirm={() => this.saveTag('markPaid')}
					onCancel={() => this.setState({ tagModalVisible: false })} />
			</Page>
		);
	}

	renderTicketTab() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Grid container spacing={16}>
					<Grid item xs={12} md={6}>
						<Section title={i18n.t('tickets.ticketDetails')}>
							<div className={classes.flexRow} style={{ alignItems: 'flex-end' }}>
								<FormControl className={classes.formControl}>
									<InputLabel>{i18n.t('tickets.status') + '*'}</InputLabel>
									<Select value={this.state.status}
										required
										error={(!!this.state.errors.length && !this.state.status)}
										onChange={(e) => this.onDropDownChanged(e.target.value, 'status')}
										style={{ width: 240, marginRight: 16 }}
										className={classes.textField}>
										<MenuItem value={'opened'}>{i18n.t('tickets.states.opened')}</MenuItem>
										<MenuItem value={'closed'}>{i18n.t('tickets.states.closed')}</MenuItem>
									</Select>
								</FormControl>

								<FormControl className={classes.formControl}>
									<InputLabel>{i18n.t('tickets.priority') + '*'}</InputLabel>
									<Select value={this.state.priority}
										required
										error={(!!this.state.errors.length && !this.state.priority)}
										onChange={(e) => this.onDropDownChanged(e.target.value, 'priority')}
										style={{ width: 240, marginRight: 16 }}
										className={classes.textField}>
										<MenuItem value={'low'}>{i18n.t('tickets.priorityStates.low')}</MenuItem>
										<MenuItem value={'medium'}>{i18n.t('tickets.priorityStates.medium')}</MenuItem>
										<MenuItem value={'high'}>{i18n.t('tickets.priorityStates.high')}</MenuItem>
										<MenuItem value={'urgent'}>{i18n.t('tickets.priorityStates.urgent')}</MenuItem>
									</Select>
								</FormControl>

								<TextField
									required
									label={i18n.t('tickets.timeEstimate')}
									value={this.state.timeEstimate}
									style={{ width: 170 }}
									onChange={(e) => this.setState({ timeEstimate: e.target.value, dirty: true })}
									type="number"
									error={!!this.state.errors.length && (!this.state.timeEstimate) || !Number.isInteger(this.state.timeEstimate / 0.25)}
									helperText={(!!this.state.errors.length && !this.state.timeEstimate) && i18n.t('general.required')}
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{ min: 0, max: 99, step: 0.25 }}
									margin="normal"
								/>
							</div>

							<div className={classes.flexRow}>
								<FormControl style={{ width: 300 }}>
									<InputLabel>{i18n.t('tickets.tags')}</InputLabel>
									<Query query={TAGS}>
										{({ data, loading, error }) => {
											if (loading) {
												return null;
											}

											if (error) {
												return null;
											}

											return (
												<Select
													className={classes.textField}
													fullWidth
													value={''}
													onChange={(e) => this.onTagSelected(e)}>
													{
														data.tags.tags.filter((tag) => {
															return !_find(this.state.tags, { id: tag.id });
														}).map((tag, i) => {
															return <MenuItem key={i} value={tag}>{tag.en}</MenuItem>
														})
													}
												</Select>
											)
										}}
									</Query>
								</FormControl>

								<IconButton size="small" onClick={() => this.setState({ tagModalVisible: true })}>
									<AddIcon />
								</IconButton>
							</div>

							<div>
								{
									this.state.tags && this.state.tags.length ?
										this.state.tags.map((tag, i) => {
											return <Chip key={i} label={tag.en} onDelete={(e) => this.onTagDeleted(i)}></Chip>
										})
										: null
								}
							</div>
						</Section>
					</Grid>
					<Grid item xs={12} md={6}>
						<Section title={i18n.t('tickets.project')}>
								
							{
								(this.ticket && this.ticket.id) ?
								null
								:
								<SearchBar
									value={this.state.projectSearchString}
									label={i18n.t('tickets.project')}
									required
									query={SEARCH_PROJECTS}
									getQueryResultItemLabel={(item) => item.name}
									error={!!this.state.errors.length && !this.state.projectSearchString.length}
									onSelect={(e) => this.onSelectProject(e)}
									onChange={(value) => this.setState({ projectSearchString: value, showClientList: false })}
								/>
							}

							{
								(this.state.project) ?
								<div>
									<Typography variant="subtitle2">{i18n.t('projects.projectName')}</Typography>
									<Typography>{this.state.project.name}</Typography>
									<br />

									<Typography variant="subtitle2">{i18n.t('general.address')}</Typography>
									<Typography>{this.state.project.address.line1}{this.state.project.address.line2 ? `, ${this.state.project.address.line2}` : ''}</Typography>
									<Typography>{this.state.project.address.city}, {this.state.project.address.province}</Typography>
									<Typography>{this.state.project.address.postalCode}</Typography>
									<br />

									
									<Typography variant="subtitle2">{i18n.t('projects.warranty')}</Typography>
									{
										this.checkWarranty()
									}	
									<br />

									<Typography variant="subtitle2">{i18n.t('general.client', { count: 2 })}</Typography>
								</div>
								: null
							}

							{
								(this.state.showClientList && this.state.project) ?
								<FormControl component="fieldset" className={classes.formControl}>
									<RadioGroup
										aria-label="Clients"
										name="clients"
										className={classes.group}
										value={this.state.ticketClient}
										onChange={(e) => this.setState({ ticketClient: e.target.value, dirty: true })}>
										{
											this.state.project.clients.map((client, i) => (
												<FormControlLabel
													key={i}
													value={client.id}
													control={<Radio />}
													label={client.firstName + ' ' + client.lastName} />
											))
										}
									</RadioGroup>
								</FormControl>
								: null
							}
						</Section>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}

	renderDescriptionTab() {
		return(
			<Descriptions currentUser={this.props.currentUser} ticketId={this.ticket.id} />
		)
	}


	renderNotes(isInternal) {
		return (
			<Notes parentId={this.ticket.id} schemaType={'ticket'} isInternal={!!isInternal} />
		);
	}

	renderServiceCallsTab() {
		return <TicketServiceCalls ticketId={this.ticket.id} />
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}

	checkWarranty (){
		const now = moment()
		if(this.state.project.warrantyExpiration || this.state.project.warrantyStart){ 
			if (!(now < moment(this.state.project.warrantyStart))) {
				if (!(now > moment(this.state.project.warrantyExpiration))) {
					return <Typography style={{ color: "green" }}>{i18n.t('Under Warranty')}</Typography>
				} else {
					return  <Typography style={{ color: "red" }}>{i18n.t('Warranty Expired')}</Typography>
				}
			} else {
				return <Typography>{`${i18n.t('Activation Time :')} ${moment(this.state.project.warrantyStart)}`}</Typography>
			}
		} else {
			return <Typography>{i18n.t('No Warranty Information')}</Typography>
		}
	}

	setTicketDescriptions (field, value, index) {
		const { ticketDescriptions } = this.state;

		this.setState({
			ticketDescriptions: [
				...ticketDescriptions.slice(0, index),
				{
					...ticketDescriptions[index],
					[field]: value
				},
				...ticketDescriptions.slice(index + 1)
			],
			dirty: true
		});
	}
	
	removeTicketDescriptions (index) {
		this.setState({
			ticketDescriptions: [
				...this.state.ticketDescriptions.slice(0, index),
				...this.state.ticketDescriptions.slice(index + 1),
			],
			dirty: true
		});
	}

	saveTicket() {
		const { client } = this.props;

		const {
			ticketClient,
			project,
			timeEstimate,
			tags,
			status,
			priority,
		} = this.state;

		var errors = []
		var tagsID = []


		if (!status) errors.push(i18n.t('tickets.errors.statusRequired'));
		if (!project) errors.push(i18n.t('tickets.errors.projectRequired'));
		if (!ticketClient) errors.push(i18n.t('tickets.errors.clientRequired'));
		if (!priority) errors.push(i18n.t('tickets.errors.priorityRequired'));
		if (!timeEstimate) errors.push(i18n.t('tickets.errors.timeEstimateRequired'))

		if (errors.length) {
			this.setState({
				errors
			});
			return;
		}
		if (tags && tags.length) {
			tags.map((tag) => {
				tagsID.push(tag.id)
			})
		}

		this.setState({
			loading: true,
		});

		let mutation = this.ticket && this.ticket.id ? EDIT_TICKET : CREATE_TICKET;

		client.mutate({
			mutation,
			variables: {
				id: this.ticket ? this.ticket.id : '',
				status,
				descriptions: [],
				tags: tagsID,
				project: project.id,
				client: ticketClient,
				timeEstimate: Number.parseFloat(timeEstimate),
				priority,
			}
		}).then((res) => {
			if (this.ticket && this.ticket.id) {
				this.setState({
					loading: false,
					dirty: false,
					snackbar: true
				});
			} else {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/tickets/' + res.data.createTicket.id, {
						title: `Ticket #${res.data.createTicket.workOrder}`
					});
				});
			}
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false
			});
		});
	}

	handleInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
			dirty: true
		})
	}

	onSearchProjects(e) {
		const value = e.target.value;

		this.setState({
			anchorEl: e.currentTarget
		});

		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				projectSearch: value,
				open: value.length > 1
			});
		}, 500);
	}

	onSelectProject(e) {
		this.setState({
			project: e,
			projectSearchString: e.name,
			showClientList: true,
			dirty: true
		});
	}

	onTagSelected(e) {
		var flag = false
		if (this.state.tags && this.state.tags.length) {
			for (var tag of this.state.tags) {
				tag === e.target.value ? flag = true : null
			}
		}

		if (!flag) {
			this.setState({
				tags: [
					...this.state.tags,
					e.target.value
				],
				dirty: true
			})
		}
	}

	onTagDeleted(i) {
		this.setState({
			tags: [
				...this.state.tags.slice(0, i),
				...this.state.tags.slice(i + 1)
			],
			dirty: true

		})
	}

	saveTag() {
		const { client } = this.props;

		if (this.state.newTagEN && this.state.newTagFR) {
			client.mutate({
				mutation: CREATE_TAG,
				variables: {
					en: this.state.newTagEN,
					fr: this.state.newTagFR
				},
				refetchQueries: [{
					query: TAGS
				}]
			}).then(() => {
				this.setState({
					tagModalVisible: false,
					newTagEN: '',
					newTagFR: ''
				});
			}).catch((e) => {
				this.props.ui.showError(getGraphQLError(e));
				this.setState({
					loading: false
				});
			});
		}
	}

	onDropDownChanged(value, action) {
		this.setState({
			[action]: value,
			dirty: true
		});
	}

	handleCloseSnackbar(reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	formatNoteLabel(note) {
		const date = moment(note.date)
		const str = `${date.format('YYYY-MM-DD hh:mm A')} - ${note.by.firstName} ${note.by.lastName}`;
		return str
	}

	compare(a, b) {
		const aDate = new Date(a.date)
		const bDate = new Date(b.date)
		if (aDate < bDate) {
			return 1;
		}
		if (aDate > bDate) {
			return -1;
		}
		return 0;
	}
}

const Ticket = compose(
	withUI,
	withApollo,
	withQuery,
	withUser,
	withStyles(styles)
)(_Ticket);

export default class TicketContainer extends Component {
	render() {
		const { match } = this.props;

		if (match.params && match.params.id) {
			return (
				<Ticket query={TICKET} variables={{ id: match.params.id }} {...this.props} />
			);
		} else {
			return (
				<Ticket {...this.props} />
			);
		}

	}
}