import gql from 'graphql-tag';
import { invoice, invoices } from 'graph/types';

export const INVOICE = gql`
	query invoice($id: ID!) {
		invoice(id: $id) {
			${invoice}
		}
	}
`;

export const PUBLIC_INVOICE = gql`
	query publicInvoice($publicId: String!) {
		publicInvoice(publicId: $publicId) {
			publicId
			state
			number
			representative
			terms
			description
			deliveredAt
			items {
				description
				quantity
				rate
			}
		}
	}
`;

export const INVOICES = gql`
	query invoices(
		$from: Date
		$to: Date
		$searchString: String
		$state: String
		$project: String
		$client: String
		$sort: String
		$direction: SortDirection
		$limit: Int
		$page: Int
	) {
		invoices(
			from: $from
			to: $to
			searchString: $searchString
			state: $state
			project: $project
			client: $client
			sort: $sort
			direction: $direction
			limit: $limit
			page: $page
		) {
			count
			invoices {
				${invoices}
			}
		}
	}
`;

export const GET_INVOICE_NUMBER = gql`
	query getInvoiceNumber {
		getInvoiceNumber
	}
`;