import PropTypes from 'prop-types';
import {
	TextField,
	Popper,
	Paper,
	List,
	ListItem,
	Typography,
	CircularProgress,
	//withStyles
} from '@material-ui/core';
import withStyles from 'utils/withStyles';
import { Query } from 'react-apollo';

const styles = (theme) => ({
	popperPaper: {
		width: '100%',
		minWidth: 200
	}
});

class SearchBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchParameter: '',
			popperOpen: false,
			anchorEl: null
		};

		this.onChangeText = this.onChangeText.bind(this);
		this.closePopper = this.closePopper.bind(this);
		this.openPopper = this.openPopper.bind(this);
	}

	render() {
		const { error, variant, value, required, label, placement, query, queryParamKey, getQueryResultItemLabel, classes } = this.props;

		const operation = query.definitions[0].name.value;

		return (
			<React.Fragment>
				<TextField
					className={classes.textField}
					variant={variant}
					fullWidth
					required={required}
					autoComplete="off"
					label={label}
					value={value}
					error={error}
					onFocus={this.openPopper}
					onBlur={this.closePopper}
					onChange={this.onChangeText} />

				<Popper
					style={{ zIndex: 1500 }}
					placement={placement}
					open={this.state.popperOpen}
					anchorEl={this.state.anchorEl}>
						
					<Paper elevation={8} className={classes.popperPaper}>
						<Query query={query} fetchPolicy="network-only" variables={{
							[queryParamKey]: this.state.searchParameter
						}}>
						{({ loading, error, data }) => (
							<List>
								{
									loading ?
									<ListItem><CircularProgress size={24} style={{ margin: 'auto' }} /></ListItem>
									:
									data && data[operation] && data[operation] && data[operation].length ?
									data[operation].map((item, i) => (
										<ListItem button key={i} onMouseDown={(e) => this.onSelectItem(item)} onTouchStart={(e) => this.onSelectItem(item)}>
											<Typography>{getQueryResultItemLabel(item)}</Typography>
										</ListItem>
									))
									:
									<ListItem><Typography variant="caption">{i18n.t('general.noResults')}</Typography></ListItem>
								}
							</List>
						)}
						</Query>
					</Paper>
				</Popper>
			</React.Fragment>
		)
	}

	onChangeText(e) {
		const { onChange } = this.props;

		const value = e.target.value;
		
		this.setState({
			anchorEl: e.currentTarget
		});

		if (onChange && typeof onChange === 'function') {
			onChange(e.target.value);
		}

		clearTimeout(this.debounce);

		this.debounce = setTimeout(() => {
			this.setState({
				searchParameter: value,
				popperOpen: value.length > 1
			});
		}, 500);
	}

	closePopper(e) {
		this.setState({ popperOpen: false });
	}

	openPopper(e) {
		if (this.state.searchParameter.length > 1) {
			this.setState({ popperOpen: true })
		}
	}

	onSelectItem(item) {
		const { onSelect } = this.props;

		this.setState({
			popperOpen: false
		});

		if (onSelect && typeof onSelect === 'function') {
			onSelect(item);
		}
	}
}

SearchBar.propTypes = {
	value: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	placement: PropTypes.oneOf([
		'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top'
	]),
	query: PropTypes.object.isRequired,
	queryParamKey: PropTypes.string,
	getQueryResultItemLabel: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	onSelect: PropTypes.func
}

SearchBar.defaultProps = {
	placement: 'bottom-start',
	queryParamKey: 'searchString'
}

export default withStyles(styles)(SearchBar);