import {
	Typography,
	Divider,
	Select,
	MenuItem,
	Paper,
	IconButton
} from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import PeopleIcon from '@material-ui/icons/People';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import ChatIcon from '@material-ui/icons/Chat';
import AVTimerIcon from '@material-ui/icons/AvTimer';
import PriorityIcon from '@material-ui/icons/PriorityHigh';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateMomentUtils from '@date-io/moment';
import moment from 'moment';
import { Link, Prompt } from 'react-router-dom';
import _find from 'lodash/find';
import {
	Page,
	PageHeader,
	PageBody,
	Section,
	Button,
	SearchBar,
	Snackbar,
	TechCard,
	TextField,
	Banner,
	SignaturePad,
	Grid,
	Confirm
} from 'components';
import { withApollo, compose } from 'react-apollo';
import { withQueries } from 'utils/withQuery';
import withUI from 'utils/withUI';
import withStyles from 'utils/withStyles'
import getGraphQLError from 'utils/getGraphQLError';
import { SERVICE_CALL, TICKET_SERVICE_CALLS, TICKET, SEARCH_TECHS } from 'graph/queries';
import { ADMIN_CREATE_SERVICE_CALL, ADMIN_EDIT_SERVICE_CALL } from 'graph/mutations';
import { LENGTH_MAX_STRING } from 'utils/constants';

const styles = (theme) => ({
	detailItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: 150,
		margin: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px 0 0`
	}
});

class ServiceCall extends Component {
	constructor(props) {
		super(props);

		const { serviceCall, ticket } = props.queryResult;

		if (serviceCall) {
			this.serviceCall = serviceCall.serviceCall;
			this.ticket = serviceCall.serviceCall.ticket;

			this.state = {
				date: this.serviceCall.date,
				status: this.serviceCall.status,
				techSearchString: '',
				techNotes: this.serviceCall.techNotes || '',
				workItems: this.serviceCall.workItems,
				equipmentUsed: this.serviceCall.equipmentUsed,
				errors: [],
				dirty: false,
				snackbar: false,
				closeTicketModalOpen: false
			};
		} else {
			this.ticket = ticket.ticket;

			this.state = {
				date: new Date(),
				status: 'open',
				techSearchString: '',
				techNotes: '',
				workItems: [],
				equipmentUsed: [],
				errors: [],
				dirty: false,
				snackbar: false,
				closeTicketModalOpen: false
			};
		}

		this.saveServiceCall = this.saveServiceCall.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.getBannerErrorMessage = this.getBannerErrorMessage.bind(this);
		this.formatedDescriptionCategories = this.formatedDescriptionCategories.bind(this)
	}

	render() {
		return (
			<Page>
				<PageHeader>
					<Button
						key={1}
						color="primary"
						variant="contained"
						icon={SaveIcon}
						loading={this.state.loading}
						disabled={!this.state.dirty}
						onClick={() => this.saveServiceCall()}
					>{i18n.t('general.save')}</Button>
				</PageHeader>

				<Banner
					message={this.getBannerErrorMessage()}
					visible={!!this.state.errors.length}
					icon={<ErrorIcon />}
					actions={[{
						label: i18n.t('general.okay'),
						onClick: () => this.setState({ errors: [] })
					}]} />

				<MuiPickersUtilsProvider utils={DateMomentUtils}>
					<PageBody>
						{this.renderServiceCallTab()}
					</PageBody>
				</MuiPickersUtilsProvider>

				<Snackbar
					message={i18n.t('admins.editAdminComplete')}
					open={this.state.snackbar}
					onClose={this.handleCloseSnackbar} />

				<Prompt when={this.state.dirty} message={i18n.t('general.unsavedChangesPrompt')} />
			</Page>
		);
	}

	renderServiceCallTab() {
		return (
			<Grid container spacing={16}>
				<Grid item xs={12} lg={4}>
					{this.renderServiceCallDetails()}
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					{this.renderTicketDetails()}
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					{this.renderProjectDetails()}
				</Grid>
				<Grid item xs={12}>
					{this.renderWorkItems()}
				</Grid>
				<Grid item xs={12}>
					{this.renderEquipmentUsed()}
				</Grid>
				<Grid item xs={12}>
					{this.renderNotes()}
				</Grid>

				<Confirm
					title={i18n.t('serviceCalls.closeAssociatedTicketTitle')}
					body={i18n.t('serviceCalls.closeAssociatedTicketBody')}
					open= {this.state.closeTicketModalOpen}
					confirmButtonText={i18n.t('general.yes')}
					onConfirm={() => this.saveServiceCall(true)}
					additionalButtons={[{ onClick: () => this.saveServiceCall(false), text: i18n.t('general.no')}]}
					onCancel={() => this.setState({ closeTicketModalOpen: false })}
					cancelButtonText={i18n.t('general.cancel')} />
			</Grid>
		);
	}

	renderServiceCallDetails() {
		const { classes } = this.props;

		let tsig = [];
		if (this.serviceCall && this.serviceCall.techSignature && this.serviceCall.techSignature.length) {
			tsig = JSON.parse(this.serviceCall.techSignature);
		}

		let csig = [];
		if (this.serviceCall && this.serviceCall.clientSignature && this.serviceCall.clientSignature.length) {
			csig = JSON.parse(this.serviceCall.clientSignature);
		}

		return (
			<Section title={i18n.t('title.serviceCall')}>
				<Select value={this.state.status}
					fullWidth
					error={(!!this.state.errors.length && !this.state.status)}
					onChange={(e) => this.setState({ status: e.target.value, dirty: true })}
					style={{ marginBottom: 32 }}>
					<MenuItem value={'open'}>{i18n.t('serviceCalls.states.open')}</MenuItem>
					<MenuItem value={'booked'}>{i18n.t('serviceCalls.states.booked')}</MenuItem>
					<MenuItem value={'complete'}>{i18n.t('serviceCalls.states.complete')}</MenuItem>
					<MenuItem value={'approved'}>{i18n.t('serviceCalls.states.approved')}</MenuItem>
				</Select>
				
				<DatePicker
					format="YYYY-MM-DD"
					fullWidth
					variant="outlined"
					required
					label={i18n.t('general.date')}
					value={this.state.date}
					style={{ marginBottom: 16 }}
					onChange={(e) => this.setState({ date: e.toDate(), dirty: true })} />

				{
					this.serviceCall && (this.serviceCall.status === 'complete' || this.serviceCall.status === 'approved') ?
					<Grid container spacing={16}>
						<Grid item xs={6} lg={12}>
							<TextField
								fullWidth
								readOnly
								value={this.serviceCall.signingTechName? this.serviceCall.signingTechName : ''}
								label={i18n.t('serviceCalls.signingTechName')} />

							<div id="techSignatureReadOnly" style={{ marginTop: 8, position: 'relative' }}>
								<SignaturePad readonly path={tsig} parentId="techSignatureReadOnly" />
							</div>
						</Grid>

						<Grid item xs={6} lg={12}>
							<TextField
								fullWidth
								readOnly
								value={this.serviceCall.signingClientName? this.serviceCall.signingClientName : ''}
								label={i18n.t('serviceCalls.signingClientName')} />

							<div id="clientSignatureReadOnly" style={{ marginTop: 8, position: 'relative' }}>
								<SignaturePad readonly path={csig} parentId="clientSignatureReadOnly" />
							</div>
						</Grid>
					</Grid>
					: null
				}
			</Section>
		);
	}

	renderTicketDetails() {
		const { classes } = this.props;
		const ticket = this.ticket;

		return (
			<Section title={i18n.t('tickets.ticket')}>
				<Grid container spacing={16}>
					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<ConfirmationNumberIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('tickets.workOrder')}</Typography>
								<Link to={`/tickets/${ticket.id}`}><Typography>#{ticket.workOrder}</Typography></Link>
							</div>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<ContactSupportIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('tickets.status')}</Typography>
								<Typography>{ticket.status}</Typography>
							</div>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<PriorityIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('tickets.priority')}</Typography>
								<Typography>{`${ticket.priority}`}</Typography>
							</div>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<AVTimerIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('tickets.timeEstimate')}</Typography>
								<Typography>{`${ticket.timeEstimate}`}</Typography>
							</div>
						</div>
					</Grid>
				</Grid>

				<Divider className={classes.divider} />

				<div className={classes.detailItem}>
					<div>
						<ChatIcon className={classes.icon} fontSize="large" />
					</div>
					<div>
						<Typography variant="subtitle2">{i18n.t('tickets.categories')}</Typography>
						<Typography>{this.formatedDescriptionCategories(ticket)}</Typography>
					</div>
				</div>
			</Section>
		);
	}

	renderProjectDetails() {
		const { classes } = this.props;
		const ticket = this.ticket;
		const project = ticket.project;

		return (
			<Section title={i18n.t('tickets.project')}>
				<Grid container spacing={16}>
					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<StoreIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('tickets.project')}</Typography>
								<Link to={`/projects/${project.id}`}><Typography>{project.name}</Typography></Link>
							</div>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div className={classes.detailItem}>
							<div>
								<PeopleIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('general.client')}</Typography>
								<Link to={`/clients/${ticket.client.id}`}><Typography>{`${ticket.client.firstName} ${ticket.client.lastName}`}</Typography></Link>
							</div>
						</div>
					</Grid>

					<Grid item xs={12}>
						<div className={classes.detailItem}>
							<div>
								<MapIcon className={classes.icon} fontSize="large" />
							</div>
							<div>
								<Typography variant="subtitle2">{i18n.t('general.address')}</Typography>
								<Typography>{`${project.address.line1}, ${project.address.line2 ? project.address.line2 + ', ' : ''}${project.address.city}, ${project.address.postalCode}`}</Typography>
							</div>
						</div>
					</Grid>
				</Grid>

				{
					ticket.client.phones && ticket.client.phones.length ?
						<React.Fragment>
							<Divider className={classes.divider} />

							<Grid container spacing={16}>
								{
									ticket.client.phones.map((p, i) => {
										return (
											<Grid item xs={6} key={i}>
												<div className={classes.detailItem}>
													<div>
														<PhoneIcon className={classes.icon} fontSize="large" />
													</div>
													<div>
														<Typography variant="subtitle2">{p.label}</Typography>
														<Typography>{p.number}</Typography>
													</div>
												</div>
											</Grid>
										)
									})
								}
							</Grid>
						</React.Fragment>
						: null
				}
			</Section>
		);
	}

	renderNotes() {
		const { classes } = this.props;

		if (this.serviceCall && (this.serviceCall.status === 'complete' || this.serviceCall.status === 'approved')) {
			return (
				<Section title={i18n.t('tickets.notes')}>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								variant="outlined"
								readOnly
								multiline
								rows={8}
								rowsMax={8}
								autoComplete="off"
								label={i18n.t('serviceCalls.techNotes')}
								value={this.state.techNotes} />
						</Grid>
					</Grid>
				</Section>
			);
		}

		return null;
	}

	renderWorkItems() {
		const { classes } = this.props;

		return (
			<Section title={i18n.t('serviceCalls.workItems')}>
				<SearchBar
					value={this.state.techSearchString}
					label={i18n.t('title.techs')}
					required
					query={SEARCH_TECHS}
					getQueryResultItemLabel={(tech) => `${tech.firstName} ${tech.lastName} (${tech.email})`}
					onSelect={(e) => this.onSelectTech(e)}
					onChange={(value) => this.setState({ techSearchString: value })}
				/>

				<Grid container spacing={16}>
					{
						this.state.workItems.map((item, i) => {
							const { tech, startTime, endTime } = item;

							return (
								<Grid key={i} item xs={12} sm={6} md={4}>
									<TechCard tech={tech} showEmail={false} showPhones={false} onRemove={() => this.onRemoveTech(i)}>
										<TimePicker
											format="HH:mm"
											fullWidth
											variant="outlined"
											required
											minutesStep={5}
											ampm={false}
											label={i18n.t('serviceCalls.startTime')}
											value={startTime}
											className={classes.textField}
											onChange={(e) => this.setWorkItemTime('startTime', e, i)} />

										<TimePicker
											format="HH:mm"
											fullWidth
											variant="outlined"
											required
											minutesStep={5}
											ampm={false}
											label={i18n.t('serviceCalls.endTime')}
											value={endTime}
											className={classes.textField}
											onChange={(e) => this.setWorkItemTime('endTime', e, i)} />

										{
											(item.notes) ?
												<div>
													<Typography variant="subtitle2">{i18n.t('tickets.notes')}</Typography>
													<Typography>{item.notes}</Typography>
												</div>
												: null
										}

										{
											this.serviceCall && (this.serviceCall.status === 'complete' || this.serviceCall.status === 'approved') ?
											<div>
												<div>
													<Typography variant="subtitle2">{i18n.t('serviceCalls.actualStartTime')}</Typography>
													<Typography>{moment(item.actualStartTime).format(i18n.t('formats.time'))}</Typography>
												</div>

												<Divider className={classes.divider} />

												<div>
													<Typography variant="subtitle2">{i18n.t('serviceCalls.actualEndTime')}</Typography>
													<Typography>{moment(item.actualEndTime).format(i18n.t('formats.time'))}</Typography>
												</div>

												<Divider className={classes.divider} />

												<div>
													<Typography variant="subtitle2">{i18n.t('serviceCalls.breakTime')}</Typography>
													<Typography>{item.breakTime}</Typography>
												</div>
											</div>
											: null
										}
									</TechCard>
								</Grid>
							);
						})
					}
				</Grid>

				{
					this.serviceCall && (this.serviceCall.status === 'complete' || this.serviceCall.status === 'approved') ?
					<div style={{ marginTop: 16 }}>
						<Typography variant="subtitle2">{i18n.t('serviceCalls.totalHours')}</Typography>
						<Typography>{this.serviceCall.totalHours}</Typography>
					</div>
					: null
				}
			</Section>
		);
	}

	renderEquipmentUsed() {
		const { classes } = this.props;

		return (
			<Section title={i18n.t('serviceCalls.equipmentUsed')} buttons={[
				<Button
					key={1}
					color="primary"
					variant="contained"
					icon={AddIcon}
					onClick={() => this.addEquipmentUsed()}
				>{i18n.t('general.add')}</Button>
			]}>
				<Grid container spacing={16}>
					{
						this.state.equipmentUsed.map((item, i) => {
							const { name, price, quantity, notes } = item;

							return (
								<Grid key={i} item xs={12} sm={6} md={4}>
									<Paper className={classes.card}>
										<div className={classes.flexRow}>
											<TextField
												required
												autoComplete="off"
												label={i18n.t('general.name')}
												value={name}
												className={classes.textField}
												style={{ flex: 1 }}
												onChange={(e) => this.setEquipmentUsed('name', e.target.value, i)} />

											<IconButton
												onClick={() => this.removeEquipmentUsed(i)}>
												<CloseIcon fontSize="small" />
											</IconButton>
										</div>

										<TextField
											fullWidth
											type="number"
											required
											autoComplete="off"
											label={i18n.t('serviceCalls.price')}
											value={price}
											className={classes.textField}
											onChange={(e) => this.setEquipmentUsed('price', e.target.value, i)}
											onBlur={(e) => this.validateEquipmentUsedPrice(i, e.target.value)} />

										<TextField
											fullWidth
											type="number"
											required
											autoComplete="off"
											label={i18n.t('tickets.quantity')}
											value={quantity}
											className={classes.textField}
											onChange={(e) => this.setEquipmentUsed('quantity', e.target.value, i)}
											onBlur={(e) => this.validateEquipmentUsedQuantity(i, e.target.value)} />

										<TextField
											fullWidth
											autoComplete="off"
											label={i18n.t('tickets.notes')}
											value={notes}
											className={classes.textField}
											onChange={(e) => this.setEquipmentUsed('notes', e.target.value, i)} />
									</Paper>
								</Grid>
							);
						})
					}
				</Grid>
			</Section>
		);
	}

	formatedDescriptionCategories (ticket) {
		if (ticket.descriptions && ticket.descriptions.length > 0) {
			let text = "";

			ticket.descriptions.map((desc, i) => {
				text = text + `${i18n.t(`tickets.descriptionCategories.${desc.category}`)}, `;
			});

			text = text.substring(0, text.length - 2)
			if (text.length > LENGTH_MAX_STRING) {
				return text.substring(0, LENGTH_MAX_STRING)
			} else return text
		} else return ""
	}

	onSelectTech(tech) {
		if (_find(this.state.workItems, (at) => at.tech.id === tech.id)) {
			return;
		}

		this.setState({
			workItems: [
				...this.state.workItems,
				{
					tech,
					startTime: moment('09:00', 'HH:mm').toDate(),
					endTime: moment('12:00', 'HH:mm').toDate()
				}
			],
			dirty: true
		});
	}

	onRemoveTech(index) {
		this.setState({
			workItems: [
				...this.state.workItems.slice(0, index),
				...this.state.workItems.slice(index + 1)
			],
			dirty: true
		});
	}

	setWorkItemTime(startOrEnd, time, index) {
		const workItem = this.state.workItems[index];

		if (
			startOrEnd === 'startTime' && time.isAfter(workItem.endTime) ||
			startOrEnd === 'endTime' && time.isBefore(workItem.startTime)
		) {
			this.setState({
				workItems: [
					...this.state.workItems.slice(0, index),
					{
						...this.state.workItems[index],
						startTime: time.toDate(),
						endTime: time.toDate()
					},
					...this.state.workItems.slice(index + 1)
				],
				dirty: true
			});
		} else {
			this.setState({
				workItems: [
					...this.state.workItems.slice(0, index),
					{
						...this.state.workItems[index],
						[startOrEnd]: time.toDate()
					},
					...this.state.workItems.slice(index + 1)
				],
				dirty: true
			});
		}
	}

	addEquipmentUsed() {
		this.setState({
			equipmentUsed: [
				...this.state.equipmentUsed,
				{
					name: '',
					price: 100,
					quantity: 1,
					notes: ''
				}
			],
			dirty: true
		});
	}

	removeEquipmentUsed(index) {
		this.setState({
			equipmentUsed: [
				...this.state.equipmentUsed.slice(0, index),
				...this.state.equipmentUsed.slice(index + 1),
			],
			dirty: true
		});
	}

	setEquipmentUsed(field, value, index) {
		const { equipmentUsed } = this.state;

		this.setState({
			equipmentUsed: [
				...equipmentUsed.slice(0, index),
				{
					...equipmentUsed[index],
					[field]: value
				},
				...equipmentUsed.slice(index + 1)
			],
			dirty: true
		});
	}

	validateEquipmentUsedPrice(i, value) {
		const { equipmentUsed } = this.state;

		let v = parseFloat(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		v = Math.round(v * 100) / 100;

		this.setState({
			equipmentUsed: [
				...equipmentUsed.slice(0, i),
				{
					...equipmentUsed[i],
					price: v
				},
				...equipmentUsed.slice(i + 1)
			],
			dirty: true
		});
	}

	validateEquipmentUsedQuantity(i, value) {
		const { equipmentUsed } = this.state;

		let v = parseInt(value);

		if (isNaN(v)) {
			v = 0;
		} else if (v < 0) {
			v = 0;
		}

		this.setState({
			equipmentUsed: [
				...equipmentUsed.slice(0, i),
				{
					...equipmentUsed[i],
					quantity: v
				},
				...equipmentUsed.slice(i + 1)
			],
			dirty: true
		});
	}

	handleCloseSnackbar(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			snackbar: false
		});
	}

	getBannerErrorMessage() {
		return (
			<ul>
				{
					this.state.errors.map((err, i) => <li key={i}>{err}</li>)
				}
			</ul>
		);
	}

	saveServiceCall(closeTicket) {
		const { client } = this.props;

		const errors = [];

		let variables = {};

		const mutation = this.serviceCall && this.serviceCall.id ? ADMIN_EDIT_SERVICE_CALL : ADMIN_CREATE_SERVICE_CALL;

		const {
			date,
			status,
			workItems,
			equipmentUsed
		} = this.state;

		if (this.serviceCall && this.serviceCall.id && status === 'approved' && !this.state.closeTicketModalOpen) {
			this.setState({ closeTicketModalOpen: true });
			return;
		}

		if ((!this.serviceCall || !this.serviceCall.id) && moment(date).endOf('day').isBefore(moment())) errors.push(i18n.t('serviceCalls.errors.serviceCallDate'));
		if ((status === 'booked' || status === 'completed' || status === 'approved') && !workItems.length) errors.push(i18n.t('serviceCalls.errors.atLeastOneWorkItem'));

		if (errors.length) {
			this.setState({
				errors
			});
			return;
		}

		variables = {
			ticket: this.ticket.id,
			date,
			status,
			workItems: workItems.map((item) => ({
				tech: item.tech.id,
				startTime: moment(item.startTime).toDate(),
				endTime: moment(item.endTime).toDate()
			})),
			equipmentUsed: equipmentUsed.map((n) => ({
				name: n.name,
				notes: n.notes,
				price: n.price,
				quantity: n.quantity
			}))
		};

		if (this.serviceCall && this.serviceCall.id) {
			variables.id = this.serviceCall.id;
			variables.closeTicket = closeTicket;
		}

		this.setState({
			loading: true,
			closeTicketModalOpen: false
		});

		client.mutate({
			mutation,
			variables,
			refetchQueries: [{
				query: TICKET_SERVICE_CALLS,
				variables: { ticketId: this.ticket.id }
			}],
		}).then((res) => {
			if (this.serviceCall && this.serviceCall.id) {
				this.setState({
					loading: false,
					dirty: false,
					snackbar: true
				});
			} else {
				this.setState({
					dirty: false
				}, () => {
					this.props.history.push('/serviceCalls/' + res.data.admin_createServiceCall.id, {
						title: i18n.t('title.serviceCall')
					});
				});
			}
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
			this.setState({
				loading: false
			});
		});
	}
}

export default compose(
	withQueries(function () {
		const { match, location, history } = this.props;

		let queries = {};

		if (match && match.params && match.params.id) {
			queries = {
				serviceCall: {
					query: SERVICE_CALL,
					variables: {
						id: match.params.id
					}
				}
			};
		} else if (location.state && location.state.ticketId) {
			queries = {
				ticket: {
					query: TICKET,
					variables: {
						id: location.state.ticketId
					}
				}
			};
		} else {
			history.push('/tickets');
		}

		return queries;
	}),
	withStyles(styles),
	withApollo,
	withUI
)(ServiceCall)
