export const project = `
	id
	name
	createdAt
	warrantyExpiration
	warrantyStart
	clients {
		id
		firstName
		lastName
		email
		phones {
			label
			number
		}
	}
	leadTech {
		id
		firstName
		lastName
		email
	}
	address {
		label
		line1
		line2
		city
		province
		country
		postalCode
	}
	notes {
		id
		note
		createdAt
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	internalNotes {
		id
		note
		createdAt
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	keyStore {
		id
		label
		value
	}
`;

export const projectNotes = `
	id
	notes {
		id
		note
		createdAt
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
	internalNotes {
		id
		note
		createdAt
		by {
			id
			firstName
			lastName
			email
			type
		}
	}
`;

export const projectKeyStore = `
	id
	keyStore {
		id
		label
		value
	}
`;

export const projects = `
	id
	name
	warrantyExpiration
	warrantyStart
	createdAt
	clients {
		id
		firstName
		lastName
		email
	}
	address {
		label
		line1
		line2
		city
		province
		country
		postalCode
	}
`;
