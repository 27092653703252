import withUI from 'utils/withUI';
import getGraphQLError from 'utils/getGraphQLError';
import { withApollo, compose } from 'react-apollo';
import {
	Typography,
} from '@material-ui/core';
import {
	Section,
	TextField,
	Button,
	Confirm
} from 'components';

import { DELETE_KEYSTORE } from 'graph/mutations';

class KeyStoreItem extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			deleteKeyStoreConfirmOpen: false
		};

		this.onView = this.onView.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.deleteKeyStore = this.deleteKeyStore.bind(this);
		this.copyText = this.copyText.bind(this);
	}

	render() {
		const { item } = this.props;
		const { visible } = this.state;

		const { label, value } = item;

		return (
			<Section style={{ paddingBottom: 4 }}>
				<Typography variant="caption">{label}</Typography>
				
				<TextField
					inputRef={(ref) => this.textField = ref}
					fullWidth
					value={visible ? value : '*************'}
					style={{ flex: 1 }}
					InputProps={{
						style: {
							fontFamily: 'monospace',
							fontSize: 14
						}
					}} />
				
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
					{
						!visible ?
						<Button
							variant="text"
							onClick={this.onView}
							color="default">{i18n.t('general.view')}</Button>
						:
						<Button
							variant="text"
							onClick={this.copyText}
							color="default">{i18n.t('general.copy')}</Button>
					}
					
					<Button
						variant="text"
						onClick={this.onRemove}
						color="default">{i18n.t('general.remove')}</Button>
				</div>

				<Confirm
					title={i18n.t('projects.deleteKeyStoreTitle')}
					body={i18n.t('projects.deleteKeyStoreBody')}
					open={this.state.deleteKeyStoreConfirmOpen}
					confirmButtonText={i18n.t('general.delete')}
					onConfirm={this.deleteKeyStore}
					onCancel={() => this.setState({ deleteKeyStoreConfirmOpen: false })} />
			</Section>
		)
	}

	onView() {
		this.setState({
			visible: !this.state.visible
		});
	}

	onRemove() {
		this.setState({
			deleteKeyStoreConfirmOpen: true
		});
	}

	copyText() {
		this.textField.select();
		document.execCommand('copy');
	}

	deleteKeyStore() {
		const { projectId, item, client } = this.props;

		client.mutate({
			mutation: DELETE_KEYSTORE,
			variables: {
				projectId,
				keyStoreId: item.id
			}
		}).then(() => {
			this.setState({
				deleteKeyStoreConfirmOpen: false
			})
		}).catch((e) => {
			this.props.ui.showError(getGraphQLError(e));
		});
	}
}

export default compose(
	withUI,
	withApollo
)(KeyStoreItem);